export const
environment = {
    production: false,
    BACK_URL: 'https://x9o1k3lp33.execute-api.us-west-2.amazonaws.com/devintent/mpp/',
    stage: 'dev',
    apiKey: 'FYMqL66fBJ7VGpGsIu4oC37v7w8R65TP5Gl2GV2o'
};


export const env = {
    production: false,
    BACK_URL: 'https://x9o1k3lp33.execute-api.us-west-2.amazonaws.com/devintent/mmp/',
    stage: 'dev',
    apiKey: 'FYMqL66fBJ7VGpGsIu4oC37v7w8R65TP5Gl2GV2o'
};

export const cdkEnv = {
    production: false,
    BACK_URL: 'https://fynh70pua3.execute-api.us-west-2.amazonaws.com/dev/v2/mmp/',
    stage: 'dev',
    apiKey: 'FYMqL66fBJ7VGpGsIu4oC37v7w8R65TP5Gl2GV2o'
}


export const cdkEnvV1 = {
    production: false,
    BACK_URL: 'https://fynh70pua3.execute-api.us-west-2.amazonaws.com/dev/mmp/',
    stage: 'dev',
    apiKey: 'FYMqL66fBJ7VGpGsIu4oC37v7w8R65TP5Gl2GV2o'

}

export const cdkEnvV3 = {
  production: false,
  BACK_URL: "https://fynh70pua3.execute-api.us-west-2.amazonaws.com/dev/v3/",
  stage: "dev",
  apiKey: "FYMqL66fBJ7VGpGsIu4oC37v7w8R65TP5Gl2GV2o",
};

export const cdkEnvironment = {
  production: true,
  BACK_URL: "https://fynh70pua3.execute-api.us-west-2.amazonaws.com/",
  MMP: "mmp/",
  V1: "",
  V2: "v2/",
  V3: "v3/",
  stage: "dev/",
  apiKey: "FYMqL66fBJ7VGpGsIu4oC37v7w8R65TP5Gl2GV2o",
};
