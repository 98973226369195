<div class="movanologin" [ngClass]="{'movanoDarkLog': darkMode}">
  <div class="movanologin_switch">
    <div class="movano_switch" style="position: relative; ">
    <!--   <label class="switch">
        <input [checked]="darkMode" type="checkbox" (change)="changeTheme()">
        <span class="slider round"></span>
      </label> -->
    </div>
 <!--    <svg class="movanoHeader_Functions_Icon" viewBox="0 0 11 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.77761 8.76924C5.77761 5.86289 7.56864 3.37988 10.1005 2.36225C10.825 2.06917 10.7111 0.986418 9.94582 0.815457C9.0503 0.620072 8.0978 0.571226 7.11274 0.701482C3.44114 1.18994 0.502227 4.21026 0.119599 7.89001C-0.385145 12.7828 3.433 16.9103 8.21992 16.9103C8.81421 16.9103 9.38408 16.8451 9.94582 16.723C10.7192 16.5521 10.8413 15.4774 10.1086 15.1762C7.48723 14.126 5.76947 11.586 5.77761 8.76924Z"
        [ngStyle]="{fill : darkMode ? '#FFFFFF' : 'var(--main-blue-dark)', boxShadow: darkMode ? '0px 0px  5px rgba(255, 255, 255, 0.5)' : 'none'}" />
    </svg> -->
  </div>
  <div class="movanologin_box">
    <div class="movanologin_box_imgHeader">
      <img src="{{LITERALS.MOVANO.IMG_LOGO}}">
      <span>MOVANO</span>
    </div>
    <!-- <img class="movanologin_box_imgHeader" src="{{LITERALS.MOVANO.IMG_HEADER}}" alt=""> -->
    <div class="movanologin_desktop">
      <div class="movanologin_desktop_tittle">
        <div class="movanologin_desktop_box">
        <img src="{{LITERALS.MOVANO.IMG_LOGO}}">
        <svg width="10.2vw" height="1.6667vw" viewBox="0 0 147 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M20.335 23.0444L20.3038 7.13004L12.4091 20.392H10.9737L3.07889 7.22366V23.0444H0.083252V1.20117H2.64203L11.7538 16.5538L20.7407 1.20117H23.2995L23.3307 23.0444H20.335Z"
            fill="#054280" />
          <path
            d="M39.7467 23.294C37.5416 23.294 35.5549 22.8155 33.7866 21.8586C32.0184 20.8809 30.6246 19.5391 29.6052 17.8332C28.6067 16.1274 28.1074 14.2239 28.1074 12.1228C28.1074 10.0217 28.6067 8.11819 29.6052 6.41234C30.6246 4.70649 32.0184 3.37509 33.7866 2.41815C35.5549 1.44041 37.5416 0.951538 39.7467 0.951538C41.931 0.951538 43.9073 1.44041 45.6756 2.41815C47.4438 3.37509 48.8272 4.70649 49.8258 6.41234C50.8243 8.09739 51.3236 10.0009 51.3236 12.1228C51.3236 14.2447 50.8243 16.1586 49.8258 17.8644C48.8272 19.5495 47.4438 20.8809 45.6756 21.8586C43.9073 22.8155 41.931 23.294 39.7467 23.294ZM39.7467 20.5168C41.3485 20.5168 42.784 20.1528 44.0529 19.4247C45.3427 18.6965 46.3517 17.698 47.0798 16.429C47.8287 15.1392 48.2032 13.7038 48.2032 12.1228C48.2032 10.5417 47.8287 9.11674 47.0798 7.84775C46.3517 6.55796 45.3427 5.54901 44.0529 4.8209C42.784 4.0928 41.3485 3.72875 39.7467 3.72875C38.1449 3.72875 36.6887 4.0928 35.3781 4.8209C34.0883 5.54901 33.0689 6.55796 32.32 7.84775C31.5919 9.11674 31.2279 10.5417 31.2279 12.1228C31.2279 13.7038 31.5919 15.1392 32.32 16.429C33.0689 17.698 34.0883 18.6965 35.3781 19.4247C36.6887 20.1528 38.1449 20.5168 39.7467 20.5168Z"
            fill="#054280" />
          <path
            d="M74.3645 1.20117L64.7847 23.0444H61.6955L52.0845 1.20117H55.4546L63.3181 19.1438L71.2441 1.20117H74.3645Z"
            fill="#054280" />
          <path
            d="M90.3664 17.5836H78.7583L76.3555 23.0444H73.1415L83.0333 1.20117H86.1226L96.0456 23.0444H92.7692L90.3664 17.5836ZM89.2742 15.0872L84.5623 4.38404L79.8505 15.0872H89.2742Z"
            fill="#054280" />
          <path
            d="M118.089 1.20117V23.0444H115.53L102.425 6.75559V23.0444H99.3041V1.20117H101.863L114.969 17.49V1.20117H118.089Z"
            fill="#054280" />
          <path
            d="M134.488 23.294C132.283 23.294 130.296 22.8155 128.528 21.8586C126.76 20.8809 125.366 19.5391 124.346 17.8332C123.348 16.1274 122.849 14.2239 122.849 12.1228C122.849 10.0217 123.348 8.11819 124.346 6.41234C125.366 4.70649 126.76 3.37509 128.528 2.41815C130.296 1.44041 132.283 0.951538 134.488 0.951538C136.672 0.951538 138.649 1.44041 140.417 2.41815C142.185 3.37509 143.569 4.70649 144.567 6.41234C145.566 8.09739 146.065 10.0009 146.065 12.1228C146.065 14.2447 145.566 16.1586 144.567 17.8644C143.569 19.5495 142.185 20.8809 140.417 21.8586C138.649 22.8155 136.672 23.294 134.488 23.294ZM134.488 20.5168C136.09 20.5168 137.525 20.1528 138.794 19.4247C140.084 18.6965 141.093 17.698 141.821 16.429C142.57 15.1392 142.944 13.7038 142.944 12.1228C142.944 10.5417 142.57 9.11674 141.821 7.84775C141.093 6.55796 140.084 5.54901 138.794 4.8209C137.525 4.0928 136.09 3.72875 134.488 3.72875C132.886 3.72875 131.43 4.0928 130.119 4.8209C128.83 5.54901 127.81 6.55796 127.061 7.84775C126.333 9.11674 125.969 10.5417 125.969 12.1228C125.969 13.7038 126.333 15.1392 127.061 16.429C127.81 17.698 128.83 18.6965 130.119 19.4247C131.43 20.1528 132.886 20.5168 134.488 20.5168Z"
            fill="#054280" />
        </svg>
      </div>
      <span class="movanologin_desktop_Slogan">
        <span>Ultimate </span> <span>you.</span>
      </span>
    </div>
      <img class="movanologin_desktop_imgRings" src="{{LITERALS.MOVANO.IMG_RINGS}}">
    </div>
    <form class="movanologin_form" [formGroup]="loginFormGroup">
      <div class="movanologin_form_front">
        <h2>Welcome</h2>
        <input class="movanologin_form_front_text"type="text" formControlName="email" placeholder="Email address">
        <input class="movanologin_form_front_pass"type="password" formControlName="password" placeholder="password">
        <app-movano-button *ngIf="!loginLoading"  (click)="loginWithCognito()" [buttonText]="'Login'" [size]="'medium'"></app-movano-button>
        <div *ngIf="loginLoading">
          <p-progressSpinner styleClass="custom-spinner" strokeWidth="2"
            animationDuration="1.5s">
          </p-progressSpinner>
        </div>
        <a (click)="recoverPass()">Forgot password?</a>
      </div>
    </form>
  </div>
</div>
