<div class="movanoActivity" [ngClass]="{'movanoActivity_Dark' : darkMode}">
  <!-- <span class="movanoActivity_Tittle">{{front ? 'Activity' : 'Wearable Syncs'}}</span> -->
  <span class="movanoActivity_Tittle" style="padding-left: 250px; opacity: 0.5;">{{'LAST MONTH'}}</span>
  <div class="movanoActivity_Components" style="padding-left: 100px;">
    <span class="movanoActivity_Tittle" style="padding-left: 100px;padding-top: 30px;" >{{'Workouts'}}</span>
    <div class="movanoActivity_Components_Info" style="padding-top: 50px;">
      <div class="movanoActivity_Components_Info_Block">
        <span [style.color]="darkMode ? '#7797B8' : '#284767'" class="movanoActivity_Components_Info_Text1">
          Best {{'day'}}
        </span>
        <span [style.color]="darkMode ? '#0DCCFF' : '#054280'" class="movanoActivity_Components_Info_Title1" style="font-size: 20px;">
         20 Aug
        </span>
        <span [style.color]="darkMode ? '#0DCCFF' : '#054280'" class="movanoActivity_Components_Info_Text2">
          99 syncs
        </span>
      </div>
      <div class="movanoActivity_Components_Info_Block">
        <span [style.color]="darkMode ? '#7797B8' : '#284767'" class="movanoActivity_Components_Info_Text1">
          Average syncs
        </span>
        <span [style.color]="darkMode ? '#FFFFFF' : '#0B84FF'" class="movanoActivity_Components_Info_Title2">
          73.22 per day
        </span>
<!--         <span [style.color]="darkMode ? '#FFFFFF' : '#0B84FF'" class="movanoActivity_Components_Info_Text2">
          {{this.countMonth}}/month
        </span> -->
      </div>
    </div>

    <div class="movanoActivity_Components" style="padding-left: 200px;">
      <span class="movanoActivity_Tittle" style="padding-left: 460px;padding-top: 30px;">{{'Wearable Syncs'}}</span>
      <div class="movanoActivity_Components_Info" style="padding-top: 50px;">
        <div class="movanoActivity_Components_Info_Block">
          <span [style.color]="darkMode ? '#7797B8' : '#284767'" class="movanoActivity_Components_Info_Text1">
            Best {{'day'}}
          </span>
          <span [style.color]="darkMode ? '#0DCCFF' : '#054280'" class="movanoActivity_Components_Info_Title1" style="font-size: 20px;">
           FRI
          </span>
          <span [style.color]="darkMode ? '#0DCCFF' : '#054280'" class="movanoActivity_Components_Info_Text2">
            28 syncs
          </span>
        </div>
        <div class="movanoActivity_Components_Info_Block">
          <span [style.color]="darkMode ? '#7797B8' : '#284767'" class="movanoActivity_Components_Info_Text1">
            Average syncs
          </span>
          <span [style.color]="darkMode ? '#FFFFFF' : '#0B84FF'" class="movanoActivity_Components_Info_Title2">
            1485 per day
          </span>
  <!--         <span [style.color]="darkMode ? '#FFFFFF' : '#0B84FF'" class="movanoActivity_Components_Info_Text2">
            {{this.countMonth}}/month
          </span> -->
        </div>
      </div>


    <!-- <div class="movanoActivity_Components_ChartDividers_Icons" *ngIf="front">
      <svg
      style="position: relative; top: 5em; object-position: 50% 0%; object-fit: none;"
        viewBox="0 0 22 4" fill="none"
      xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.5" d="M5.76 4.83999L3.96 3.04999L2.55 4.45999L4.34 6.24999L5.76 4.83999ZM3 10.5H0V12.5H3V10.5ZM12 0.549988H10V3.49999H12V0.549988ZM19.45 4.45999L18.04 3.04999L16.25 4.83999L17.66 6.24999L19.45 4.45999ZM16.24 18.16L18.03 19.96L19.44 18.55L17.64 16.76L16.24 18.16ZM19 10.5V12.5H22V10.5H19ZM11 5.49999C7.69 5.49999 5 8.18999 5 11.5C5 14.81 7.69 17.5 11 17.5C14.31 17.5 17 14.81 17 11.5C17 8.18999 14.31 5.49999 11 5.49999ZM10 22.45H12V19.5H10V22.45ZM2.55 18.54L3.96 19.95L5.75 18.15L4.34 16.74L2.55 18.54Z" fill="#FF830D"/>
      </svg>
      <svg
      style="position: relative; object-position: 50% 0%; object-fit: none;"
        viewBox="0 0 22 23" fill="none"
      xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.5" d="M5.76 4.83999L3.96 3.04999L2.55 4.45999L4.34 6.24999L5.76 4.83999ZM3 10.5H0V12.5H3V10.5ZM12 0.549988H10V3.49999H12V0.549988ZM19.45 4.45999L18.04 3.04999L16.25 4.83999L17.66 6.24999L19.45 4.45999ZM16.24 18.16L18.03 19.96L19.44 18.55L17.64 16.76L16.24 18.16ZM19 10.5V12.5H22V10.5H19ZM11 5.49999C7.69 5.49999 5 8.18999 5 11.5C5 14.81 7.69 17.5 11 17.5C14.31 17.5 17 14.81 17 11.5C17 8.18999 14.31 5.49999 11 5.49999ZM10 22.45H12V19.5H10V22.45ZM2.55 18.54L3.96 19.95L5.75 18.15L4.34 16.74L2.55 18.54Z" fill="#FF830D"/>
      </svg><svg
      style="position: relative; top: 5em; object-position: 50% 0%; object-fit: none;"
        viewBox="0 0 22 4" fill="none"
      xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.5" d="M5.76 4.83999L3.96 3.04999L2.55 4.45999L4.34 6.24999L5.76 4.83999ZM3 10.5H0V12.5H3V10.5ZM12 0.549988H10V3.49999H12V0.549988ZM19.45 4.45999L18.04 3.04999L16.25 4.83999L17.66 6.24999L19.45 4.45999ZM16.24 18.16L18.03 19.96L19.44 18.55L17.64 16.76L16.24 18.16ZM19 10.5V12.5H22V10.5H19ZM11 5.49999C7.69 5.49999 5 8.18999 5 11.5C5 14.81 7.69 17.5 11 17.5C14.31 17.5 17 14.81 17 11.5C17 8.18999 14.31 5.49999 11 5.49999ZM10 22.45H12V19.5H10V22.45ZM2.55 18.54L3.96 19.95L5.75 18.15L4.34 16.74L2.55 18.54Z" fill="#FF830D"/>
      </svg>
      <svg
      style="position: relative; object-position: 50% 0%; object-fit: none;" viewBox="0 0 13 20" fill="none"
      xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.5" d="M7.01 9.99997C7.01 6.42997 9.21 3.37997 12.32 2.12997C13.21 1.76997 13.07 0.43997 12.13 0.22997C11.03 -0.0100302 9.85999 -0.0700303 8.64999 0.0899697C4.13999 0.68997 0.529995 4.39997 0.059995 8.91997C-0.560005 14.93 4.13 20 10.01 20C10.74 20 11.44 19.92 12.13 19.77C13.08 19.56 13.23 18.24 12.33 17.87C9.10999 16.58 7 13.46 7.01 9.99997Z" fill="#7797B8"/>
      </svg>
      <svg
      style="position: relative; top: 5em; object-position: 50% 0%; object-fit: none;"
        viewBox="0 0 22 4" fill="none"
      xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.5" d="M5.76 4.83999L3.96 3.04999L2.55 4.45999L4.34 6.24999L5.76 4.83999ZM3 10.5H0V12.5H3V10.5ZM12 0.549988H10V3.49999H12V0.549988ZM19.45 4.45999L18.04 3.04999L16.25 4.83999L17.66 6.24999L19.45 4.45999ZM16.24 18.16L18.03 19.96L19.44 18.55L17.64 16.76L16.24 18.16ZM19 10.5V12.5H22V10.5H19ZM11 5.49999C7.69 5.49999 5 8.18999 5 11.5C5 14.81 7.69 17.5 11 17.5C14.31 17.5 17 14.81 17 11.5C17 8.18999 14.31 5.49999 11 5.49999ZM10 22.45H12V19.5H10V22.45ZM2.55 18.54L3.96 19.95L5.75 18.15L4.34 16.74L2.55 18.54Z" fill="#FF830D"/>
      </svg>
    </div>
    <div echarts [options]="option_activity??{}" class="movanoActivity_Components_Chart"></div>
  </div> -->


  <!-- <div class="movanoActivity_Buttons">
    <button [disabled]="front" (click)="buttonHandle(true)">
      <svg width="max(0.69vw, 10px)" height="max(0.83vw, 12px)" viewBox="0 0 10 12" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M9.33334 10.3166L9.33334 1.68329C9.33334 1.02495 8.60834 0.624954 8.05 0.983288L1.26667 5.29995C0.750001 5.62495 0.750001 6.37495 1.26667 6.70829L8.05 11.0166C8.60834 11.375 9.33334 10.975 9.33334 10.3166Z"
          [ngStyle]="{fill : (darkMode) ? '#D1F5FF' : '#284767', opacity: (front)  ? 0.5 : 1 }" />
      </svg>
    </button>

    <button [disabled]="!front" (click)="buttonHandle(false)">
      <svg width="max(0.69vw, 10px)" height="max(0.83vw, 12px)" viewBox="0 0 10 12" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0.666664 1.68332V10.3167C0.666664 10.975 1.39166 11.375 1.95 11.0167L8.73333 6.69999C9.25 6.37499 9.25 5.62499 8.73333 5.29165L1.95 0.98332C1.39166 0.624986 0.666664 1.02499 0.666664 1.68332Z"
          [ngStyle]="{fill : (darkMode) ? '#D1F5FF' : '#284767', opacity: (!front)  ? 0.5 : 1 }" />
      </svg>
    </button>
  </div> -->
</div>
