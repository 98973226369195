import { NgIf } from '@angular/common';
import { Component, DoCheck, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-movano-button',
  templateUrl: './movano-button.component.html',
  styleUrls: ['./movano-button.component.scss']
})
export class MovanoButtonComponent {

  @Input() percentage : number;
  @Input() buttonText : string;
  @Input() size : string;
  @Input() color : string;
  @Input() outlined : boolean;
  @Input() iconButton : boolean;
  @Input() disable ?: boolean;
  handler : any;

  constructor(){
    this.buttonText = '';
    this.size = 'medium';
    this.color = 'blue';
    this.outlined = false;
    this.iconButton = false;
    this.percentage = -1; 
  }
}
