<div class="movanoLast" [style.background]="lstCmpSelected.bck" [ngClass]="{'movanoLast_Dark' : darkMode}">
  <div class="movanoLast_Txt">
    <!--TITLE-->
    <!------------------------------------------------------------------------------------->
    <div class="movanoLast_Tittle">
      <span>Last month {{dashboardType}}</span>
      <span>{{lstCmpSelected.total}}</span>
      <span>{{lstCmpSelected.percentage| number: '1.0-0'}}% of total devices</span>
    </div>
    <!------------------------------------------------------------------------------------->

    <!--ATRIBUTES-->
    <!------------------------------------------------------------------------------------->
    <div class="movanoLast_Legend">
      <!-- Iterate over objects in lstCmpSelected.cmp -->
      <div *ngFor="let legendObj of lstCmpSelected.cmp; let i = index"
        class="movanoLast_Legend_Atribute"
        [ngClass]="{'movanoLast_Legend_AtributeHidden' : legendObj.data! <= 0}"
        [style.color]="legendObj.colorLight">
        <!-- Icon -->
        <img *ngIf="legendObj.data! > 0" [src]="ICONS + dashboardType + i + (darkMode ? '_Dark.svg' : '.svg')" style="height: max(1vw, 14px);">
        <!-- Value -->
        <span *ngIf="legendObj.data! > 0" [style.color]="(darkMode ? legendObj.colorLight : legendObj.colorDark)">{{legendObj.data??0 | number}}</span>
        <!-- Indicator -->
        <div *ngIf="legendObj.data! > 0">
          <svg *ngIf="legendObj.data! > legendObj.lastData!" width="max(0.9vw, 13px)" height="max(0.9vw, 13px)" viewBox="0 0 13 9"
            fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.317566" y="0.482422" width="12.3896" height="2.201" rx="1.1005"
              [style.fill]="legendObj.colorLight" />
            <rect x="0.317566" y="5.98535" width="12.3896" height="2.201" rx="1.1005"
              [style.fill]="legendObj.colorLight" />
          </svg>
          <svg *ngIf="legendObj.data! === legendObj.lastData!" width="max(0.9vw, 13px)" height="max(0.9vw, 13px)" viewBox="0 0 12 9"
            fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M1.68332 8.63611L10.3167 8.63611C10.975 8.63611 11.375 7.91111 11.0167 7.35277L6.69999 0.569442C6.37499 0.0527751 5.62499 0.0527752 5.29165 0.569442L0.98332 7.35278C0.624986 7.91111 1.02499 8.63611 1.68332 8.63611Z"
              [style.fill]="legendObj.colorLight" />
          </svg>
          <svg *ngIf="legendObj.data! < legendObj.lastData!" width="max(0.9vw, 13px)" height="max(0.9vw, 13px)" viewBox="0 0 12 10"
            fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M10.3167 0.96936L1.68335 0.96936C1.02501 0.96936 0.625013 1.69436 0.983346 2.25269L5.30001 9.03603C5.62501 9.55269 6.37501 9.55269 6.70835 9.03603L11.0167 2.25269C11.375 1.69436 10.975 0.96936 10.3167 0.96936Z"
              [style.fill]="legendObj.colorLight" />
          </svg>
        </div>
      </div>
    </div>
    <!------------------------------------------------------------------------------------->
  </div>
  <!--BARS-->
  <!------------------------------------------------------------------------------------->
  <div class="movanoLast_Bars" [ngClass]="{'movanoLast_Bars_Divided' : dividedBars.includes(dashboardType)}">
    <span>{{this.getTodayDate()}}</span>
    <!-- Main Bar -->
    <!-- The main bar have the other ones -->
    <div class="movanoLast_Bars_Bar" [style.background]="lstCmpSelected.cmp[0].barBckLight">
      <span [style.color]="lstCmpSelected.cmp[0].colorLight">{{percentageBar0 | number:
        '1.0-0'}}%</span>
      <!-- First Bar -->
      <div *ngIf="lstCmpSelected.cmp[1].data!" class="movanoLast_Bars_Bar_Blue"
        [style.height]="percentageBar1+'%'"
        [style.background]="lstCmpSelected.cmp[1].barBckLight">
        <span [style.color]="lstCmpSelected.cmp[1].colorLight"
          *ngIf="percentageBar1ToShow > 8">
          {{percentageBar1ToShow | number:'1.0-0'}}%
        </span>
        <span [style.color]="lstCmpSelected.cmp[1].colorLight" *ngIf="percentageBar1ToShow <= 8"
          style="bottom: 0px !important; top: auto !important">
          {{percentageBar1ToShow | number:'1.0-0'}}%
        </span>
      </div>
      <!-- Second Bar -->
      <div *ngIf="lstCmpSelected.cmp[2].data != 0" class="movanoLast_Bars_Bar_Red"
        [style.height]="percentageBar2+'%'"
        [style.background]="lstCmpSelected.cmp[2].barBckLight">
        <span [style.color]="lstCmpSelected.cmp[2].colorLight"
          *ngIf="percentageBar2ToShow > 8">
          {{percentageBar2ToShow | number:'1.0-0'}}%
        </span>
        <span [style.color]="lstCmpSelected.cmp[2].colorLight" *ngIf="percentageBar2ToShow <= 8"
          style="bottom: 0px !important; top: auto !important">
          {{percentageBar2ToShow | number:'1.0-0'}}%
        </span>

      </div>
    </div>
  </div>
  <!------------------------------------------------------------------------------------->
</div>
