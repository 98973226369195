import { AfterViewInit, Component, OnInit, Input } from '@angular/core';
import { ntfListTable } from 'src/app/shared/movano-interfaces';
import { DarkModeService } from '../../dark-mode.service';
import * as lit from '../../shared/movano-literals';
import { Subscription } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { MovanoService } from 'src/app/movano.service';
import { MatDialog } from '@angular/material/dialog';
import { NtfNotificationsListMessageComponent } from './message/ntf-notifications-list-message.component';
import { Renderer2, ElementRef } from '@angular/core';
import { NotifiationListSideFilterComponent } from './side-filter/notifiation-list-side-filter.component';

@Component({
  selector: 'ntf-notification-list',
  templateUrl: './ntf-notification-list.component.html',
  styleUrls: ['./ntf-notification-list.component.scss']
})
export class NtfNotificationListComponent implements OnInit {

  protected LITERALS = lit;
  protected darkMode: boolean = false;
  private darkModeSub: Subscription = new Subscription();
  protected menuDisplay: boolean = false;
  protected userDisplay: boolean = false;
  protected loadingData: boolean = false;
  protected filterDisplay: boolean = false;
  protected ntfLists: ntfListTable[] = [];
  filteredUsers: ntfListTable[] = [];
  isFiltered: boolean = false;
  protected type: string = 'notifications';
  protected pageSelected: number = 0;
  protected firstPage: number = 0;
  protected lastPage: number = 0;
  protected rows: number = 16;
  protected dataAvailable : boolean = false;
  public jobId?: string;
  public name?: string; 
  



  protected statusColor : Map<string, string> = new Map<string, string>([
    ['dev', 'green'],
    ['staging', 'orange'],
    ['prod', 'blue']
  ])

  constructor(
    protected dialog: MatDialog,
    private route: ActivatedRoute,
    protected movanoService: MovanoService,
    private renderer: Renderer2, private el: ElementRef,
    private darkModeSvc: DarkModeService) {
    this.darkModeSub = this.darkModeSvc.getVariable().subscribe((val: any) => {
      this.darkMode = val;
    });
  
  
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.jobId = params['jobId'];
      console.log(`Job ID from params: ${this.jobId}`);
    });

    this.route.queryParams.subscribe(params => {
      this.name = params['insightAppName'];
      console.log(`insightAppName from params: ${this.name}`);
    });
    
  }

  updatePaginator(data: number[]) {
    this.rows = data[0];
    this.pageSelected = data[1];
  }

  onNtfListsChanged(updatedNtfLists: ntfListTable[]) {
    this.ntfLists = updatedNtfLists;
  }
  onLoadingDataChange(loading: boolean) {
    this.loadingData = loading;
}

onDataAvailable(data: boolean){
  this.dataAvailable = data;
}
onFilter(event: any) {
  this.filteredUsers = event.filteredValue;
  this.isFiltered = this.filteredUsers.length !== this.ntfLists.length;
}


  protected openUserPanel(notification: ntfListTable) {
    this.dialog.open(NtfNotificationsListMessageComponent, {
      data: { notification }
    });
  }

  getNotificationStatus(sentCustomer: boolean) {
    if (sentCustomer) {
      return { text: 'Yes', color: 'rgba(0, 178, 119, 1)' };
    } else {
      return { text: 'No', color: 'rgba(227, 20, 130, 1)' };
    }
  } 
}
