import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent {

  protected lastPage: number = 0;
  protected firstPage: number = 0;

  @Input() pageSelected: number = 0;
  @Input() rows: number = 14;
  @Input() state: any;
  @Input() lenght: number = 0;
  @Input() type: string = '';
  @Output() updatePaginator = new EventEmitter<[number, number]>();

  previous() {
    if ((this.pageSelected / this.rows) <= 0) return;

    this.pageSelected = this.pageSelected - this.rows;

    const visiblePageCount = 4;

    if ((this.pageSelected / this.rows) < (this.firstPage / this.rows)) {
      this.firstPage = this.firstPage - this.rows;
    }

    this.updatePaginator.emit([this.rows, this.pageSelected]);
  }

  next(_totalPages: number) {
    if ((this.pageSelected / this.rows) >= _totalPages - 1) return;

    this.pageSelected = this.pageSelected + this.rows;

    const visiblePageCount = 4;

    if ((this.pageSelected / this.rows) > (this.firstPage / this.rows) + visiblePageCount - 1) {
      this.firstPage = this.firstPage + this.rows;
    }

    this.updatePaginator.emit([this.rows, this.pageSelected]);
  }

  selecPage(_page: number) {
    this.pageSelected = _page * this.rows;

    const visiblePageCount = 4;

    if (_page >= (this.firstPage / this.rows) + visiblePageCount) {
      this.firstPage = (_page - visiblePageCount + 1) * this.rows;
    } else if (_page < (this.firstPage / this.rows)) {
      this.firstPage = _page * this.rows;
    }

    this.updatePaginator.emit([this.rows, this.pageSelected]);
  }
}
