import { Component, TemplateRef, ViewChild, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { DarkModeService } from 'src/app/dark-mode.service';
import { MovanoService } from 'src/app/movano.service';
import { mapTable, mapTableDevices } from 'src/app/shared/movano-interfaces';
import { Table } from 'primeng/table';


@Component({
  selector: 'app-stats-map',
  templateUrl: './stats-map.component.html',
  styleUrls: ['./stats-map.component.scss']
})
export class StatsMapComponent {


  

  protected mapList: mapTable[] = [];
  protected mapListDevices: mapTableDevices[] = [];

  protected loadingMapList : boolean = true;
  protected darkMode: boolean = false;
  private darkModeSub: Subscription = new Subscription();
  @Input() templateName!: string;

  @ViewChild('mapUsers', { static: true }) mapUsers!: TemplateRef<any>;
  @ViewChild('mapDevices', { static: true }) mapDevices!: TemplateRef<any>;

  
  
  constructor(private darkModeSvc: DarkModeService, protected movanoService: MovanoService,)
    {
    this.darkModeSub = this.darkModeSvc.getVariable().subscribe((val: any) => {
      this.darkMode = val;
    });
     this.getMapDataList(); 
     this.getMapDevicesList();

  }

   async getMapDataList() {
    this.loadingMapList = true;
    this.movanoService.getMapList().subscribe((res: any) => {
      this.mapList = res.sort((a: any, b: any) => b.total - a.total);
      this.loadingMapList = false;
      console.log(res);
    });
  } 

  async getMapDevicesList() {
    this.loadingMapList = true;
    this.movanoService.getMapDevicesList().subscribe((res: any) => {
        this.mapListDevices = res.sort((a: any, b: any) => b.devices - a.devices);
        this.loadingMapList = false;
        console.log(this.mapListDevices);
    });
}

  getTemplate(): TemplateRef<any> {
    switch (this.templateName) {
      case 'mapUsers':
        return this.mapUsers;
      case 'mapDevices':
        return this.mapDevices;
      // Add more cases for additional templates
      default:
        return this.mapUsers;  
    }
  }


}
