<!-- <movano-header
[table]="userTable"
[userDisplay]="userDisplay"
[menuDisplay]="menuDisplay"
[title]="LITERALS.NOTIFICATIONS.TITTLE"
[filterDisplay]="filterDisplay"></movano-header> -->
<!-- <div class="movanoUsers" [ngClass]="{'movanoDark': darkMode}">
  <div [ngStyle]="{display: (!loadingUserList) ? 'block' : 'none'}" class="movanoTableBox">
    <p-table id="userTable" #userTable class="movano-Table" [globalFilterFields]="['user_id','gender', 'country', 'age', 'register_date', 'last_sync']"
      [value]="users" [paginator]="true" [rows]="rows" [first]="pageSelected">
      <ng-template pTemplate="header">
        <tr [ngClass]="'movanoTable_Header'">
          <th>{{LITERALS.NOTIFICATIONS.TABLE_USER_ID}}</th>
          <th>{{LITERALS.NOTIFICATIONS.TABLE_GENDER}}</th>
          <th>{{LITERALS.NOTIFICATIONS.TABLE_AGE}}</th>
          <th>{{LITERALS.NOTIFICATIONS.TABLE_REGISTER_DATE}}</th>
          <th>{{LITERALS.NOTIFICATIONS.TABLE_COUNTRY}}</th>
          <th>{{LITERALS.NOTIFICATIONS.TABLE_STATE}}</th>
          <th>{{LITERALS.NOTIFICATIONS.TABLE_LAST_SYNC}}</th>
          <th>{{LITERALS.NOTIFICATIONS.TABLE_DEVICES}}</th>
          <th></th>
          <th></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-product let-i="rowIndex">
        <tr [ngStyle]="{
          'background' : darkMode ?
          i%2 == 0 ? '#05294d' : '#05305B' :
          i%2 == 0 ? '#f3f6f9' : 'white'
        }" [ngClass]="'movanoTable_Obj'">
          <td>{{product.user_id}}</td>
          <td>
            <div *ngIf="product.gender === 'F' || product.gender === 'W'"
              class="movanoTable_Obj_Badge movanoTable_Obj_Badge_Female">{{LITERALS.MOVANO.FEMININE}}</div>
            <div *ngIf="product.gender === 'M'" class="movanoTable_Obj_Badge">{{LITERALS.MOVANO.MASCULINE}}</div>
            <div *ngIf="product.gender === 'O'" class="movanoTable_Obj_Badge movanoTable_Obj_Badge_NoBinary">
              {{LITERALS.MOVANO.NON_BINARY}}</div>
          </td>
          <td>{{product.age}}</td>
          <td>{{(product.register_date > 0) ? (product.register_date * 1000 | date:'shortDate') : '' }}</td>
          <td></td>
          <td>{{product.state}}</td>
          <td>{{(product.last_sync > 0) ? (product.last_sync * 1000 | date:'shortDate') : '' }}</td>
          <td>{{product.devices}}</td>
          <td class="movanoTable_click" (click)="openUserDevicesPanel(product.user_id)">
            <svg width="max(1vw,14px)" height="max(1vw,14px)" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g id="remove_red_eye" clip-path="url(#clip0_1510_2444)">
                <path id="Vector"
                  d="M7.00004 3.79167C9.21087 3.79167 11.1825 5.03417 12.145 7C11.1825 8.96583 9.21671 10.2083 7.00004 10.2083C4.78337 10.2083 2.81754 8.96583 1.85504 7C2.81754 5.03417 4.78921 3.79167 7.00004 3.79167ZM7.00004 2.625C4.08337 2.625 1.59254 4.43917 0.583374 7C1.59254 9.56083 4.08337 11.375 7.00004 11.375C9.91671 11.375 12.4075 9.56083 13.4167 7C12.4075 4.43917 9.91671 2.625 7.00004 2.625ZM7.00004 5.54167C7.80504 5.54167 8.45837 6.195 8.45837 7C8.45837 7.805 7.80504 8.45833 7.00004 8.45833C6.19504 8.45833 5.54171 7.805 5.54171 7C5.54171 6.195 6.19504 5.54167 7.00004 5.54167ZM7.00004 4.375C5.55337 4.375 4.37504 5.55333 4.37504 7C4.37504 8.44667 5.55337 9.625 7.00004 9.625C8.44671 9.625 9.62504 8.44667 9.62504 7C9.62504 5.55333 8.44671 4.375 7.00004 4.375Z"
                  [ngStyle]="{fill : darkMode ? 'white' : '#054280'}" />
              </g>
              <defs>
                <clipPath id="clip0_1510_2444">
                  <rect width="14" height="14" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </td>
          <td class="movanoTable_click" (click)="openUserPanel(product.user_id)">
            <svg viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g id="Group 721">
                <path id="Vector 23" d="M1 7V12" stroke="#0B84FF" stroke-width="2" stroke-linecap="round"/>
                <path id="Vector 24" d="M6 4L6 12" stroke="#0B84FF" stroke-width="2" stroke-linecap="round"/>
                <path id="Vector 25" d="M11 1L11 12" stroke="#0B84FF" stroke-width="2" stroke-linecap="round"/>
              </g>
            </svg>
          </td>
        </tr>
      </ng-template>

      <ng-template pTemplate="paginatorright" let-state>
        <div class="movanoPaginator">
          <svg (click)="previous()" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M11.3333 5.33335L3.21996 5.33335L6.94663 1.60669L5.99996 0.666687L0.666626 6.00002L5.99996 11.3334L6.93996 10.3934L3.21996 6.66669L11.3333 6.66669V5.33335Z"
              [ngStyle]="{fill : state.page > 0 ? '#0B84FF' : '#AABED3'}" />
          </svg>
          <td
            [ngClass]="(firstPage + (rows * 0)) === pageSelected ? 'movanoPaginator_PageSlcFirst' : 'movanoPaginator_PageSlc'"
            (click)="selecPage((firstPage/rows))">
            {{(firstPage/rows) + 1 | number:'1.0-0'}}</td>
          <td *ngIf="(firstPage/rows) + 2 <=  state.pageCount"
            [ngClass]="(firstPage + (rows * 1)) === pageSelected ? 'movanoPaginator_PageSlcFirst' : 'movanoPaginator_PageSlc'"
            (click)="selecPage((firstPage/rows) + 1)">
            {{(firstPage/rows) + 2 | number:'1.0-0'}}</td>
          <td *ngIf="(firstPage/rows) + 3 <=  state.pageCount"
            [ngClass]="(firstPage + (rows * 2)) === pageSelected ? 'movanoPaginator_PageSlcFirst' : 'movanoPaginator_PageSlc'"
            (click)="selecPage((firstPage/rows) + 2)">
            {{(firstPage/rows) + 3 | number:'1.0-0'}}</td>
          <td *ngIf="(firstPage/rows) + 4 <=  state.pageCount"
            [ngClass]="(firstPage + (rows * 3)) === pageSelected ? 'movanoPaginator_PageSlcFirst' : 'movanoPaginator_PageSlc'"
            (click)="selecPage((firstPage/rows) + 3)">
            {{(firstPage/rows) + 4 | number:'1.0-0'}}</td>
          <svg *ngIf="(firstPage/rows) + 5 < state.pageCount" viewBox="0 0 18 8" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <rect width="18" height="8" rx="4" fill="#C4D9EE" />
            <circle cx="9" cy="4" r="1" fill="var(--main-blue-dark)" />
            <circle cx="13" cy="4" r="1" fill="var(--main-blue-dark)" />
            <circle cx="5" cy="4" r="1" fill="var(--main-blue-dark)" />
          </svg>
          <td *ngIf="(firstPage/rows) + 5 <=  state.pageCount"
            [ngClass]="(firstPage + (rows * 3)) < pageSelected ? 'movanoPaginator_PageSlcFirst' : 'movanoPaginator_PageSlc'"
            (click)="selecPage(state.pageCount - 1)">{{state.pageCount}}</td>
          <svg (click)="next(state.pageCount)" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M0.666647 6.66665L8.77998 6.66665L5.05331 10.3933L5.99998 11.3333L11.3333 5.99998L5.99998 0.666646L5.05998 1.60665L8.77998 5.33331L0.666647 5.33331L0.666647 6.66665Z"
              [ngStyle]="{fill : state.page < state.pageCount-1 ? '#0B84FF' : '#AABED3'}" />
          </svg>
        </div>
        <span class="movanoPaginatorAmont">{{users.length}} users</span>
      </ng-template>
    </p-table>
    <div id="mobileScroll" class="movanoTableBox_mobileShadow"></div>
    <div id="mobileScroll_Left" class="movanoTableBox_mobileShadow movanoTableBox_mobileShadow_Left"></div>
  </div>

  <div *ngIf="loadingUserList" style="position: absolute; top: 50%; left: 50%;">
    <p-progressSpinner styleClass="{{darkMode ? 'custom-spinner' : 'custom-spinner-blue'}}" strokeWidth="2"
      animationDuration="1.5s">
    </p-progressSpinner>
  </div>
</div> -->

<div class="movanoHeader_Tittle_buttons">
  <div class="movanoHeader_Tittle_buttons_chartType"
   [ngClass]="{'movanoHeader_Tittle_buttons_chartType-selected' : selectedTab === 'insight-application'}"
  (click)="selectTab('insight-application')">Insight Application</div>

  <div class="movanoHeader_Tittle_buttons_chartType"
  [ngClass]="{'movanoHeader_Tittle_buttons_chartType-selected' : selectedTab === 'notification-list'}"
  (click)="selectTab('notification-list')">Notification List</div>

  <div class="movanoHeader_Tittle_buttons_chartType"
  [ngClass]="{'movanoHeader_Tittle_buttons_chartType-selected' : selectedTab === 'insight-job-executions'}"
  (click)="selectTab('insight-job-executions')">Insight Job Executions</div>
</div>

<router-outlet></router-outlet>
