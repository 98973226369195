<div class="movanoActivityLog" [ngClass]="{'movanoDark': darkMode}">
  <div class="movanoActivityLog_Header">
    <div class="movanoActivityLog_Header_Buttons">
      <button (click)="previusDate()">
        <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M20 8.75L4.7875 8.75L11.775 1.7625L10 1.74846e-06L-1.74846e-06 10L10 20L11.7625 18.2375L4.7875 11.25L20 11.25L20 8.75Z"
            [ngStyle]="{fill : '#0B84FF'}" />
        </svg>
      </button>
      <button [disabled]="!nextIsPosible" (click)="nextDate()">
        <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M7.64949e-07 11.25L15.2125 11.25L8.225 18.2375L10 20L20 10L10 -8.74228e-07L8.2375 1.7625L15.2125 8.75L9.83506e-07 8.75L7.64949e-07 11.25Z"
            [ngStyle]="{fill : (nextIsPosible) ? '#0B84FF' : '#AABED3'}" />
        </svg>
      </button>
    </div>
    <div class="movanoCalendar">
      <mat-icon [ngStyle]="{color : (false) ? '#FFFFFF' : ''}">calendar_today</mat-icon>
      <p-calendar #dayCalendar [maxDate]="today!" (onSelect)="nextIsPosible = daySelected!.getTime() < today!.getTime()" (onShow)="calendarOpen = true"
        (onClose)="calendarOpen = false" [dateFormat]=" 'd MM ‘y'" [(ngModel)]="daySelected">
      </p-calendar>
      <svg [ngStyle]="{'transform' : (calendarOpen)?'rotate(180deg)':'rotate(0deg)', 'opacity' : (calendarOpen)?'0.5':'1'}" style="position: absolute; bottom: 42%; right:0.84vw" width="0.555vw" height="0.4166vw" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.35199 -2.83255e-08L0.648012 -3.21366e-07C0.136801 -3.43711e-07 -0.173808 0.514539 0.104446 0.910793L3.45643 5.72499C3.7088 6.09167 4.2912 6.09167 4.55004 5.72499L7.89555 0.910793C8.17381 0.514539 7.8632 -5.97973e-09 7.35199 -2.83255e-08Z" fill="#284767" />
      </svg>
    </div>
  </div>

  <div *ngIf="loadingData; else dataLoaded" class="movanoHealthMetrics_NoData">
    <p-progressSpinner [style]="{width: '3vw', height: '3vw', position: 'absolute', left : '38.5vw'}"
      styleClass="custom-spinner-blue" strokeWidth="2" animationDuration="1.5s">
    </p-progressSpinner>
  </div>
  <ng-template #dataLoaded>
    <div *ngIf="dataAvailable; else noData" class="movanoActivityLog_Box">
      <div class="movanoActivityLog_User">
        <div *ngIf="mixpanelUserProfile" class="movanoActivityLog_User_Profile">User Profile
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42 42" fill="none">
            <path d="M0 0H37C39.7614 0 42 2.23858 42 5V42H5C2.23858 42 0 39.7614 0 37V0Z" fill="#C4D9EE" />
            <path
              d="M21 20.1667C23.5091 20.1667 25.5273 18.1133 25.5273 15.5833C25.5273 13.0533 23.5091 11 21 11C18.4909 11 16.4545 13.0533 16.4545 15.5833C16.4545 18.1133 18.4909 20.1667 21 20.1667ZM21 23.8333C17.6727 23.8333 11 25.52 11 28.875V31.1667C11 32.175 11.8182 33 12.8182 33H29.1818C30.1818 33 31 32.175 31 31.1667V28.875C31 25.52 24.3273 23.8333 21 23.8333Z"
              fill="#054280" />
          </svg>
          <div>
            <div class="movanoActivityLog_User_Title">User</div>
            <div class="movanoActivityLog_User_Subtitle">{{ mixpanelUserProfile.uuid }}</div>
          </div>
          <div class="movanoActivityLog_Hr"> </div>
          <div>
            <div class="movanoActivityLog_User_Title">Location</div>
            <div class="movanoActivityLog_User_Subtitle">{{ mixpanelUserProfile.location }}</div>
            <div class="movanoActivityLog_Hr"> </div>
          </div>
          <div>
            <div class="movanoActivityLog_User_Title">Updated at</div>
            <div class="movanoActivityLog_User_Subtitle">{{ mixpanelUserProfile.properties['Updated at'] }}</div>
          </div>
        </div>

        <div *ngIf="mixpanelUserProfile" class="movanoActivityLog_User_ProfileProperties">
          <div class="movanoActivityLog_User_ProfileProperties_Title">
            User profile properties
          </div>
          <div class="movanoHeader_Tittle_Search scale-up-hor-left">
            <svg width="max(1.11vw, 16px)" height="max(1.11vw, 16px)" viewBox="0 0 16 16" fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M10.9167 9.66667H10.2583L10.025 9.44167C10.8417 8.49167 11.3333 7.25833 11.3333 5.91667C11.3333 2.925 8.90833 0.5 5.91667 0.5C2.925 0.5 0.5 2.925 0.5 5.91667C0.5 8.90833 2.925 11.3333 5.91667 11.3333C7.25833 11.3333 8.49167 10.8417 9.44167 10.025L9.66667 10.2583V10.9167L13.8333 15.075L15.075 13.8333L10.9167 9.66667V9.66667ZM5.91667 9.66667C3.84167 9.66667 2.16667 7.99167 2.16667 5.91667C2.16667 3.84167 3.84167 2.16667 5.91667 2.16667C7.99167 2.16667 9.66667 3.84167 9.66667 5.91667C9.66667 7.99167 7.99167 9.66667 5.91667 9.66667Z"
                fill="var(--main-blue-dark)" />
            </svg>
            <span class="p-input-icon-left">
              <input class="movanoInputSearch" type="text" [(ngModel)]="inputValue" placeholder="Search an user" />
            </span>
            <svg (click)="resetInput()" width="max(0.833vw, 16px)" height="max(0.833vw, 16px)" viewBox="0 0 12 12"
              fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M11.25 1.8075L10.1925 0.75L6 4.9425L1.8075 0.75L0.75 1.8075L4.9425 6L0.75 10.1925L1.8075 11.25L6 7.0575L10.1925 11.25L11.25 10.1925L7.0575 6L11.25 1.8075Z"
                fill="#C4D9EE" />
            </svg>

          </div>
          <div *ngIf="mixpanelUserProfile" class="movanoActivityLog_User_ProfileProperties_Scroll">
            <div *ngFor="let property of mixpanelUserProfile.properties | keyvalue">
              <div *ngIf="property.key.includes(inputValue)">
                <div class="movanoActivityLog_User_Title">{{property.key}}</div>
                <div class="movanoActivityLog_User_Subtitle">
                  {{ (property.value === '&lt;null&gt;' || !property.value) ? 'No data available' : property.value }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
          <div *ngIf="mixpanelUserProfile"  class="movanoActivityLog_Container">
            <div class="movanoActivityLog_Container_Events">
              
            <div class="movanoActivityLog_Feed">Activity Feed</div>
            <div class="movanoActivityLog_Feed_Events">
              <div class="movanoActivityLog_Feed_Events_Function">
                <svg (click)="getInfo()" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" fill="none">
                  <g clip-path="url(#clip0_2003_1042)">
                    <path
                      d="M13.2373 4.7625C12.1498 3.675 10.6573 3 8.99982 3C5.68482 3 3.00732 5.685 3.00732 9C3.00732 12.315 5.68482 15 8.99982 15C11.7973 15 14.1298 13.0875 14.7973 10.5H13.2373C12.6223 12.2475 10.9573 13.5 8.99982 13.5C6.51732 13.5 4.49982 11.4825 4.49982 9C4.49982 6.5175 6.51732 4.5 8.99982 4.5C10.2448 4.5 11.3548 5.0175 12.1648 5.835L9.74982 8.25H14.9998V3L13.2373 4.7625Z"
                      fill="#0B84FF" />
                  </g>
                  <defs>
                    <clipPath id="clip0_2003_1042">
                      <rect fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <div style="color: #0B84FF;" class="movanoActivityLog_Feed_Events_Function_Title">Refresh data</div>
              </div>
              <div class="movanoActivityLog_Feed_Events_Function">
                <svg style="position: absolute; left: 0px;" viewBox="0 0 14 14" fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <line x1="1.03033" y1="0.96967" x2="13.0303" y2="12.9697" stroke="#7797B8" stroke-width="1.5" />
                </svg>
                <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_1909_9634)">
                    <path
                      d="M7.99984 4.33333C10.5265 4.33333 12.7798 5.75333 13.8798 8C12.7798 10.2467 10.5332 11.6667 7.99984 11.6667C5.4665 11.6667 3.21984 10.2467 2.11984 8C3.21984 5.75333 5.47317 4.33333 7.99984 4.33333ZM7.99984 3C4.6665 3 1.81984 5.07333 0.666504 8C1.81984 10.9267 4.6665 13 7.99984 13C11.3332 13 14.1798 10.9267 15.3332 8C14.1798 5.07333 11.3332 3 7.99984 3ZM7.99984 6.33333C8.91984 6.33333 9.6665 7.08 9.6665 8C9.6665 8.92 8.91984 9.66667 7.99984 9.66667C7.07984 9.66667 6.33317 8.92 6.33317 8C6.33317 7.08 7.07984 6.33333 7.99984 6.33333ZM7.99984 5C6.3465 5 4.99984 6.34667 4.99984 8C4.99984 9.65333 6.3465 11 7.99984 11C9.65317 11 10.9998 9.65333 10.9998 8C10.9998 6.34667 9.65317 5 7.99984 5Z"
                      fill="#7797B8" />
                  </g>
                  <defs>
                    <clipPath id="clip0_1909_9634">
                      <rect fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <div style="color: #7797B8;" class="movanoActivityLog_Feed_Events_Function_Title">Hide events</div>
              </div>
            </div>
            </div>
            
          <div *ngIf="loadingActivityFeed; else activityFeed" class="movanoHealthMetrics_NoData">
            <p-progressSpinner [style]="{width: '3vw', height: '3vw'}"
              styleClass="custom-spinner-blue" strokeWidth="2" animationDuration="1.5s">
            </p-progressSpinner>
          </div>
          <ng-template #activityFeed>
            No data available
          </ng-template>
          </div>
    </div>
    <ng-template #noData>
      <div class="movanoActivityLog_NoData">
        <span>NO DATA AVAILABLE</span>
      </div>
    </ng-template>
  </ng-template>
</div>