<div class="movanoMap">
    

      <ng-container [ngTemplateOutlet]="getTemplate()"></ng-container>
      <ng-template #mapUsers>
        <div>
          <span>Users geo localization</span>
          </div>

          <div class="movanoMap_Container">
            Map
          </div>

        <div class="movanoSpotCheck_History">
          <div class="movanoSpotCheck_History_Scroll_Line movanoSpotCheck_History_Scroll_LineDays"> 
       <span class="movanoSpotCheck_History_Scroll_Line_Date"></span>
              <span style="font-weight: 400; color: #284767;"> State</span>
              <span style="font-weight: 400; color: #284767;">Men</span>
              <span style="font-weight: 400; color: #284767;">Women</span>
              <span style="font-weight: 400; color: #284767;">Total</span>
            </div>
            <div class="movanoSpotCheck_History_Scroll">
              <div *ngFor="let data of mapList; let i = index">
                <div class="movanoSpotCheck_History_Scroll_Line">
                  <span class="movanoSpotCheck_History_Scroll_Line_Date"
                    [ngClass]="{ 'resized-font-size': i < 3 }"
                    [ngStyle]="{
                      'font-weight': i < 3 ? '700' : '400',
                      'color': i < 3 ? '#FF830D' : '#7797B8'
                    }">{{i+1}}</span>
            
                  <span [ngClass]="{ 'resized-font-size': i < 3 }"
                    [ngStyle]="{
                      'font-weight': i < 3 ? '700' : '400',
                      'color': i < 3 ? '#FF830D' : '#7797B8'
                    }">{{data.state}}</span>
            
                  <span [ngClass]="{ 'resized-font-size': i < 3 }"
                    [ngStyle]="{
                      'font-weight': '400',
                      'color': '#0B84FF'
                    }">{{data.men}}</span>
            
                  <span [ngClass]="{ 'resized-font-size': i < 3 }"
                    [ngStyle]="{
                      'font-weight': '400',
                      'color': '#E31482'
                    }">{{data.women}}</span>
            
                  <span [ngClass]="{ 'resized-font-size': i < 3 }"
                    [ngStyle]="{
                      'font-weight': i < 3 ? '700' : '400',
                      'color': i < 3 ? '#FF830D' : '#7797B8'
                    }">{{data.total}}</span>
                </div>
              </div>
            </div>
            
        </div>
        </ng-template>


        <ng-template #mapDevices>
          <div>
            <span>Sales geo localization</span>
            </div>
  
            <div class="movanoMap_Container">
              Map
            </div>
  
          <div class="movanoSpotCheck_History">
            <div class="movanoSpotCheck_History_Scroll_line movanoSpotCheck_History_Scroll_lineDays"> 
         <span class="movanoSpotCheck_History_Scroll_line_Date"></span>
                <span style="font-weight: 400; color: #284767;"> State</span>
                <span style="font-weight: 400; color: #284767;">New</span>
                <span style="font-weight: 400; color: #284767;">Devices</span>
              </div>
              <div class="movanoSpotCheck_History_Scroll">
                <div *ngFor="let data of mapListDevices; let i = index">
                  <div class="movanoSpotCheck_History_Scroll_line">
                    
                    <span class="movanoSpotCheck_History_Scroll_line_Date" 
                      [ngClass]="{ 'resized-font-size': i < 3 }"
                      [ngStyle]="{
                        'font-weight': i < 3 ? '700' : '400',
                        'color': i < 3 ? '#054280' : '#7797B8'
                      }">{{i+1}}</span>
                    
                    <span style="font-weight: 400; color: #7797B8;"
                      [ngClass]="{ 'resized-font-size': i < 3 }"
                      [ngStyle]="{
                        'font-weight': i < 3 ? '700' : '400',
                        'color': i < 3 ? '#054280' : '#7797B8'
                      }">{{data.state}}</span>
                    
                    <span 
                      [ngClass]="{ 'resized-font-size': i < 3 }"
                      [ngStyle]="{
                        'font-weight':'400',
                        'color': '#00B277'
                      }">
                      {{data.new}}
                    </span>
                    
                    <span 
                      [ngClass]="{ 'resized-font-size': i < 3 }"
                      [ngStyle]="{
                        'font-weight': i < 3 ? '500' : '400',
                        'color': i < 3 ? '#054280' : '#7797B8'
                      }">
                      {{data.devices}}
                    </span>
                    
                  </div> 
                </div>            
              </div>
              
          </div>
          </ng-template>
    </div>
