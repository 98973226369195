<div class="movanoWorkoutUsage">
<div class="movanoMiddleBar">
    <div class="movanoMiddleBar_First">
      <app-new-time-selector style="padding-top:20px;" #list_calendar (initGetInfo)="emitGetInfo($event)" [slctdDateOpt]="selectedDate" [timeZones]="timeZones"></app-new-time-selector>
    <svg class="movanoHeader_Tittle_btn" style="margin-left: 20px;" width="max(1.11vw, 16px)" height="max(1.11vw, 16px)" *ngIf="!searchDisplay"
        (click)="searchDisplay = true" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M10.9167 9.66667H10.2583L10.025 9.44167C10.8417 8.49167 11.3333 7.25833 11.3333 5.91667C11.3333 2.925 8.90833 0.5 5.91667 0.5C2.925 0.5 0.5 2.925 0.5 5.91667C0.5 8.90833 2.925 11.3333 5.91667 11.3333C7.25833 11.3333 8.49167 10.8417 9.44167 10.025L9.66667 10.2583V10.9167L13.8333 15.075L15.075 13.8333L10.9167 9.66667V9.66667ZM5.91667 9.66667C3.84167 9.66667 2.16667 7.99167 2.16667 5.91667C2.16667 3.84167 3.84167 2.16667 5.91667 2.16667C7.99167 2.16667 9.66667 3.84167 9.66667 5.91667C9.66667 7.99167 7.99167 9.66667 5.91667 9.66667Z"
          fill="var(--main-blue-dark)" />
      </svg>
      <div style="margin-right: 20px; margin-left: 20px;" *ngIf="searchDisplay" class="movanoHeader_Tittle_Search scale-up-hor-left">
        <svg  width="max(1.11vw, 16px)" height="max(1.11vw, 16px)"
          viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M10.9167 9.66667H10.2583L10.025 9.44167C10.8417 8.49167 11.3333 7.25833 11.3333 5.91667C11.3333 2.925 8.90833 0.5 5.91667 0.5C2.925 0.5 0.5 2.925 0.5 5.91667C0.5 8.90833 2.925 11.3333 5.91667 11.3333C7.25833 11.3333 8.49167 10.8417 9.44167 10.025L9.66667 10.2583V10.9167L13.8333 15.075L15.075 13.8333L10.9167 9.66667V9.66667ZM5.91667 9.66667C3.84167 9.66667 2.16667 7.99167 2.16667 5.91667C2.16667 3.84167 3.84167 2.16667 5.91667 2.16667C7.99167 2.16667 9.66667 3.84167 9.66667 5.91667C9.66667 7.99167 7.99167 9.66667 5.91667 9.66667Z"
            fill="var(--main-blue-dark)" />
        </svg>
        <span class="p-input-icon-left">
          <input class="movanoInputSearch" type="text" placeholder="Search user"  pInputText />
        </span>
        <svg width="max(0.833vw, 16px)" height="max(0.833vw, 16px)"
          (click)=" searchDisplay = false;" viewBox="0 0 12 12" fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M11.25 1.8075L10.1925 0.75L6 4.9425L1.8075 0.75L0.75 1.8075L4.9425 6L0.75 10.1925L1.8075 11.25L6 7.0575L10.1925 11.25L11.25 10.1925L7.0575 6L11.25 1.8075Z"
            fill="#C4D9EE" />
        </svg>
      </div>
      <svg style="margin-left: 20px;" (click)="filterDisplay = true" class="movanoHeader_Tittle_btn" width="max(0.9vw, 16px)"
      height="max(0.9vw, 16px)" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.364543 1.48084C0.443709 1.58375 4.90871 7.28375 4.90871 7.28375V12.0417C4.90871 12.4771 5.26496 12.8333 5.70829 12.8333H7.29954C7.73496 12.8333 8.09913 12.4771 8.09913 12.0417V7.27584C8.09913 7.27584 12.4454 1.71834 12.6512 1.465C12.857 1.21167 12.8333 0.958338 12.8333 0.958338C12.8333 0.522922 12.477 0.166672 12.0337 0.166672H0.966209C0.483293 0.166672 0.166626 0.546672 0.166626 0.958338C0.166626 1.11667 0.214126 1.30667 0.364543 1.48084Z"
        fill="var(--main-blue-dark)" />
    </svg>
  </div>
  
  <div  class="movanoMiddleBar_Last">
    <span class="movanoMiddleBar_Last_Amount" >{{workoutUsage.length}} sessions</span>
      </div>
      </div>
      <p-table *ngIf="dataAvailable" id="workoutUsageTable" #insightAppTable dataKey="name" class="movano-Table"
      [globalFilterFields]="['activity_type','hour', 'step_count', 'calories', 'distance', 'active_TIME']" [value]="workoutUsage"
      [paginator]="true" [rows]="rows" [first]="pageSelected">
      <ng-template pTemplate="header">
        <tr [ngClass]="'movanoTable_Header'">
          <th pSortableColumn="name" (click)="sort('name')" >
            {{LITERALS.WRK_USAGE.ACTIVITY}}
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path id="name-icon" d="M5.25 -2.29485e-07L5.25 9.1275L1.0575 4.935L-2.62268e-07 6L6 12L12 6L10.9425 4.9425L6.75 9.1275L6.75 -2.95052e-07L5.25 -2.29485e-07Z" fill="#C4D9EE"/>
              </svg> 
              </th>
          <th pSortableColumn="application_id" (click)="sort('application_id')">
            {{LITERALS.WRK_USAGE.TABLE_DATE}}
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path id="application_id-icon" d="M5.25 -2.29485e-07L5.25 9.1275L1.0575 4.935L-2.62268e-07 6L6 12L12 6L10.9425 4.9425L6.75 9.1275L6.75 -2.95052e-07L5.25 -2.29485e-07Z" fill="#C4D9EE"/>
              </svg>
          </th>
          <th pSortableColumn="type" (click)="sort('type')">{{LITERALS.WRK_USAGE.TABLE_HOUR}}
            <svg (click)="sort('type')" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path id="type-icon" d="M5.25 -2.29485e-07L5.25 9.1275L1.0575 4.935L-2.62268e-07 6L6 12L12 6L10.9425 4.9425L6.75 9.1275L6.75 -2.95052e-07L5.25 -2.29485e-07Z" fill="#C4D9EE"/>
              </svg></th>
          <th pSortableColumn="release_version" (click)="sort('release_version')">{{LITERALS.WRK_USAGE.TABLE_STEP_COUNT}}
            <svg  width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path id="release_version-icon" d="M5.25 -2.29485e-07L5.25 9.1275L1.0575 4.935L-2.62268e-07 6L6 12L12 6L10.9425 4.9425L6.75 9.1275L6.75 -2.95052e-07L5.25 -2.29485e-07Z" fill="#C4D9EE"/>
              </svg></th>
          <th pSortableColumn="architecture" (click)="sort('architecture')">{{LITERALS.WRK_USAGE.TABLE_CALORIES}}
            <svg  width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path id="architecture-icon" d="M5.25 -2.29485e-07L5.25 9.1275L1.0575 4.935L-2.62268e-07 6L6 12L12 6L10.9425 4.9425L6.75 9.1275L6.75 -2.95052e-07L5.25 -2.29485e-07Z" fill="#C4D9EE"/>
              </svg></th>
          <th pSortableColumn="last_modified" (click)="sort('last_modified')">{{LITERALS.WRK_USAGE.TABLE_DISTANCE}}
            <svg  width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path id="last_modified-icon" d="M5.25 -2.29485e-07L5.25 9.1275L1.0575 4.935L-2.62268e-07 6L6 12L12 6L10.9425 4.9425L6.75 9.1275L6.75 -2.95052e-07L5.25 -2.29485e-07Z" fill="#C4D9EE"/>
            </svg></th>
          <th pSortableColumn="status" (click)="sort('status')">{{LITERALS.WRK_USAGE.TABLE_ACTIVE_TIME}}
            <svg  width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path id="status-icon" d="M5.25 -2.29485e-07L5.25 9.1275L1.0575 4.935L-2.62268e-07 6L6 12L12 6L10.9425 4.9425L6.75 9.1275L6.75 -2.95052e-07L5.25 -2.29485e-07Z" fill="#C4D9EE"/>
            </svg></th>
          <th></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-data let-i="rowIndex" let-expanded="expanded">
        <tr [ngStyle]="{
          'background' : darkMode ?
          i%2 == 0 ? '#05294d' : '#05305B' :
          i%2 == 0 ? '#f3f6f9' : 'white'
        }" [ngClass]="'movanoTable_Obj'">
          <td>{{data.activity_type_name}}</td>
          <td [ngStyle]="{'opacity' : (expanded) ? '0%' : '100%'}">{{data.date | date:'MMM d, y'}}</td>
          <td [ngStyle]="{'opacity' : (expanded) ? '0%' : '100%'}">{{data.hour}}</td>
          <td [ngStyle]="{'opacity' : (expanded) ? '0%' : '100%'}">{{data.step_count}}</td>
          <td [ngStyle]="{'opacity' : (expanded) ? '0%' : '100%'}">{{data.total_calories | number: '1.0-0'}}</td>
          <td [ngStyle]="{'opacity' : (expanded) ? '0%' : '100%'}">{{data.total_distance | number: '1.2-2'}}</td>
          <td [ngStyle]="{'opacity' : (expanded) ? '0%' : '100%'}">{{data.total_active_minutes}}'
          </td>
          <td class="movanoTable_click">
            <div type="button" pButton [pRowToggler]="data"
              class="p-button-text p-button-rounded p-button-plain"
              [icon]="expanded ? 'pi pi-angle-up' : 'pi pi-angle-down'"></div>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="paginatorright" let-state>
        <paginator [pageSelected]="pageSelected" [rows]="rows" [state]="state" [lenght]="workoutUsage.length"
          (updatePaginator)="updatePaginator($event)" [type]="type"></paginator>
      </ng-template>

      </p-table>


      <div *ngIf="!dataAvailable" class="movanoWorkoutUsage_NoData">
        <span>NO DATA AVAILABLE</span>
      </div>
    </div>
   
