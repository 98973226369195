import { Component, EventEmitter, OnDestroy, OnInit, Output, QueryList, ViewChild, ViewChildren} from '@angular/core';
//import * as console from 'console';
import { footerInfo, incidentCarrousel, salesProductionCarrousel, salesSyncCarrousel, totalInfoPerMonth } from '../shared/movano-interfaces';
import { deviceTable, deviceAll } from '../shared/movano-interfaces';
import { Table } from 'primeng/table';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MovanoService } from '../movano.service';
import { FilterService } from 'primeng/api';
import * as lit from '../shared/movano-literals';
import { RecoverPasswordComponent } from '../login/recover-password/recover-password.component';
import { lastValueFrom, Subscription } from 'rxjs';
import { DarkModeService } from '../dark-mode.service';
import { HeaderComponent } from '../header/header.component';
import { DevicesTicketsComponent } from './devices-tickets/devices-tickets.component';
import { UserPanelComponent } from '../users/panel/user-panel.component';
import { MatCheckbox } from '@angular/material/checkbox';


@Component({
  selector: 'app-devices',
  templateUrl: './devices.component.html',
  styleUrls: ['./devices.component.scss']
})
export class DevicesComponent implements OnInit, OnDestroy {
  protected LITERALS = lit;
  protected selectedDate: number = 1;
  protected version = 1.11;
  protected innerWidth: any;
  protected darkMode: boolean = false;
  private darkModeSub : Subscription = new Subscription();
  protected userDisplay: boolean = false;
  protected menuDisplay: boolean = false;
  protected searchDisplay: boolean = false;
  protected monthData?: totalInfoPerMonth;
  protected footerData?: footerInfo;
  protected salesAndProductionCarrouselData?: salesProductionCarrousel;
  protected syncAndSleepCarrouselData?: salesSyncCarrousel;
  protected incidentCarrouselData?: incidentCarrousel;
  protected pageSelected: number = 0;
  protected firstPage: number = 0;
  protected lastPage: number = 0;
  protected rows: number = 14;
  protected REFRESH_TIME: number = 12000;
  protected firmwares : string [] = [];
  firmwaresCheckboxs: boolean[] = [];
  firstFourFirmwares: string[] = [];
  remainingFirmwares: string[] = [];
  showMore: boolean = false;
  protected firmwaresFiltered : string [] = [];
  protected deviceTypes: string [] = [];
  protected deviceCheckboxs : boolean [] = [];
  protected deviceFiltered : string [] = [];
  protected selectedStatuses: string[] = [];
 protected pauseAll: boolean = false;
  items?: any[];

  selectedItem: any;

  suggestions: any[] = [];

  devices: deviceTable[] = [];
  devicesFiltered: deviceTable[] = [];
  filteredDevices: deviceTable[] = [];
  filterDisplay: boolean = false;
  filter_Active: boolean = false;
  tickets: boolean = false;
  filter_Blocked: boolean = false;
  filter_Inactive: boolean = false;
  protected filter_lastCharge: Date[] = [];
  protected filter_lastSync: Date[] = [];
  isFiltered: boolean = false;
  protected timeZones : string[] = [];
  protected loadingData: boolean = false;
  protected daySelected?: Date;
  protected rangeSelected: Date[] = [];


  protected actualTimeZone: string = '';
  protected timeOptions: string[] = ['', 'week', 'month', ''];
  protected lastselectedDate : number = 0;
  protected dataAvailable: boolean = false;

  today: Date;
  @ViewChild('deviceTable') table!: Table;
  @ViewChildren(MatCheckbox) checkboxes!: QueryList<MatCheckbox>;
  @Output() getInfo = new EventEmitter<[number, boolean, Date | Date[], string, number]>();

  constructor(
    protected dialog: MatDialog,
    protected router: Router,
    protected movanoService: MovanoService,
    private filterService: FilterService,
    private darkModeSvc: DarkModeService) {
    this.today = new Date();
    //console.log(this.today.getTime());
  }
  async ngOnInit() {
    this.darkModeSub = this.darkModeSvc.getVariable().subscribe((val : any) => {
      this.darkMode = val;
    });
    this.today = new Date(Date.now());
    this.emitGetInfo();


    await this.getUserDetails();
   // await this.getDevicesList();
    this.filteredDevices = this.devices;
    let tableElement = document.getElementsByClassName("p-datatable-wrapper")[0];
    let scrollHelper = document.getElementById("mobileScroll");
    let scrollHelperLeft = document.getElementById("mobileScroll_Left");
    if (tableElement && scrollHelper) {
      tableElement.addEventListener("scroll", function () {
        if(tableElement.scrollLeft > (tableElement.scrollWidth - tableElement.clientWidth - 15)){
          scrollHelper!.style.display = "none";
        } else {
          scrollHelper!.style.display = "block";
        }

        if(tableElement.scrollLeft > 15){
          scrollHelperLeft!.style.display = "block";
        } else {
          scrollHelperLeft!.style.display = "none";
        }
      });
    }
    else{
      // console.log(tableElement);
      // console.log(scrollHelper);
    }
    const customFilterName = 'multi-select-in';
    this.filterService.register(customFilterName, (value : any, filter : any): boolean => {
      if (filter == null || filter.length == 0) {
        return true;
      }
      let selected : boolean = false;
      //console.log(filter);
      filter.forEach((element : any) => {
        if(element === value){
          selected = true;
          return;
        }
      });
      return selected;
    });
  }

  ngOnDestroy() {
    this.darkModeSub.unsubscribe();
  }

  search(event: any, _row: string, _table: Table) {
    const value = event.target.value.trim().toLowerCase();;

    if(value){
      this.filteredDevices = this.devices.filter(item =>
        item.device_serial.toLowerCase().includes(value) ||
        item.customer_uuid.toLowerCase().includes(value)
      );
    }else{
      this.filteredDevices = this.devices;
    }
  }

  clearSearch(inputElement: HTMLInputElement, _table: Table) {
    inputElement.value = '';
    _table.filter('', 'customer_uuid', 'contains');
    _table.filter('', 'device_serial', 'contains');
    this.isFiltered = false;
  }

  toggleShowMore() {
    this.showMore = !this.showMore;
  }

  protected openTicketsPanel(_userId: string) {
    this.dialog.open(DevicesTicketsComponent, {data: {user: _userId}});
    }

    applyFilter() {
      this.table.reset();

      if (this.selectedStatuses.length > 0) {
        this.table.filter(this.selectedStatuses, 'status', 'multi-select-in');
      }

      if(this.firmwaresFiltered.length > 0){
        this.table.filter(this.firmwaresFiltered, 'firmware', 'multi-select-in');
      }

      if (this.filter_lastCharge.length === 2 && this.filter_lastCharge[0] && this.filter_lastCharge[1]) {
        const startDate = this.formatDate(this.filter_lastCharge[0]);
        const endDate = this.formatDate(this.filter_lastCharge[1]);
        this.table.filter(startDate, 'last_charge', 'gte');
        this.table.filter(endDate, 'last_charge', 'lte');
      }
      if (this.filter_lastSync.length === 2 && this.filter_lastSync[0] && this.filter_lastSync[1]) {

        const startDate = this.formatDate(this.filter_lastSync[0]);
        const endDate = this.formatDate(this.filter_lastSync[1]);
        this.table.filter(startDate, 'last_sync', 'gte');
        this.table.filter(endDate, 'last_sync', 'lte');
      }

      console.log('Last Sync Date:', this.filter_lastSync);
      this.checkIfFiltered();
    }


    formatDate(date: Date): string {
      const year = date.getFullYear();
      const month = ('0' + (date.getMonth() + 1)).slice(-2);
      const day = ('0' + date.getDate()).slice(-2);
      return `${year}-${month}-${day}`;
    }

    onStatusChange(status: string, event: any, table: Table) {
      if (event.checked) {
        this.selectedStatuses.push(status);
      } else {
        const index = this.selectedStatuses.indexOf(status);
        if (index > -1) {
          this.selectedStatuses.splice(index, 1);
        }
      }
      this.applyFilter();
    }


    checkIfFiltered() {
      const hasActiveFilter = this.filter_Active || this.filter_Inactive || this.firmwaresFiltered.length > 0 || this.filter_lastCharge.length > 0 || this.filter_lastSync.length > 0 || this.selectedStatuses.length > 0;
      this.isFiltered = hasActiveFilter;
    }

    resetFilter(_table: Table) {

            //RESET VALUES
      _table.reset();
      this.firmwaresFiltered = [];
      this.selectedStatuses = [];
      this.filter_lastCharge = [];
      this.filter_lastSync = [];
      this.firmwaresCheckboxs = Array(this.firmwares.length).fill(false);
      this.checkboxes.forEach(checkbox => checkbox.checked = false);
      this.tickets = false;
      this.isFiltered = false;
      this.filteredDevices = this.devices;

          // RESET PRIME NG FILTERS
     _table.filter('', 'status', 'multi-select-in')
     _table.filter('', 'firmware', 'multi-select-in')
      _table.filter('', 'last_charge', 'gte');
      _table.filter('', 'last_charge', 'lte');
      _table.filter('', 'last_sync', 'gte');
      _table.filter('', 'last_sync', 'lte');
  }

  toggleFirmwareFilter(firmware: string, table: Table) {
    const index = this.firmwaresFiltered.indexOf(firmware);
    if (index > -1) {
      this.firmwaresFiltered.splice(index, 1);
    } else {
      this.firmwaresFiltered.push(firmware);
    }
    this.applyFilter();
  }

  //Logout and comeback to fontPage
  async logOutWithCognito() {
    await this.movanoService.LogOut()
    this.router.navigate(['']);
  }

  recoverPass() {
    this.userDisplay = false;
    this.dialog.open(RecoverPasswordComponent);
  }
  changeTheme() {
    this.darkModeSvc.setVariable(!this.darkMode);
  }
  updateInfo() {
    throw new Error('Method not implemented.');
  }
  goTo(_place: string) {
    this.router.navigate([_place]);
  }

  protected loadingDevicesList : boolean = true;


  emitGetInfo(calendarData?: [any, boolean, Date | Date[], string, any]) {
    this.getInfo.emit(calendarData);
    console.log('Parent component emitting:', calendarData);
    this.loadData(calendarData);
  }

  onFilter(event: any) {
    this.filteredDevices = event.filteredValue;
    this.isFiltered = this.filteredDevices.length !== this.devices.length;
  }

  protected openUserPanel(_userId: string) {
    this.dialog.open(UserPanelComponent, { data: { user: _userId } });
  }

  getBadgeColor(status: string): string {
    switch(status) {
      case 'Sold':
        return 'movanoTable_Obj_Badge_blueDark';
      case 'Registered':
        return 'movanoTable_Obj_Badge_blueDark';
      case 'Problem detected':
            return 'movanoTable_Obj_Badge_red';
      case 'Active':
            return 'movanoTable_Obj_Badge_green';
      case 'Repairing':
            return 'movanoTable_Obj_Badge_orange';
      case 'Repaired':
              return 'movanoTable_Obj_Badge_orangeDark';
      case 'Disposal/Returned':
              return 'movanoTable_Obj_Badge_gray';
        default:
            return '';
    }
  }

  exportCSV() {
    if (this.devices && this.devices.length > 0) {
      const csvData = this.convertToCSV(this.devices);
      const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', 'devices.csv');
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      console.error('No hay datos para exportar');
    }
  }

  convertToCSV(objArray: any[]): string {
    const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    let row = '';

    for (const index in array[0]) {
      if (array[0].hasOwnProperty(index)) {
        // Now convert each value to a string
        const header = index + ',';
        row += header;
      }
    }

    row = row.slice(0, -1);
    str += row + '\r\n';

    for (let i = 0; i < array.length; i++) {
      let line = '';
      for (const index in array[i]) {
        if (array[i].hasOwnProperty(index)) {
          if (line !== '') line += ',';
          line += array[i][index];
        }
      }

      str += line + '\r\n';
    }
    return str;
  }



  //Get user details is now only using to know if the user is logged
  async getUserDetails() {
    const user = await this.movanoService.getUser();
    if (!user) {
      this.router.navigate(['login']);
    }
  }

  previous() {
    // Set first index of page to firstIndex - rows
    if ((this.pageSelected / this.rows) <= 0) return;
    this.pageSelected = this.pageSelected - this.rows;
    this.firstPage =
      (this.pageSelected >= (3 * this.rows)) ?
        (this.pageSelected <= this.lastPage - (2 * this.rows)) ?
          Math.abs(this.pageSelected - (2 * this.rows)) : Math.abs(this.lastPage - (4 * this.rows)) : 0;
  }

  next(_totalPages: number) {
    // Set first index of page to firstIndex + rows
    if ((this.pageSelected / this.rows) >= _totalPages - 1) return;
    this.pageSelected = this.pageSelected + this.rows;
    this.firstPage =
      (this.pageSelected >= (3 * this.rows)) ?
        (this.pageSelected <= this.lastPage - (2 * this.rows)) ?
          Math.abs(this.pageSelected - (2 * this.rows)) : Math.abs(this.lastPage - (4 * this.rows)) : 0;
  }

  selecPage(_page: number) {
    this.pageSelected = _page * this.rows;
    this.firstPage =
      (this.pageSelected >= (3 * this.rows)) ?
        (this.pageSelected <= this.lastPage - (2 * this.rows)) ?
          Math.abs(this.pageSelected - (2 * this.rows)) : Math.abs(this.lastPage - (4 * this.rows)) : 0;
  }


  async loadData(calendarData?: [any, boolean, Date | Date[], string, any]) {
    if (calendarData) {
      console.log(calendarData);
      this.lastselectedDate = this.selectedDate;
      this.selectedDate = parseInt(calendarData[0]);
      if (this.selectedDate !== 3) {
          this.daySelected = calendarData[2] as Date;
      } else {
          this.rangeSelected = calendarData[2] as Date[];
      }
      this.actualTimeZone = calendarData[3];
  } else {
      this.selectedDate = 0;
      this.actualTimeZone = this.timeZones ? this.timeZones[0] : '';
  }

    try {
      let date: Date | undefined;
      let timeFrame: string | undefined = '';
      console.log(this.selectedDate);

      if (this.selectedDate === 0) {

      } else if (this.selectedDate === 1) {
          // Handle "last 7 days"
          timeFrame = 'last7Days';
      } else if (this.selectedDate === 2) {
          // Handle "last month"
          timeFrame = 'lastMonth';
      } else if (this.selectedDate === 5) {
        //Year to date isn't supported for users or devices because it doesn't allow ranges
      }
      else if (this.selectedDate === 6) {
        // Handle "lastweek"
        timeFrame = 'lastWeek';
    }
      else if (this.selectedDate === 4) {
        // Handle "custom"
        date = this.daySelected;
    }

      const res = await lastValueFrom(this.movanoService.getDeviceTable(1, 0, date, timeFrame));

        this.devices = res.data;
        console.log('API Response Data:', res.data);
        this.loadingDevicesList = false;
      this.dataAvailable = this.devices.length > 0;
      this.firmwares = Array.from(new Set(this.devices.map(d => d.firmware).filter(firmware => firmware)));
      this.firmwaresCheckboxs = Array(this.firmwares.length).fill(false);
      this.firstFourFirmwares = this.firmwares.slice(0, 4);
      this.remainingFirmwares = this.firmwares.slice(4);
      this.filteredDevices = this.devices;

    } catch (error) {
        console.log(error);
        this.dataAvailable = false;
    } finally {
        this.loadingData = false;
    }
}


}
