<div class="movanoStats" [ngClass]="{ movanoStats_Dark: darkMode }">
  <span class="movanoStats_Tittle">Wearable stats</span>
  <div style="display: flex; flex-direction: row">
    <div class="movanoStats_DognutStats">
      <div echarts [options]="option_HardwareVersion ?? option_DonutEmpty" style="height: 8em; width: 8em"></div>
      <div class="movanoStats_DognutStats_Highlight">
        <span [ngStyle]="{ color: darkMode ? '#FFFFFF' : '#061E37' }" style="font-size: 167%; font-weight: 700"
          >{{ this.hardVer304 }}%</span
        >
        <div style="display: flex; gap: 0.25em">
          <span [ngStyle]="{ color: darkMode ? '#FF77BF' : '#061E37' }" style="font-size: 100%; font-weight: 400"
            >{{ this.hardVerA00 }}%</span
          >
        </div>
      </div>
    </div>
    <div class="movanoStats_LegendUser" style="gap: 0.5em">
      Hardware version
      <div style="display: flex; gap: 1.1em; color: #022e5c; align-items: center">
        <div style="width: 1.5em; border-radius: 0.75em; background-color: #022e5c; height: 0.67em"></div>
        A00
      </div>
      <div
        style="display: flex; gap: 1.1em; color: #0b84ff; align-items: center"
      >
        <div style="width: 1.5em; border-radius: 0.75em; background-color: #0b84ff; height: 0.67em"></div>
        304
      </div>
      <div style="display: flex; gap: 1.1em; color: #878889; align-items: center">
        <div style="width: 1.5em; border-radius: 0.75em; background-color: #878889; height: 0.67em"></div>
        Others
      </div>
    </div>
  </div>
  <div class="movanoStats_Divider" [ngStyle]="{ borderColor: darkMode ? '#ffffff2b' : '#D1F5FF' }"></div>
  <div style="display: flex; flex-direction: row">
    <div class="movanoStats_DognutStats">
      <div echarts [options]="option_WearableStats ?? option_DonutEmpty" style="height: 8em; width: 8em"></div>
      <div class="movanoStats_DognutStats_Highlight">
        <span
          [ngStyle]="{ color: darkMode ? '#FFFFFF' : '#061E37' }"
          style="font-size: 167%; font-weight: 700"
          >{{ this.firmwareVersionPer[0] }}</span
        >
        <div style="display: flex; gap: 0.33em">
          <span
            [ngStyle]="{ color: darkMode ? '#000A14' : '#054280' }"
            style="font-size: 100%; font-weight: 400"
            >{{ this.firmwareVersionPer[1] }}</span
          >
          <span style="color: #0b84ff; font-size: 100%; font-weight: 400" >{{ this.firmwareVersionPer[2] }}</span>
        </div>
      </div>
    </div>
    <div class="movanoStats_LegendUser" style="gap: 0.4em; padding-left: 20px;">
      Firmware version
      <div *ngFor="let firmware of firmwareVersionName; let i = index" style="display: flex; gap: 1.1em; color: #022e5c; align-items: center">
        <div [style.background-color]="getFirmwareColor(i)" style="width: 1.5em; border-radius: 0.75em; height: 0.67em"></div>
        {{ firmware || 'Others' }}
      </div>
    </div>
  </div>
  <div class="movanoStats_Divider" [ngStyle]="{ borderColor: darkMode ? '#ffffff2b' : '#D1F5FF' }"></div>
  <div style="display: flex; flex-direction: row">
    <div class="movanoStats_DognutStats">
      <div echarts [options]="option_AppVersion ?? option_DonutEmpty" style="height: 8em; width: 8em"></div>
      <div class="movanoStats_DognutStats_Highlight">
        <span
          [ngStyle]="{ color: darkMode ? '#FFFFFF' : '#49004B' }"
          style="font-size: 167%; font-weight: 700"
          >{{ this.appVersionPer[0] }}</span
        >
        <div style="display: flex; gap: 0.25em">
          <span
            [ngStyle]="{ color: darkMode ? '#FF77BF' : '#66006A' }"
            style="font-size: 100%; font-weight: 400"
            >{{ this.appVersionPer[1] }}</span
          >
          <span style="color: #b01065; font-size: 100%; font-weight: 400" >{{ this.appVersionPer[2] }}</span>
        </div>
      </div>
    </div>
    <div class="movanoStats_LegendUser" style="gap: 0.5em; padding-left: 20px;">
      App version
      <div *ngFor="let app of appVersionName; let i = index" style="display: flex; gap: 1.1em; color: #022e5c; align-items: center">
        <div [style.background-color]="getAppColor(i)" style="width: 1.5em; border-radius: 0.75em; height: 0.67em"></div>
        {{ app || 'Others' }}
      </div>
    </div>
  </div>
</div>
