<form [formGroup]="recoverFormGroup" [ngClass]="{'movanoDarkRP': darkMode}">
  <div class="recoverPass" *ngIf="step === 0">
    <button mat-icon-button mat-dialog-close class="recoverPass_close">
      <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M11.25 1.8075L10.1925 0.75L6 4.9425L1.8075 0.75L0.75 1.8075L4.9425 6L0.75 10.1925L1.8075 11.25L6 7.0575L10.1925 11.25L11.25 10.1925L7.0575 6L11.25 1.8075Z"
          fill="#C4D9EE" />
      </svg>
    </button>
    <div class="recoverPass_Header">
      <span class="recoverPass_Header_Tittle">{{LITERALS.RECOVER_PASS.TITTLE}}</span>
      <span class="recoverPass_Header_Text">{{LITERALS.RECOVER_PASS.TEXT}}</span>
    </div>
    <input [ngClass]="{errorInput: errorMsg}" type="text" placeholder="Email addres" formControlName="email"
      style="margin-bottom: 1vw;">
    <span class="recoverPass_Error" *ngIf="errorMsg">Error: {{errorMsg}}</span>
    <app-movano-button (click)="forgotPassword()" [buttonText]="LITERALS.RECOVER_PASS.BUTTON"
      [size]="'medium'"></app-movano-button>
  </div>

  <div class="recoverPass" *ngIf="step === 2">
    <button mat-icon-button mat-dialog-close class="recoverPass_close">
      <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M11.25 1.8075L10.1925 0.75L6 4.9425L1.8075 0.75L0.75 1.8075L4.9425 6L0.75 10.1925L1.8075 11.25L6 7.0575L10.1925 11.25L11.25 10.1925L7.0575 6L11.25 1.8075Z"
          fill="#C4D9EE" />
      </svg>
    </button>
    <div class="recoverPass_Header">
      <span class="recoverPass_Header_Tittle">{{LITERALS.RECOVER_PASS.CODE_TITTLE}}</span>
      <span class="recoverPass_Header_Text">{{LITERALS.RECOVER_PASS.CODE_TEXT}}</span>
    </div>
    <input type="txt" formControlName="code" placeholder="Code" style="margin-bottom: 1vw;">
    <span class="recoverPass_Error" *ngIf="codeError">Error: {{LITERALS.RECOVER_PASS.CODE_ERROR}}</span>
    <app-movano-button *ngIf="codeError" (click)="newCodeCognito()"
      [buttonText]="LITERALS.RECOVER_PASS.CODE_BUTTON_RESEND" [size]="'medium'" [outlined]="true">
    </app-movano-button>
    <app-movano-button (click)="step = 4" [buttonText]="LITERALS.RECOVER_PASS.CODE_BUTTON"
      [size]="'medium'"></app-movano-button>
  </div>

  <div class="recoverPass" *ngIf="step === 4">
    <button mat-icon-button mat-dialog-close class="recoverPass_close">
      <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M11.25 1.8075L10.1925 0.75L6 4.9425L1.8075 0.75L0.75 1.8075L4.9425 6L0.75 10.1925L1.8075 11.25L6 7.0575L10.1925 11.25L11.25 10.1925L7.0575 6L11.25 1.8075Z"
          fill="#C4D9EE" />
      </svg>
    </button>
    <div class="recoverPass_Header">
      <span class="recoverPass_Header_Tittle">{{LITERALS.RECOVER_PASS.NEW_PASS_TITTLE}}</span>
    </div>
    <div class="recoverPass_newPass">
      <input (input)="checkPassQuality()" type="password" formControlName="password" placeholder="New password">
      <span>{{passQuality}}</span>
    </div>
    <div class="recoverPass_newPass">
      <input (input)="checkPassQuality()" type="password" formControlName="repeatPassword"
        placeholder="Repeat password">
    </div>
    <span class="recoverPass_Error" *ngIf="!comparePasswords()">Error: {{LITERALS.RECOVER_PASS.NEW_PASS_ERROR}}</span>
    <app-movano-button (click)="newPassword()" [buttonText]="LITERALS.RECOVER_PASS.NEW_PASS_BUTTON"
      [size]="'medium'"></app-movano-button>
  </div>

  <div *ngIf="step%2 === 1" style="padding: 1.39vw;">
    <p-progressSpinner [style]="{width: '3.6vw', height: '3.6vw'}" styleClass="custom-spinner" strokeWidth="2"
      animationDuration="1.5s"></p-progressSpinner>
  </div>

  <!-- <mat-spinner  [diameter]="40" *ngIf="step === 2"></mat-spinner> -->
  <div class="recoverPass" *ngIf="step === 6 && !apiError">
    <button mat-icon-button mat-dialog-close class="recoverPass_close">
      <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M11.25 1.8075L10.1925 0.75L6 4.9425L1.8075 0.75L0.75 1.8075L4.9425 6L0.75 10.1925L1.8075 11.25L6 7.0575L10.1925 11.25L11.25 10.1925L7.0575 6L11.25 1.8075Z"
          fill="#C4D9EE" />
      </svg>
    </button>

    <svg class="recoverPass_EndSVG" width="2.222vw" height="2.222vw" viewBox="0 0 32 32" fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_75_1980)">
        <path d="M12.9999 20.17L8.82991 16L7.40991 17.41L12.9999 23L24.9999 11L23.5899 9.59L12.9999 20.17Z"
          fill="#00FFAA" />
      </g>
      <circle cx="16" cy="16" r="15.5" stroke="#00FFAA" />
      <defs>
        <clipPath id="clip0_75_1980">
          <rect width="24" height="24" fill="white" transform="translate(4 4)" />
        </clipPath>
      </defs>
    </svg>

    <div class="recoverPass_Header">
      <span class="recoverPass_Tittle">{{LITERALS.RECOVER_PASS.END}}</span>
    </div>
    <app-movano-button mat-dialog-close [buttonText]="LITERALS.RECOVER_PASS.END_BUTTON"
      [size]="'medium'"></app-movano-button>
  </div>
  <div class="recoverPass" *ngIf="step === 6 && apiError">
    <button mat-icon-button mat-dialog-close class="recoverPass_close">
      <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M11.25 1.8075L10.1925 0.75L6 4.9425L1.8075 0.75L0.75 1.8075L4.9425 6L0.75 10.1925L1.8075 11.25L6 7.0575L10.1925 11.25L11.25 10.1925L7.0575 6L11.25 1.8075Z"
          fill="#C4D9EE" />
      </svg>
    </button>

    <svg class="recoverPass_EndSVG" width="2.222vw" height="2.222vw" viewBox="0 0 32 32" fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_75_1980)">
        <path d="M12.9999 20.17L8.82991 16L7.40991 17.41L12.9999 23L24.9999 11L23.5899 9.59L12.9999 20.17Z"
          fill="#00FFAA" />
      </g>
      <circle cx="16" cy="16" r="15.5" stroke="#00FFAA" />
      <defs>
        <clipPath id="clip0_75_1980">
          <rect width="24" height="24" fill="white" transform="translate(4 4)" />
        </clipPath>
      </defs>
    </svg>

    <div class="recoverPass_Header">
      <span class="recoverPass_Header_Tittle">{{LITERALS.RECOVER_PASS.END_ERROR}}</span>
      <span class="recoverPass_Header_Text">{{LITERALS.RECOVER_PASS.END_ERROR_TEXT}}</span>
    </div>
    <app-movano-button mat-dialog-close [buttonText]="LITERALS.RECOVER_PASS.END_BUTTON"
      [size]="'medium'"></app-movano-button>
  </div>
  <div class="recoverPass" *ngIf="step === 5">
  </div>
</form>