<ng-template pTemplate="header">
  <h3>{{LITERALS.FEATURES_TAB.TITTLE}}</h3>
</ng-template>
<div [ngClass]="{'movanoDark': darkMode}">
  <div class="movanoLeftMenu">
    <div class="movanoLeftMenu_Header">
      <span>{{LITERALS.FEATURES_TAB.TITTLE}}</span>
      <svg (click)="closeHimself.emit()" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M11.25 1.8075L10.1925 0.75L6 4.9425L1.8075 0.75L0.75 1.8075L4.9425 6L0.75 10.1925L1.8075 11.25L6 7.0575L10.1925 11.25L11.25 10.1925L7.0575 6L11.25 1.8075Z"
          fill="#C4D9EE" />
      </svg>
    </div>

    <div matRipple (click)="goTo('dashboard')" class="movanoLeftMenu_Button" (click)="goTo('dashboard')">
      <div class="movanoLeftMenu_Button_Tittle">
        <svg class="movanoLeftMenu_Button_Tittle_Icon01" viewBox="0 0 23 23" fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_732_5130)">
            <path
              d="M3.83333 12.4583H9.58333C10.1104 12.4583 10.5417 12.0271 10.5417 11.5V3.83333C10.5417 3.30625 10.1104 2.875 9.58333 2.875H3.83333C3.30625 2.875 2.875 3.30625 2.875 3.83333V11.5C2.875 12.0271 3.30625 12.4583 3.83333 12.4583ZM3.83333 20.125H9.58333C10.1104 20.125 10.5417 19.6938 10.5417 19.1667V15.3333C10.5417 14.8062 10.1104 14.375 9.58333 14.375H3.83333C3.30625 14.375 2.875 14.8062 2.875 15.3333V19.1667C2.875 19.6938 3.30625 20.125 3.83333 20.125ZM13.4167 20.125H19.1667C19.6938 20.125 20.125 19.6938 20.125 19.1667V11.5C20.125 10.9729 19.6938 10.5417 19.1667 10.5417H13.4167C12.8896 10.5417 12.4583 10.9729 12.4583 11.5V19.1667C12.4583 19.6938 12.8896 20.125 13.4167 20.125ZM12.4583 3.83333V7.66667C12.4583 8.19375 12.8896 8.625 13.4167 8.625H19.1667C19.6938 8.625 20.125 8.19375 20.125 7.66667V3.83333C20.125 3.30625 19.6938 2.875 19.1667 2.875H13.4167C12.8896 2.875 12.4583 3.30625 12.4583 3.83333Z"
              fill="var(--main-blue-dark)" />
          </g>
          <defs>
            <clipPath id="clip0_732_5130">
              <rect width="23" height="23" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </div>
      <div class="movanoLeftMenu_Button_Txt">
        <span>{{LITERALS.FEATURES_TAB.DASHBOARD}}</span>
      </div>
      <svg class="movanoLeftMenu_Button_Tittle_Icon02" viewBox="0 0 22 22" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_691_708)">
          <path
            d="M11 1.83337C5.94004 1.83337 1.83337 5.94004 1.83337 11C1.83337 16.06 5.94004 20.1667 11 20.1667C16.06 20.1667 20.1667 16.06 20.1667 11C20.1667 5.94004 16.06 1.83337 11 1.83337ZM9.16671 15.125V6.87504L14.6667 11L9.16671 15.125Z"
            [style.fill]="(darkMode) ? '#D1F5FF' : '#284767'"  />
        </g>
        <defs>
          <clipPath id="clip0_691_708">
            <rect width="22" height="22" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
    <hr style="border: 1px solid #C4D9EE;">
    <span class="title">Customers</span>
    <div matRipple (click)="goTo('devices')" class="movanoLeftMenu_Button">
      <div class="movanoLeftMenu_Button_Tittle">
        <svg class="movanoLeftMenu_Button_Tittle_Icon03" viewBox="0 0 14 22" fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M13.8362 11.4262C13.8362 9.21515 12.8307 7.23908 11.2676 5.96814L10.4565 0.980103L3.69726 0.980103L2.8946 5.96814C1.32307 7.23908 0.317627 9.20644 0.317627 11.4262C0.317627 13.6461 1.32307 15.6134 2.8946 16.8844L3.69726 21.8724H10.4565L11.2676 16.8844C12.8307 15.6134 13.8362 13.6373 13.8362 11.4262ZM2.00744 11.4262C2.00744 8.54485 4.28025 6.20318 7.0769 6.20318C9.87354 6.20318 12.1463 8.54485 12.1463 11.4262C12.1463 14.3076 9.87354 16.6493 7.0769 16.6493C4.28025 16.6493 2.00744 14.3076 2.00744 11.4262Z"
            fill="#284767" />
        </svg>
      </div>
      <div class="movanoLeftMenu_Button_Txt">
        <span>{{LITERALS.FEATURES_TAB.DEVICES}}</span>
      </div>
    </div>
    <div matRipple (click)="goTo('users')" class="movanoLeftMenu_Button">
      <div class="movanoLeftMenu_Button_Tittle">
        <svg class="movanoLeftMenu_Button_Tittle_Icon04" viewBox="0 0 20 17" fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M6.22953 0C4.59978 0 3.27881 1.31737 3.27881 2.94238C3.27881 4.5674 4.59978 5.88453 6.22953 5.88453C7.85927 5.88453 9.18024 4.5674 9.18024 2.94238C9.18024 1.31737 7.85927 0 6.22953 0ZM14.7541 1.96163C13.3055 1.96163 12.1311 3.13264 12.1311 4.57704C12.1311 6.02143 13.3055 7.19244 14.7541 7.19244C16.2027 7.19244 17.3769 6.02143 17.3769 4.57704C17.3769 3.13264 16.2027 1.96163 14.7541 1.96163ZM6.22953 8.5C2.7784 8.5 0 10.3916 0 12.0962V17H12.4591V12.0962C12.4591 10.3916 9.68077 8.5 6.22953 8.5ZM14.7541 9.80756C14.1495 9.80756 13.5676 9.88144 13.0224 10.0018C13.4832 10.6016 13.7705 11.2976 13.7705 12.0962V15.0383H20V13.0767C20 11.5516 17.8419 9.80756 14.7541 9.80756Z"
            fill="#284767" />
        </svg>
      </div>
      <div class="movanoLeftMenu_Button_Txt">
        <span>{{LITERALS.FEATURES_TAB.USERS}}</span>
      </div>
    </div>
    <div matRipple (click)="goTo('notifications')" class="movanoLeftMenu_Button">
      <div class="movanoLeftMenu_Button_Tittle">
        <svg class="movanoLeftMenu_Button_Tittle_Icon04" viewBox="0 0 20 17" fill="#284767" xmlns="http://www.w3.org/2000/svg">
          <path d="M7.99996 18.1667C9.00829 18.1667 9.83329 17.3417 9.83329 16.3334H6.16663C6.16663 17.3417 6.98246 18.1667 7.99996 18.1667ZM13.5 12.6667V8.08335C13.5 5.26919 11.9966 2.91335 9.37496 2.29002V0.291687H6.62496V2.29002C3.99413 2.91335 2.49996 5.26002 2.49996 8.08335V12.6667L0.666626 14.5V15.4167H15.3333V14.5L13.5 12.6667Z" fill="#284767"/>
          </svg>
          
      </div>
      <div class="movanoLeftMenu_Button_Txt">
        <span>{{LITERALS.FEATURES_TAB.NOTIFICATIONS}}</span>
      </div>
    </div>
    <div matRipple class="movanoLeftMenu_Button disabled">
      <div class="movanoLeftMenu_Button_Tittle">
        <svg class="movanoLeftMenu_Button_Tittle_Icon07"  width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path opacity="0.3" d="M14.1667 0.841677L5.83341 0.833344C4.91675 0.833344 4.16675 1.58334 4.16675 2.50001V17.5C4.16675 18.4167 4.91675 19.1667 5.83341 19.1667H14.1667C15.0834 19.1667 15.8334 18.4167 15.8334 17.5V2.50001C15.8334 1.58334 15.0834 0.841677 14.1667 0.841677ZM14.1667 15.8333H5.83341V4.16668H14.1667V15.8333Z" [style.fill]="(darkMode) ? '#D1F5FF' : '#284767'" />
          </svg>
          
          
      </div>
      <div class="movanoLeftMenu_Button_Txt disabled">
        <span>{{LITERALS.FEATURES_TAB.MOBILE}}</span>
      </div>
    </div>
    <hr style="border: 1px solid #C4D9EE;">
    <span class="title">Management</span>
    <div matRipple (click)="goTo('stats')"  class="movanoLeftMenu_Button">
      <div class="movanoLeftMenu_Button_Tittle">
        <svg class="movanoLeftMenu_Button_Tittle_Icon08" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_3262_37771)">
          <path d="M8.25 1.5V16.5C4.4475 16.125 1.5 12.9075 1.5 9C1.5 5.0925 4.4475 1.875 8.25 1.5ZM9.7725 1.5V8.2425H16.5C16.1475 4.6875 13.32 1.8525 9.7725 1.5ZM9.7725 9.7575V16.5C13.3275 16.1475 16.1475 13.3125 16.5 9.7575H9.7725Z" [style.fill]="(darkMode) ? '#D1F5FF' : '#284767'" />
          </g>
          <defs>
          <clipPath id="clip0_3262_37771">
          <rect width="18" height="18" fill="white"/>
          </clipPath>
          </defs>
          </svg>
          
      </div>
      <div  class="movanoLeftMenu_Button_Txt">
        <span>{{LITERALS.FEATURES_TAB.STATS}}</span>
      </div>
    </div>

    <div matRipple  class="movanoLeftMenu_Button">
      <div class="movanoLeftMenu_Button_Tittle">
        <svg class="movanoLeftMenu_Button_Tittle_Icon06" width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_3262_37772)">
          <path d="M11.3334 0.708344H1.41675V12.0417H2.83341V2.12501H11.3334V0.708344ZM10.6251 3.54168L14.8751 7.79168V16.2917H4.25008V3.54168H10.6251ZM9.91675 8.50001H13.8126L9.91675 4.60418V8.50001Z" [style.fill]="(darkMode) ? '#D1F5FF' : '#284767'" />
          </g>
          <defs>
          <clipPath id="clip0_3262_37772">
          <rect width="17" height="17" fill="white"/>
          </clipPath>
          </defs>
          </svg>        
      </div>
      <div class="movanoLeftMenu_Button_Txt">
        <span>{{LITERALS.FEATURES_TAB.ADMIN}}</span>
      </div>
    </div>


    <div matRipple  class="movanoLeftMenu_Button disabled">
      <div class="movanoLeftMenu_Button_Tittle">
        <svg class="movanoLeftMenu_Button_Tittle_Icon05" width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_3262_37773)">
          <path opacity="0.3" d="M10.2916 12.6667H8.70825C8.27283 12.6667 7.91658 12.3104 7.91658 11.875H2.38284V15.0417C2.38284 15.9125 3.09534 16.625 3.96617 16.625H15.0416C15.9124 16.625 16.6249 15.9125 16.6249 15.0417V11.875H11.0833C11.0833 12.3104 10.727 12.6667 10.2916 12.6667ZM15.8333 5.54167H12.6666C12.6666 3.79208 11.2495 2.375 9.49992 2.375C7.75033 2.375 6.33325 3.79208 6.33325 5.54167H3.16659C2.29575 5.54167 1.58325 6.25417 1.58325 7.125V9.5C1.58325 10.3787 2.28784 11.0833 3.16659 11.0833H7.91658V10.2917C7.91658 9.85625 8.27283 9.5 8.70825 9.5H10.2916C10.727 9.5 11.0833 9.85625 11.0833 10.2917V11.0833H15.8333C16.7041 11.0833 17.4166 10.3708 17.4166 9.5V7.125C17.4166 6.25417 16.7041 5.54167 15.8333 5.54167ZM7.91658 5.54167C7.91658 4.67083 8.62909 3.95833 9.49992 3.95833C10.3708 3.95833 11.0833 4.67083 11.0833 5.54167H7.90867H7.91658Z" [style.fill]="(darkMode) ? '#D1F5FF' : '#284767'" />
          </g>
          <defs>
          <clipPath id="clip0_3262_37773">
          <rect width="19" height="19" fill="white"/>
          </clipPath>
          </defs>
          </svg>
          
          
      </div>
      <div class="movanoLeftMenu_Button_Txt disabled">
        <span>{{LITERALS.FEATURES_TAB.ORGANIZATIONS}}</span>
      </div>
    </div>
  </div>
</div>