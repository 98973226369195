<div class="movanoStats">
  <div class="movanoStats_Components">
    <div class="movanoStats_TotalBoxes">
      <app-stats-total-info></app-stats-total-info>
      <div class="movanoCarousel_Element">
        <span class="movanoCarousel_Element_Total movanoCarousel_Element_Total_ActiveUsers">{{totalUsers}}</span>
        <div class="movanoCarousel_Element_Info">
          <span>active users</span>
          <span>{{ dynamicText }}</span>
        </div>
        <div class="movanoCarousel_Element_Arrows">
          <svg
            width="max(0.833vw, 12px)"
            height="max(0.7vw, 9px)"
            viewBox="0 0 12 9"
            fill="#00FFAA"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.68332 8.63611L10.3167 8.63611C10.975 8.63611 11.375 7.91111 11.0167 7.35277L6.69999 0.569442C6.37499 0.0527751 5.62499 0.0527752 5.29165 0.569442L0.98332 7.35278C0.624986 7.91111 1.02499 8.63611 1.68332 8.63611Z"
            />
          </svg>
          <svg
            width="max(0.833vw, 12px)"
            height="max(0.7vw, 9px)"
            viewBox="0 0 12 10"
            fill="C4D9EE"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.3167 0.96936L1.68335 0.96936C1.02501 0.96936 0.625013 1.69436 0.983346 2.25269L5.30001 9.03603C5.62501 9.55269 6.37501 9.55269 6.70835 9.03603L11.0167 2.25269C11.375 1.69436 10.975 0.96936 10.3167 0.96936Z"
            />
          </svg>
        </div>
        <div class="movanoCarousel_Element_LastTxt">
          <span class="movanoCarousel_Element_LastTxt_More">{{diference}} more</span>
          <span class="movanoCarousel_Element_LastTxt_Pre">than the last</span>
        </div>
      </div>
      <app-stats-new-users [newUsers]="newUsers" [newUsersDif]="newUsersDif" [dynamicText]="dynamicText" [dayAverage]="dayAverage"></app-stats-new-users>
      <app-stats-widget-two [maxValue]="maxValue" [minValue]="minValue" [dynamicText]="dynamicText"></app-stats-widget-two>
    </div>
    <div class="movanoStats_Middle_Activity_Wrapper">
      <div class="movanoStats_Components_Middle">
          <app-stats-widget-chart [dashboardType]="'Users'"></app-stats-widget-chart>
      </div>
        <!-- <div class="movanoStats_Components_Map">
          <app-stats-map [templateName]="'mapUsers'"></app-stats-map>
        </div> -->
      <div class="movanoDashboard_Components_ActivityStats">
        <app-stats-last-graph [darkMode]="darkMode" [footerData]="footerData"></app-stats-last-graph>
      </div>
  </div>

  <!-- <div
style="position: absolute; top: 50%; left: 50%;">
<p-progressSpinner [style]="{width: '6vw', height: '6vw'}"
  styleClass="{{darkMode ? 'custom-spinner' : 'custom-spinner-blue'}}" strokeWidth="2" animationDuration="1.5s">
</p-progressSpinner>
</div> -->
</div>


</div>
