<div class="movanoPaginator">
  <svg (click)="previous()" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.3333 5.33335L3.21996 5.33335L6.94663 1.60669L5.99996 0.666687L0.666626 6.00002L5.99996 11.3334L6.93996 10.3934L3.21996 6.66669L11.3333 6.66669V5.33335Z"
      [ngStyle]="{fill : state.page > 0 ? '#0B84FF' : '#AABED3'}" />
  </svg>
  <td
    [ngClass]="(firstPage + (rows * 0)) === pageSelected ? 'movanoPaginator_PageSlcFirst' : 'movanoPaginator_PageSlc'"
    (click)="selecPage((firstPage/rows))">
    {{(firstPage/rows) + 1 | number:'1.0-0'}}</td>
  <td *ngIf="(firstPage/rows) + 2 <=  state.pageCount"
    [ngClass]="(firstPage + (rows * 1)) === pageSelected ? 'movanoPaginator_PageSlcFirst' : 'movanoPaginator_PageSlc'"
    (click)="selecPage((firstPage/rows) + 1)">
    {{(firstPage/rows) + 2 | number:'1.0-0'}}</td>
  <td *ngIf="(firstPage/rows) + 3 <=  state.pageCount"
    [ngClass]="(firstPage + (rows * 2)) === pageSelected ? 'movanoPaginator_PageSlcFirst' : 'movanoPaginator_PageSlc'"
    (click)="selecPage((firstPage/rows) + 2)">
    {{(firstPage/rows) + 3 | number:'1.0-0'}}</td>
  <td *ngIf="(firstPage/rows) + 4 <=  state.pageCount"
    [ngClass]="(firstPage + (rows * 3)) === pageSelected ? 'movanoPaginator_PageSlcFirst' : 'movanoPaginator_PageSlc'"
    (click)="selecPage((firstPage/rows) + 3)">
    {{(firstPage/rows) + 4 | number:'1.0-0'}}</td>
  <svg *ngIf="(firstPage/rows) + 5 < state.pageCount" viewBox="0 0 18 8" fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <rect width="18" height="8" rx="4" fill="#C4D9EE" />
    <circle cx="9" cy="4" r="1" fill="var(--main-blue-dark)" />
    <circle cx="13" cy="4" r="1" fill="var(--main-blue-dark)" />
    <circle cx="5" cy="4" r="1" fill="var(--main-blue-dark)" />
  </svg>
  <td *ngIf="(firstPage/rows) + 5 <=  state.pageCount"
    [ngClass]="(firstPage + (rows * 3)) < pageSelected ? 'movanoPaginator_PageSlcFirst' : 'movanoPaginator_PageSlc'"
    (click)="selecPage(state.pageCount - 1)">{{state.pageCount}}</td>
  <svg (click)="next(state.pageCount)" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.666647 6.66665L8.77998 6.66665L5.05331 10.3933L5.99998 11.3333L11.3333 5.99998L5.99998 0.666646L5.05998 1.60665L8.77998 5.33331L0.666647 5.33331L0.666647 6.66665Z"
      [ngStyle]="{fill : state.page < state.pageCount-1 ? '#0B84FF' : '#AABED3'}" />
  </svg>
</div>
<!-- <span class="movanoPaginatorAmont">{{lenght}} {{type}}</span> -->
<!-- {{state | json}} -->