<div class="movanoCarousel"
 [ngClass]="{'movanoCarousel_Dark' : darkMode}">
  <!-- RINGS PRODUCTION -->
  <div class="movanoCarousel_Element">
    <span class="movanoCarousel_Element_Total movanoCarousel_Element_Total_RingsProduction">{{totalProduction}}</span>
    <div class="movanoCarousel_Element_Info">
      <span>rings in production </span>
      <span>{{selectedDate}}</span>
    </div>
    <div class="movanoCarousel_Element_Arrows">
      <svg width="max(0.833vw, 12px)" height="max(0.7vw, 9px)" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M1.68332 8.63611L10.3167 8.63611C10.975 8.63611 11.375 7.91111 11.0167 7.35277L6.69999 0.569442C6.37499 0.0527751 5.62499 0.0527752 5.29165 0.569442L0.98332 7.35278C0.624986 7.91111 1.02499 8.63611 1.68332 8.63611Z"
          [ngStyle]="{fill : moreProduction ? '#00FFAA' : '#C4D9EE'}" />
      </svg>
      <svg width="max(0.833vw, 12px)" height="max(0.7vw, 9px)" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M10.3167 0.96936L1.68335 0.96936C1.02501 0.96936 0.625013 1.69436 0.983346 2.25269L5.30001 9.03603C5.62501 9.55269 6.37501 9.55269 6.70835 9.03603L11.0167 2.25269C11.375 1.69436 10.975 0.96936 10.3167 0.96936Z"
          [ngStyle]="{fill : moreProduction ? '#C4D9EE' : '#E22B2B'}" />
      </svg>
    </div>
    <div class="movanoCarousel_Element_LastTxt">
      <span class="movanoCarousel_Element_LastTxt_More">{{differProduction}} {{moreProduction ? "more" : "less"}}</span>
      <span class="movanoCarousel_Element_LastTxt_Pre">than the previous</span>
    </div>
  </div>
  <!-- RINGS PRODUCTION -->


  <!-- RINGS SOLD -->
  <div class="movanoCarousel_Element">
    <span class="movanoCarousel_Element_Total movanoCarousel_Element_Total_RingsSold">{{totalSold}}</span>
    <div class="movanoCarousel_Element_Info">
      <span>rings sold</span>
      <span>{{selectedDate}}</span>
    </div>
    <div class="movanoCarousel_Element_Arrows">
      <svg width="max(0.833vw, 12px)" height="max(0.7vw, 9px)" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M1.68332 8.63611L10.3167 8.63611C10.975 8.63611 11.375 7.91111 11.0167 7.35277L6.69999 0.569442C6.37499 0.0527751 5.62499 0.0527752 5.29165 0.569442L0.98332 7.35278C0.624986 7.91111 1.02499 8.63611 1.68332 8.63611Z"
          [ngStyle]="{fill : moreSold ? '#00FFAA' : '#C4D9EE'}" />
      </svg>
      <svg width="max(0.833vw, 12px)" height="max(0.7vw, 9px)" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M10.3167 0.96936L1.68335 0.96936C1.02501 0.96936 0.625013 1.69436 0.983346 2.25269L5.30001 9.03603C5.62501 9.55269 6.37501 9.55269 6.70835 9.03603L11.0167 2.25269C11.375 1.69436 10.975 0.96936 10.3167 0.96936Z"
          [ngStyle]="{fill : moreSold ? '#C4D9EE' : '#E22B2B'}" />
      </svg>
    </div>
    <div class="movanoCarousel_Element_LastTxt">
      <span class="movanoCarousel_Element_LastTxt_More">{{differSold}}  {{moreSold ? "more" : "less"}}</span>
      <span class="movanoCarousel_Element_LastTxt_Pre">than the previous</span>
    </div>
  </div>
  <!-- RINGS SOLD -->


  <!-- NEW USERS -->
  <div class="movanoCarousel_Element">
    <span class="movanoCarousel_Element_Total movanoCarousel_Element_Total_NewUsers">{{totalNewUser}}</span>
    <div class="movanoCarousel_Element_Info">
      <span>new user</span>
      <span>{{selectedDate}}</span>
    </div>
    <div class="movanoCarousel_Element_Arrows">
      <svg width="max(0.833vw, 12px)" height="max(0.7vw, 9px)" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M1.68332 8.63611L10.3167 8.63611C10.975 8.63611 11.375 7.91111 11.0167 7.35277L6.69999 0.569442C6.37499 0.0527751 5.62499 0.0527752 5.29165 0.569442L0.98332 7.35278C0.624986 7.91111 1.02499 8.63611 1.68332 8.63611Z"
          [ngStyle]="{fill : moreNewUser ? '#00FFAA' : '#C4D9EE'}" />
      </svg>
      <svg width="max(0.833vw, 12px)" height="max(0.7vw, 9px)" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M10.3167 0.96936L1.68335 0.96936C1.02501 0.96936 0.625013 1.69436 0.983346 2.25269L5.30001 9.03603C5.62501 9.55269 6.37501 9.55269 6.70835 9.03603L11.0167 2.25269C11.375 1.69436 10.975 0.96936 10.3167 0.96936Z"
          [ngStyle]="{fill : moreNewUser ? '#C4D9EE' : '#E22B2B'}" />
      </svg>
    </div>
    <div class="movanoCarousel_Element_LastTxt">
      <span class="movanoCarousel_Element_LastTxt_More">{{differNewUser}}  {{moreNewUser ? "more" : "less"}}</span>
      <span class="movanoCarousel_Element_LastTxt_Pre">than the previous</span>
    </div>
  </div>
  <!-- NEW USERS -->


  <!-- INCIDENTS -->
<!--   <div class="movanoCarousel_Element">
    <span class="movanoCarousel_Element_Total movanoCarousel_Element_Total_Incidents">{{totalIncidents}}</span>
    <div class="movanoCarousel_Element_Info">
      <span>incidents</span>
      <span>{{selectedDate}}</span>
    </div>
    <div class="movanoCarousel_Element_Arrows">
      <svg width="max(0.833vw, 12px)" height="max(0.7vw, 9px)" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M1.68332 8.63611L10.3167 8.63611C10.975 8.63611 11.375 7.91111 11.0167 7.35277L6.69999 0.569442C6.37499 0.0527751 5.62499 0.0527752 5.29165 0.569442L0.98332 7.35278C0.624986 7.91111 1.02499 8.63611 1.68332 8.63611Z"
          [ngStyle]="{fill : moreIncidents ? '#00FFAA' : '#C4D9EE'}" />
      </svg>
      <svg width="max(0.833vw, 12px)" height="max(0.7vw, 9px)" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M10.3167 0.96936L1.68335 0.96936C1.02501 0.96936 0.625013 1.69436 0.983346 2.25269L5.30001 9.03603C5.62501 9.55269 6.37501 9.55269 6.70835 9.03603L11.0167 2.25269C11.375 1.69436 10.975 0.96936 10.3167 0.96936Z"
          [ngStyle]="{fill : moreIncidents ? '#C4D9EE' : '#E22B2B'}" />
      </svg>
    </div>
    <div class="movanoCarousel_Element_LastTxt">
      <span class="movanoCarousel_Element_LastTxt_More">{{differIncidents}}  {{moreIncidents ? "more" : "less"}}</span>
      <span class="movanoCarousel_Element_LastTxt_Pre">than the previous</span>
    </div>
  </div> -->
  <!-- INCIDENTS -->


  <!-- UNDER REPAIR -->
<!--   <div class="movanoCarousel_Element">
    <span class="movanoCarousel_Element_Total movanoCarousel_Element_Total_UnderRepair">{{totalUnderRep}}</span>
    <div class="movanoCarousel_Element_Info">
      <span>under repair</span>
      <span>{{selectedDate}}</span>
    </div>
    <div class="movanoCarousel_Element_Arrows">
      <svg width="max(0.833vw, 12px)" height="max(0.7vw, 9px)" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M1.68332 8.63611L10.3167 8.63611C10.975 8.63611 11.375 7.91111 11.0167 7.35277L6.69999 0.569442C6.37499 0.0527751 5.62499 0.0527752 5.29165 0.569442L0.98332 7.35278C0.624986 7.91111 1.02499 8.63611 1.68332 8.63611Z"
          [ngStyle]="{fill : moreUnderRep ? '#00FFAA' : '#C4D9EE'}" />
      </svg>
      <svg width="max(0.833vw, 12px)" height="max(0.7vw, 9px)" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M10.3167 0.96936L1.68335 0.96936C1.02501 0.96936 0.625013 1.69436 0.983346 2.25269L5.30001 9.03603C5.62501 9.55269 6.37501 9.55269 6.70835 9.03603L11.0167 2.25269C11.375 1.69436 10.975 0.96936 10.3167 0.96936Z"
          [ngStyle]="{fill : moreUnderRep ? '#C4D9EE' : '#E22B2B'}" />
      </svg>
    </div>
    <div class="movanoCarousel_Element_LastTxt">
      <span class="movanoCarousel_Element_LastTxt_More">{{differUnderRep}}  {{moreUnderRep ? "more" : "less"}}</span>
      <span class="movanoCarousel_Element_LastTxt_Pre">than the previous</span>
    </div>
  </div> -->
  <!-- UNDER REPAIR -->


  <!-- SLEEP ANALYSIS -->
  <div class="movanoCarousel_Element">
    <span class="movanoCarousel_Element_Total movanoCarousel_Element_Total_SleepAnalysis">{{totalSleepAnaly}}</span>
    <div class="movanoCarousel_Element_Info">
      <span>sleep analysis</span>
      <span>syncs {{selectedDate}}</span>
    </div>
    <div class="movanoCarousel_Element_Arrows">
      <svg width="max(0.833vw, 12px)" height="max(0.7vw, 9px)" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M1.68332 8.63611L10.3167 8.63611C10.975 8.63611 11.375 7.91111 11.0167 7.35277L6.69999 0.569442C6.37499 0.0527751 5.62499 0.0527752 5.29165 0.569442L0.98332 7.35278C0.624986 7.91111 1.02499 8.63611 1.68332 8.63611Z"
          [ngStyle]="{fill : moreSleepAnaly ? '#00FFAA' : '#C4D9EE'}" />
      </svg>
      <svg width="max(0.833vw, 12px)" height="max(0.7vw, 9px)" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M10.3167 0.96936L1.68335 0.96936C1.02501 0.96936 0.625013 1.69436 0.983346 2.25269L5.30001 9.03603C5.62501 9.55269 6.37501 9.55269 6.70835 9.03603L11.0167 2.25269C11.375 1.69436 10.975 0.96936 10.3167 0.96936Z"
          [ngStyle]="{fill : moreSleepAnaly ? '#C4D9EE' : '#E22B2B'}" />
      </svg>
    </div>
    <div class="movanoCarousel_Element_LastTxt">
      <span class="movanoCarousel_Element_LastTxt_More">{{differSleepAnaly}}  {{moreSleepAnaly ? "more" : "less"}}</span>
      <span class="movanoCarousel_Element_LastTxt_Pre">than the previous</span>
    </div>
  </div>
  <!-- SLEEP ANALYSIS -->

  <!--SECOND PART-->
    <!-- RINGS PRODUCTION -->
    <div class="movanoCarousel_Element">
      <span class="movanoCarousel_Element_Total movanoCarousel_Element_Total_RingsProduction">{{totalProduction}}</span>
      <div class="movanoCarousel_Element_Info">
        <span>rings in production</span>
        <span>{{selectedDate}}</span>
      </div>
      <div class="movanoCarousel_Element_Arrows">
        <svg width="max(0.833vw, 12px)" height="max(0.7vw, 9px)" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M1.68332 8.63611L10.3167 8.63611C10.975 8.63611 11.375 7.91111 11.0167 7.35277L6.69999 0.569442C6.37499 0.0527751 5.62499 0.0527752 5.29165 0.569442L0.98332 7.35278C0.624986 7.91111 1.02499 8.63611 1.68332 8.63611Z"
            [ngStyle]="{fill : moreProduction ? '#00FFAA' : '#C4D9EE'}" />
        </svg>
        <svg width="max(0.833vw, 12px)" height="max(0.7vw, 9px)" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M10.3167 0.96936L1.68335 0.96936C1.02501 0.96936 0.625013 1.69436 0.983346 2.25269L5.30001 9.03603C5.62501 9.55269 6.37501 9.55269 6.70835 9.03603L11.0167 2.25269C11.375 1.69436 10.975 0.96936 10.3167 0.96936Z"
            [ngStyle]="{fill : moreProduction ? '#C4D9EE' : '#E22B2B'}" />
        </svg>
      </div>
      <div class="movanoCarousel_Element_LastTxt">
        <span class="movanoCarousel_Element_LastTxt_More">{{differProduction}} {{moreProduction ? "more" : "less"}}</span>
        <span class="movanoCarousel_Element_LastTxt_Pre">than the previous</span>
      </div>
    </div>
    <!-- RINGS PRODUCTION -->


    <!-- RINGS SOLD -->
    <div class="movanoCarousel_Element">
      <span class="movanoCarousel_Element_Total movanoCarousel_Element_Total_RingsSold">{{totalSold}}</span>
      <div class="movanoCarousel_Element_Info">
        <span>rings sold</span>
        <span>{{selectedDate}}</span>
      </div>
      <div class="movanoCarousel_Element_Arrows">
        <svg width="max(0.833vw, 12px)" height="max(0.7vw, 9px)" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M1.68332 8.63611L10.3167 8.63611C10.975 8.63611 11.375 7.91111 11.0167 7.35277L6.69999 0.569442C6.37499 0.0527751 5.62499 0.0527752 5.29165 0.569442L0.98332 7.35278C0.624986 7.91111 1.02499 8.63611 1.68332 8.63611Z"
            [ngStyle]="{fill : moreSold ? '#00FFAA' : '#C4D9EE'}" />
        </svg>
        <svg width="max(0.833vw, 12px)" height="max(0.7vw, 9px)" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M10.3167 0.96936L1.68335 0.96936C1.02501 0.96936 0.625013 1.69436 0.983346 2.25269L5.30001 9.03603C5.62501 9.55269 6.37501 9.55269 6.70835 9.03603L11.0167 2.25269C11.375 1.69436 10.975 0.96936 10.3167 0.96936Z"
            [ngStyle]="{fill : moreSold ? '#C4D9EE' : '#E22B2B'}" />
        </svg>
      </div>
      <div class="movanoCarousel_Element_LastTxt">
        <span class="movanoCarousel_Element_LastTxt_More">{{differSold}}  {{moreSold ? "more" : "less"}}</span>
        <span class="movanoCarousel_Element_LastTxt_Pre">than the previous</span>
      </div>
    </div>
    <!-- RINGS SOLD -->


    <!-- NEW USERS -->
    <div class="movanoCarousel_Element">
      <span class="movanoCarousel_Element_Total movanoCarousel_Element_Total_NewUsers">{{totalNewUser}}</span>
      <div class="movanoCarousel_Element_Info">
        <span>new user</span>
        <span>{{selectedDate}}</span>
      </div>
      <div class="movanoCarousel_Element_Arrows">
        <svg width="max(0.833vw, 12px)" height="max(0.7vw, 9px)" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M1.68332 8.63611L10.3167 8.63611C10.975 8.63611 11.375 7.91111 11.0167 7.35277L6.69999 0.569442C6.37499 0.0527751 5.62499 0.0527752 5.29165 0.569442L0.98332 7.35278C0.624986 7.91111 1.02499 8.63611 1.68332 8.63611Z"
            [ngStyle]="{fill : moreNewUser ? '#00FFAA' : '#C4D9EE'}" />
        </svg>
        <svg width="max(0.833vw, 12px)" height="max(0.7vw, 9px)" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M10.3167 0.96936L1.68335 0.96936C1.02501 0.96936 0.625013 1.69436 0.983346 2.25269L5.30001 9.03603C5.62501 9.55269 6.37501 9.55269 6.70835 9.03603L11.0167 2.25269C11.375 1.69436 10.975 0.96936 10.3167 0.96936Z"
            [ngStyle]="{fill : moreNewUser ? '#C4D9EE' : '#E22B2B'}" />
        </svg>
      </div>
      <div class="movanoCarousel_Element_LastTxt">
        <span class="movanoCarousel_Element_LastTxt_More">{{differNewUser}}  {{moreNewUser ? "more" : "less"}}</span>
        <span class="movanoCarousel_Element_LastTxt_Pre">than the previous</span>
      </div>
    </div>
    <!-- NEW USERS -->


    <!-- INCIDENTS -->
<!--     <div class="movanoCarousel_Element">
      <span class="movanoCarousel_Element_Total movanoCarousel_Element_Total_Incidents">{{totalIncidents}}</span>
      <div class="movanoCarousel_Element_Info">
        <span>incidents</span>
        <span>{{selectedDate}}</span>
      </div>
      <div class="movanoCarousel_Element_Arrows">
        <svg width="max(0.833vw, 12px)" height="max(0.7vw, 9px)" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M1.68332 8.63611L10.3167 8.63611C10.975 8.63611 11.375 7.91111 11.0167 7.35277L6.69999 0.569442C6.37499 0.0527751 5.62499 0.0527752 5.29165 0.569442L0.98332 7.35278C0.624986 7.91111 1.02499 8.63611 1.68332 8.63611Z"
            [ngStyle]="{fill : moreIncidents ? '#00FFAA' : '#C4D9EE'}" />
        </svg>
        <svg width="max(0.833vw, 12px)" height="max(0.7vw, 9px)" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M10.3167 0.96936L1.68335 0.96936C1.02501 0.96936 0.625013 1.69436 0.983346 2.25269L5.30001 9.03603C5.62501 9.55269 6.37501 9.55269 6.70835 9.03603L11.0167 2.25269C11.375 1.69436 10.975 0.96936 10.3167 0.96936Z"
            [ngStyle]="{fill : moreIncidents ? '#C4D9EE' : '#E22B2B'}" />
        </svg>
      </div>
      <div class="movanoCarousel_Element_LastTxt">
        <span class="movanoCarousel_Element_LastTxt_More">{{differIncidents}}  {{moreIncidents ? "more" : "less"}}</span>
        <span class="movanoCarousel_Element_LastTxt_Pre">than the previus</span>
      </div>
    </div> -->
    <!-- INCIDENTS -->


    <!-- UNDER REPAIR -->
<!--     <div class="movanoCarousel_Element">
      <span class="movanoCarousel_Element_Total movanoCarousel_Element_Total_UnderRepair">{{totalUnderRep}}</span>
      <div class="movanoCarousel_Element_Info">
        <span>under repair</span>
        <span>{{selectedDate}}</span>
      </div>
      <div class="movanoCarousel_Element_Arrows">
        <svg width="max(0.833vw, 12px)" height="max(0.7vw, 9px)" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M1.68332 8.63611L10.3167 8.63611C10.975 8.63611 11.375 7.91111 11.0167 7.35277L6.69999 0.569442C6.37499 0.0527751 5.62499 0.0527752 5.29165 0.569442L0.98332 7.35278C0.624986 7.91111 1.02499 8.63611 1.68332 8.63611Z"
            [ngStyle]="{fill : moreUnderRep ? '#00FFAA' : '#C4D9EE'}" />
        </svg>
        <svg width="max(0.833vw, 12px)" height="max(0.7vw, 9px)" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M10.3167 0.96936L1.68335 0.96936C1.02501 0.96936 0.625013 1.69436 0.983346 2.25269L5.30001 9.03603C5.62501 9.55269 6.37501 9.55269 6.70835 9.03603L11.0167 2.25269C11.375 1.69436 10.975 0.96936 10.3167 0.96936Z"
            [ngStyle]="{fill : moreUnderRep ? '#C4D9EE' : '#E22B2B'}" />
        </svg>
      </div>
      <div class="movanoCarousel_Element_LastTxt">
        <span class="movanoCarousel_Element_LastTxt_More">{{differUnderRep}}  {{moreUnderRep ? "more" : "less"}}</span>
        <span class="movanoCarousel_Element_LastTxt_Pre">than the previus</span>
      </div>
    </div> -->
    <!-- UNDER REPAIR -->


    <!-- SLEEP ANALYSIS -->
    <div class="movanoCarousel_Element">
      <span class="movanoCarousel_Element_Total movanoCarousel_Element_Total_SleepAnalysis">{{totalSleepAnaly}}</span>
      <div class="movanoCarousel_Element_Info">
        <span>sleep analysis</span>
        <span>syncs {{selectedDate}}</span>
      </div>
      <div class="movanoCarousel_Element_Arrows">
        <svg width="max(0.833vw, 12px)" height="max(0.7vw, 9px)" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M1.68332 8.63611L10.3167 8.63611C10.975 8.63611 11.375 7.91111 11.0167 7.35277L6.69999 0.569442C6.37499 0.0527751 5.62499 0.0527752 5.29165 0.569442L0.98332 7.35278C0.624986 7.91111 1.02499 8.63611 1.68332 8.63611Z"
            [ngStyle]="{fill : moreSleepAnaly ? '#00FFAA' : '#C4D9EE'}" />
        </svg>
        <svg width="max(0.833vw, 12px)" height="max(0.7vw, 9px)" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M10.3167 0.96936L1.68335 0.96936C1.02501 0.96936 0.625013 1.69436 0.983346 2.25269L5.30001 9.03603C5.62501 9.55269 6.37501 9.55269 6.70835 9.03603L11.0167 2.25269C11.375 1.69436 10.975 0.96936 10.3167 0.96936Z"
            [ngStyle]="{fill : moreSleepAnaly ? '#C4D9EE' : '#E22B2B'}" />
        </svg>
      </div>
      <div class="movanoCarousel_Element_LastTxt">
        <span class="movanoCarousel_Element_LastTxt_More">{{differSleepAnaly}}  {{moreSleepAnaly ? "more" : "less"}}</span>
        <span class="movanoCarousel_Element_LastTxt_Pre">than the previus</span>
      </div>
    </div>
    <!-- SLEEP ANALYSIS -->
</div>
