<app-stats-user-activity-header></app-stats-user-activity-header>
<div class="movanoStats">
  <div class="movanoStats_Components">
    <div class="movanoStats_TotalBoxes">
      <div class="half-width">
        <app-stats-widget-chart
          [dashboardType]="'Sleep analysis'"
          style="margin-top: 1%"
          [showLegend]="true"
          [showAverage]="false"
          [showGrid]="false"
          [sleepStatUserActivity]="true"
          class="echart-big">
        </app-stats-widget-chart>
        <app-stats-user-activity-donuts></app-stats-user-activity-donuts>
      </div>
      <div class="half-width column-container">
        <div class="row">
          <div class="column" style="flex-basis: 100%;" style="flex-basis: 15%;" >
            <p class="columnTitle">Spot Check</p>
            <p class="MaxMin">
              <span class="max">Max</span> /<span class="min">Min Value</span>
            </p>
            <p class="number"><span class="numberSpot1">{{maxSpot}}</span><span class="numberSpot2">/{{minSpot}}</span></p>
            <p class="grey">{{avgSpot}} average</p>
          </div>
          <app-stats-widget-chart
            [dashboardType]="'SpotChecks'"
            style="margin-top: 1%"
            [showLegend]="false"
            [showTitle]="false"
            [showAverage]="false"
            [showYAxisStats]="false"
            [sleepStatUserActivity]="false"
            [showGrid]="false"
            [showBoxShadow]="false"
            class="echart-small"
            style="flex-basis: 85%;" >
          </app-stats-widget-chart>
        </div>
        <div class="row">
          <div class="column" style="flex-basis: 100%;" style="flex-basis: 15%;">
            <p class="columnTitle">Workout usage</p>
            <p class="MaxMin">
              <span class="max">Max</span> /<span class="min">Min Value</span>
            </p>
            <p class="number"><span class="numberWork1">{{maxWork}}</span><span class="numberWork2">/{{minWork}}</span></p>
            <p class="grey">{{avrgWork}} average</p>
            <p class="columSubtitle">Best Hours range</p>
            <p class="number2">{{bestHoursWork}}</p>
            <p class="grey">{{perTotalWork}}% of total</p>
          </div>
          <app-stats-widget-chart
            [dashboardType]="'Workout usage'"
            style="margin-top: 1%"
            [showLegend]="false"
            [showTitle]="false"
            [showAverage]="false"
            [showYAxisStats]="false"
            [sleepStatUserActivity]="false"
            [showGrid]="false"
            [showBoxShadow]="false"
            class="echart-small"
            style="flex-basis: 85%;" >
          </app-stats-widget-chart>
        </div>
        <div class="row">
          <div class="column" style="flex-basis: 100%;" style="flex-basis: 15%;">
            <p class="columnTitle">Notifications</p>
            <p class="MaxMin">
              <span class="max">Max</span> /<span class="min">Min Value</span>
            </p>
            <p class="number"><span class="numberNot1">{{maxNotifications}}</span><span class="numberNot2">/{{minNotifications}}</span></p>
            <p class="grey">{{avgNotifications}} average</p>
            <p class="columSubtitle">Sent to costumer</p>
            <p class="number2">{{sendToCostumer}}%</p>
            <p class="pink">{{notSendToCostumer}}({{perNotSendToCostumer}}%) not sent</p>
          </div>
          <app-stats-widget-chart
            [dashboardType]="'Notifications'"
            style="margin-top: 1%"
            [showLegend]="false"
            [showTitle]="false"
            [showAverage]="false"
            [showYAxisStats]="false"
            [sleepStatUserActivity]="false"
            [showGrid]="false"
            [showBoxShadow]="false"
            class="echart-small"
            style="flex-basis: 85%;" >
          </app-stats-widget-chart>
        </div>
      </div>
    </div>
  </div>
</div>
