export const TODAY = 'today';

export const ACTUAL_WEEK = 'Actual Week';
export const LAST_WEEK = 'Last Week';
export const LAST_SEVEN_DAYS = 'Last 7 days';

export const ACTUAL_MONTH = 'Actual Month';
export const LAST_MONTH = 'Last Month';
export const SELECTED_MONTH = 'Month-';

export const ACTUAL_YEAR = 'Actual Year';
export const YEAR_TO_DATE = 'Year to date';
