import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

//Material
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatRippleModule} from '@angular/material/core';
import {MatTooltipModule} from '@angular/material/tooltip';
import { MatFormFieldModule } from '@angular/material/form-field';



//Prime NG
import { ChartModule } from 'primeng/chart';
import { DividerModule } from 'primeng/divider';
import { ButtonModule } from 'primeng/button';
import { RippleModule } from 'primeng/ripple';
import { SidebarModule } from 'primeng/sidebar';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { TableModule } from 'primeng/table';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { ProgressBarModule } from 'primeng/progressbar';
import { ToastModule } from 'primeng/toast';

//Components
import { LoginComponent } from './login/login.component';
import { MovanoButtonComponent } from './widgets/movano-button/movano-button.component';
import { RecoverPasswordComponent } from './login/recover-password/recover-password.component';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TesterComponent } from './tester/tester.component';
import { MovanoChartComponent } from './widgets/movano-chart/movano-chart.component';
import { MovanoInfoCarouselComponent } from './widgets/movano-info-carousel/movano-info-carousel.component';
import { MovanoTotaInfoComponent } from './widgets/movano-tota-info/movano-tota-info.component';
import { MovanoStatsComponent } from './widgets/movano-stats/movano-stats.component';
import { MovanoActivityComponent } from './widgets/movano-activity/movano-activity.component';
import { MovanoHistoryComponent } from './widgets/movano-history/movano-history.component';
import { MovanoLastComptComponent } from './widgets/movano-last-compt/movano-last-compt.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { MovanoMapComponent } from './widgets/movano-map/movano-map.component';
import { DevicesComponent } from './devices/devices.component';
import { UsersComponent } from './users/users.component';
import { UserPanelComponent } from './users/panel/user-panel.component';
import { UserPanelHealthMetricsComponent } from './users/panel/health-metrics/user-panel-health-metrics.component';
import { UserPanelWaveformDataComponent } from './users/panel/waveform-data/user-panel-waveform-data.component';
import { UserPanelActivityLogComponent } from './users/panel/activity-log/user-panel-activity-log.component';
import { UserPanelSleepDataComponent } from './users/panel/sleep-data/user-panel-sleep-data.component';
import { UserMenuComponent } from './user-menu/user-menu.component';
import { FeaturesTabComponent } from './features-tab/features-tab.component';
import { UserFilterComponent } from './users/filter/user-filter.component';
import { UserDeviceTableComponent } from './users/deviceTable/user-device-table.component';
import { MovanoBarChartComponent } from './widgets/movano-bar-chart/movano-bar-chart.component';
import { NtfInsightApplicationComponent } from './notifications/insight-application/ntf-insight-application.component';
import { InsightAppFilterComponent } from './notifications/insight-application/filter/insight-app-filter.component';
import { NtfNotificationListComponent } from './notifications/notification-list/ntf-notification-list.component';
import { NtfNotificationsListMessageComponent } from './notifications/notification-list/message/ntf-notifications-list-message.component';
import { NotificationListFilterComponent } from './notifications/notification-list/filter/notification-list-filter.component';
import { NotifiationListSideFilterComponent } from './notifications/notification-list/side-filter/notifiation-list-side-filter.component';
import { NtfInsightJobExecutionsComponent } from './notifications/insight-job-executions/ntf-insight-job-executions.component';
import { InsightJobExecutionsFilterComponent } from './notifications/insight-job-executions/filter/insight-job-executions-filter.component';
import { InsightJobExecutionsRunsComponent } from './notifications/insight-job-executions/runs/insight-job-executions-runs.component';
import { UserPanelTimeSelectorComponent } from './users/panel/time-selector/user-panel-time-selector.component';
import { UserPanelSpotCheckComponent } from './users/panel/spot-check/user-panel-spot-check.component';
import { CognitoUserCreateEditComponent } from './cognito-user/create-edit/cognito-user-create-edit.component';
import { CognitoUserTableComponent } from './cognito-user/table/cognito-user-table.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { PaginatorComponent } from './paginator/paginator.component';
import { MovanoHeaderComponent } from './movano-header/movano-header.component';
//E-CHARTS
import { NgxEchartsModule } from 'ngx-echarts';
import { HeaderComponent } from './header/header.component';
import { DevicesTicketsComponent } from './devices/devices-tickets/devices-tickets.component';
import { DevicesTicketsInfoComponent } from './devices/devices-tickets-info/devices-tickets-info.component';
import { UsersBugsComponent } from './users/users-bugs/users-bugs.component';
import { UsersBugsInfoComponent } from './users/users-bugs-info/users-bugs-info.component';
import { UserTicketsComponent } from './users/user-tickets/user-tickets.component';
import { UserTicketsInfoComponent } from './users/user-tickets-info/user-tickets-info.component';
import { WorkoutUsageComponent } from './users/panel/workout-usage/workout-usage.component';
import { UsersNotificationsListComponent } from './users/users-notifications-list/users-notifications-list.component';
import { UsersNtfListFilterComponent } from './users/users-notifications-list/filter/users-ntf-list-filter.component';
import { UsersNtfListSideFilterComponent } from './users/users-notifications-list/side-filter/users-ntf-list-side-filter.component';
import { UsersNtfListMessageComponent } from './users/users-notifications-list/message/users-ntf-list-message.component';
import { CalendarComponent } from './calendar/calendar.component';
import { NewTimeSelectorComponent } from './new-time-selector/new-time-selector.component';
import { StatsComponent } from './stats/stats.component';
import { StatsUsersComponent } from './stats/stats-users/stats-users.component';
import { StatsDevicesComponent } from './stats/stats-devices/stats-devices.component';
import { StatsActivityComponent } from './stats/stats-activity/stats-activity.component';
import { StatsBugsComponent } from './stats/stats-bugs/stats-bugs.component';
import { StatsHealthComponent } from './stats/stats-health/stats-health.component';
import { StatsWidgetsComponent } from './stats-widgets/stats-widgets.component';
import { StatsTotalInfoComponent } from './stats-widgets/stats-total-info/stats-total-info.component';
import { StatsNewUsersComponent } from './stats-widgets/stats-new-users/stats-new-users.component';
import { StatsWidgetTwoComponent } from './stats-widgets/stats-widget-two/stats-widget-two.component';
import { StatsWidgetChartComponent } from './stats-widgets/stats-widget-chart/stats-widget-chart.component';
import { StatsLastGraphComponent } from './stats-widgets/stats-last-graph/stats-last-graph.component';
import { StatsMapComponent } from './stats-widgets/stats-map/stats-map.component';
import { StatsDevicesLastComponent } from './stats/stats-devices/stats-devices-last/stats-devices-last.component';
import { StatsCalendarComponent } from './stats/stats-calendar/stats-calendar.component';
import { StatsUserActivityHeaderComponent } from './stats-widgets/stats-user-activity-header/stats-user-activity-header.component';
import { StatsUserActivityDonutsComponent } from './stats-widgets/stats-user-activity-donuts/stats-user-activity-donuts.component';




@NgModule({
  declarations: [
    AppComponent,
    MovanoButtonComponent,
    LoginComponent,
    RecoverPasswordComponent,
    TesterComponent,
    MovanoChartComponent,
    MovanoInfoCarouselComponent,
    MovanoTotaInfoComponent,
    MovanoStatsComponent,
    MovanoActivityComponent,
    MovanoHistoryComponent,
    MovanoLastComptComponent,
    DashboardComponent,
    MovanoMapComponent,
    DevicesComponent,
    UsersComponent,
    UserPanelComponent,
    UserMenuComponent,
    FeaturesTabComponent,
    UserFilterComponent,
    UserDeviceTableComponent,
    UserPanelHealthMetricsComponent,
    UserPanelWaveformDataComponent,
    MovanoBarChartComponent,
    UserPanelActivityLogComponent,
    UserPanelSleepDataComponent,
    UserPanelTimeSelectorComponent,
    CognitoUserCreateEditComponent,
    CognitoUserTableComponent,
    NotificationsComponent,
    NtfInsightApplicationComponent,
    NtfNotificationListComponent,
    NtfInsightJobExecutionsComponent,
    PaginatorComponent,
    MovanoHeaderComponent,
    NtfNotificationsListMessageComponent,
    InsightAppFilterComponent,
    NotificationListFilterComponent,
    NotifiationListSideFilterComponent,
    InsightJobExecutionsFilterComponent,
    InsightJobExecutionsRunsComponent,
    UserPanelSpotCheckComponent,
    HeaderComponent,
    DevicesTicketsComponent,
    DevicesTicketsInfoComponent,
    UsersBugsComponent,
    UsersBugsInfoComponent,
    UserTicketsComponent,
    UserTicketsInfoComponent,
    WorkoutUsageComponent,
    UsersNotificationsListComponent,
    UsersNtfListFilterComponent,
    UsersNtfListSideFilterComponent,
    UsersNtfListMessageComponent,
    CalendarComponent,
    NewTimeSelectorComponent,
    StatsComponent,
    StatsUsersComponent,
    StatsDevicesComponent,
    StatsActivityComponent,
    StatsBugsComponent,
    StatsHealthComponent,
    StatsWidgetsComponent,
    StatsTotalInfoComponent,
    StatsNewUsersComponent,
    StatsWidgetTwoComponent,
    StatsWidgetChartComponent,
    StatsLastGraphComponent,
    StatsMapComponent,
    StatsDevicesLastComponent,
    StatsCalendarComponent,
    StatsUserActivityHeaderComponent,
    StatsUserActivityDonutsComponent,


  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatSelectModule,
    MatIconModule,
    MatDialogModule,
    MatButtonModule,
    MatFormFieldModule,
    MatProgressSpinnerModule,
    CalendarModule,
    DropdownModule,
    AutoCompleteModule,
    TableModule,
    ProgressSpinnerModule,
    ChartModule,
    DividerModule,
    SidebarModule,
    MatSidenavModule,
    MatRippleModule,
    ButtonModule,
    RippleModule,
    ProgressBarModule,
    ToastModule,
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts')
    }),
    MatCheckboxModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
