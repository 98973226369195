import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { healthMetrics_Battery, healthMetrics_Hour, healthMetrics_Day} from 'src/app/shared/movano-interfaces';
import { Subscription } from 'rxjs';
import { DarkModeService } from 'src/app/dark-mode.service';

interface MeasureColors {
  [key: string]: string[];
};

@Component({
  selector: 'app-user-panel',
  templateUrl: './user-panel.component.html',
  styleUrls: ['./user-panel.component.scss']
})

export class UserPanelComponent implements OnInit, OnDestroy {
  selectedTab: string = 'healthMetrics'; // Default tab

  selectTab(tab: string) {
    this.selectedTab = tab;
  }

  protected selectedDate: number = 2;
  protected timeZones: string[] = []; //UTC, Madrid, California... etc
  protected calendarInfo: [any, boolean, Date | Date[], string, any] = [
    2,
    false,
    new Date("2024-10-10T13:11:23.264Z"),
    "",
    "2"
]

  //DARK MODE
  protected darkMode: boolean = false;
  private darkModeSub: Subscription = new Subscription();

  //Last health metrics saved variables
  protected needHaveHealthMetrics: boolean = true;
  protected measures_LastData?: healthMetrics_Hour | healthMetrics_Day;
  protected measures_Battery?: healthMetrics_Battery;
  protected daySelected?: Date = undefined;
  protected dayView?: boolean;
  protected healthMetrics: boolean = true;

  protected utc: boolean = false;

  constructor(
    protected dialogRef: MatDialogRef<UserPanelComponent>,
    private darkModeSvc: DarkModeService,
    @Inject(MAT_DIALOG_DATA) protected data: { user: string }) {

  };

  ngOnInit(): void {
    this.darkModeSub = this.darkModeSvc.getVariable().subscribe((val: any) => {
      this.darkMode = val;
    });
  }

  ngOnDestroy() {
    this.darkModeSub.unsubscribe();
  }

  closePanel() {
    this.dialogRef.close();
  }

  healthMetricsData(data: [healthMetrics_Hour | healthMetrics_Day | undefined, healthMetrics_Battery | undefined, Date | undefined, boolean | undefined]) {
    const [measure_Data, battery_data, day, dayVw] = data;

    if (!measure_Data) {
      if (day) this.daySelected = day;

      return;
    };

    this.measures_LastData = measure_Data;
    this.measures_Battery = battery_data;
    this.needHaveHealthMetrics = false;
    this.daySelected = day;
    this.dayView = dayVw;
  }

  getCalendarInfo(calendarData: [any, boolean, Date | Date[], string, any]) {
    this.calendarInfo = calendarData;
  }

}
