import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LAST_WEEK } from '../../constants';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  private calendarOption = new BehaviorSubject<string>(LAST_WEEK);
  public calendarObservable$ = this.calendarOption.asObservable();

  setCalendarSelectedOption(calendarData: string) {
    this.calendarOption.next(calendarData);
  }
}
