import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { DarkModeService } from '../dark-mode.service';
import * as lit from '../shared/movano-literals';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { MovanoService } from 'src/app/movano.service';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {

  protected LITERALS = lit;
  protected darkMode: boolean = false;
  private darkModeSub: Subscription = new Subscription();
  protected menuDisplay: boolean = false;
  protected userDisplay: boolean = false;
  protected selectedTab: string = 'insight-application';

  constructor(
    private router: Router,
     private route: ActivatedRoute,
    protected movanoService: MovanoService,
    private darkModeSvc: DarkModeService) {
    this.darkModeSub = this.darkModeSvc.getVariable().subscribe((val: any) => {
      this.darkMode = val;
    });
  }  

  ngOnInit() {
    // Listen to route changes
    this.router.events.pipe(
      filter((event: any) => event instanceof NavigationEnd)
    ).subscribe(() => {
      const tab = this.route.firstChild?.snapshot.url[0]?.path || 'insight-application';
      this.movanoService.changeTab(tab);
    });

    // Subscribe to the current tab
    this.movanoService.currentTab.subscribe(tab => {
      this.selectedTab = tab;
    });

    // Initialize the selected tab based on the current route
    const initialTab = this.route.firstChild?.snapshot.url[0]?.path || 'insight-application';
    this.movanoService.changeTab(initialTab);
  }

  selectTab(tab: string) {
    this.movanoService.changeTab(tab);
    this.router.navigate([`/notifications/${tab}`]);
  }


  changeTheme() {
    this.darkModeSvc.setVariable(!this.darkMode);
  }


}
