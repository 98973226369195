import { Component, Input, SimpleChanges } from '@angular/core';
import { incidentCarrousel, salesProductionCarrousel, salesSyncCarrousel } from '../../shared/movano-interfaces';

@Component({
  selector: 'app-movano-history',
  templateUrl: './movano-history.component.html',
  styleUrls: ['./movano-history.component.scss']
})
export class MovanoHistoryComponent {

  Pro_Pro : number[] = [];
  Sold_Pro : number[] = [];
  Dispo_Pro : number[] = [];
  Date_Pro : string[] = [];
  
  ReMovano_Sales : number[] = [];
  ReOther_Sales : number[] = [];
  SoldMovano_Sales : number[] = [];
  SoldOther_Sales : number[] = [];
  Date_Sales : string[] = [];

  Sleep_Syncs : number[] = [];
  Sold_Syncs : number[] = [];
  Sync_Syncs : number[] = [];
  Date_Syncs : string[] = [];

  Inc_Incidents : number[] = [];
  Repng_Incidents : number[] = [];
  Rep_Incidents : number[] = [];
  Date_Incidents : string[] = [];

  @Input() historyType : string;
  @Input() darkMode : boolean;
  @Input() productionData ?: salesProductionCarrousel;
  @Input() incidentsData ?: incidentCarrousel;
  @Input() syncsData ?: salesSyncCarrousel;
  purgeData: boolean = false;

  //HISTORY PRODUCTION
  historyDataMax : number = 100;
  historyDataBlue : number[] = [50, 60, 90, 70, 90, 60];
  historyDataRed : number[] = [30, 20, 10, 10, 40, 10];
  historyDataLabels : string[] = ['10/02', '01/01', '10/02', '01/01', '10/02', '01/01'];
  
  
  constructor(){
    this.historyType = 'sales';
    this.darkMode = true;
  }

  //Is called when any input variable change
  ngOnChanges(changes: SimpleChanges) {
    if (this.productionData) {
      this.Pro_Pro = this.productionData.sideUpperGraph.sideTotalProducedArray;
      this.Sold_Pro = this.productionData.sideUpperGraph.sideTotalSoldArray;
      this.Dispo_Pro = this.productionData.sideUpperGraph.sideTotalDisposalArray;
      this.Date_Pro = this.productionData.sideUpperGraph.sideDatesArray;
      
      this.ReMovano_Sales = this.productionData.sideLowerGraph.sideTotalReturnedMovanoArray;
      this.ReOther_Sales = this.productionData.sideLowerGraph.sideTotalReturnedMovanoArray
      this.SoldMovano_Sales = this.productionData.sideLowerGraph.sideTotalSoldMovanoArray;
      this.SoldOther_Sales =this.productionData.sideLowerGraph.sideTotalSoldOtherArray;
      this.Date_Sales = this.productionData.sideLowerGraph.sideDatesLowerArray;
    }
    if(this.syncsData){
      this.Sleep_Syncs = this.syncsData.sideGraph.sideTotalSleepArray;
      this.Sold_Syncs = this.syncsData.sideGraph.sideTotalSoldArray;
      this.Sync_Syncs = this.syncsData.sideGraph.sideTotalSyncArray;
      this.Date_Syncs = this.syncsData.sideGraph.sideDatesArray;
    }
    if(this.incidentsData){
      this.Inc_Incidents = this.incidentsData.sideGraph.sideTotalIncidentArray;
      this.Repng_Incidents = this.incidentsData.sideGraph.sideTotalRepairingArray;
      this.Rep_Incidents = this.incidentsData.sideGraph.sideTotalRepairedArray;
      this.Date_Incidents = this.incidentsData.sideGraph.sideDatesArray;
    }
    this.purgeFakeData()
  }

  purgeFakeData() {
    if (this.purgeData) {
      if (this.productionData) {
        this.Pro_Pro = new Array(this.Pro_Pro.length).fill(0);
        this.Sold_Pro = this.productionData.sideUpperGraph.sideTotalSoldArray;
        this.Dispo_Pro = new Array(this.Dispo_Pro.length).fill(0);

        for(let i = 0; i < this.ReMovano_Sales.length; i++){
            this.ReMovano_Sales[i] =0;
            this.ReOther_Sales[i] = 0;
            this.SoldMovano_Sales[i] = this.SoldMovano_Sales[i]+this.SoldOther_Sales[i]
            this.SoldOther_Sales[i] = 0;
        }
      }
      if(this.incidentsData){
        this.Inc_Incidents = new Array(this.Inc_Incidents.length).fill(0);
        this.Repng_Incidents = new Array(this.Repng_Incidents.length).fill(0);
        this.Rep_Incidents = new Array(this.Rep_Incidents.length).fill(0);
      }
    }
  }

}
