<ng-template pTemplate="header">
  <h3>{{ LITERALS.LOGGED_TAB.TITTLE }}</h3>
</ng-template>
<div class="movanoUserMenu" [ngClass]="{ movanoUserMenu_Dark: darkMode }">
  <div class="movanoUserMenu_Header">
    <span>{{ LITERALS.LOGGED_TAB.TITTLE }}</span>
    <div class="movanoUserMenu_Header_Right">
      <div class="movanoUserMenu_Header_Right_DarkMode">
        <div class="movano_switch" style="position: relative">
          <!--   <label class="switch">
            <input [checked]="darkMode" type="checkbox" (change)="changeTheme()">
            <span class="slider round"></span>
          </label> -->
        </div>
        <!--      <svg class="movano_switch_icon" viewBox="0 0 11 17" fill="none" xmlns="http://www.w3.org/2000/svg"
          [ngStyle]="{filter: darkMode ? 'drop-shadow( 0px 0px 5px rgba(255, 255, 255, 0.5))' : 'unset'}">
          <path
            d="M5.77761 8.76924C5.77761 5.86289 7.56864 3.37988 10.1005 2.36225C10.825 2.06917 10.7111 0.986418 9.94582 0.815457C9.0503 0.620072 8.0978 0.571226 7.11274 0.701482C3.44114 1.18994 0.502227 4.21026 0.119599 7.89001C-0.385145 12.7828 3.433 16.9103 8.21992 16.9103C8.81421 16.9103 9.38408 16.8451 9.94582 16.723C10.7192 16.5521 10.8413 15.4774 10.1086 15.1762C7.48723 14.126 5.76947 11.586 5.77761 8.76924Z"
            [ngStyle]="{fill : darkMode ? '#FFFFFF' : '#054280'}" />
        </svg> -->
      </div>

      <svg (click)="closeHimself.emit()" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M11.25 1.8075L10.1925 0.75L6 4.9425L1.8075 0.75L0.75 1.8075L4.9425 6L0.75 10.1925L1.8075 11.25L6 7.0575L10.1925 11.25L11.25 10.1925L7.0575 6L11.25 1.8075Z"
          fill="#C4D9EE"
        />
      </svg>
    </div>
  </div>
  <div class="movanoUserMenu_userIMG">
    <div class="movanoUserMenu_userIMG_userIcon">
      <svg
        width="max(2.22vw, 32px)"
        height="max(2.36vw, 34px)"
        viewBox="0 0 17 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.5 7.91667C10.6327 7.91667 12.3482 6.14333 12.3482 3.95833C12.3482 1.77333 10.6327 0 8.5 0C6.36727 0 4.63636 1.77333 4.63636 3.95833C4.63636 6.14333 6.36727 7.91667 8.5 7.91667ZM8.5 11.0833C5.67182 11.0833 0 12.54 0 15.4375V17.4167C0 18.2875 0.695454 19 1.54545 19H15.4545C16.3045 19 17 18.2875 17 17.4167V15.4375C17 12.54 11.3282 11.0833 8.5 11.0833Z"
        />
      </svg>
    </div>
    <div class="movanoUserMenu_userIMG_Txt">
      <span>{{ LITERALS.LOGGED_TAB.STATUS }}</span>
      <span>{{ accountName || accountEmail }}</span>
    </div>
  </div>
  <div class="role">
    <span>Role:</span>
    <span>{{ accountType || 'Unknown' }}</span>
  </div>
  <div class="movanoUserMenu_Log">
    <app-movano-button (click)="logOutWithCognito()" [buttonText]="'Log out'" [color]="'magenta'"></app-movano-button>
    <span (click)="recoverPass()">{{ LITERALS.LOGGED_TAB.RESET_PASS }}</span>
  </div>
  <div class="movanoUserMenu_Version">
    <span>Version: {{ version?.portalVersion }}</span>
  </div>
</div>
