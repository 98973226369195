<div class="notification_List_Filter">
      <div class="movanoUserPanel_Header_Tittle">
        <span>
          User {{userData}} Notifications
        </span>
        <svg  (click)="closePanel()" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M11.25 1.8075L10.1925 0.75L6 4.9425L1.8075 0.75L0.75 1.8075L4.9425 6L0.75 10.1925L1.8075 11.25L6 7.0575L10.1925 11.25L11.25 10.1925L7.0575 6L11.25 1.8075Z"
            fill="#C4D9EE" />
        </svg>
    </div>      
    <div class="movanoMiddleBar">
      <div class="movanoMiddleBar_First">
        <div class="notification_List_Filter_Block" style="padding-top:10px;">
          <app-new-time-selector #list_calendar (initGetInfo)="emitGetInfo($event)" [slctdDateOpt]="selectedDate" [timeZones]="timeZones"  [ngClass]="{ 'disabled': isTimeSelectorDisabled }"></app-new-time-selector>
        <!--   <app-user-panel-time-selector #list_calendar (initGetInfo)="emitGetInfo($event)" [slctdDateOpt]="selectedDate" [timeZones]="timeZones"></app-user-panel-time-selector> -->
          </div>
      <svg class="movanoHeader_Tittle_btn" style="margin-left:20px;"  width="max(1.11vw, 16px)" height="max(1.11vw, 16px)" *ngIf="!searchDisplay"
          (click)="searchDisplay = true" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M10.9167 9.66667H10.2583L10.025 9.44167C10.8417 8.49167 11.3333 7.25833 11.3333 5.91667C11.3333 2.925 8.90833 0.5 5.91667 0.5C2.925 0.5 0.5 2.925 0.5 5.91667C0.5 8.90833 2.925 11.3333 5.91667 11.3333C7.25833 11.3333 8.49167 10.8417 9.44167 10.025L9.66667 10.2583V10.9167L13.8333 15.075L15.075 13.8333L10.9167 9.66667V9.66667ZM5.91667 9.66667C3.84167 9.66667 2.16667 7.99167 2.16667 5.91667C2.16667 3.84167 3.84167 2.16667 5.91667 2.16667C7.99167 2.16667 9.66667 3.84167 9.66667 5.91667C9.66667 7.99167 7.99167 9.66667 5.91667 9.66667Z"
            fill="var(--main-blue-dark)" />
        </svg>
        <div style="margin-left:20px; margin-top:5px; margin-right: 20px;" *ngIf="searchDisplay" class="movanoHeader_Tittle_Search scale-up-hor-left">
          <svg (click)="search($event, 'name', table)" width="max(1.11vw, 16px)" height="max(1.11vw, 16px)"
            viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M10.9167 9.66667H10.2583L10.025 9.44167C10.8417 8.49167 11.3333 7.25833 11.3333 5.91667C11.3333 2.925 8.90833 0.5 5.91667 0.5C2.925 0.5 0.5 2.925 0.5 5.91667C0.5 8.90833 2.925 11.3333 5.91667 11.3333C7.25833 11.3333 8.49167 10.8417 9.44167 10.025L9.66667 10.2583V10.9167L13.8333 15.075L15.075 13.8333L10.9167 9.66667V9.66667ZM5.91667 9.66667C3.84167 9.66667 2.16667 7.99167 2.16667 5.91667C2.16667 3.84167 3.84167 2.16667 5.91667 2.16667C7.99167 2.16667 9.66667 3.84167 9.66667 5.91667C9.66667 7.99167 7.99167 9.66667 5.91667 9.66667Z"
              fill="var(--main-blue-dark)" />
          </svg>
          <span class="p-input-icon-left">
            <input class="movanoInputSearch" type="text" placeholder="Search notification"  pInputText (input)="search($event, 'name',table)" />
          </span>
          <svg width="max(0.833vw, 16px)" height="max(0.833vw, 16px)"
            (click)="table.reset(); searchDisplay = false;" viewBox="0 0 12 12" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M11.25 1.8075L10.1925 0.75L6 4.9425L1.8075 0.75L0.75 1.8075L4.9425 6L0.75 10.1925L1.8075 11.25L6 7.0575L10.1925 11.25L11.25 10.1925L7.0575 6L11.25 1.8075Z"
              fill="#C4D9EE" />
          </svg>
        </div>
        <svg (click)="filterDisplay = true" class="movanoHeader_Tittle_btn" width="max(0.9vw, 16px)"
        height="max(0.9vw, 16px)" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0.364543 1.48084C0.443709 1.58375 4.90871 7.28375 4.90871 7.28375V12.0417C4.90871 12.4771 5.26496 12.8333 5.70829 12.8333H7.29954C7.73496 12.8333 8.09913 12.4771 8.09913 12.0417V7.27584C8.09913 7.27584 12.4454 1.71834 12.6512 1.465C12.857 1.21167 12.8333 0.958338 12.8333 0.958338C12.8333 0.522922 12.477 0.166672 12.0337 0.166672H0.966209C0.483293 0.166672 0.166626 0.546672 0.166626 0.958338C0.166626 1.11667 0.214126 1.30667 0.364543 1.48084Z"
          fill="var(--main-blue-dark)" />
      </svg>
    </div>

        <div class="movanoMiddleBar_Last">
          <span class="movanoMiddleBar_Last_Amount" >{{ isFiltered ? filteredUsers.length : ntfLists.length }} notifications</span>
          <div style="margin-top: 5px;" class="movano_switch lp">
            <label class="switch">
              <input type="checkbox" (change)="onSwitchToggle($event)" checked>
              <span class="slider round"></span>
            </label>
          </div>
          <span class="movanoMiddleBar_Last_Tittle">Show all history</span>
        </div>
    </div>


      
  </div>
  
  
  
  <p-sidebar styleClass="menu-filter" [modal]="false" [(visible)]="filterDisplay" (onShow)="onResize()" position="left">
    <notifiation-list-side-filter (closeHimself)="filterDisplay = false" [table]="table"></notifiation-list-side-filter>
  </p-sidebar>
  
