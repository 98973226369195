import { SharedService } from './../shared.service';
import { ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import {
  footerInfo
} from '../../shared/movano-interfaces';
import { MatDialog } from '@angular/material/dialog';
import { MovanoService } from 'src/app/movano.service';
import { DarkModeService } from 'src/app/dark-mode.service';
import { DashboardTypeService } from 'src/app/dashboard-type.service';
import { Router } from '@angular/router';
import { abbrNum } from '../../shared/utils';
import { USE_SPACES, USE_CAPITALS, NUM_DECIMALS } from '../../shared/constants';
import { TODAY, LAST_WEEK, LAST_SEVEN_DAYS, LAST_MONTH, YEAR_TO_DATE } from '../../../constants';

@Component({
  selector: 'app-stats-users',
  templateUrl: './stats-users.component.html',
  styleUrls: ['./stats-users.component.scss']
})
export class StatsUsersComponent{

  protected dynamicText: string = 'last week';
  protected totalUsers: string = '';
  protected diference: string = '';
  protected newUsers: string = '';
  protected newUsersDif: string = '';
  protected maxValue: string = '';
  protected minValue: string = '';
  protected dayAverage: string = '';


  // FORMAT OPTIONS
  numDecimals = NUM_DECIMALS;
  useCapitals = USE_CAPITALS
  useSpaces = USE_SPACES;

  //NEW USERS
  moreNewUser: boolean = true;
  totalNewUser: string = '0';
  differNewUser: string = '0';
  //NEW USERS


  //RINGS SOLD
  moreSold: boolean = false;
  totalSold: string = '514';
  differSold: string = '25';


  constructor(
    public _dialog: MatDialog,
    private movanoSvc: MovanoService,
    private darkModeSvc: DarkModeService,
    private dashboardSVC: DashboardTypeService,
    private router: Router,
    private changeDetector: ChangeDetectorRef,
    private sharedService: SharedService
  ) {
    // Initialization code
  }

  protected dashboardType: string = 'Users';
  protected statsFront: boolean = true;
  protected darkMode: boolean = false;
  protected innerWidth: any;
  protected loadingData: boolean = false;

  @Input() calendarData: string = '';

  // Variables to store the data for different time frames
  protected statsToday?: any;
  protected statsLastWeek?: any;
  protected statsLastMonth?: any;
  protected statsLast7Days?: any;
  protected statsYearToDate?: any;
  protected statsCustomRange?: any;

  ngOnInit() {
    this.innerWidth = document.documentElement.clientWidth;
    document.body.style.backgroundColor = this.darkMode ? '#0c1d35' : '#f3f9fe';
    this.sharedService.calendarObservable$.subscribe(data => {
      this.updateInfo(data);
    })
    this.initializeWithLastWeekData();
    this.getFooterInfo();
  }

  async initializeWithLastWeekData() {
    this.loadingData = true;
    this.statsLastWeek = await lastValueFrom(this.movanoSvc.getStatsSummaryUsersLastWeek());
    this.totalUsers = this.statsLastWeek.data.active.count;
    this.diference = this.statsLastWeek.data.active.dif;
    this.newUsers = this.statsLastWeek.data.new_users.count;
    this.newUsersDif = this.statsLastWeek.data.new_users.dif;
    this.maxValue = this.statsLastWeek.data.new_users.max_in_one_day;
    this.minValue = this.statsLastWeek.data.new_users.min_in_one_day;
    this.dayAverage = this.statsLastWeek.data.new_users.day_average
    this.changeDetector.detectChanges();
    this.loadingData = false;
  }

  async updateInfo(data: any) {
    this.loadingData = true;
    // Determine which request to make based on calendarData
    switch (data) {
      case 'today':
        this.statsToday = await lastValueFrom(this.movanoSvc.getStatsSummaryUsersToday());
        this.dynamicText = 'today'; // Update text for today
        this.totalUsers = this.statsToday.data.active.count;
        this.diference = this.statsToday.data.active.dif;
        this.newUsers = this.statsToday.data.new_users.count;
        this.newUsersDif = this.statsToday.data.new_users.dif;
        this.maxValue = this.statsToday.data.new_users.max_in_one_day
        this.minValue = this.statsToday.data.new_users.min_in_one_day
        this.dayAverage = this.statsToday.data.new_users.day_average
        break;
      case 'Last Week':
        this.statsLastWeek = await lastValueFrom(this.movanoSvc.getStatsSummaryUsersLastWeek());
        this.dynamicText = 'last week'; // Update text for last week
        this.totalUsers = this.statsLastWeek.data.active.count
        this.diference = this.statsLastWeek.data.active.dif;
        this.newUsers = this.statsLastWeek.data.new_users.count;
        this.newUsersDif = this.statsLastWeek.data.new_users.dif;
        this.maxValue = this.statsLastWeek.data.new_users.max_in_one_day
        this.minValue = this.statsLastWeek.data.new_users.min_in_one_day
        this.dayAverage = this.statsLastWeek.data.new_users.day_average
        break;
      case 'Last Month':
        this.statsLastMonth = await lastValueFrom(this.movanoSvc.getStatsSummaryUsersLastMonth());
        this.dynamicText = 'last month'; // Update text for last month
        this.totalUsers = this.statsLastMonth.data.active.count
        this.diference = this.statsLastMonth.data.active.dif;
        this.newUsers = this.statsLastMonth.data.new_users.count;
        this.newUsersDif = this.statsLastMonth.data.new_users.dif;
        this.maxValue = this.statsLastMonth.data.new_users.max_in_one_day
        this.minValue = this.statsLastMonth.data.new_users.min_in_one_day
        this.dayAverage = this.statsLastMonth.data.new_users.day_average
        break;
      case 'Last 7 days':
        this.statsLast7Days = await lastValueFrom(this.movanoSvc.getStatsSummaryUsersLast7Days());
        this.dynamicText = 'last 7 days'; // Update text for last 7 days
        this.totalUsers = this.statsLast7Days.data.active.count
        this.diference = this.statsLast7Days.data.active.dif;
        this.newUsers = this.statsLast7Days.data.new_users.count;
        this.newUsersDif = this.statsLast7Days.data.new_users.dif;
        this.maxValue = this.statsLast7Days.data.new_users.max_in_one_day
        this.minValue = this.statsLast7Days.data.new_users.min_in_one_day
        this.dayAverage = this.statsLast7Days.data.new_users.day_average
        break;
      case 'Year to date':
        this.statsYearToDate = await lastValueFrom(this.movanoSvc.getStatsSummaryUsersYearToDate());
        this.dynamicText = 'year to date'; // Update text for year to date
        this.totalUsers = this.statsYearToDate.data.active.count
        this.diference = this.statsYearToDate.data.active.dif;
        this.newUsers = this.statsYearToDate.data.new_users.count;
        this.newUsersDif = this.statsYearToDate.data.new_users.dif;
        this.maxValue = this.statsYearToDate.data.new_users.max_in_one_day
        this.minValue = this.statsYearToDate.data.new_users.min_in_one_day
        this.dayAverage = this.statsYearToDate.data.new_users.day_average
        break;
      case 'customRange':
        // Replace with actual start and end dates
        const startDate = '2024-01-01';
        const endDate = '2024-01-31';
        this.statsCustomRange = await lastValueFrom(this.movanoSvc.getStatsSummaryUsersCustomDay(startDate, endDate));
        this.dynamicText = 'custom range'; // Update text for custom range
        this.totalUsers = this.statsCustomRange.data.active.count
        this.diference = this.statsCustomRange.data.active.dif;
        this.newUsers = this.statsCustomRange.data.new_users.count;
        this.newUsersDif = this.statsCustomRange.data.new_users.dif;
        this.maxValue = this.statsCustomRange.data.new_users.max_in_one_day
        this.minValue = this.statsCustomRange.data.new_users.min_in_one_day
        this.dayAverage = this.statsCustomRange.data.new_users.day_average
        break;
      default:
        console.error('Unknown calendarData value:', this.calendarData);
        this.dynamicText = 'unknown'; // Default case if none match
    }
    this.changeDetector.detectChanges();
    // this.loadingData = false;
  }

  protected footerData?: footerInfo;

  async getFooterInfo() {
    const footerData = await lastValueFrom(this.movanoSvc.getFooterInfo());
    this.footerData = footerData;
  }

  setValues = async (data: string) => {
    const dataOfSelectedDate = await this.getDashboardChartDataByDate(data);

    // NEW USERS
    this.totalNewUser = abbrNum(dataOfSelectedDate.newUsers.count, this.numDecimals, this.useSpaces, this.useCapitals);
    this.differNewUser = abbrNum(dataOfSelectedDate.newUsers.dif, this.numDecimals, this.useSpaces, this.useCapitals);
    this.moreNewUser = Number(this.differNewUser) >= 0;


    // RINGS SOLD
    this.totalSold = abbrNum(dataOfSelectedDate.sales.count, this.numDecimals, this.useSpaces, this.useCapitals);
    this.differSold = abbrNum(dataOfSelectedDate.sales.dif, this.numDecimals, this.useSpaces, this.useCapitals);
    this.moreSold = Number(this.differSold) >= 0;
  }

  getDashboardChartDataByDate = async (date: string) => {
    if (date === TODAY) {
      const response = await lastValueFrom(this.movanoSvc.getStatsSummarySalesToday());
      return response.data.carousel;
    }

    if (date === LAST_WEEK) {
      const response = await lastValueFrom(this.movanoSvc.getStatsSummarySalesLastWeek());
      return response.data.carousel;
    }

    if (date === LAST_SEVEN_DAYS) {
      const response = await lastValueFrom(this.movanoSvc.getStatsSummarySalesLast7Days());
      return response.data.carousel;
    }

    if (date === LAST_MONTH) {
      const response = await lastValueFrom(this.movanoSvc.getStatsSummarySalesLastMonth());
      return response.data.carousel;
    }

    if (date === YEAR_TO_DATE) {
      const response = await lastValueFrom(this.movanoSvc.getStatsSummarySalesYearToDate());
      return response.data.carousel;
    }

    const startDate = this.convertDateToISO(date.split('-')[0]);
    const endDate = this.convertDateToISO(date.split('-')[1]);

    const response = await lastValueFrom(this.movanoSvc.getStatsSummarySalesCustomDay(startDate, endDate));

    return response.data.carousel;
  }

  convertDateToISO = (dateString: string) => {
    const date = new Date(dateString);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
  }



}
