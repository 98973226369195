import { Component } from '@angular/core';

@Component({
  selector: 'app-stats-activity',
  templateUrl: './stats-activity.component.html',
  styleUrls: ['./stats-activity.component.scss']
})
export class StatsActivityComponent {

}
