<div class="movanoBarChart" [ngClass]="{'movanoBarChart_Dark' : darkMode}">
  <div class="movanoBarChart_Header">
    <!--TITLE & BUTTONS-->
    <!------------------------------------------------------------------------------------->
    <span class="movanoBarChart_Header_Tittle">{{dashboardType}}</span>
    <span class="movanoBarChart_Header_Day">{{ dayLabel }}</span>
    <div class="movanoBarChart_Header_Buttons">
      <button (click)="passToMap(false)">
        <svg viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M9.33334 10.3166L9.33334 1.68329C9.33334 1.02495 8.60834 0.624954 8.05 0.983288L1.26667 5.29995C0.750001 5.62495 0.750001 6.37495 1.26667 6.70829L8.05 11.0166C8.60834 11.375 9.33334 10.975 9.33334 10.3166Z"
            [ngStyle]="{fill : (darkMode) ? '#D1F5FF' : '#284767', opacity: 1}" />
        </svg>
      </button>
      <button (click)="passToMap(true)">
        <svg viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M0.666664 1.68332V10.3167C0.666664 10.975 1.39166 11.375 1.95 11.0167L8.73333 6.69999C9.25 6.37499 9.25 5.62499 8.73333 5.29165L1.95 0.98332C1.39166 0.624986 0.666664 1.02499 0.666664 1.68332Z"
            [ngStyle]="{fill : (darkMode) ? '#D1F5FF' : '#284767', opacity: 1 }" />
        </svg>
      </button>
    </div>
  </div>

  <!--CHARTS-->
  <!------------------------------------------------------------------------------------->
  <app-stats-widget-chart *ngIf="dashboardType==='Users'" [dashboardType]="'Users'"></app-stats-widget-chart>
  <app-stats-widget-chart *ngIf="dashboardType==='Sales'" [dashboardType]="'Sales'"></app-stats-widget-chart>
  <app-stats-widget-chart *ngIf="dashboardType==='Activity'" [dashboardType]="'Activity'"></app-stats-widget-chart>
  <app-stats-widget-chart *ngIf="dashboardType==='SpotChecks'" [dashboardType]="'SpotChecks'"></app-stats-widget-chart>
</div>
