import { Component, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { DarkModeService } from '../dark-mode.service';
import * as lit from '../shared/movano-literals';
import { Table } from 'primeng/table';

@Component({
  selector: 'movano-header',
  templateUrl: './movano-header.component.html',
  styleUrls: ['./movano-header.component.scss']
})
export class MovanoHeaderComponent {
  protected LITERALS = lit;
  protected darkMode: boolean = false;
  private darkModeSub: Subscription = new Subscription();
  protected searchDisplay: boolean = false;
  protected today?: Date;
  @Input() userDisplay: boolean = false;
  @Input() table !: Table;
  @Input() menuDisplay: boolean = false;
  @Input() filterDisplay: boolean = false;
  @Input() title: string = '';
  @Input() showTitle: boolean = true;

  constructor(private darkModeSvc: DarkModeService){
    this.today = new Date();
    this.darkModeSub = this.darkModeSvc.getVariable().subscribe((val: any) => {
      this.darkMode = val;
    });
  }
  
  ngOnDestroy() {
    this.darkModeSub.unsubscribe();
  }

  
  search(event: any, _row: string, _table: Table) {
    _table.reset();
    _table.filter(event.target.value, _row, 'contains');
  }

  
  changeTheme() {
    this.darkModeSvc.setVariable(!this.darkMode);
  }

  
  onResize() {
    const checkboxDivs = document.querySelectorAll('.movano_checkBox') as NodeListOf<HTMLElement>;
    const checkbox = document.querySelectorAll('mat-checkbox') as NodeListOf<HTMLElement>;
    const windowWidth = window.innerWidth;
    const checkboxSize = Math.max(windowWidth / 1440, 1) * 0.85; // Ajusta este valor según tus necesidades

    checkboxDivs.forEach((cb: HTMLElement) => {
      cb.style.width = `calc(50px * ${checkboxSize})`;
    });

    //console.log(checkbox);
    checkbox.forEach((cb: HTMLElement) => {
      cb.style.transform = `scale(${checkboxSize})`;
    });
  }
}
