import { AfterViewInit, Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { DarkModeService } from '../../dark-mode.service';
import * as lit from '../../shared/movano-literals';
import { Router } from '@angular/router';
import { ntfInsightAppTable } from 'src/app/shared/movano-interfaces';
import { MovanoService } from 'src/app/movano.service';
import { Renderer2, ElementRef } from '@angular/core';
import { InsightAppFilterComponent } from './filter/insight-app-filter.component';

@Component({
  selector: 'ntf-insight-application',
  templateUrl: './ntf-insight-application.component.html',
  styleUrls: ['./ntf-insight-application.component.scss']
})
export class NtfInsightApplicationComponent implements OnInit, AfterViewInit, OnDestroy{

  protected LITERALS = lit;
  protected darkMode: boolean = false;
  private darkModeSub: Subscription = new Subscription();
  protected menuDisplay: boolean = false;
  protected userDisplay: boolean = false; 


  protected loadingData: boolean = false;
  protected filterDisplay: boolean = false;
  filteredUsers: ntfInsightAppTable[] = [];
  isFiltered: boolean = false;
  protected insightApps: ntfInsightAppTable[] = [];
  protected pageSelected: number = 0;
  protected firstPage: number = 0;
  protected lastPage: number = 0;
  protected rows: number = 14;
  protected type: string = 'applications';
  protected selectedTab: string = 'insight-application';
  private subscription: Subscription = new Subscription();
  @ViewChild(InsightAppFilterComponent) filterComponent!: InsightAppFilterComponent;
 
  constructor(
    protected movanoService: MovanoService,
    protected router: Router,
    private renderer: Renderer2, private el: ElementRef,
    private darkModeSvc: DarkModeService) {
    this.darkModeSub = this.darkModeSvc.getVariable().subscribe((val: any) => {
      this.darkMode = val;
    });
  }


  async ngOnInit() {
    await this.getNtfnInsightList();
    this.subscription = this.movanoService.currentTab.subscribe(tab => {
      this.selectedTab = tab;
    });
  }


  ngAfterViewInit() {
    this.sort('name'); 
    this.sort('application_id'); 
    this.sort('type'); 
    this.sort('release_version'); 
    this.sort('architecture'); 
    this.sort('last_modified'); 
   
  }

  updatePaginator(data: number[]) {
    this.rows = data[0];
    this.pageSelected = data[1];
  }

  async getNtfnInsightList() {
    this.loadingData = true;
    this.movanoService.getNtfnInsightApp().subscribe((res: any) => {
      console.log('API Response:', res.data); 
      this.insightApps = res.data;
      this.lastPage = this.insightApps.length;
      this.loadingData = false;
    });
  }

  onFilterApplied(filteredData: ntfInsightAppTable[]) {
    this.insightApps = filteredData;
  }

  onFilter(event: any) {
    this.filteredUsers = event.filteredValue;
    this.isFiltered = this.filteredUsers.length !== this.insightApps.length;
  }

  getBadgeColor(status: string): string {
    switch(status) {
        case 'manual':
            return 'movanoTable_Obj_Badge_blue';
        case 'deleted':
            return 'movanoTable_Obj_Badge_red';
        case 'active':
            return 'movanoTable_Obj_Badge_green';
        case 'inactive':
          return 'movanoTable_Obj_Badge_orange';
        default:
            return '';
    }
}
 


  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  goTo(place: string, name: string) {
    this.movanoService.changeTab(place);
    this.router.navigate([`notifications/${place}`], { queryParams: { name } });
  }

  handleNtfClick(name: string) {
    this.router.navigate(['/notifications/notification-list'], { queryParams: { insightAppName: name } });
  }
  

  handleJobClick(name:string){
    this.router.navigate(['/notifications/insight-job-executions'], { queryParams: { name: name } });
  }

  sort(column: string) {
    if (this.movanoService.currentSortedColumn && this.movanoService.currentSortedColumn !== column) {

      this.movanoService.sortOrder[this.movanoService.currentSortedColumn] = 'none';
  
      const prevIcon = this.el.nativeElement.querySelector(`#${this.movanoService.currentSortedColumn}-icon`);
      const { fill, transform } = this.movanoService.updateIcon(this.movanoService.currentSortedColumn);
      this.renderer.setStyle(prevIcon, 'fill', fill);
      this.renderer.setStyle(prevIcon, 'transform', transform);
      this.renderer.setStyle(prevIcon, 'transform-origin', 'center');
    }
    this.movanoService.sortTable(this.insightApps, column);
    const icon = this.el.nativeElement.querySelector(`#${column}-icon`);
    const { fill, transform } = this.movanoService.updateIcon(column);
    this.renderer.setStyle(icon, 'fill', fill);
    this.renderer.setStyle(icon, 'transform', transform);
    this.renderer.setStyle(icon, 'transform-origin', 'center');
  }
}



