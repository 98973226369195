<div class="movanoBarChart" [ngClass]="{'movanoBarChart_Dark' : darkMode}">
  <div class="movanoBarChart_Header">
    <!--TITLE & BUTTONS-->
    <!------------------------------------------------------------------------------------->
    <span class="movanoBarChart_Header_Tittle">{{dashboardType}}</span>
    <!-- <span class="movanoBarChart_Header_Day">September</span> -->

    <!------------------------------------------------------------------------------------->

    <!--LEGEND HEADER-->
    <!------------------------------------------------------------------------------------->
    <div style="width: 1043px; display: flex; justify-content: center;">
      <div class="movanoBarChart_Header_Legend">
        <div *ngFor="let legendObj of legendObjects.get(dashboardType); let i = index"
          class="movanoBarChart_Header_Legend_Elements {{dashboardType}}" [class]="selectedLegend === i ? 'legendActive' : 'legendNoActive'" (click)="changeLegend(i)">
          <img [src]="ICONS + dashboardType + i + (darkMode ? '_Dark.svg' : '.svg')" style="height: max(0.69vw, 10px);">
          <span [style.color]="darkMode ? legendObj.colorDark : legendObj.colorLight"> {{legendObj.text}} </span>
        </div>
      </div>
    </div>
    <!------------------------------------------------------------------------------------->


  </div>
  <!------------------------------------------------------------------------------------->

  <!--CHART-->
  <!------------------------------------------------------------------------------------->
  <div echarts [options]="option_users??{}" class="movanoBarChart_Chart"></div>
  <!------------------------------------------------------------------------------------->
</div>

