import { AfterViewInit, Component, OnInit, OnDestroy, ViewChild, EventEmitter, Output, Inject } from '@angular/core';
import { lastValueFrom, Subscription } from 'rxjs';
import { DarkModeService } from '../../../dark-mode.service';
import * as lit from '../../../shared/movano-literals';
import { Router } from '@angular/router';
import {  workoutUsageTable } from 'src/app/shared/movano-interfaces';
import { MovanoService } from 'src/app/movano.service';
import { Renderer2, ElementRef } from '@angular/core';
import { Table } from 'primeng/table';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-workout-usage',
  templateUrl: './workout-usage.component.html',
  styleUrls: ['./workout-usage.component.scss']
})
export class WorkoutUsageComponent {

  protected LITERALS = lit;
  protected workoutUsage: workoutUsageTable[] = [];
  protected darkMode: boolean = false;
  private darkModeSub: Subscription = new Subscription();
  protected menuDisplay: boolean = false;
  protected filterDisplay: boolean = false;
  protected searchDisplay: boolean = false;
  protected selectedDate: number = 1;
  protected pageSelected: number = 0;
  protected firstPage: number = 0;
  protected lastPage: number = 0;
  protected rows: number = 9;
  protected loadingData: boolean = false;
  protected timeZones : string[] = [];
  protected daySelected?: Date;
  protected rangeSelected: Date[] = [];
  protected today?: Date;  
  protected actualTimeZone: string = '';
  protected timeOptions: string[] = ['', 'week', 'month', ''];
  protected lastselectedDate : number = 0;
  protected dataAvailable: boolean = false;
   protected type: string = 'workouts'
  @Output() getInfo = new EventEmitter<[number, boolean, Date | Date[], string, number]>();





  constructor(
    protected movanoService: MovanoService,
    protected router: Router,
    private renderer: Renderer2, private el: ElementRef,
    private darkModeSvc: DarkModeService,
    @Inject(MAT_DIALOG_DATA) protected data: { user: string }) {
    this.darkModeSub = this.darkModeSvc.getVariable().subscribe((val: any) => {
      this.darkMode = val;
    });
  }


  async ngOnInit() {
    this.today = new Date(Date.now());
    this.emitGetInfo();
 
  }

  ngAfterViewInit() {
    this.sort('name'); 
    this.sort('application_id'); 
    this.sort('type'); 
    this.sort('release_version'); 
    this.sort('architecture'); 
    this.sort('last_modified'); 
   
  }



  search(event: any, _row: string, _table: Table) {
    _table.reset();
    _table.filter(event.target.value, _row, 'contains');

}

emitGetInfo(calendarData?: [any, boolean, Date | Date[], string, any]): void {
  this.getInfo.emit(calendarData);
  this.loadData(calendarData);
}

updatePaginator(data: number[]) {
  this.rows = data[0];
  this.pageSelected = data[1];
}



async loadData(calendarData?: [any, boolean, Date | Date[], string, any]) {

  let dateStart: Date | undefined;
  let dateEnd: Date | undefined;
  let timeFrame: string | undefined = '';
  let res: any 


  this.loadingData = true;
  //this.loadingDataChange.emit(this.loadingData);

  try {
    if (!calendarData) {
      this.selectedDate = 2; 
    } else {
      console.log(calendarData);
      this.lastselectedDate = this.selectedDate;
      this.selectedDate = parseInt(calendarData[0]);
      if (this.selectedDate !== 3) {
        this.daySelected = calendarData[2] as Date;
      } else {
        this.rangeSelected = calendarData[2] as Date[];
      }
      this.actualTimeZone = calendarData[3];
    }

    //this.dataAvailable = true;
    //this.dataAvailableChange.emit(this.dataAvailable);
    this.loadingData = true;
    //this.loadingDataChange.emit(this.loadingData);

   
    if (this.selectedDate === 0) {
      // Handle "today" or any custom day
      timeFrame = 'today' 
    } else if (this.selectedDate === 1) {
      // Handle "last week"
      timeFrame = 'lastWeek'
    } else if (this.selectedDate === 2) {
      // Handle " last month"
      timeFrame = 'lastMonth'
     
    } else if (this.selectedDate === 3) {
      // Handle "range"
      dateStart = this.rangeSelected[0];
      dateEnd = this.rangeSelected[1];
    }
    else if (this.selectedDate === 4) {
      // Handle "last 7 days"
      timeFrame = 'last7Days'
    }
    else if (this.selectedDate === 5)
    {
      timeFrame = 'actualYear'
    }
      res = await lastValueFrom(this.movanoService.getWrkUsageData(this.data.user, 'utc', 0, timeFrame));
   
      console.log('API Response:', res.data.sessions);

      this.workoutUsage = res.data.sessions;
     // this.ntfListsChanged.emit(this.ntfLists);

      this.dataAvailable = this.workoutUsage.length > 0;
      //this.dataAvailableChange.emit(this.dataAvailable);

    }

    
   catch (error) {
    console.error('Error fetching data:', error);

    this.dataAvailable = false;
    this.workoutUsage = [];
    //this.dataAvailableChange.emit(this.dataAvailable);
  } finally {
    this.loadingData = false;
    //this.loadingDataChange.emit(this.loadingData);
  }


}


sort(column: string) {
  if (this.movanoService.currentSortedColumn && this.movanoService.currentSortedColumn !== column) {

    this.movanoService.sortOrder[this.movanoService.currentSortedColumn] = 'none';

    const prevIcon = this.el.nativeElement.querySelector(`#${this.movanoService.currentSortedColumn}-icon`);
    const { fill, transform } = this.movanoService.updateIcon(this.movanoService.currentSortedColumn);
    this.renderer.setStyle(prevIcon, 'fill', fill);
    this.renderer.setStyle(prevIcon, 'transform', transform);
    this.renderer.setStyle(prevIcon, 'transform-origin', 'center');
  }
  this.movanoService.sortTable(this.workoutUsage, column);
  const icon = this.el.nativeElement.querySelector(`#${column}-icon`);
  const { fill, transform } = this.movanoService.updateIcon(column);
  this.renderer.setStyle(icon, 'fill', fill);
  this.renderer.setStyle(icon, 'transform', transform);
  this.renderer.setStyle(icon, 'transform-origin', 'center');
}
}

