import { Component } from '@angular/core';

@Component({
  selector: 'app-stats-bugs',
  templateUrl: './stats-bugs.component.html',
  styleUrls: ['./stats-bugs.component.scss']
})
export class StatsBugsComponent {

}
