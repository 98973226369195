import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ntfInsightJobTable } from 'src/app/shared/movano-interfaces';
import { Subscription, lastValueFrom } from 'rxjs';
import { DarkModeService } from '../../dark-mode.service'; 
import * as lit from '../../shared/movano-literals';
import { InsightJobExecutionsRunsComponent } from './runs/insight-job-executions-runs.component';
import { MovanoService } from 'src/app/movano.service';
import { MatDialog } from '@angular/material/dialog';
import { Renderer2, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Table } from 'primeng/table';

@Component({
  selector: 'ntf-insight-job-executions',
  templateUrl: './ntf-insight-job-executions.component.html',
  styleUrls: ['./ntf-insight-job-executions.component.scss']
})
export class NtfInsightJobExecutionsComponent implements OnInit, AfterViewInit {
  protected LITERALS = lit;
  protected darkMode: boolean = false;
  private darkModeSub: Subscription = new Subscription();
  protected menuDisplay: boolean = false;
  protected userDisplay: boolean = false; 
  protected loadingData: boolean = false;
  protected filterDisplay: boolean = false;
  protected insightJobs: ntfInsightJobTable[] = [];
  filteredUsers: ntfInsightJobTable[] = [];
  isFiltered: boolean = false;
  protected pageSelected: number = 0;
  protected firstPage: number = 0;
  protected lastPage: number = 0;
  protected rows: number = 14;
  protected nextIsPosible: boolean = false;
  protected daySelected?: Date;
  protected rangeSelected: Date[] = [];
  protected today?: Date;  
  public name?: string;
  protected type: string = 'executions'
  protected selectedDate: number = 2;
  protected actualTimeZone: string = '';
  protected selectedStatuses: string[] = [];
  
  /**
  0 => today / any day
  1 => week
  2 => month (default)
  3 => range day
  */

  protected dataAvailable: boolean = false;




  constructor(
    protected dialog: MatDialog,
    protected movanoService: MovanoService,
    private route: ActivatedRoute,
    private renderer: Renderer2, private el: ElementRef,
    private darkModeSvc: DarkModeService) {
    this.darkModeSub = this.darkModeSvc.getVariable().subscribe((val: any) => {
      this.darkMode = val;
    });
  }

  async ngOnInit() {
  this.route.queryParams.subscribe(params => {
    this.name = params['name'];
    console.log(`name from insight params: ${this.name}`);
  });

  }

  ngAfterViewInit() {
    this.sort('job_name'); 
    this.sort('runs'); 
    this.sort('schedule'); 
    this.sort('last_run'); 
    this.sort('next_run'); 
    this.sort('status_last_task'); 
   
  }

  updatePaginator(data: number[]) {
    this.rows = data[0];
    this.pageSelected = data[1];
  }


    onInsightJobsChanged(updatedInsightJobs: ntfInsightJobTable[]) {
      // Update the ntfLists array in the parent component
      this.insightJobs = updatedInsightJobs;
    }
    

  onLoadingDataChange(loading: boolean) {
      this.loadingData = loading;
  }
  
  onDataAvailable(data: boolean){
    this.dataAvailable = data;
    console.log(data);
  }

  handleGetInfo(calendarData: [any, boolean, Date | Date[], string, any]) {
    this.selectedDate = parseInt(calendarData[0]);
    if (this.selectedDate !== 3) {
      this.daySelected = calendarData[2] as Date;
    } else {
      this.rangeSelected = calendarData[2] as Date[];
    }
    this.actualTimeZone = calendarData[3];
  }

  

protected openNtfRuns(insightAppName: string) {
  console.log('Opening runs for notification:', insightAppName);
  this.dialog.open(InsightJobExecutionsRunsComponent, {
      data: { insightAppName: insightAppName,
              selectedDate: this.selectedDate,
              daySelected: this.daySelected,
              rangeSelected: this.rangeSelected
       }
  });
}

  getBadgeColor(status: string): string {
    switch(status) {
        case 'Running':
            return 'movanoTable_Obj_Badge_blue';
        case 'Finished with errors':
            return 'movanoTable_Obj_Badge_red';
        case 'succeed':
            return 'movanoTable_Obj_Badge_green';
            case 'error in execution':
              return 'movanoTable_Obj_Badge_orange';
        default:
            return '';
    }
}

onFilter(event: any) {
  this.filteredUsers = event.filteredValue;
  this.isFiltered = this.filteredUsers.length !== this.insightJobs.length;
}

sort(column: string) {
  if (this.movanoService.currentSortedColumn && this.movanoService.currentSortedColumn !== column) {
    this.movanoService.sortOrder[this.movanoService.currentSortedColumn] = 'none';

    const prevIcon = this.el.nativeElement.querySelector(`#${this.movanoService.currentSortedColumn}-icon`);
    const { fill, transform } = this.movanoService.updateIcon(this.movanoService.currentSortedColumn);
    this.renderer.setStyle(prevIcon, 'fill', fill);
    this.renderer.setStyle(prevIcon, 'transform', transform);
    this.renderer.setStyle(prevIcon, 'transform-origin', 'center');
  }
  this.movanoService.sortTable(this.insightJobs, column);
  const icon = this.el.nativeElement.querySelector(`#${column}-icon`);
  const { fill, transform } = this.movanoService.updateIcon(column);
  this.renderer.setStyle(icon, 'fill', fill);
  this.renderer.setStyle(icon, 'transform', transform);
  this.renderer.setStyle(icon, 'transform-origin', 'center');
}
}
