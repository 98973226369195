import { created } from 'amplify/backend/function/mmpBaseLayer/ts/exceptions';
import { environment, env, cdkEnv, cdkEnvV1 } from '../../environments/environment';

export const MOVANO = Object.freeze({
  IMG_HEADER: '../../assets/dashboardTittle.png',
  IMG_HEADER_DARK: '../../assets/dashboardTittleDark.png',
  IMG_LOGO: '../../assets/MovanoLogo.png',
  IMG_SLOGAN: '../../assets/MovanoSlogan.png',
  IMG_RINGS: '../../assets/MovanoRings.png',
  TIME_OPT01: 'Last week',
  TIME_OPT02: 'Last Month',
  TIME_OPT03: 'Last 3 Months',
  TIME_OPT04: 'Last 6 Months',
  FEMININE: 'F',
  MASCULINE: 'M',
  NON_BINARY: 'N',
  VERSION: '1.12'
});

export const MOVANO_BACK = Object.freeze({
  BACK_URL: environment.BACK_URL,
  NEW_BACK_URL: env.BACK_URL, 
  CDK_BACK_URL: cdkEnv.BACK_URL,
  CDK_BACK_URL_V1 : cdkEnvV1.BACK_URL,
  LAST_MONTH_INFO: 'header',
  FOOTER_INFO: 'footer',
  SALES_PRODUCTION: 'stats',
  SYNCS_SLEEP: 'sync',
  INCIDENT: 'incidences',
  DEVICE_TABLE: 'device/list',
  USER_TABLE: 'user/list',
  USER_DEVICES: 'user/devices/',
  DEVICE_MEASURE_LINE: 'user/health-metrics/hour/',
  DEVICE_MEASURE_RANGE: 'user/health-metrics/day/',
  DEVICE_MEASURE_ATOMIC: 'user/data/atomic/hour/',
  WAVEFORM: 'user/wave/hour/sns/',
  WAVEFORM_TODAY: 'user/wave/today/',
  MIXPANEL_USER_PROFILE: 'user/mixpanel/profile/',
  SLEEP_DATA_DAY: 'user/sleep/day/',
  SLEEP_DATA_HOUR: 'user/sleep/hour/', 
  SPOT_DAY: 'user/spot/day/',
  SPOT_HOUR :'user/spot/hour/',
  INSIGHT_APP :'notifications/insight-application',
  NOTIFICATION_LIST: 'notifications/notification-list',
  JOB_EXECUTION: 'notifications/insight-job-executions',
  WORKOUT_USAGE: 'user/workout/'
});

export const RECOVER_PASS  = Object.freeze({
  TITTLE: 'Recover you password',
  TEXT: 'Please, type your email address to recover your password and access your account',
  BUTTON: 'Recover password',
  WEAK: 'Weak',
  MEDIUM: 'Average',
  STRONG: 'Strong',
  ERROR: 'Incorrect email',
  CODE_TITTLE: 'Check your inbox',
  CODE_TEXT: 'We sent you an email with a validation code. Please type it here to set a new password',
  CODE_ERROR: 'Incorrect code',
  CODE_BUTTON: 'Set a new password',
  CODE_BUTTON_RESEND: 'Send me a code again',
  NEW_PASS_TITTLE: 'Set a new password',
  NEW_PASS_TEXT: 'Write the new password and the code that we send to',
  NEW_PASS_BUTTON: 'Recover password',
  NEW_PASS_ERROR: 'Passwords dont match',
  END: 'Password saved!',
  END_BUTTON: 'Back to login',
  END_ERROR: 'An error ocurred!',
  END_ERROR_TEXT: 'There was an error during the API call',
});

export const DASHBOARD = Object.freeze({
  TITTLE: 'Dashboard',
  BAR_ICONS : '../../assets/barChart_legendIcons',
  LAST_COMP_ICONS: '../../assets/lastCompt_icons'
});

export const DEVICES = Object.freeze({
  TITTLE: 'Devices',
  TABLE_NAME: 'Device name',
  TABLE_USERID: 'Associated User ID',
  TABLE_TYPE: 'Device type',
  TABLE_SN: 'Serial Number',
  TABLE_FIRMWARE: 'Firmware',
  TABLE_HARDWARE: 'Hardware',
  TABLE_STATUS: 'Status',
  TABLE_LAST_CHARGE: 'Last charge',
  TABLE_LAST_SYNC: 'Last sync',
  TABLE_TICKET: 'Tickets',
  ACTIVE: 'active',
  BLOCKED: 'blocked',
  INACTIVE: 'inactive',
  TICKET_CREATED: 'Created',
  TICKET_OPENED: 'Opened',
  TICKET_SOLVED: 'Solved',
  TICKET_TITLE: 'Ticket title',
  TICKET_STATUS: 'Ticket Status',
  TICKET_INFO: 'Info'

});

export const USERS = Object.freeze({
  TITTLE: 'Users',
  TABLE_USER_ID: 'User ID',
  TABLE_GENDER: 'Gender',
  TABLE_AGE: 'Age',
  TABLE_REGISTER_DATE: 'Register date',
  TABLE_COUNTRY: 'Country',
  TABLE_STATE: 'State',
  TABLE_LAST_SYNC: 'Last sync',
  TABLE_DEVICES: 'Devices',
  TABLE_BUGS: 'Bugs',
  TABLE_TICKETS: 'Tickets',
  DEVICE_SN: 'Serial Number',
  DEVICE_TYPE: 'Device type',
  DEVICE_EMPTY: '',
  DEVICE_NAME: 'Device name',
  DEVICE_FIRMWARE: 'Firmware',
  DEVICE_STATUS: 'Status',
  DEVICE_LAST_CHARGE: 'Last charge',
  DEVICE_LAST_SYNC: 'Last sync',
  DEVICE_BATTERY: 'Battery',
  TABLE_BUG_CREATED: 'Created',
  TABLE_BUG_TITLE: 'Bug title',
});

export const NTF_INSIGHT_APP = Object.freeze({
  TITTLE: 'Notifications',
  TABLE_NAME: 'Name',
  TABLE_APPLICATION_ID: 'Application ID',
  TABLE_TYPE: 'Type',
  TABLE_RELEASE_VERSION: 'Release version',
  TABLE_ARCHITECTURE: 'Architecture',
  TABLE_LAST_MODIFIED: 'Last modified',
  TABLE_STATUS: 'Status',
  SUB_NAME: 'Name',
  SUB_TYPE: 'Type',
  SUB_ARCHITECTURE: 'Architecture',
  SUB_INTERACTIVE_ENDPOINT: 'Interactive endpoint',
  SUB_CREATION_TIME: 'Creation time',
  SUB_APPLICATION_ID: 'Application ID',
  SUB_RELEASE_VERSION: 'Release version',
  SUB_STATUS: 'Status',
  SUB_ARN: 'ARN',
  SUB_LAST_UPDATED: 'Last Updated',
});

export const NTF_LIST = Object.freeze({
  TITTLE: 'Notifications',
  TABLE_NAME: 'Insight Application',
  TABLE_USER_ID: 'User ID',
  TABLE_JOB_NAME: 'Job Name',
  TABLE_NOTIFICATION_ID: 'Notification ID',
  TABLE_MESSAGE_TYPE: 'Message Type',
  TABLE_SENT_CUSTOMER: 'Sent to Customer',
  TABLE_SEND_DATE: 'Send date',
  TABLE_INFO: 'Info',
});

export const NTF_INSIGHT_JOB = Object.freeze({
  TITTLE: 'Notifications',
  TABLE_EXECUTION: 'Execution Name',
  TABLE_RUNS: 'Runs',
  TABLE_SCHEDULE: 'Schedule',
  TABLE_LAST_RUN: 'Last Run',
  TABLE_NEXT_RUN: 'Next Run',
  TABLE_STATUS: 'Status Last Job',
});

export const NTF_INSIGHT_JOB_RUNS = Object.freeze({
  RUN_NAME: 'Job name',
  RUN_ID: 'Job run ID',
  NOTIFICATION_LOGS: 'Notification logs',
  START_TIME: 'Start time',
  RUN_TIME: 'Run time',
  RUN_STATUS: 'Run status',
  EXPANDED_USER_ID: 'User ID',
  EXPANDED_NOTIFICATION_ID: 'Notificiation ID',
  EXPANDED_MESSAGE_TYPE: 'Message type',
  EXPANDED_SEND_DATE: 'Send date',
  EXPANDED_INFO: 'Info',
});

export const WRK_USAGE = Object.freeze({
  ACTIVITY: 'Activity type',
  TABLE_DATE: 'Date',
  TABLE_HOUR: 'Hour',
  TABLE_STEP_COUNT: 'Step count',
  TABLE_CALORIES: 'Calories (cal)',
  TABLE_DISTANCE: 'Distance (m)',
  TABLE_ACTIVE_TIME: 'Active time',

});

export const STATS_SUMMARY = Object.freeze({
  TITTLE: 'Stats & Summary',
 
});

export const FILTER = Object.freeze({
  TITTLE: 'Filter',
  RESET: 'Reset',
  DEVICE_STATUS: 'Status',
  DEVICE_TYPE: 'Device type',
  DEVICE_FIRMWARE: 'Firmware',
  DEVICE_LAST_CHARGE: 'Last charge',
  DEVICE_LAST_SYNC: 'Last Sync',
  USER_GENDER: 'Gender',
  USER_COUNTRY_PLACEHOLDER : 'Select Country',
  USER_COUNTRY: 'Country',
  USER_REGISTER_DATE: 'Register Date',
  USER_LAST_SYNC: 'Last Sync',
  NTF_LIST_APPS: 'Applications',
});

export const LOGGED_TAB = Object.freeze({
  TITTLE: 'Menu',
  STATUS: 'You are logged as',
  RESET_PASS: 'Reset password',
  MOVANO: 'Movano users'
});

export const FEATURES_TAB = Object.freeze({
  TITTLE: 'Menu',
  DASHBOARD: 'Dashboard',
  DEVICES: 'Devices',
  USERS: 'Users',
  NOTIFICATIONS: 'Notifications',
  MOBILE: 'Mobile App',
  STATS: 'Stats & Summary',
  ADMIN: 'Administration',
  ORGANIZATIONS: 'Organizations'
});

export const COGNITO_NEW_EDIT_USER ={
  NEW_TITTLE : 'New user',
  EDIT_TITTLE : 'Edit Movano user',
  ID: 'User ID',
  ID_PLACEHOLDER: 'ID-XXXXXX-XX',
  NAME: 'Username',
  NAME_PLACEHOLDER: 'NAME-XXXXXX-XX',
  FULL_NAME: 'Full name',
  FULL_NAME_PLACEHOLDER: 'FULL_NAME-XXXXXX-XX',
  ROLE: 'Role',
  ROLE_PLACEHOLDER: 'Select an option',
  EMAIL: 'Email',
  EMAIL_PLACEHOLDER: 'xxxx@xxx.com',
  NEW_BUTTON: 'Add user',
  EDIT_BUTTON: 'Update user',
}

export const MONTHS_SHORT = ["Jan", "Feb", "March", "Apr", "May", "June", "July", "Aug", "Sep", "Oct", "Nov", "Dec"];
export const MONTHS = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
