import { AfterViewInit, Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { incidentCarrousel, salesProductionCarrousel, salesSyncCarrousel } from '../../shared/movano-interfaces';
import { EChartsOption } from 'echarts';

@Component({
  selector: 'app-movano-chart',
  templateUrl: './movano-chart.component.html',
  styleUrls: ['./movano-chart.component.scss']
})
export class MovanoChartComponent implements OnInit, OnChanges, AfterViewInit {
  chartData: any;
  basicOptions: any;
  chartTypes = ['Sales', 'Activity', 'Incidents'];
  pluginLabel = ChartDataLabels;
  labelColor = '#00593B';
  option_users ?: EChartsOption;

  public innerWidth: any;
  ctx: any;
  @Input() darkMode: boolean;
  @Input() chartNow: number;
  @Input() salesData?: salesProductionCarrousel;
  @Input() syncsData?: salesSyncCarrousel;
  @Input() incidentsData?: incidentCarrousel;
  @Input() selectedDate: number = 1;
  @Input() paused: boolean = false;
  @Output() changeComponent = new EventEmitter<boolean>();
  @Output() pauseChart = new EventEmitter<boolean>();

  literal : object = {
    tittle : ''
  }

  private purgeData: boolean = false;
  maxValueChart = [0, 0, 0];
  avgValueChart = [2, 2, 2];
  totalValueChart = [[0,0], [0,0], [0,0]];

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.innerWidth = document.documentElement.clientWidth;
    this.update();
  }

  constructor(private primengConfig: PrimeNGConfig) {
    this.innerWidth = document.documentElement.clientWidth;
    this.darkMode = true;
    this.chartNow = 0;
    // this.salesData = {} as salesProductionCarrousel;
    // this.syncsData = {} as salesSyncCarrousel;
    // this.incidentsData = {} as incidentCarrousel;
  }

  ngOnInit() {
    this.innerWidth = document.documentElement.clientWidth;
    this.labelColor = this.darkMode ? '#00B377' : '#00593B';
    this.update();
  }

  ngAfterViewInit() {
    //this.primengConfig.ripple = true;
    //const canvas = document.getElementById('myChart')!.getElementsByTagName('canvas')[0] as unknown as HTMLCanvasElement;
    //this.ctx = canvas.getContext('2d');

    this.update();
  }

  update() {
    switch (this.chartTypes[this.chartNow]) {
      case 'Sales':
        this.chartData = this.salesChart();
        this.labelColor = this.darkMode ? '#0B84FF' : '#054280';
        break;
      case 'Activity':
        this.chartData = this.activityChart();
        this.labelColor = this.darkMode ? '#00B377' : '#00593B';
        break;
      case 'Incidents':
        this.chartData = this.incidentsChart();
        this.labelColor = this.darkMode ? '#FFFFFF' : '#66006A';
        break;
    }
    this.applyDarkTheme();
  }

  ngOnChanges(changes: SimpleChanges) {

   //console.log("chart: ", this.chartNow);
    if (this.salesData) {
      this.maxValueChart[0] = Math.max(...this.salesData.salesChart.monvano_unit_sold);
    }
    if (this.incidentsData) {
      this.maxValueChart[2] = Math.max(...this.incidentsData.incidentChart.unit_incident);
    }
    if (this.syncsData) {
      this.maxValueChart[1] = Math.max(...this.syncsData.syncChart.usage_sync);
    }

    this.update();
  }

  pause() {
    this.paused = !this.paused;
    this.pauseChart.emit();
  }

  passToMap(next: boolean) {
    this.changeComponent.emit(next);
  }

  changeChart(nextChart: number) {
    this.chartNow += nextChart;
    this.update();
  }

  applyDarkTheme() {
    this.basicOptions = {
      plugins: {
        legend: false,
        tooltip: {
          enabled: false,
          position: 'nearest',
          external: (context: any) => {
            this.externalTooltipHandler(context, this.chartTypes[this.chartNow], this.totalValueChart[this.chartNow])
          }
        },
        datalabels: (this.innerWidth > 426) ? {
          color: this.labelColor,
          font: {
            size: (this.innerWidth > 1440) ? this.innerWidth * 0.00694 : '10px',
            weight: '700',
          },
          align: 'top',
          anchor: 'end',
          formatter: (val: any, context: any) => {
            if (this.chartTypes[this.chartNow] == 'Incidents')
              return (context.dataIndex % 5 === 1 && context.datasetIndex === 0 && !Number.isNaN(val)) ? `${val}` : '';

            return (context.dataIndex % 5 === 1 && context.datasetIndex === 1 && !Number.isNaN(val)) ? `${val}` : '';
          }
        } : false,
      },
      interaction: {
        mode: 'nearest',
        axis: 'x',
        intersect: false
      },
      maintainAspectRatio: false,
      responsive: true,
      scales: {
        x: {
          display: false,
          stacked: 'single',
          ticks: {
            color: 'black'
          },
          grid: {
            color: 'rgba(255,255,255,0)'
          }
        },
        y: {
          display: false,
          beginAtZero: true,
          ticks: {
            color: 'black'
          },
          grid: {
            color: 'rgba(255,255,255,0)'
          }
        }
      }
    };

    this.option_users = {
      xAxis: {
        type: 'category',
        data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
      },
      yAxis: {
        type: 'value'
      },
      series: [
        {
          data: [120, 200, 150, 80, 70, 110, 130],
          type: 'bar'
        }
      ]
    }
  }

  externalTooltipHandler(context: any, _title: string, _max : number[]) {
    // Tooltip Element
    const { chart, tooltip } = context;
    let tooltipEl = chart.canvas.parentNode.querySelector('div');

    if (!tooltipEl) {
      tooltipEl = document.createElement('div');
      tooltipEl.style.background = 'rgba(0, 0, 0, 0.7)';
      tooltipEl.style.borderRadius = '3px';
      tooltipEl.style.color = 'white';
      tooltipEl.style.opacity = 1;
      tooltipEl.style.pointerEvents = 'none';
      tooltipEl.style.position = 'absolute';
      tooltipEl.style.transform = 'translate(-110%, 0)';
      tooltipEl.style.transition = 'all .1s ease';

      const table = document.createElement('table');
      table.style.margin = '0px';

      tooltipEl.appendChild(table);
      chart.canvas.parentNode.appendChild(tooltipEl);
    }
    // Hide if no tooltip
    if (tooltip.opacity === 0) {
      tooltipEl.style.opacity = 0;
      return;
    }

    // Set Text
    if (tooltip.body) {
      const titleLines = tooltip.title || [];

      const bodyLines = tooltip.body.map((b: { lines: any; }) => b.lines);

      const tableHead = document.createElement('thead');

      const tableBody = document.createElement('tbody');
      tableHead.classList.add('chartToolTip');

      const span = document.createElement('span');
      span.style.border = 'none';
      span.style.color = '#7797B8';
      span.style.fontSize = 'max(0.7vw, 10px)';
      span.style.fontWeight = '500';
      span.style.height = 'max(1.04vw, 15px)';
      span.style.width = 'max(3.82vw, 55px)';
      span.textContent = _title;
      span.style.marginLeft = '10px';

      tableBody.appendChild(span);

      const div = document.createElement('div');
      div.style.display = 'flex';
      div.style.flexDirection = 'row';
      div.style.background = 'none !important';
      div.style.height = '30px !important';

      const tr = document.createElement('tr');
      tr.style.backgroundColor = 'inherit';
      tr.style.borderWidth = '0';
      tr.style.display = 'flex';
      tr.style.flexDirection = 'column';

      const td = document.createElement('td');
      td.style.borderWidth = '0';

      let text = document.createTextNode(bodyLines[1] || '0');
      td.appendChild(text);
      tr.appendChild(td);
      div.appendChild(tr);

      const td_percentage = document.createElement('td');
      td_percentage.style.borderWidth = '0';
      td_percentage.style.fontWeight = '400';
      td_percentage.style.fontSize = 'max(0.9vw, 13px)';
      td_percentage.style.opacity = '0.7';
      let min : number = 0;
      if(_max[1] > 0) {
        min  = (Number(bodyLines[1]) * 100) / _max[1];
        min = Math.round(min);
      }
      const text_percentage = document.createTextNode(min.toString() + '%');

      td_percentage.appendChild(text_percentage);
      tr.appendChild(td_percentage);

      const tr_ = document.createElement('tr');
      tr_.style.backgroundColor = 'inherit';
      tr_.style.borderWidth = '0';
      tr_.style.display = 'flex';
      tr_.style.flexDirection = 'column';

      const td_ = document.createElement('td');
      td_.style.borderWidth = '0';

      let text_ = document.createTextNode(bodyLines[0] || '0');

      //td.appendChild(span);
      td_.appendChild(text_);
      tr_.appendChild(td_);
      div.appendChild(tr_);

      const td_percentage_ = document.createElement('td');
      td_percentage_.style.borderWidth = '0';
      td_percentage_.style.fontWeight = '400';
      td_percentage_.style.fontSize = 'max(0.9vw, 13px)';
      td_percentage_.style.opacity = '0.7';
      let max : number = 0;
      if(_max[0] > 0){
        max = (Number(bodyLines[0]) * 100) / _max[0];
        max = Math.round(max);
      }
      const text_percentage_ = document.createTextNode(max.toString() + '%');

      td_percentage_.appendChild(text_percentage_);
      tr_.appendChild(td_percentage_);

      tableBody.appendChild(div);
      const tableRoot = tooltipEl.querySelector('table');

      // Remove old children
      while (tableRoot.firstChild) {
        tableRoot.firstChild.remove();
      }

      // Add new children
      tableRoot.appendChild(tableHead);
      tableRoot.appendChild(tableBody);
      //tableRoot.style.backgroundColor = this.darkMode ? '#061E37' : '#FFFFFF';
      tableRoot.classList.add('chartToolTipDiv');
    }

    const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

    // Display, position, and set styles for font
    tooltipEl.style.opacity = 1;
    tooltipEl.style.left = positionX + tooltip.caretX + 'px';
    tooltipEl.style.top = positionY + tooltip.caretY + 'px';
    tooltipEl.style.font = tooltip.options.bodyFont.string;
    tooltipEl.style.padding = tooltip.options.padding + 'px ' + tooltip.options.padding + 'px';
  };

  activityChart(): any {
    let gradient, gradient2, gradient3;
    if (this.ctx) {
      gradient = this.ctx!.createLinearGradient(0, 0, 0, (this.innerWidth < 1440) ? 400 : (this.innerWidth * 0.27));
      gradient.addColorStop(0, this.darkMode ? 'rgba(199, 253, 235, 0.2)' : '#C7FDEB');
      gradient.addColorStop(1, this.darkMode ? 'rgba(199, 253, 235, 0)' : 'rgba(199, 253, 235, 0)');
      gradient2 = this.ctx!.createLinearGradient(0, 0, 0, (this.innerWidth < 1440) ? 400 : (this.innerWidth * 0.27));
      gradient2.addColorStop(0, 'rgba(0, 179, 119, 1)');
      gradient2.addColorStop(1, 'rgba(0, 179, 119, 0)');
      gradient3 = this.ctx!.createLinearGradient(0,
        (this.innerWidth < 1440) ? 200 : (this.innerWidth * 0.2083), 0,
        (this.innerWidth < 1440) ? 440 : (this.innerWidth * 0.3125));
      gradient3.addColorStop(0, 'rgba(255, 255, 255, 1)');
      gradient3.addColorStop(1, 'rgba(255, 255, 255, 0)');
    }

    let xLabel: any = [];
    let usageSleep: number[] = [NaN];
    let usageSync: number[] = [NaN];
    let totalSync: number = 0;
    let totalSleep: number = 0;

    if (this.syncsData) {
      xLabel.push('');
      this.syncsData.syncChart.usage_sleep.forEach(element => {
        xLabel.push('');
        usageSleep.push(element);
        totalSleep += Number(element);
      });
      xLabel.push('');
      usageSleep.push(NaN);

      this.syncsData.syncChart.usage_sync.forEach(element => {
        usageSync.push(element);
        totalSync += Number(element);
      });
      usageSync.push(NaN);
      this.avgValueChart[1] = totalSync / this.syncsData.syncChart.usage_sync.length;
      this.totalValueChart[1][0] = totalSleep;
      this.totalValueChart[1][1] = totalSync;

    }

    return {
      labels: (this.syncsData) ?
        xLabel
        :
        ['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',],

      datasets: [{
        type: 'bar',
        borderRadius: 1000,
        borderWidth: 0,
        spacing: this.innerWidth * 0.009,
        barThickness: Math.max(this.innerWidth * 0.002777, 3),
        borderColor: 'rgba(0, 179, 119, 0)',
        hoverBorderWidth: 0,
        hoverBackgroundColor: this.darkMode ? '#FFFFFF' : '#012E1E',
        fill: false,
        data: (this.syncsData) ?
          usageSleep
          :
          [NaN, 10, 4, 13, 16, 20, 14, 14, 10, 4, 13, 16, 20, 14, 14, 10, 4, 13, 16, 20, 14, 14, 10, 4, 13, 16, 20, 14, 14, 10, 4, 13, 16, 20, 14, 14, 10, 4, 13, 16, 20, 14, 14, 10, 4, 13, 16, 20, 14, 14, 10, 4, 13, 16, NaN, NaN],
        backgroundColor: this.darkMode ? (this.ctx) ? gradient3 : '' : '#598573'
      }, {
        type: 'bar',
        borderRadius: 1000,
        borderWidth: 0,
        hoverBorderWidth: 0,
        spacing: this.innerWidth * 0.009,
        barThickness: Math.max(this.innerWidth * 0.002777, 3),
        hoverBorderColor: 'rgba(0, 0, 0, 0.025)',
        hoverBackgroundColor: '#00B377',
        borderColor: 'rgba(0, 179, 119, 0)',
        fill: false,
        data: (this.syncsData) ?
          usageSync
          :
          [NaN, 25, 12, 48, 56, 76, 42, 50, 25, 12, 48, 56, 76, 42, 50, 25, 12, 48, 56, 76, 42, 50, 25, 12, 48, 56, 76, 42, 50, 25, 12, 48, 56, 76, 42, 50, 25, 12, 48, 56, 76, 42, 50, 25, 12, 48, 56, 76, 42, 50, 25, 12, 48, 56, NaN, NaN],
        backgroundColor: 'rgba(0, 179, 119, 0.4)'
      },
      {

        data: [5 - 2, 6 - 2, 7 - 2, 8 - 2, 8 - 2, 8 - 2, 6 - 2, 8 - 2, 5 - 2, 2 - 2, 0, 4 - 2, 6 - 2, 7 - 2, 8 - 2, 7 - 2, 8 - 2, 6 - 2, 7 - 2, 8 - 2, 8 - 2, 8 - 2, 6 - 2, 4 - 2, 3 - 2, 5 - 2, 6 - 2, 7 - 2, 7 - 2, 8 - 2, 9 - 2, 10 - 2, 12 - 2, 11 - 2, 8 - 2, 9 - 2, 8 - 2, 7 - 2, 6 - 2, 5 - 2, 5 - 2],
        fill: true,
        borderColor: '#FFA716',
        hoverBorderWidth: 0,
        hoverBackgroundColor: 'transparent',
        borderWidth: 0,
        tension: .45,
        spanGaps: true,
        radius: 0,
        interaction: {
          intersect: false
        },
        backgroundColor: (this.ctx) ? gradient2 : ''
      },
      {
        data: [5, 6, 7, 8, 8, 8, 6, 8, 5, 2, 1, 4, 6, 7, 8, 7, 8, 6, 7, 8, 8, 8, 6, 4, 3, 5, 6, 7, 7, 8, 9, 10, 12, 11, 8, 9, 8, 7, 6, 5, 5],
        fill: true,
        borderWidth: 0,
        tension: .45,
        spanGaps: true,
        hoverBorderWidth: 0,
        hoverBackgroundColor: 'transparent',
        radius: 0,
        interaction: {
          intersect: false
        },
        backgroundColor: (this.ctx) ? gradient : ''
      }]
    };
  }

  salesChart(): any {

    let gradient, gradient2, gradient3, gradient4;

    if (this.ctx) {
      gradient = this.ctx!.createLinearGradient(0, (this.innerWidth * 0.1083), 0, (this.innerWidth < 1440) ? 440 : (this.innerWidth * 0.3125));
      gradient.addColorStop(0, 'rgba(11, 132, 255, 0.4)');
      gradient.addColorStop(1, 'rgba(13, 204, 255, 0.4)');
      gradient2 = this.ctx!.createLinearGradient(0, (this.innerWidth < 1440) ? 200 : (this.innerWidth * 0.2083), 0, (this.innerWidth < 1440) ? 440 : (this.innerWidth * 0.3125));
      gradient2.addColorStop(0, 'rgba(5, 66, 128, 0.5)');
      gradient2.addColorStop(1, 'rgba(11, 132, 255, 0.5)');
      gradient3 = this.ctx!.createLinearGradient(0, (this.innerWidth < 1440) ? 200 : (this.innerWidth * 0.2083), 0, (this.innerWidth < 1440) ? 440 : (this.innerWidth * 0.3125));
      gradient3.addColorStop(0, 'rgba(255, 255, 255, 0.65)');
      gradient3.addColorStop(1, 'rgba(255, 255, 255, 0)');
      gradient4 = this.ctx!.createLinearGradient(0, (this.innerWidth * 0.1083), 0, (this.innerWidth < 1440) ? 440 : (this.innerWidth * 0.3125));
      gradient4.addColorStop(0, 'rgba(11, 132, 255, 0.65)');
      gradient4.addColorStop(1, 'rgba(13, 204, 255, 0.65)');
    }
    let xLabel: any = [];
    let movanoUnitSold: number[] = [NaN];
    let otherUnitSold: number[] = [NaN];
    let totalOtherSales: number = 0;
    if (this.salesData) {
      xLabel.push('');
      this.salesData.salesChart.other_unit_sold.forEach(element => {
        xLabel.push('');
        otherUnitSold.push(element);
        totalOtherSales += Number(element);
      });
      xLabel.push('');
      otherUnitSold.push(NaN);
      let totalSales: number = 0;
      this.salesData.salesChart.monvano_unit_sold.forEach((element: number) => {
        movanoUnitSold.push(element);
        totalSales += Number(element);
      });
      this.avgValueChart[0] = totalSales / this.salesData.salesChart.monvano_unit_sold.length;
      this.totalValueChart[0][0] = totalOtherSales;
      this.totalValueChart[0][1] = totalSales;

      movanoUnitSold.push(NaN);
    }
    let salesChart = {
      labels: (this.salesData) ? xLabel
        :
        ['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',],

      datasets: [{
        type: 'bar',
        borderRadius: 1000,
        borderWidth: 0,
        spacing: this.innerWidth * 0.009,
        barThickness: Math.max(this.innerWidth * 0.002777, 3),
        borderColor: 'rgba(0, 179, 119, 0)',
        hoverBorderWidth: 0,
        hoverBackgroundColor: this.darkMode ? '#FFFFFF' : '#054280',
        fill: false,
        data: (this.salesData) ? otherUnitSold : [NaN, 10, 4, 13, 16, 20, 14, 14, 10, 4, 13, 16, 20, 14, 14, 10, 4, 13, 16, 20, 14, 14, 10, 4, 13, 16, 20, 14, 14, 10, 4, 13, 16, 20, 14, 14, 10, 4, 13, 16, 20, 14, 14, 10, 4, 13, 16, 20, 14, 14, 10, 4, 13, 16, NaN, NaN],
        backgroundColor: (this.ctx) ? (this.darkMode ? gradient3 : gradient2) : ''
      }, {
        type: 'bar',
        borderRadius: 1000,
        borderWidth: 0,
        spacing: this.innerWidth * 0.009,
        barThickness: Math.max(this.innerWidth * 0.002777, 3),
        borderColor: 'rgba(0, 179, 119, 0)',
        hoverBorderWidth: 0,
        hoverBackgroundColor: '#0B84FF',
        fill: false,
        data: (this.salesData) ? movanoUnitSold : [NaN, 25, 12, 48, 56, 76, 42, 50, 25, 12, 48, 56, 76, 42, 50, 25, 12, 48, 56, 76, 42, 50, 25, 12, 48, 56, 76, 42, 50, 25, 12, 48, 56, 76, 42, 50, 25, 12, 48, 56, 76, 42, 50, 25, 12, 48, 56, 76, 42, 50, 25, 12, 48, 56, NaN, NaN],
        backgroundColor: (this.ctx) ? (this.darkMode ? gradient4 : gradient) : ''
      }]
    };
    //this.purgeFakeDate(salesChart);
    return salesChart;
  }

  incidentsChart(): any {
    let gradient, gradient2, gradient3;
    if (this.ctx) {
      gradient = this.ctx!.createLinearGradient(0, 0, 0, (this.innerWidth < 1440) ? 400 : (this.innerWidth * 0.27));
      gradient.addColorStop(0, 'rgba(102, 0, 106, 0.4)');
      gradient.addColorStop(1, 'rgba(176, 16, 101, 0.4)');
      gradient2 = this.ctx!.createLinearGradient(0, 0, 0, (this.innerWidth < 1440) ? 400 : (this.innerWidth * 0.27));
      gradient2.addColorStop(0, 'rgba(227, 20, 130, 0.65)');
      gradient2.addColorStop(1, 'rgba(227, 20, 130, 0)');
      gradient3 = this.ctx!.createLinearGradient(0, 0, 0, (this.innerWidth < 1440) ? 400 : (this.innerWidth * 0.27));
      gradient3.addColorStop(0, 'rgba(0, 255, 170, 1)');
      gradient3.addColorStop(1, 'rgba(0, 255, 170, 0)');
    }
    let xLabel: any = [];
    let untilIncident: number[] = [NaN];
    let untilRepaired: number[] = [NaN];
    let untilRepairing: number[] = [NaN];
    if (this.incidentsData) {
      let totalUntilIncident: number = 0;
      xLabel.push('');
      this.incidentsData.incidentChart.unit_incident.forEach(element => {
        xLabel.push('');
        untilIncident.push(element);
        totalUntilIncident += Number(element);
      });
      xLabel.push('');
      untilIncident.push(NaN);

      this.incidentsData.incidentChart.unit_repaired.forEach(element => {
        untilRepaired.push(element);
      });
      untilRepaired.push(NaN);

      this.incidentsData.incidentChart.unit_repairing.forEach(element => {
        untilRepairing.push(element);
      });
      untilRepairing.push(NaN);

      this.avgValueChart[2] = totalUntilIncident / this.incidentsData.incidentChart.unit_incident.length;
      this.totalValueChart[2][0] = totalUntilIncident;
      this.totalValueChart[2][1] = totalUntilIncident;
    }
    let repairData =
    {
      labels: (this.incidentsData) ?
        xLabel
        :
        ['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',],

      datasets: [{
        type: 'bar',
        borderRadius: 1000,
        borderWidth: 0,
        spacing: this.innerWidth * 0.009,
        barThickness: Math.max(this.innerWidth * 0.002777, 3),
        borderColor: 'rgba(0, 179, 119, 0)',
        hoverBorderWidth: 0,
        hoverBackgroundColor: this.darkMode ? '#FFFFFF' : '#66006A',
        fill: false,
        data: (this.incidentsData) ?
          untilIncident
          :
          [NaN, 25, 12, 48, 56, 76, 42, 50, 25, 12, 48, 56, 76, 42, 50, 25, 12, 48, 56, 76, 42, 50, 25, 12, 48, 56, 76, 42, 50, 25, 12, 48, 56, 76, 42, 50, 25, 12, 48, 56, 76, 42, 50, 25, 12, 48, 56, 76, 42, 50, 25, 12, 48, 56, NaN, NaN],
        backgroundColor: this.darkMode ? 'rgba(255, 221, 239, 0.4)' : (this.ctx) ? gradient : ''
      },
      {
        data: (this.incidentsData) ?
          untilRepaired
          :
          [1, NaN, NaN, NaN, NaN, NaN, 1.5, NaN, NaN, NaN, NaN, NaN, 2.2, NaN, NaN, NaN, NaN, NaN, 2.7, NaN, NaN, NaN, NaN, NaN, 3.2, NaN, NaN, NaN, NaN, NaN, 4.5, NaN, NaN, NaN, NaN, NaN, 7, NaN, NaN, NaN, NaN, NaN, 9, NaN, NaN, NaN, NaN, NaN, 17, NaN, NaN, NaN, NaN, NaN, NaN, 20,],
        fill: true,
        borderColor: '#FFA716',
        hoverBorderWidth: 0,
        hoverBackgroundColor: 'transparent',
        borderWidth: 0,
        tension: .45,
        spanGaps: true,
        radius: 0,
        interaction: {
          intersect: false
        },
        backgroundColor: (this.ctx) ? gradient3 : ''
      },
      {
        data: (this.incidentsData) ?
          untilRepairing
          :
          [15, NaN, NaN, NaN, NaN, NaN, 20, NaN, NaN, NaN, NaN, NaN, 27, NaN, NaN, NaN, NaN, NaN, 32, NaN, NaN, NaN, NaN, NaN, 37, NaN, NaN, NaN, NaN, NaN, 50, NaN, NaN, NaN, NaN, NaN, 52, NaN, NaN, NaN, NaN, NaN, 55, NaN, NaN, NaN, NaN, NaN, 62, NaN, NaN, NaN, NaN, NaN, NaN, 65,],
        fill: true,
        borderWidth: 0,
        tension: .45,
        spanGaps: true,
        hoverBorderWidth: 0,
        hoverBackgroundColor: 'transparent',
        radius: 0,
        interaction: {
          intersect: false
        },
        backgroundColor: (this.ctx) ? gradient2 : ''
      }]
    };

    //this.purgeFakeDate(repairData);

    return repairData;
  }

  purgeFakeDate(repairData: any) {
    if (this.purgeData) {

      switch (this.chartTypes[this.chartNow]) {
        case 'Sales':
          let totalSales: number = 0;
          for (let i = 0; i < repairData.datasets[1].data.length; i++) {
            if(Number.isNaN(repairData.datasets[1].data[i])) continue;

            repairData.datasets[1].data[i] = +repairData.datasets[1].data[i] + +repairData.datasets[0].data[i];
            repairData.datasets[0].data[i] = 0;

            totalSales = +repairData.datasets[1].data[i] + +totalSales;
            this.maxValueChart[0] = (this.maxValueChart[0] < +repairData.datasets[1].data[i]) ? +repairData.datasets[1].data[i] : this.maxValueChart[0];
          };

          this.avgValueChart[0] = totalSales / repairData.datasets[1].data.length;
          this.totalValueChart[0][0] = totalSales;
          break;
        case 'Activity':
          break;
        case 'Incidents':
          let len = repairData.labels.length;
          repairData.datasets[0].data = new Array(len).fill(0);
          repairData.datasets[1].data = new Array(len).fill(0);
          repairData.datasets[2].data = new Array(len).fill(0);
          this.avgValueChart[2] = 0;
          this.totalValueChart[2][0] = -0;
          break;
      }
    }
  }
}
