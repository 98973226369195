import { Component, Inject, Input } from '@angular/core';
import * as lit from '../../../shared/movano-literals';
import { DarkModeService } from '../../../dark-mode.service';
import { Subscription } from 'rxjs';
import { MovanoService } from 'src/app/movano.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ntfListTable } from 'src/app/shared/movano-interfaces';

@Component({
  selector: 'app-users-ntf-list-message',
  templateUrl: './users-ntf-list-message.component.html',
  styleUrls: ['./users-ntf-list-message.component.scss']
})
export class UsersNtfListMessageComponent {

  protected LITERALS = lit;
  protected loadingUserDeviceList : boolean = true;
  protected ntfMessage : string = '';
  protected darkMode: boolean = false;
  private darkModeSub: Subscription = new Subscription();
  protected menuDisplay: boolean = false;
  protected userDisplay: boolean = false;
  protected loadingData: boolean = false;
  protected filterDisplay: boolean = false;
  protected ntfLists: ntfListTable[] = [];
  protected pageSelected: number = 0;
  protected firstPage: number = 0;
  protected lastPage: number = 0;
  protected rows: number = 16;
  notificationData: any;

    constructor(private darkModeSvc: DarkModeService, protected movanoService: MovanoService,
      protected dialogRef: MatDialogRef<UsersNtfListMessageComponent>,
      @Inject(MAT_DIALOG_DATA) protected data: { notification: any })
  {
    console.log(data.notification); 
    this.darkModeSub = this.darkModeSvc.getVariable().subscribe((val: any) => {
    this.darkMode = val;
    if (data.notification.info) {
    this.notificationData = data.notification.info;
  }
  });
  }

  protected closePanel() {
  this.dialogRef.close();
  }

}
