import { Component } from '@angular/core';

@Component({
  selector: 'app-stats-widgets',
  templateUrl: './stats-widgets.component.html',
  styleUrls: ['./stats-widgets.component.scss']
})
export class StatsWidgetsComponent {

}
