import { Component } from '@angular/core';

@Component({
  selector: 'app-users-ntf-list-side-filter',
  templateUrl: './users-ntf-list-side-filter.component.html',
  styleUrls: ['./users-ntf-list-side-filter.component.scss']
})
export class UsersNtfListSideFilterComponent {

}
