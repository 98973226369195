import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { Dashboard } from 'aws-cdk-lib/aws-cloudwatch';
import { EChartsOption } from 'echarts';
import * as echarts from 'echarts';
import { Subscription } from 'rxjs';
import { DashboardTypeService } from 'src/app/dashboard-type.service';
import { DashboardComponent } from 'src/app/dashboard/dashboard.component';
import { barChart, barSideChart, incidentCarrousel, salesProductionCarrousel, salesSyncCarrousel } from 'src/app/shared/movano-interfaces';
import { DASHBOARD } from 'src/app/shared/movano-literals';
import { fixPixelsBy1440 } from 'src/app/shared/utils';
import { SharedService } from 'src/app/stats/shared.service';

interface legendObj { text: string, colorDark: string, colorLight: string, colorStart: string, colorEnd: string, averageLine: boolean, bar: boolean, values: number[], shadowColor?: string, emphasisColor?: string, averageColor?: string }
@Component({
  selector: 'app-movano-bar-chart',
  templateUrl: './movano-bar-chart.component.html',
  styleUrls: ['./movano-bar-chart.component.scss']
})
export class MovanoBarChartComponent implements OnInit {
  chartData: any;
  //chartTypes = ['Sales', 'Activity', 'Users', 'Spot checks'];
  labelColor = '#00593B';
  option_users?: EChartsOption;

  //Dashboard type
  protected dashboardType: string = 'Sales';
  private dashboardTypeSub: Subscription = new Subscription();
  protected innerWidth: number = 1440;

  protected today: Date;
  protected slctDate: Date;
  private dateSub: Subscription = new Subscription();
  protected dateStart: Date;
  protected dateEnd: Date;
  private dataSub: Subscription = new Subscription();
  protected data: barChart[] = [];
  private dateOptSub: Subscription = new Subscription();
  protected dateOptions: number = 0;
  protected dataCalendar: string = '';
  protected dayLabel: string = '';

  protected amountOfDays: number = 30;

  ctx: any;
  @Input() darkMode: boolean | undefined;
  //@Input() chartNow ?: number;
  // @Input() salesData?: salesProductionCarrousel;
  // @Input() syncsData?: salesSyncCarrousel;
  // @Input() incidentsData?: incidentCarrousel;
  @Input() selectedDate: number = 1;
  @Input() paused: boolean = false;

  @Output() changeComponent = new EventEmitter<boolean>();
  @Output() pauseChart = new EventEmitter<boolean>();


  totalValueNowBar01 = 0;
  totalValueNowBar02 = 0;
  //averageChart = 0;
  mainColor = 'black';
  secondColor = 'black';
  maxValueChart = 1;
  ICONS: string = DASHBOARD.BAR_ICONS + '/';
  legendObjects: Map<string, legendObj[]> = new Map<string, legendObj[]>([
    ['Activity', [
      //Syncs BAR
      //-----------------------------------------------------------
      {
        text: 'Syncs', colorDark: '#FFFFFF', colorLight: '#00B277',
        colorStart: 'rgba(0, 179, 119, 0.40)', colorEnd: 'rgba(0, 179, 119, 0.40)',
        shadowColor: 'rgba(0, 179, 119, 0.40)', emphasisColor: '#00B377', averageColor: '#00B377',
        averageLine: true, bar: true, values: []
      },
      //Sleep Analysis BAR
      //-----------------------------------------------------------
      {
        text: 'Sleep analysis', colorDark: '#FFFFFF', colorLight: '#061E37',
        colorStart: 'rgba(1, 46, 30, 0.50)', colorEnd: 'rgba(1, 46, 30, 0.50)',
        shadowColor: 'rgba(0, 179, 119, 0.40)', emphasisColor: '#012E1E', averageColor: '#00B377',
        averageLine: false, bar: true, values: []
      },
      //Inactive LINE
      //-----------------------------------------------------------
      {
        text: 'Inactive', colorDark: '#FFFFFF', colorLight: '#92E0C6',
        colorStart: '#C7FDEB', colorEnd: '#C7FDEB00',
        averageLine: false, bar: false, values: []
      },
      //Active LINE
      //-----------------------------------------------------------
      {
        text: 'Active', colorDark: '#FFFFFF', colorLight: 'rgba(0, 179, 119, 0.75)',
        colorStart: '#00B37740', colorEnd: '#00B37700',
        averageLine: false, bar: false, values: []
      },
    ]
    ],
    ['Sales', [
      //Direct Sales BAR
      //-----------------------------------------------------------
      {
        text: 'New Devices linked', colorDark: '#FFFFFF', colorLight: '#0B84FF',
        colorStart: '#0B84FF', colorEnd: '#0DCCFF',
        shadowColor: 'rgba(11, 132, 255, 0.50)', emphasisColor: '#0B84FF', averageColor: '#0B84FF',
        averageLine: true, bar: true, values: []
      },
      //Partner Sales BAR
      //-----------------------------------------------------------
      {
        text: 'Partner sales', colorDark: '#FFFFFF', colorLight: '#054280',
        colorStart: '#054280', colorEnd: '#0B84FF',
        shadowColor: 'rgba(11, 132, 255, 0.50)', emphasisColor: '#054280', averageColor: '#054280',
        averageLine: false, bar: true, values: []
      },
    ]
    ],
    ['Users', [
      //Total Users LINE
      //-----------------------------------------------------------
      {
        text: 'Total users', colorDark: '#FFFFFF', colorLight: 'rgba(253, 130, 14, 0.5)',
        colorStart: '#FF830D40', colorEnd: '#FF830D00',
        averageLine: false, bar: false, values: []
      },
      //Active Users LINE
      //-----------------------------------------------------------
      {
        text: 'Active users', colorDark: '#FFFFFF', colorLight: '#8F4600',
        colorStart: '#92470033', colorEnd: '#92470000',
        averageLine: false, bar: false, values: []
      },
      //New Users BAR
      //-----------------------------------------------------------
      {
        text: 'New users', colorDark: '#FFFFFF', colorLight: '#FD820E',
        colorStart: '#FF830D', colorEnd: '#FF830D',
        shadowColor: 'rgba(255, 131, 13, 0.50)', emphasisColor: '#FF830D', averageColor: '#FF830D',
        averageLine: true, bar: true, values: []
      },
    ]
    ],
    ['SpotChecks', [
      //Spot Check BAR
      //-----------------------------------------------------------
      {
        text: 'Spot check', colorDark: '#FFFFFF', colorLight: '#054280',
        colorStart: '#054280', colorEnd: '#7797B868',
        shadowColor: 'rgba(40, 71, 103, 0.30)', emphasisColor: '#054280', averageColor: '#054280',
        averageLine: true, bar: true, values: []
      },
      //Low Perfussion BAR
      //-----------------------------------------------------------
      {
        text: 'Low perfussion', colorDark: '#FFFFFF', colorLight: '#E31583',
        colorStart: '#E31482', colorEnd: '#E3148266',
        shadowColor: 'rgba(40, 71, 103, 0.30)', emphasisColor: '#E31482', averageColor: '#E31482',
        averageLine: false, bar: true, values: []
      },
    ]
    ],
  ])

  constructor(private dashboardSVC: DashboardTypeService,
    private sharedService: SharedService) {
    this.today = new Date();
    this.slctDate = new Date(this.today);
    this.dateStart = new Date(this.today);
    this.dateEnd = new Date(this.today);
  }

  @HostListener('window:resize', ['$event']) //Event to know the window width
  onResize(event: any) {
    this.innerWidth = document.documentElement.clientWidth;
    this.update();
  }

  ngOnInit() {
    this.data.forEach((bData: barChart) => {
      this.legendObjects.get(bData.label)?.forEach((lgObj: legendObj, i: number) => {
        lgObj.values = bData.values[i];
      });
    })
    this.labelColor = this.darkMode ? '#00B377' : '#00593B';
    this.dashboardTypeSub = this.dashboardSVC.getDashboardType().subscribe((val: any) => {
      this.dashboardType = val;
      this.update();
    });
    this.dateOptSub = this.dashboardSVC.getDateOptSelected().subscribe((val: number) => {
      this.dateOptions = val;
      this.update();
    });
    this.dateSub = this.dashboardSVC.getDate().subscribe((val: Date[]) => {
      this.dateStart = val[0];
      this.dateEnd = val[1];
      this.update();
    });
    this.dataSub = this.dashboardSVC.getBarSideData().subscribe((val: barSideChart | undefined) => {
      if (!val) return;

      this.data = val.barGraph;
      this.data.forEach((bData: barChart) => {
        this.legendObjects.get(bData.label)?.forEach((lgObj: legendObj, i: number) => {
          lgObj.values = bData.values[i];
        });
      })
      this.update();
    });
    this.update();
    this.sharedService.calendarObservable$.subscribe(data => {
      console.log('Received data from calendarObservable$', data);
      this.updateDayLabel(data);
    })
  }

  /**
   * Performs necessary cleanup tasks when the component is about to be destroyed.
   * It unsubscribes from the `darkModeSub` subscription to prevent memory leaks.
   * This method is called before the component is removed from the DOM.
   */
  ngOnDestroy() {
    this.dashboardTypeSub.unsubscribe();
  }


  update() {
    this.amountOfDays = this.data[0].values[0].length - 1;
    switch (this.dashboardType) {
      case 'Sales':
        this.mainColor = '#0B83FE';
        this.secondColor = '#054280';
        this.labelColor = this.darkMode ? '#0B84FF' : '#054280';
        break;
      case 'Activity':
        this.mainColor = '#01B277';
        this.secondColor = '#284767';
        this.labelColor = this.darkMode ? '#00B377' : '#00593B';
        break;
      case 'Users':
        this.mainColor = '#FF830D';
        this.secondColor = '#FF830D';
        this.labelColor = this.darkMode ? '#FFFFFF' : '#FF830D';
        break;
      case 'SpotChecks':
        this.mainColor = '#054180';
        this.secondColor = '#E21581';
        this.labelColor = this.darkMode ? '#FFFFFF' : '#054180';
        break;
    };
    this.option_users = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'none'
        },
        formatter: (params: any) => {
          //console.log(params);
          let barUnits = 0;
          (params as any[]).forEach((element: any) => {
            if (element.seriesType === 'bar') barUnits++;
          });
          function formatDate(inputDate: number, dateSlc: Date): string {
            //console.log(inputDate);
            const date = new Date(dateSlc);
            date.setDate(date.getDate() + inputDate);
            const year = date.getFullYear().toString().substring(2);
            const month = new Intl.DateTimeFormat('en', { month: 'short' }).format(date);
            const day = date.getDate();

            return `${day} ${month} '${year}`;
          }

          return (barUnits > 1) ? `
          <div style="display: flex;  flex-direction: column;  font-family: Zen Kaku Gothic Antique;">
            <span style="padding-bottom:fixPixelsBy1440(5, this.innerWidth);font-size: fixPixelsBy1440(10, this.innerWidth); font-weight: 500; color: #7797B8">
            ${formatDate(params[0].axisValueLabel, this.slctDate)}
            </span>
              <div style="display: flex; font-family: Zen Kaku Gothic Antique;">
                <div style="display: flex; flex-direction: column; color: ${this.mainColor}; border-right: max(0.1vw, 2px) solid #D1F5FF; padding-right: max(0.7vw, 10px); gap: max(0.3vw, 5px)">
                  <span style="font-size: max(1.4vw, 20px); font-weight: 700; line-height: 130.4%;">
                    ${params[params.length - 2].data}
                  </span>
                  <span style="font-size: max(0.7vw, 10px); font-weight: 500; line-height: 130.4%;">${(this.totalValueNowBar01 > 0) ? Math.round((params[params.length - 2].data / this.totalValueNowBar01) * 100) : 0}%</span>
                </div>
                <div style="display: flex; flex-direction: column; color: ${this.secondColor}; padding-left: max(0.7vw, 10px); gap: max(0.3vw, 5px)">
                  <span style="font-size: max(1.4vw, 20px); font-weight: 700; line-height: 130.4%;">
                    ${params[params.length - 1].data}
                  </span>
                  <span style="font-size: max(0.7vw, 10px); font-weight: 500; line-height: 130.4%;">${(this.totalValueNowBar02 > 0) ? Math.round((params[params.length - 1].data / this.totalValueNowBar02) * 100) : 0}%</span>
                </div>
            </div>
          </div>
          ` : `
          <div style="display: flex;  flex-direction: column;  font-family: Zen Kaku Gothic Antique;">
            <span style="font-size: fixPixelsBy1440(10, this.innerWidth); font-weight: 500; line-height: 130.4%; color: ${this.mainColor}>
            ${formatDate(params[0].axisValueLabel, this.slctDate)}
            </span>
              <div style="display: flex; font-family: Zen Kaku Gothic Antique;">
                <div style="display: flex; flex-direction: column; color: ${this.mainColor}; gap: max(0.3vw, 5px)">
                  <span style="font-size: max(1.4vw, 20px); font-weight: 700; line-height: 130.4%;">
                    ${params[params.length - 1].data}
                  </span>
                  <span style="font-size: max(0.7vw, 10px); font-weight: 500; line-height: 130.4%;">${(this.totalValueNowBar01 > 0) ? Math.round((params[params.length - 1].data / this.totalValueNowBar01) * 100) : 0}%</span>
                </div>
            </div>
          </div>
          `},
      },
      grid: {
        height: '100%',
        width: '98%',
        left: 0,
        right: '2%',
        top: '7%',
        bottom: 0,
      },
      xAxis:
      {
        type: 'category',
        position: 'top',
        show: true,
        boundaryGap: false,
        min: -1,
        max: (this.dateOptions === 0 || this.dateOptions === 2) ? this.amountOfDays + 1 : this.amountOfDays,
        splitLine: {
          show: true, // Mostrar las líneas en el grid
          lineStyle: {
            width: fixPixelsBy1440(1, this.innerWidth),
            color: this.darkMode ? 'rgba(255, 255, 255, 0.10)' : '#D1F5FF', // Color de las líneas en el grid
          },

          interval: (this.dateOptions === 0 || this.dateOptions === 2) ? (this.dateOptions === 0) ?
            function (index: number, value: string) {
              return index > -1 && index < 7;
            } :
            function (index: number, value: string) {
              return index > -1 && index < 12;
            } :
            function (index: number, value: string) {
              return index > -1;
            },
        },
        // data: (this.selectedDate == 0) ? this.timeLabels
        //   :
        //   (this.measures_MonthWeek) ? this.measures_MonthWeek![0].dates : [],

        axisLabel: {
          interval: 0,
          formatter: (value: any, index: number) => {
            if (index <= 0 || index > this.amountOfDays + 1) return '';
            //MONTHS & DATE RANGE
            if (this.dateOptions === 1 || this.dateOptions === 3) return value;
            //WEEKS
            if (this.dateOptions === 0) {
              const weekLetter: string[] = ['M', 'T', 'W', 'T', 'F', 'S', 'S'];
              const date = new Date(this.slctDate);
              date.setDate(date.getDate() + +value);
              return `${weekLetter[date.getDay()]}${date.getDate()}`;
            }
            //YEAR
            if (this.dateOptions === 2) {
              const monthsNames: string[] = ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'];
              const date = new Date(this.slctDate);
              date.setMonth(date.getMonth() + +value);
              return `${monthsNames[value % 12]}`;
            }
          },
          fontWeight: 700,
          fontFamily: 'Zen Kaku Gothic Antique',
          fontSize: fixPixelsBy1440(13, this.innerWidth),
          // line-height: '133.4%',
          color: 'rgba(119, 151, 184, 1)',
        }
      },
      yAxis: {
        type: 'value',
        splitNumber: fixPixelsBy1440(4, this.innerWidth),
        nameTextStyle: {
          fontSize: fixPixelsBy1440(10, this.innerWidth),
          fontWeight: 700,
          fontFamily: 'Zen Kaku Gothic Antique',
        },
        axisLabel: {
          inside: true,
          formatter: function (value: any, index: any) {
            if (index === 0) return '';
            return '- ' + value;
          },
          fontWeight: 700,
          color: this.secondColor,
          fontFamily: 'Zen Kaku Gothic Antique',
          fontSize: fixPixelsBy1440(10, this.innerWidth),
        },
        axisLine: {
          show: false
        },
        axisTick: {
          show: false
        },
        splitLine: {
          show: false,
        }
      },
      series: []
    };
    //console.log(this.legendObjects.get(this.dashboardType));
    const objectsAmount = this.legendObjects.get(this.dashboardType)?.length || 0;
    const slctdOject = this.legendObjects.get(this.dashboardType);
    this.totalValueNowBar01 = 0;
    this.totalValueNowBar02 = 0;
    if (!slctdOject) return;
    for (let i: number = (objectsAmount - 1); i >= 0; i--) {
      const lgObj = slctdOject[i];
      let serieBar = (lgObj.bar) ?
        this.barChart(lgObj.values, lgObj.colorStart, lgObj.colorEnd, lgObj.shadowColor!, lgObj.emphasisColor!, lgObj.averageColor!, lgObj.averageLine) :
        this.lineChart(lgObj.values, lgObj.colorStart, lgObj.colorEnd);
      serieBar.zlevel = (objectsAmount - 1) - i;
      (this.option_users!.series as any[]).push(serieBar);
      if (lgObj.bar) {
        if (this.totalValueNowBar01 === 0) this.totalValueNowBar01 = lgObj.values.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
        else this.totalValueNowBar02 = lgObj.values.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
      }
    }
    //console.log(this.option_users);
    // this.legendObjects.get(this.dashboardType)?.forEach((lgObj : legendObj, i: number) => {
    //   let serieBar = (lgObj.bar) ?
    //   this.barChart(lgObj.values, lgObj.colorStart, lgObj.colorEnd, lgObj.shadowColor!, lgObj.emphasisColor!, lgObj.averageColor!, lgObj.averageLine) :
    //   this.lineChart(lgObj.values, lgObj.colorStart, lgObj.colorEnd);
    //   serieBar.z = objectsAmount - i;
    //   (this.option_users!.series as any[]).push(serieBar);
    // });
    //console.log(this.option_users);
  }

  passToMap(next: boolean) {
    if (next) {
      this.dashboardSVC.next(this.dashboardType);
      return;
    }
    this.dashboardSVC.previous(this.dashboardType);
    //this.changeComponent.emit(next);
    // // if (next) {
    // //   this.chartNow = (this.chartNow < this.chartTypes.length - 1) ? this.chartNow + 1 : 0;
    // // } else {
    // //   this.chartNow = (this.chartNow > 0) ? this.chartNow - 1 : this.chartTypes.length - 1;
    // // }
    // this.chartNow = (next)
    // ? (this.chartNow + 1) % this.chartTypes.length
    // : (this.chartNow - 1 + this.chartTypes.length) % this.chartTypes.length;
    // this.update();
  }

  barChart(bar_data: number[], bar_color_start: string, bar_color_last: string, shadow_color: string, emphasis_color: string, average_color: string, average_line: boolean): echarts.SeriesOption {
    return {
      type: 'bar',
      data: bar_data,
      emphasis: {
        itemStyle: {
          color: emphasis_color,
          shadowColor: shadow_color, // Color de la sombra
          shadowBlur: 10, // Intensidad de la sombra
        }
      },
      label: (average_line) ? {
        show: true,
        position: 'top',
        color: average_color,
        fontFamily: 'Zen Kaku Gothic Antique',
        fontSize: fixPixelsBy1440(10, this.innerWidth),
        formatter: function (params: any) {
          // Mostrar etiqueta solo cada 4 valores (0-indexed)
          if (params.dataIndex % 3 === 0) {
            return params.value;
          } else {
            return '';
          }
        },
      } : {},
      barMinWidth: fixPixelsBy1440(1, this.innerWidth),
      markLine: (average_line) ? {
        silent: true,
        symbol: 'none',
        precision: 1,
        lineStyle: {
          color: average_color,
          type: 'dashed'
        },
        label: {
          distance: [fixPixelsBy1440(75, this.innerWidth), fixPixelsBy1440(17, this.innerWidth)],
          fontFamily: 'Zen Kaku Gothic Antique',
          color: average_color,
          position: 'insideStartTop',
          padding: fixPixelsBy1440(7, this.innerWidth),
          borderRadius: fixPixelsBy1440(5, this.innerWidth),
          borderWidth: fixPixelsBy1440(1, this.innerWidth),
          borderType: 'dashed',
          borderColor: average_color,
          backgroundColor: '#FFFFFF',
          formatter: [
            '{a|Average}',
            '{b|{c}}'
          ].join('\n'),
          rich: {
            a: {
              fontWeight: 500,
              lineHeight: fixPixelsBy1440(14, this.innerWidth),
              fontSize: fixPixelsBy1440(10, this.innerWidth),
            },
            b: {
              fontWeight: 700,
              lineHeight: fixPixelsBy1440(27, this.innerWidth),
              fontSize: fixPixelsBy1440(20, this.innerWidth),
            },
          },
          show: true
        },
 
      } : {},
      //zlevel: 2,
      stack: 'barStack',
      barWidth: fixPixelsBy1440(4, this.innerWidth),
      itemStyle: {
        borderRadius: (this.option_users?.series as any[]).length <= 0 && false ? 0 : [5, 5, 0, 0],

        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          { offset: 0, color: bar_color_start },
          { offset: 1, color: bar_color_last }
        ])
      }
    };
  }

  lineChart(line_data: number[], start_color: string, last_color: string): echarts.SeriesOption {
    return {
      name: 'Line 4',
      type: 'line',
      stack: 'lineStack',
      //zlevel: 1,
      smooth: true,
      lineStyle: {
        width: 0
      },
      showSymbol: false,
      emphasis: {
        itemStyle: {
          borderWidth: 0,
        },
      },
      symbolSize: 0,
      areaStyle: {
        opacity: 0.8,
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          { offset: 0, color: start_color },
          { offset: 1, color: last_color }
        ])
      },
      data: line_data,
    };
  }

  updateDayLabel(data: any) {
    const today = new Date();

    switch (data) {
      case 'today':
        const day = today.getDate();
        const month = today.toLocaleDateString('en-US', { month: 'long' });
        const year = today.getFullYear().toString().slice(-2); // Obtener los últimos 2 dígitos del año

        this.dayLabel = `${day}-${month} ${year}`;
        break;

      case 'Last Week':
        const currentDayOfWeek = today.getDay();
        const daysSinceLastSunday = currentDayOfWeek;
        const lastWeekEnd = new Date(today);
        lastWeekEnd.setDate(today.getDate() - daysSinceLastSunday - 1);

        const lastWeekStart = new Date(lastWeekEnd);
        lastWeekStart.setDate(lastWeekEnd.getDate() - 6);

        const options = { day: 'numeric', month: 'long' };

        const startDay = lastWeekStart.getDate();
        const endDay = lastWeekEnd.getDate();
        const startMonth = lastWeekStart.toLocaleDateString('en-US', { month: 'long' });
        const endMonth = lastWeekEnd.toLocaleDateString('en-US', { month: 'long' });

        if (startMonth === endMonth) {
          this.dayLabel = `${startDay}-${endDay} ${endMonth}`;
        } else {
          this.dayLabel = `${startDay} ${startMonth} - ${endDay} ${endMonth}`;
        }
        break;

      case 'Last Month':
        const lastMonth = new Date(today);
        lastMonth.setMonth(today.getMonth() - 1);
        const monthName = lastMonth.toLocaleString('en-US', { month: 'long' });
        this.dayLabel = monthName;
        break;

      case 'Last 7 days':
        const last7DaysStart = new Date(today);
        last7DaysStart.setDate(today.getDate() - 7);

        const startDay7 = last7DaysStart.getDate();
        const endDay7 = today.getDate();
        const startMonth7 = last7DaysStart.toLocaleDateString('en-US', { month: 'long' });
        const endMonth7 = today.toLocaleDateString('en-US', { month: 'long' });

        if (startMonth7 === endMonth7) {
          this.dayLabel = `${startDay7}-${endDay7} ${endMonth7}`;
        } else {
          this.dayLabel = `${startDay7} ${startMonth7} - ${endDay7} ${endMonth7}`;
        }
        break;

        case 'Year to date':
          const startOfYear = new Date(today.getFullYear(), 0, 1); // 1 de enero del año actual

          const startDayYear = startOfYear.getDate(); // Siempre será 1
          const startMonthYear = startOfYear.toLocaleDateString('en-US', { month: 'short' }); // Tres primeras letras
          const endDayYear = today.getDate();
          const endMonthYear = today.toLocaleDateString('en-US', { month: 'short' }); // Tres primeras letras

          if (startMonthYear === endMonthYear) {
            this.dayLabel = `1 ${startMonthYear} - ${endDayYear} ${endMonthYear}`;
          } else {
            this.dayLabel = `1 ${startMonthYear} - ${endDayYear} ${endMonthYear}`;
          }
          break;

      default:
        this.dayLabel = '';
        break;
    }
  }

}

