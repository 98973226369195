import { Component } from '@angular/core';

@Component({
  selector: 'app-stats-health',
  templateUrl: './stats-health.component.html',
  styleUrls: ['./stats-health.component.scss']
})
export class StatsHealthComponent {

}
