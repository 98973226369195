import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, QueryList, SimpleChanges, ViewChild, ViewChildren } from '@angular/core';
import { Chart } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {footerInfo} from "../../shared/movano-interfaces";
import _default from "chart.js/dist/plugins/plugin.legend";
import labels = _default.defaults.labels;
import { round } from '../../shared/utils';
import { EChartsOption } from 'echarts';
import { SharedService } from 'src/app/stats/shared.service';
import { MovanoService } from 'src/app/movano.service';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-movano-stats',
  templateUrl: './movano-stats.component.html',
  styleUrls: ['./movano-stats.component.scss']
})
export class MovanoStatsComponent implements OnInit, OnChanges, AfterViewInit {
  [x: string]: any
  genderPrueba = ChartDataLabels;

  @Input() darkMode: boolean;
  @Input() footerData: footerInfo | undefined;
  @Input() front: boolean;
  @Output() pauseStats = new EventEmitter<boolean>();
  purgeData: boolean = false;
  paused : boolean = false;

  interestData: any;
  totalDataInterest: number;
  basicOptions: any;
  genderOptions: any;
  womenCountBigger: boolean = false;
  genderData: any;
  genderPercentageArray: any[] = [];
  genderDataChart: any;
  totalPeople = 1000;
  barGender: any;


  firmwareData: any;
  firmwareOptions: any;
  versionData: any;
  versionOptions: any;
  charginMax = '38h 40min';
  charginAvarage = '25h 25min';
  charginMin = '5h 05min';
  percentageChar = this.purgeData?0.01:0.75;

  genderStats: any;

  protected statsToday?: any;
  protected statsLastWeek?: any;
  protected statsLastMonth?: any;
  protected statsLast7Days?: any;
  protected statsYearToDate?: any;
  protected statsCustomRange?: any;

  protected activePercentage: string = "";
  protected inactivePercentage: string = "";
  protected activeUsers: string = "";
  protected inactiveUsers: string = "";
  protected menActiveUsers: string = "";
  protected womenActiveUsers: string = "";
  protected menActiveUsersUnits: string = "";
  protected womenActiveUsersUnits: string = "";
  protected menInactiveUsers: string = "";
  protected womenInactiveUsers: string = "";
  protected menInactiveUsersUnits: string = "";
  protected womenInactiveUsersUnits: string = "";

  protected firmwareVersionName?: any;
  protected firmwareVersionPer?: any;
  protected appVersionName?: any;
  protected appVersionPer?: any;
  protected genderTotals?: any;

  public innerWidth: any;

  ctx: any;
  canvas: any;
  genderChart: any;
  hoverValue: any;
  chartGender: any;
  @ViewChildren('genderData') private mychart: any;

  protected option_DonutEmpty : EChartsOption={
    tooltip: {
      show: false,
      trigger: 'item'
    },
    series: [
      {
        name: 'Access From',
        type: 'pie',
        radius: ['80%', '90%'],
        avoidLabelOverlap: false,
        itemStyle: {
          borderRadius: '50%'
        },
        label: {
          show: false,
          position: 'center'
        },
        labelLine: {
          show: false
        },
        data: []
      }
    ]
  };
  protected option_WearableStats ?: EChartsOption;
  protected option_Gender ?: EChartsOption;
  protected option_AppVersion ?: EChartsOption;
  protected option_Interest ?: EChartsOption;

  xAxys = ['0', '5', '18', '35', '50'];
  ageRangePercentages: number[] =[];
  menPercentages: any;
  womenPercentages: any;

  constructor(    private sharedService: SharedService,
    private changeDetector: ChangeDetectorRef, private movanoSvc: MovanoService,) {
    this.darkMode = true;
    this.front = true;
    this.totalDataInterest=0;
  }

  ngOnInit(): void {
    this.innerWidth = document.documentElement.clientWidth;
    this.sharedService.calendarObservable$.subscribe(data => {
      this.updateInfo(data);
    })
    this.movanoSvc.getStatsSummaryUsersToday().subscribe(
      (data) => {
        this.genderStats = data;
        console.log('GenderStats:', this.genderStats);
      },
      (error) => {
        console.error('Error getting gender stats', error);
      }
    );
    this.initializeWithLastWeekData();
  }

  ngAfterViewInit() {
    this.updateInfo();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.innerWidth = document.documentElement.clientWidth;
    this.updateInfo();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.updateInfo();
  }
  async initializeWithLastWeekData() {
    this.statsLastWeek = await lastValueFrom(this.movanoSvc.getStatsSummaryUsersLastWeek());
    this.activePercentage = this.statsLastWeek.data.active.percentage_from_total
    this.inactivePercentage = this.statsLastWeek.data.inactive.percentage_from_total
    this.activeUsers = this.statsLastWeek.data.active.count
    this.inactiveUsers = this.statsLastWeek.data.inactive.count
    this.menActiveUsers = this.statsLastWeek.data.active.gender.man.percentage
    this.womenActiveUsers = this.statsLastWeek.data.active.gender.woman.percentage
    this.menActiveUsersUnits = this.statsLastWeek.data.active.gender.man.count
    this.womenActiveUsersUnits = this.statsLastWeek.data.active.gender.woman.count
    this.menInactiveUsers = this.statsLastWeek.data.inactive.gender.man.percentage
    this.womenInactiveUsers = this.statsLastWeek.data.inactive.gender.woman.percentage
    this.menInactiveUsersUnits = this.statsLastWeek.data.inactive.gender.man.count
    this.womenInactiveUsersUnits = this.statsLastWeek.data.inactive.gender.woman.count

    this.statsLastWeek = await lastValueFrom(this.movanoSvc.getStatsSummaryDevicesLastWeek());
    this.firmwareVersionName = this.getFirmwareVersionsName(this.statsLastWeek)
    this.firmwareVersionPer = this.getFirmwareVersionsPer(this.statsLastWeek)
    this.appVersionName = this.getAppVersionsName(this.statsLastWeek)
    this.appVersionPer = this.getAppVersionsPer(this.statsLastWeek)
  }

  async updateInfo(data?: any) {
    let gradient;
    if (this.ctx) {
      gradient = this.ctx.createConicGradient(0, 100, 100);
      gradient.addColorStop(0, "rgba(37, 134, 221,0)");
      gradient.addColorStop(0.5, "rgba(37, 134, 221,0.1)");
      gradient.addColorStop(0.75, "rgba(37, 134, 221,0)");
    }
    this.innerWidth = document.documentElement.clientWidth;
    this.interestData = this.getInterestsDonut();
    this.genderData = this.getGenderDonut();
    this.genderDataChart = this.getGenderBars();
    this.barGender = {
      plugins: {
        legend: {
          display: false
        },
        tooltip: {
          callbacks: {
            label: (context: any) => {
              const datasetIndex = context.datasetIndex; // Índice del dataset (0: hombres, 1: mujeres)
              const index = context.dataIndex; // Índice del dato dentro del dataset

              let percentage;
              let gender;

              if (datasetIndex === 0) {
                // Hombres
                percentage = this.menPercentages[index];
                gender = 'Men';
              } else if (datasetIndex === 1) {
                // Mujeres
                percentage = this.womenPercentages[index];
                gender = 'Women';
              }

              return `${gender} - ${percentage}%`;
            }
          }
        }
      },
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        x: {
          grid: {
            display: false
          },
          ticks: {
            display: false
          }
        },
        y: {
          grid: {
            display: false
          },
          ticks: {
            display: false
          },
          border: {
            display: false // Oculta la línea del borde del eje Y
          }
        }
      },
      elements: {
        bar: {
          borderWidth: 0 // Opcional: Oculta el borde de las barras
        }
      },
      layout: {
        padding: {
          top: 0,
          bottom: 0
        }
      }
    };

    this.firmwareData = this.getFirmwareDonut();
    this.versionData = this.getAppDonut();
    this.applyDarkTheme();

    switch (data) {
      case 'today':
        this.statsToday = await lastValueFrom(this.movanoSvc.getStatsSummaryUsersToday());
        this.activePercentage = this.statsToday.data.active.percentage_from_total
        this.inactivePercentage = this.statsToday.data.inactive.percentage_from_total
        this.activeUsers = this.statsToday.data.active.count
        this.inactiveUsers = this.statsToday.data.inactive.count
        this.menActiveUsers = this.statsToday.data.active.gender.man.percentage
        this.womenActiveUsers = this.statsToday.data.active.gender.woman.percentage
        this.menActiveUsersUnits = this.statsToday.data.active.gender.man.count
        this.womenActiveUsersUnits = this.statsToday.data.active.gender.woman.count
        this.menInactiveUsers = this.statsToday.data.inactive.gender.man.percentage
        this.womenInactiveUsers = this.statsToday.data.inactive.gender.woman.percentage
        this.menInactiveUsersUnits = this.statsToday.data.inactive.gender.man.count
        this.womenInactiveUsersUnits = this.statsToday.data.inactive.gender.woman.count

        this.statsToday = await lastValueFrom(this.movanoSvc.getStatsSummaryDevicesToday());
        this.firmwareVersionName = this.getFirmwareVersionsName(this.statsToday)
        this.firmwareVersionPer = this.getFirmwareVersionsPer(this.statsToday)
        this.appVersionName = this.getAppVersionsName(this.statsToday)
        this.appVersionPer = this.getAppVersionsPer(this.statsToday)

        break;
      case 'Last Week':
        this.statsLastWeek = await lastValueFrom(this.movanoSvc.getStatsSummaryUsersLastWeek());
        this.activePercentage = this.statsLastWeek.data.active.percentage_from_total
        this.inactivePercentage = this.statsLastWeek.data.inactive.percentage_from_total
        this.activeUsers = this.statsLastWeek.data.active.count
        this.inactiveUsers = this.statsLastWeek.data.inactive.count
        this.menActiveUsers = this.statsLastWeek.data.active.gender.man.percentage
        this.womenActiveUsers = this.statsLastWeek.data.active.gender.woman.percentage
        this.menActiveUsersUnits = this.statsLastWeek.data.active.gender.man.count
        this.womenActiveUsersUnits = this.statsLastWeek.data.active.gender.woman.count
        this.menInactiveUsers = this.statsLastWeek.data.inactive.gender.man.percentage
        this.womenInactiveUsers = this.statsLastWeek.data.inactive.gender.woman.percentage
        this.menInactiveUsersUnits = this.statsLastWeek.data.inactive.gender.man.count
        this.womenInactiveUsersUnits = this.statsLastWeek.data.inactive.gender.woman.count

        this.statsLastWeek = await lastValueFrom(this.movanoSvc.getStatsSummaryDevicesLastWeek());
        this.firmwareVersionName = this.getFirmwareVersionsName(this.statsLastWeek)
        this.firmwareVersionPer = this.getFirmwareVersionsPer(this.statsLastWeek)
        this.appVersionName = this.getAppVersionsName(this.statsLastWeek)
        this.appVersionPer = this.getAppVersionsPer(this.statsLastWeek)
        break;
      case 'Last Month':
        this.statsLastMonth = await lastValueFrom(this.movanoSvc.getStatsSummaryUsersLastMonth());
        this.activePercentage = this.statsLastMonth.data.active.percentage_from_total
        this.inactivePercentage = this.statsLastMonth.data.inactive.percentage_from_total
        this.activeUsers = this.statsLastMonth.data.active.count
        this.inactiveUsers = this.statsLastMonth.data.inactive.count
        this.menActiveUsers = this.statsLastMonth.data.active.gender.man.percentage
        this.womenActiveUsers = this.statsLastMonth.data.active.gender.woman.percentage
        this.menActiveUsersUnits = this.statsLastMonth.data.active.gender.man.count
        this.womenActiveUsersUnits = this.statsLastMonth.data.active.gender.woman.count
        this.menInactiveUsers = this.statsLastMonth.data.inactive.gender.man.percentage
        this.womenInactiveUsers = this.statsLastMonth.data.inactive.gender.woman.percentage
        this.menInactiveUsersUnits = this.statsLastMonth.data.inactive.gender.man.count
        this.womenInactiveUsersUnits = this.statsLastMonth.data.inactive.gender.woman.count

        this.statsLastMonth = await lastValueFrom(this.movanoSvc.getStatsSummaryDevicesLastMonth());
        this.firmwareVersionName = this.getFirmwareVersionsName(this.statsLastMonth)
        this.firmwareVersionPer = this.getFirmwareVersionsPer(this.statsLastMonth)
        this.appVersionName = this.getAppVersionsName(this.statsLastMonth)
        this.appVersionPer = this.getAppVersionsPer(this.statsLastMonth)

        break;
      case 'Last 7 days':
        this.statsLast7Days = await lastValueFrom(this.movanoSvc.getStatsSummaryUsersLast7Days());
        this.activePercentage = this.statsLast7Days.data.active.percentage_from_total
        this.inactivePercentage = this.statsLast7Days.data.inactive.percentage_from_total
        this.activeUsers = this.statsLast7Days.data.active.count
        this.inactiveUsers = this.statsLast7Days.data.inactive.count
        this.menActiveUsers = this.statsLast7Days.data.active.gender.man.percentage
        this.womenActiveUsers = this.statsLast7Days.data.active.gender.woman.percentage
        this.menActiveUsersUnits = this.statsLast7Days.data.active.gender.man.count
        this.womenActiveUsersUnits = this.statsLast7Days.data.active.gender.woman.count
        this.menInactiveUsers = this.statsLast7Days.data.inactive.gender.man.percentage
        this.womenInactiveUsers = this.statsLast7Days.data.inactive.gender.woman.percentage
        this.menInactiveUsersUnits = this.statsLast7Days.data.inactive.gender.man.count
        this.womenInactiveUsersUnits = this.statsLast7Days.data.inactive.gender.woman.count

        this.statsLast7Days = await lastValueFrom(this.movanoSvc.getStatsSummaryDevicesLast7Days());
        this.firmwareVersionName = this.getFirmwareVersionsName(this.statsLast7Days)
        this.firmwareVersionPer = this.getFirmwareVersionsPer(this.statsLast7Days)
        this.appVersionName = this.getAppVersionsName(this.statsLast7Days)
        this.appVersionPer = this.getAppVersionsPer(this.statsLast7Days)

        break;
      case 'Year to date':
        this.statsYearToDate = await lastValueFrom(this.movanoSvc.getStatsSummaryUsersYearToDate());
        this.activePercentage = this.statsYearToDate.data.active.percentage_from_total
        this.inactivePercentage = this.statsYearToDate.data.inactive.percentage_from_total
        this.activeUsers = this.statsYearToDate.data.active.count
        this.inactiveUsers = this.statsYearToDate.data.inactive.count
        this.menActiveUsers = this.statsYearToDate.data.active.gender.man.percentage
        this.womenActiveUsers = this.statsYearToDate.data.active.gender.woman.percentage
        this.menActiveUsersUnits = this.statsYearToDate.data.active.gender.man.count
        this.womenActiveUsersUnits = this.statsYearToDate.data.active.gender.woman.count
        this.menInactiveUsers = this.statsYearToDate.data.inactive.gender.man.percentage
        this.womenInactiveUsers = this.statsYearToDate.data.inactive.gender.woman.percentage
        this.menInactiveUsersUnits = this.statsYearToDate.data.inactive.gender.man.count
        this.womenInactiveUsersUnits = this.statsYearToDate.data.inactive.gender.woman.count

        this.statsYearToDate = await lastValueFrom(this.movanoSvc.getStatsSummaryDevicesYearToDate());
        this.firmwareVersionName = this.getFirmwareVersionsName(this.statsYearToDate)
        this.firmwareVersionPer = this.getFirmwareVersionsPer(this.statsYearToDate)
        this.appVersionName = this.getAppVersionsName(this.statsYearToDate)
        this.appVersionPer = this.getAppVersionsPer(this.statsYearToDate)

        break;
      case 'customRange':
        // Replace with actual start and end dates
        const startDate = '2024-01-01';
        const endDate = '2024-01-31';
        this.statsCustomRange = await lastValueFrom(this.movanoSvc.getStatsSummaryUsersCustomDay(startDate, endDate));
        this.activePercentage = this.statsCustomRange.data.active.percentage_from_total
        this.inactivePercentage = this.statsCustomRange.data.inactive.percentage_from_total
        this.activeUsers = this.statsCustomRange.data.active.count
        this.inactiveUsers = this.statsCustomRange.data.inactive.count
        this.menActiveUsers = this.statsCustomRange.data.active.gender.man.percentage
        this.womenActiveUsers = this.statsCustomRange.data.active.gender.woman.percentage
        this.menActiveUsersUnits = this.statsCustomRange.data.active.gender.man.count
        this.womenActiveUsersUnits = this.statsCustomRange.data.active.gender.woman.count
        this.menInactiveUsers = this.statsCustomRange.data.inactive.gender.man.percentage
        this.womenInactiveUsers = this.statsCustomRange.data.inactive.gender.woman.percentage
        this.menInactiveUsersUnits = this.statsCustomRange.data.inactive.gender.man.count
        this.womenInactiveUsersUnits = this.statsCustomRange.data.inactive.gender.woman.count

        this.statsCustomRange = await lastValueFrom(this.movanoSvc.getStatsSummaryDevicesCustomDay(startDate, endDate));
        this.firmwareVersionName = this.getFirmwareVersionsName(this.statsCustomRange)
        this.firmwareVersionPer = this.getFirmwareVersionsPer(this.statsCustomRange)
        this.appVersionName = this.getAppVersionsName(this.statsCustomRange)
        this.appVersionPer = this.getAppVersionsPer(this.statsCustomRange)
        break;
      default:
        console.error('Unknown active users value:'); // Default case if none match
    }

    this.option_WearableStats = {
      tooltip: {
        show: true,
        trigger: 'item',
        confine: true,
        formatter: (params:any) => {
          // Obtén el color correspondiente al índice del dato
          const color = this.getFirmwareColor(params.dataIndex);

          const firmwareName = this.firmwareVersionName[params.dataIndex];

          // Devuelve el formato deseado
          return `
            <div style="display: flex; align-items: center;">
              <div style="width: 12px; height: 12px; background-color: ${color}; margin-right: 5px; border-radius: 50%;"></div>
              ${firmwareName}: ${params.percent.toFixed(2)}%
            </div>
          `;
        }
      },
      series: [
        {
          name: 'Access From',
          type: 'pie',
          radius: ['80%', '90%'],
          color: this.firmwareVersionPer.map((value: any, index: number) => this.getFirmwareColor(index)),
          avoidLabelOverlap: false,
          itemStyle: {
            borderRadius: '50%'
          },
          emphasis: {
            scaleSize: 2,
            itemStyle: {
              shadowColor: this.darkMode ? 'rgba(255,255,255,0.5)' : 'rgba(0,0,0,0.25)' ,
              shadowBlur: 5,
            },
          },
          label: {
            show: false,
            position: 'center'
          },
          labelLine: {
            show: false
          },
          data: this.firmwareVersionPer
        }
      ]
    };
    const genderTotals = [
      this.genderStats.data.total_users.gender.man.count,
      this.genderStats.data.total_users.gender.woman.count,
      this.genderStats.data.total_users.gender.other.count,
      this.genderStats.data.total_users.gender.unkown.count
    ];
    this.option_Gender = {
      tooltip: {
        show: true,
        trigger: 'item',
        confine: true,
        formatter: function (params:any) {
          const labels = ['Man', 'Women', 'Others', 'Unknown'];
          const total = genderTotals[params.dataIndex];
          return `
            <div style="color: ${params.color}; ">
              <strong style="display: block;">${labels[params.dataIndex]}</strong>
              <span style="display: block;">${params.value}%</span>
              <span style="display: block;">Total: ${total}</span>
            </div>`;
        }
      },
      series: [
        {
          name: 'Gender Distribution',
          type: 'pie',
          radius: ['80%', '90%'],
          color: [
            "#0B84FF",
            "#B01065",
            "#646464",
            "#424242"
          ],
          avoidLabelOverlap: false,
          itemStyle: {
            borderRadius: '50%',
            borderWidth: 20,
          },
          emphasis: {
            scaleSize: 2,
            itemStyle: {
              shadowColor: this.darkMode ? 'rgba(255,255,255,0.5)' : 'rgba(0,0,0,0.25)' ,
              shadowBlur: 5,
            },
          },
          label: {
            show: false,
            position: 'center'
          },
          labelLine: {
            show: false
          },
          data: [
            { value: this.genderStats.data.total_users.gender.man.percentage },
            { value: this.genderStats.data.total_users.gender.woman.percentage},
            { value: this.genderStats.data.total_users.gender.other.percentage},
            { value: this.genderStats.data.total_users.gender.unkown.percentage}
          ]
        }
      ]
    };
    this.option_AppVersion = {
      tooltip: {
        show: true,
        trigger: 'item',
        confine: true,
        formatter: (params:any) => {
          // Obtén el color correspondiente al índice del dato
          const color = this.getAppColor(params.dataIndex);

          const appName = this.appVersionName[params.dataIndex] || 'Others';

          // Devuelve el formato deseado
          return `
            <div style="display: flex; align-items: center;">
              <div style="width: 12px; height: 12px; background-color: ${color}; margin-right: 5px; border-radius: 50%;"></div>
              ${appName}: ${params.percent.toFixed(2)}%
            </div>
          `;
        }
      },
      series: [
        {
          name: 'Access From',
          type: 'pie',
          radius: ['80%', '90%'],
          color: this.appVersionPer.map((value: any, index: number) => this.getAppColor(index)),
          avoidLabelOverlap: false,
          itemStyle: {
            borderRadius: '50%'
          },
          emphasis: {
            scaleSize: 2,
            itemStyle: {
              shadowColor: this.darkMode ? 'rgba(255,255,255,0.5)' : 'rgba(0,0,0,0.25)' ,
              shadowBlur: 5,
            },
          },
          label: {
            show: false,
            position: 'center'
          },
          labelLine: {
            show: false
          },
          data: this.appVersionPer
        }
      ]
    };
    this.option_Interest = {
      tooltip: {
        show: false,
        trigger: 'item'
      },
      series: [
        {
          name: 'Access From',
          type: 'pie',
          radius: ['80%', '90%'],
          color: this.interestData.datasets[0].backgroundColor,
          avoidLabelOverlap: false,
          itemStyle: {
            borderRadius: '50%'
          },
          emphasis: {
            scaleSize: 2,
            itemStyle: {
              shadowColor: this.darkMode ? 'rgba(255,255,255,0.5)' : 'rgba(0,0,0,0.25)' ,
              shadowBlur: 5,
            },
          },
          label: {
            show: false,
            position: 'center'
          },
          labelLine: {
            show: false
          },
          data: this.interestData.datasets[0].data
        }
      ]
    };
  }


getFirmwareVersionsName(data: any) {
  let firmwareVersionArray = data.data.total_devices.firmware_version;
  let firmwareVersionNameArray = [];

  for (let key in firmwareVersionArray) {
      firmwareVersionNameArray.push({
          name: firmwareVersionArray[key].name,
          percentage: firmwareVersionArray[key].percentage
      });
  }

  firmwareVersionNameArray.sort((a, b) => b.percentage - a.percentage);

  return firmwareVersionNameArray.map(item => item.name);
}

getFirmwareVersionsPer(data: any) {
  let firmwareVersionArray = data.data.total_devices.firmware_version;
  let firmwareVersionPerArray = [];

  for (let key in firmwareVersionArray) {
      firmwareVersionPerArray.push({
          percentage: firmwareVersionArray[key].percentage
      });
  }

  firmwareVersionPerArray.sort((a, b) => b.percentage - a.percentage);

  return firmwareVersionPerArray.map(item => item.percentage);
}

getAppVersionsName(data: any) {
  let appVersionArray = data.data.total_devices.app_version;
  let appVersionNameArray = [];

  for (let key in appVersionArray) {
      appVersionNameArray.push({
          name: appVersionArray[key].name,
          percentage: appVersionArray[key].percentage
      });
  }


  appVersionNameArray.sort((a, b) => b.percentage - a.percentage);

  return appVersionNameArray.map(item => item.name);
}

getAppVersionsPer(data: any) {
  let appVersionArray = data.data.total_devices.app_version;
  let appVersionPerArray = [];

  for (let key in appVersionArray) {
      appVersionPerArray.push({
          percentage: appVersionArray[key].percentage
      });
  }

  appVersionPerArray.sort((a, b) => b.percentage - a.percentage);

  return appVersionPerArray.map(item => item.percentage);
}



  getFirmwareColor(index: number): string {
    const baseColors = [
        '#061E37',
        '#054280',
        '#0b84ff'
    ];

     if (index < baseColors.length) {
        return baseColors[index];
    }

    const maxIndex = 20;
    const startColor = baseColors[2];

    const transitionStep = (index - baseColors.length) / (maxIndex - baseColors.length);

    if (transitionStep < 0.5) {
        return this.adjustColorWithPhase(startColor, transitionStep * 2, '#0dccff');
    } else if (transitionStep < 0.75) {
        return this.adjustColorWithPhase('#0dccff', (transitionStep - 0.5) * 4, '#87cefa'); // Tono intermedio claro
    } else {
        return this.adjustColorWithPhase('#87cefa', (transitionStep - 0.75) * 4, '#d3d3d3'); // Gris claro
    }
  }
  getAppColor(index: number): string {
    const baseColors = [
        '#49004B',
        '#66006A',
        '#b01065'
    ];

     if (index < baseColors.length) {
        return baseColors[index];
    }

    const maxIndex = 20;
    const startColor = baseColors[2];

    const transitionStep = (index - baseColors.length) / (maxIndex - baseColors.length);

    if (transitionStep < 0.5) {
        return this.adjustColorWithPhase(startColor, transitionStep * 2, '#f6a1b6');
    } else if (transitionStep < 0.75) {
        return this.adjustColorWithPhase('#f6a1b6', (transitionStep - 0.5) * 4, '#f8d3e5'); // Tono intermedio claro
    } else {
        return this.adjustColorWithPhase('#f8d3e5', (transitionStep - 0.75) * 4, '#fdf4f8'); // Rosita muy claro
    }
  }
adjustColorWithPhase(startHex: string, step: number, endHex: string): string {
    let [r1, g1, b1] = [parseInt(startHex.slice(1, 3), 16), parseInt(startHex.slice(3, 5), 16), parseInt(startHex.slice(5, 7), 16)];
    let [r2, g2, b2] = [parseInt(endHex.slice(1, 3), 16), parseInt(endHex.slice(3, 5), 16), parseInt(endHex.slice(5, 7), 16)];

    const r = Math.round(r1 + (r2 - r1) * step);
    const g = Math.round(g1 + (g2 - g1) * step);
    const b = Math.round(b1 + (b2 - b1) * step);

    return `#${[r, g, b].map(x => x.toString(16).padStart(2, '0')).join('')}`;
}

  pause() {
    this.paused = !this.paused;
    this.pauseStats.emit();
  }

  applyDarkTheme() {
    this.basicOptions = {
      plugins: {
        legend: false,
        tooltip: {
          enabled: false,
          position: 'nearest',
        }
      },
      responsive: true,
    };
    this.genderOptions = {
      plugins: {
        legend: false,
        title: {
          display: true,
          text: '',
          align: 'start',
          justify: 'top',
          color: (this.darkMode) ? '#7797B8' : '#284767',
          font: {
            size: (this.innerWidth >= 1024) ? this.innerWidth / 100 : 30,
            weight: '400',
          },
        },
        tooltip: {
          enabled: false,
          position: 'nearest',
        },
        datalabels: {
          color: (this.darkMode) ? function (context: any) {
            return context.active ? '#0DCCFF' : '#054280';
          } :  '#054280',
          font: {
            size: (this.innerWidth < 1024) ? 10 : this.innerWidth / 125,
            weight: '700',
          },
          // backgroundColor:  (this.darkMode) ? function (context: any) {
          //   return (context.datasetIndex === 1) ?  '' : 'rgb(18, 47, 95)';
          // } :  function (context: any) {
          //   return (context.datasetIndex === 1) ?  '' : '#FFFFFF';
          // },
          align: 'top',
          anchor: 'end',
          formatter: (val: any, context: any) => {
            return (context.datasetIndex === 0) ? '' : `${this.ageRangePercentages[context.dataIndex]}%`;
          }
        }
      },
      responsive: true,
      scales: {
        x: {
          display: true,
          ticks: {
            color: (this.darkMode) ? "rgba(13, 204, 255, 0.5)" : '#7797B8',
            font: {
              size: (this.innerWidth >= 1024) ? this.innerWidth / 110 : 10,
            },
          },
          grid: {
            color: 'rgba(255,255,255,0)'
          }
        },
        y: {
          display: false,
          ticks: {
            color: 'rgba(255,255,255,0)'
          },
          grid: {
            color: 'rgba(255,255,255,0)'
          }
        }
      }
    }
  }
  changeInfo() {
    this.front = !this.front;
  }

  getAppVersionLength() {
    if(this.footerData?.appVersions) {
      return this.footerData.appVersions.length;
    } else {
      return 0;
    }
  }

  getAppVersionPercentage(index: number) {
    if(this.purgeData){
      return '0';
    }else {
      if (this.footerData?.appVersions) {
        return this.footerData.appVersions[index].percentage;
      } else {
        return '0';
      }
    }
  }

  getAppVersionVersion(index: number) {
    if(this.purgeData) {
      return 'undefined';
    } else {
      if (this.footerData?.firmwareVersions) {
        return this.footerData.firmwareVersions[index].version;
      } else {
        return 'undefined';
      }
    }
  }

  getAppDonut() {
    let versionData = {
      labels: ['A', 'B', 'C', 'D'],
      datasets: [
        {
          data: [250, 50, 50, 350],
          borderRadius: 1000,
          borderWidth: 0,
          spacing: 5,
          borderColor: 'rgba(0, 179, 119, 0)',
          backgroundColor: [
            "#FFDDEF",
            "#E31482",
            (this.darkMode) ? '#FF77BF' : '#66006A',
            (this.darkMode) ? '#FFFFFF' : '#49004B',
          ],
          hoverBackgroundColor: [
            "#FFDDEF",
            "#E31482",
            (this.darkMode) ? '#FF77BF' : '#66006A',
            (this.darkMode) ? '#FFFFFF' : '#49004B',
          ],
        }, {}, {}, {}
      ]
    };
    versionData.labels = [];
    versionData.datasets[0].data = [];
    if(this.purgeData){
      versionData.labels.push('Unknown');
      versionData.datasets[0].data.push(100);
    }
    else {
      this.footerData?.appVersions.forEach((version: any, index) => {
        if (index > 3)
          return;
        versionData.labels.push(version.version);
        if (versionData?.datasets[0]?.data)
          versionData.datasets[0].data.push(version.count);
      });
    }
    return versionData;
  }


  getFirmwareVersionLength() {
    if(this.footerData?.firmwareVersions) {
      return this.footerData.firmwareVersions.length;
    } else {
      return 0;
    }
  }

  getFirmwareVersionPercentage(index: number) {
    if(this.purgeData){
        return '0';
    } else {
      if (this.footerData?.firmwareVersions) {
        return this.footerData.firmwareVersions[index].percentage;
      } else {
        return '0';
      }
    }
  }

  getFirmwareVersionVersion(index: number) {
    if(this.purgeData) {
      return 'Beta Version';
    } else {
      if (this.footerData?.firmwareVersions) {
        return this.footerData.firmwareVersions[index].version;
      } else {
        return 'undefined';
      }
    }
  }

  getFirmwareDonut() {
    let firmwareData = {
      labels: ['A', 'B', 'C', 'D', 'E', 'F'],
      datasets: [
        {
          data: [150, 50, 50, 100, 200, 350],
          borderRadius: 1000,
          borderWidth: 0,
          spacing: 5,
          borderColor: 'rgba(0, 179, 119, 0)',
          backgroundColor: [
            "#C4D9EE",
            "#7797B8",
            "#0DCCFF",
            "#0B84FF",
            (this.darkMode) ? '#000A14' : '#054280',
            (this.darkMode) ? '#FFFFFF' : '#022E5C',
          ],
          hoverBackgroundColor: [
            "#C4D9EE",
            "#7797B8",
            "#0DCCFF",
            "#0B84FF",
            (this.darkMode) ? '#000A14' : '#054280',
            (this.darkMode) ? '#FFFFFF' : '#022E5C',
          ],
        }, {}, {}, {}
      ]
    };
    firmwareData.labels = [];
    firmwareData.datasets[0].data = [];
    if(this.purgeData){
      firmwareData.labels.push('Unknown');
      firmwareData.datasets[0].data.push(100);
    }
    else {
      this.footerData?.firmwareVersions.forEach((version: any) => {
        firmwareData.labels.push(version.version);
        if (firmwareData?.datasets[0]?.data)
          firmwareData.datasets[0].data.push(version.count);
      });
    }
    return firmwareData;
  }

  getInterestsLength() {
    if(this.footerData?.interestData) {
      return this.footerData.interestData.length;
    } else {
      return 0;
    }
  }

  getInterestsPercentage(index: number) {
    if(this.purgeData){
        return '0';
    } else {
      if (this.footerData?.interestData) {
        let percentage = this.footerData.interestData[index] / this.totalDataInterest * 100;
        return Math.round(percentage * 100) / 100;
      } else {
        return '0';
      }
    }
  }

  getInterestsName(index: number) {
    if(this.purgeData) {
        return 'undefined';
    } else {
      if (this.footerData?.interestNames) {
        return this.footerData.interestNames[index];
      } else {
        return 'undefined';
      }
    }
  }

  getInterestsDonut() {
    let interestData = {
      labels: ['A', 'B', 'C', 'D', 'E', 'F'],
      datasets: [
        {
          data: [50, 50, 100, 150, 200, 350],
          borderRadius: 1000,
          borderWidth: 0,
          spacing: 5,
          hoverBorderWidth: 3,
          hoverBorderColor: [
            (this.darkMode) ? 'rgba(119, 151, 184, 0.1)' : 'rgba(0, 0, 0, 0.1)',
            (this.darkMode) ? 'rgba(0, 255, 170, 0.1)' : 'rgba(0, 0, 0, 0.1)',
            (this.darkMode) ? 'rgba(255, 131, 13, 0.1)' : 'rgba(0, 0, 0, 0.1)',
            (this.darkMode) ? 'rgba(227, 20, 130, 0.1)' : 'rgba(0, 0, 0, 0.1)',
            (this.darkMode) ? 'rgba(13, 204, 255, 0.05)' : 'rgba(0, 0, 0, 0.1)',
            (this.darkMode) ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.1)',
          ],
          borderColor: 'rgba(0, 0, 0, 0)',
          backgroundColor: [
            "#7797B8",
            "#00B377",
            "#FF830D",
            "#E31482",
            "#0B84FF",
            (this.darkMode) ? '#FFFFFF' : '#054280',
          ],
          hoverBackgroundColor: [
            "#7797B8",
            "#00B377",
            "#FF830D",
            "#E31482",
            "#0B84FF",
            (this.darkMode) ? '#FFFFFF' : '#054280',
          ]
        }, {}, {}, {}
      ]
    };
    interestData.labels = [];
    interestData.datasets[0].data = [];
    if(this.purgeData || this.footerData?.interestData.length === 0) {
      interestData.labels.push('Unknown');
      interestData.datasets[0].data.push(100);
    } else {
      this.footerData?.interestData.forEach((interest: any) => {
        if (interestData?.datasets[0]?.data) {
          interestData.datasets[0].data.push(interest);
        }
      });
      this.footerData?.interestNames.forEach((interest: any) => {
        interestData.labels.push(interest);
      });
      this.totalDataInterest = this.footerData?.interestData.reduce((a: any, b: any) => a + b, 0);
    }

    return interestData;
  }

  getGenderDonut() {
    let genderData = {
      labels: ['Men', 'Women'],
      datasets: [
        {
          data: [100, 350],
          borderRadius: 1000,
          borderWidth: 3,
          spacing: 5,
          borderColor: 'rgba(0, 0, 0, 0)',
          hoverBorderColor: [
            (this.darkMode) ? 'rgba(227, 20, 130, 0.1)' : 'rgba(0, 0, 0, 0.1)',
            (this.darkMode) ? 'rgba(13, 204, 255, 0)' : 'rgba(0, 0, 0, 0)',
            (this.darkMode) ? 'rgba(119, 151, 184, 0.1)' : 'rgba(0, 0, 0, 0.1)',
          ],
          backgroundColor: [
            "#B01065",
            (this.darkMode) ? '#0DCCFF' : '#0B84FF',
            "#7797B8",
          ],
          hoverBackgroundColor: [
            "#B01065",
            (this.darkMode) ? '#0DCCFF' : '#0B84FF',
            "#7797B8",
          ],
        }, {}, {}, {}
      ]
    };

    genderData.labels.push('Other');
    const percentageWomen = this.footerData?.distribution.percentageWomen? this.footerData?.distribution.percentageWomen : 0;
    const percentageMen = this.footerData?.distribution.percentageMen? this.footerData?.distribution.percentageMen : 0;
    const percentageOther = this.footerData?.distribution.percentageOther? this.footerData?.distribution.percentageOther : 0;
    genderData.datasets[0].data = [percentageWomen, percentageMen, percentageOther];
    let genderPercentageArray = [
      {
        gender: 'F',
        percentage: percentageWomen,
        color: '#B01065',
        age: this.footerData?.distribution.averageAgeWomen? this.footerData?.distribution.averageAgeWomen : 0,
        interest: this.getBestInterest('F'),
        img: "../../assets/women_Icon.svg"
      }, {
        gender: 'M',
        percentage: percentageMen,
        color: (this.darkMode) ? '#0DCCFF' : '#0B84FF',
        age: this.footerData?.distribution.averageAgeMen? this.footerData?.distribution.averageAgeMen : 0,
        interest: this.getBestInterest('M'),
      }, {
        gender: 'O',
        percentage: percentageOther,
        color: '#7797B8',
        age: this.footerData?.distribution.averageAgeOther? this.footerData?.distribution.averageAgeOther : 0,
        interest: this.getBestInterest('O')
      }
    ]
    this.genderPercentageArray = genderPercentageArray.sort((a: any, b: any) => b.percentage - a.percentage);
    return genderData
  }

  getGenderColor(idx: number) {
    return this.genderPercentageArray[idx].color;
  }

  getGenderPercentage(idx: number) {
    return this.genderPercentageArray[idx].percentage;
  }

  getBestInterest(gender: string) {
    let bestInterest = '';
    if (gender === 'F') {
      if(this.footerData?.distribution?.interestByGender.interestWomen[0])
        bestInterest = this.footerData?.distribution?.interestByGender.interestWomen[0]?.interest;
      else
        return bestInterest
      if(this.footerData?.distribution?.interestByGender.interestWomen[1])
        bestInterest += ' / ' + this.footerData?.distribution?.interestByGender.interestWomen[1]?.interest;
    } else if (gender === 'M') {
      if(this.footerData?.distribution?.interestByGender.interestMen[0])
        bestInterest = this.footerData?.distribution?.interestByGender.interestMen[0]?.interest;
      else
        return bestInterest
      if(this.footerData?.distribution?.interestByGender.interestMen[1])
        bestInterest += ' / ' + this.footerData?.distribution?.interestByGender.interestMen[1]?.interest;
    } else {
      if(this.footerData?.distribution?.interestByGender.interestOther[0])
        bestInterest = this.footerData?.distribution?.interestByGender.interestOther[0]?.interest;
      else
        return bestInterest;
      if(this.footerData?.distribution?.interestByGender.interestMen[1])
        bestInterest += ' / ' + this.footerData?.distribution?.interestByGender.interestMen[1]?.interest;
    }

    return bestInterest;
  }

  getGenderAge(gender: string) {
    return Math.floor(this.genderPercentageArray.filter(el => el.gender===gender)[0].age);
  }

  getGenderBestInterest(gender: string) {
    return this.genderPercentageArray.filter(el => el.gender===gender)[0].interest;
  }


  getGenderBars() {
    let genderDataChart = {
      labels: ['', '', '', '', '','','',''],
      datasets: [
        {
          data: [100, 200, 350, 500, 400],
          borderRadius: 100,
          spacing: (this.innerWidth >= 1024) ? this.innerWidth * 0.1 : this.innerWidth * 0.015,
          borderWidth: 1,
          barThickness: (this.innerWidth >= 1024) ? this.innerWidth * 0.008 : 15,
          hoverInnerGlowWidth: 20,
          hoverInnerGlowColor: 'rgba(255, 255, 0, 1)',
          borderColor: 'rgba(0, 179, 119, 0)',
          backgroundColor: (this.darkMode) ? "rgba(13, 204, 255, 0.5)" : "rgba(11, 132, 255, 0.3)",
          hoverBackgroundColor: (this.darkMode) ? "#0DCCFF" : "#0B84FF",
        },
        {
          data: [20, 40, 70, 100, 80],
          borderRadius: 100,
          spacing: (this.innerWidth >= 1024) ? this.innerWidth * 0.00347 : this.innerWidth * 0.007,
          borderWidth: 1,
          barThickness: (this.innerWidth >= 1024) ? this.innerWidth * 0.008 : 15,
          borderColor: 'rgba(0, 179, 119, 0)',
          backgroundColor: (this.darkMode) ? "rgba(176, 16, 101, 0.3)" : "rgba(176, 16, 101, 0.3)",
          hoverBackgroundColor: (this.darkMode) ? "#E31482" : "#B01065",
        }
      ]
    };


    // genderDataChart.labels = []
    genderDataChart.datasets[0].data = []
    genderDataChart.datasets[1].data = []
    this.xAxys = ['up 20', '30', '40', '50', '60', '70', '80', 'more'];
    // men data
    genderDataChart.datasets[0].data.push(this.genderStats.data.total_users.per_age.upto_20.males);
    genderDataChart.datasets[0].data.push(this.genderStats.data.total_users.per_age.upto_29.males);
    genderDataChart.datasets[0].data.push(this.genderStats.data.total_users.per_age.upto_39.males);
    genderDataChart.datasets[0].data.push(this.genderStats.data.total_users.per_age.upto_49.males);
    genderDataChart.datasets[0].data.push(this.genderStats.data.total_users.per_age.upto_59.males);
    genderDataChart.datasets[0].data.push(this.genderStats.data.total_users.per_age.upto_69.males);
    genderDataChart.datasets[0].data.push(this.genderStats.data.total_users.per_age.upto_79.males);
    genderDataChart.datasets[0].data.push(this.genderStats.data.total_users.per_age.beyond_80.males);
    // women data
    genderDataChart.datasets[1].data.push(this.genderStats.data.total_users.per_age.upto_20.females);
    genderDataChart.datasets[1].data.push(this.genderStats.data.total_users.per_age.upto_29.females);
    genderDataChart.datasets[1].data.push(this.genderStats.data.total_users.per_age.upto_39.females);
    genderDataChart.datasets[1].data.push(this.genderStats.data.total_users.per_age.upto_49.females);
    genderDataChart.datasets[1].data.push(this.genderStats.data.total_users.per_age.upto_59.females);
    genderDataChart.datasets[1].data.push(this.genderStats.data.total_users.per_age.upto_69.females);
    genderDataChart.datasets[1].data.push(this.genderStats.data.total_users.per_age.upto_79.females);
    genderDataChart.datasets[1].data.push(this.genderStats.data.total_users.per_age.beyond_80.females);

    // other data
    // let otherDataArray: number[] = [];
    // otherDataArray.push(this.footerData?.distribution?.ageRanges?.other?.upToTwenty ? this.footerData?.distribution?.ageRanges?.other?.upToTwenty.count : 0);
    // otherDataArray.push(this.footerData?.distribution?.ageRanges?.other?.twentyToForty ? this.footerData?.distribution?.ageRanges?.other?.twentyToForty.count : 0);
    // otherDataArray.push(this.footerData?.distribution?.ageRanges?.other?.fortyToSixty ? this.footerData?.distribution?.ageRanges?.other?.fortyToSixty.count : 0);
    // otherDataArray.push(this.footerData?.distribution?.ageRanges?.other?.sixtyToEighty ? this.footerData?.distribution?.ageRanges?.other?.sixtyToEighty.count : 0);
    // otherDataArray.push(this.footerData?.distribution?.ageRanges?.other?.eightyToPlus ? this.footerData?.distribution?.ageRanges?.other?.eightyToPlus.count : 0);
    console.log("Men data: ", genderDataChart.datasets[0].data);
    console.log("Women data: ", genderDataChart.datasets[1].data);
    // console.log("Other data: ", otherDataArray);


    console.log('DATA',genderDataChart.datasets);
    //console.log(otherDataArray);
    const totalPeopleArray = [
      ...genderDataChart.datasets[0].data,
      ...genderDataChart.datasets[1].data,
      // ...otherDataArray
    ];
    this.totalPeople = totalPeopleArray.reduce((a, b) => a + b, 0);
    console.log("Total people: ", this.totalPeople);
    this.ageRangePercentages = [];
    for(let i:number = 0; i < genderDataChart.datasets[0].data.length; i++) {
      this.ageRangePercentages.push(round(((genderDataChart.datasets[0].data[i] + genderDataChart.datasets[1].data[i]) / this.totalPeople) * 100, 0));
    }
    console.log('porcentajes',this.ageRangePercentages)
    this.menPercentages = [];
    this.womenPercentages = [];

    for (let i: number = 0; i < genderDataChart.datasets[0].data.length; i++) {
      this.menPercentages.push(round((genderDataChart.datasets[0].data[i] / this.totalPeople) * 100, 0));
      this.womenPercentages.push(round((genderDataChart.datasets[1].data[i] / this.totalPeople) * 100, 0));
    }

    console.log('Men percentages:', this.menPercentages);
    console.log('Women percentages:', this.womenPercentages);
    return { ...genderDataChart};
  }

  purgeFakeData() {
    if (this.purgeData) {
    }
  }

  getChargingTimeMax() {
    if(this.purgeData) {
      return '0';
    } else {
      return '38h 40min';
    }
  }

  getChargingTimeMin() {
    if(this.purgeData) {
      return '0';
    } else {
      return '5h 05min';
    }
  }

  getChargingTimeAvg() {
    if(this.purgeData) {
      return '0';
    } else {
      return '28h 25min';
    }
  }
}
