import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { User } from '../../shared/movano-interfaces';
import { MovanoService } from '../../movano.service';
import * as lit from '../../shared/movano-literals';
import { Subscription } from 'rxjs';
import { DarkModeService } from 'src/app/dark-mode.service';

@Component({
  selector: 'app-recover-password',
  templateUrl: './recover-password.component.html',
  styleUrls: ['./recover-password.component.scss']
})
export class RecoverPasswordComponent implements OnInit, OnDestroy {
  protected LITERALS = lit;
  protected step: number = 0;
  //User details
  protected user: User;
  protected passQuality: string = "Weak";
  protected codeError: boolean = false;
  protected apiError: boolean = false;
  protected darkMode: boolean = false;
  private darkModeSub : Subscription = new Subscription();

  constructor(
    public _dialogRef: MatDialogRef<RecoverPasswordComponent>,
    private movanoSvc: MovanoService,
    private snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    private darkModeSvc: DarkModeService) {
    this.user = {} as User;
  }

  ngOnInit() {
    this.darkModeSub = this.darkModeSvc.getVariable().subscribe((val : any) => {
      this.darkMode = val;
    });
  }

  ngOnDestroy() {
    this.darkModeSub.unsubscribe();
  }

  errorMsg: any;
  recoverFormGroup: FormGroup = this.formBuilder.group({
    email: ['', Validators.compose([Validators.required, Validators.email])],
    password: ['', Validators.compose([Validators.required, Validators.min(8)])],
    repeatPassword: ['', Validators.compose([Validators.required, Validators.min(8)])],
    code: ['', Validators.compose([Validators.required])]
  });

  //delay = (ms: number | undefined) => new Promise(res => setTimeout(res, ms));

  //Reset password init process
  async forgotPassword() {
    if (this.recoverFormGroup.value.email) {
      this.step++;
      this.user.email = this.recoverFormGroup.value.email;
      try {
        await this.movanoSvc.forgotPassword(this.user);
        this.step++;
      } catch (e: any) {
        this.errorMsg = e.message;
        this.step--;
      }
    } else {
      this.errorMsg = 'please write a valid email';
    }
  }

  /**
   * Handles the process of setting a new password.
   */
  async newPassword() {
    if (!this.recoverFormGroup.value.code) {
      this.codeError = true;
      this.step = 2;
      return;
    }

    if (!this.recoverFormGroup.value.email) return;

    if (!this.recoverFormGroup.value.password) return;

    if (!this.comparePasswords()) return;

    this.step++;
    this.user.email = this.recoverFormGroup.value.email;
    this.user.password = this.recoverFormGroup.value.password;
    this.user.code = this.recoverFormGroup.value.code;
    try {
      await this.movanoSvc.forgotPasswordSubmit(this.user, this.user.password.trim());
      this.step++;
    } catch (e: any) {
      this.codeError = true;
      this.step = 2;
      console.log(e.message);
    }
  }

  /**
   * Generates and sends a new verification code to the user's email for password recovery.
   * Uses the email obtained from the form input to initiate the code sending process.
   * Displays a success message with the email if the code is successfully sent,
   * or shows an error message if there was an error during the process.
   */
  protected async newCodeCognito() {
    this.user.email = this.recoverFormGroup.value.email;
    try {
      await this.movanoSvc.forgotPassword(this.user);
      this.snackBar.open("new code sended to " + this.user.email, "close");
    } catch (e: any) {
      this.snackBar.open(e.message, "close");
    }
  }

  /**
   * Compares two passwords and returns a boolean value indicating if they are equal.
   * @returns {boolean} true if the passwords are equal or if the repeat password is empty, false otherwise.
   */
  comparePasswords(): boolean {
    return this.recoverFormGroup.value.password === this.recoverFormGroup.value.repeatPassword || !this.recoverFormGroup.value.repeatPassword;
  }

  checkPassQuality() : void {
    // Puntajes iniciales para cada criterio
    let puntaje = 0;
    const puntajeLongitud = 5;
    const puntajeMayusculas = 3;
    const puntajeMinusculas = 3;
    const puntajeNumeros = 2;
    const puntajeCaracteresEspeciales = 5;

    // Verificar longitud
    if (this.recoverFormGroup.value.password.length >= 8) {
      puntaje += puntajeLongitud;
    }

    // Verificar letras mayúsculas y minúsculas
    if (/[A-Z]/.test(this.recoverFormGroup.value.password)) {
      puntaje += puntajeMayusculas;
    }
    if (/[a-z]/.test(this.recoverFormGroup.value.password)) {
      puntaje += puntajeMinusculas;
    }

    // Verificar números
    if (/[0-9]/.test(this.recoverFormGroup.value.password)) {
      puntaje += puntajeNumeros;
    }

    // Verificar caracteres especiales
    if (/[!@#$%^&*(),.?":{}|<>]/.test(this.recoverFormGroup.value.password)) {
      puntaje += puntajeCaracteresEspeciales;
    }

    // Determinar la calidad de la contraseña en base al puntaje
    if (puntaje < 8) {
      this.passQuality = this.LITERALS.RECOVER_PASS.WEAK;
    } else if (puntaje < 12) {
      this.passQuality = this.LITERALS.RECOVER_PASS.MEDIUM;
    } else {
      this.passQuality = this.LITERALS.RECOVER_PASS.STRONG;
    }
  }
}
