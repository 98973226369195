import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { timeStamp } from 'console';
import { Calendar } from 'primeng/calendar';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent {
  protected displayedTimeOptions: string[] = ['Today', 'Last 7 days',  'Last month', 'Year to date', 'Custom'];
  //protected timeOptions : string[] = ['', 'week', 'month', ''];
  protected rangeSelected: Date[] = [];
  protected today: Date;
  protected daySelected: Date;
  protected calendarUp: boolean = false;
  protected utc: boolean = false;
  protected actualTimeZone: string = '';
  protected moment = require('moment-timezone');
  protected lastSlctdDateOpt: number = 1;
  @Input() timeZones?: string[];
  @Input() slctdDateOpt: number = 1;
  @Output() initGetInfo = new EventEmitter<[number, boolean, Date | Date[], string, number]>();
  @ViewChild('calendarTimeSelector') calendarTmSlctr !: Calendar;


  constructor() {
    this.today = new Date(Date.now());
    this.daySelected = new Date(Date.now());
  }

  putUTCclass() {
    const utc_option = document.querySelector('.UtcSelectors');
    const utc_list = utc_option?.parentElement;
    utc_list?.classList.add('UtcList');
  }

  getInfo(_slctdDateOpt ?: number) {
    if(_slctdDateOpt){
      this.lastSlctdDateOpt = this.slctdDateOpt; 
      this.slctdDateOpt = _slctdDateOpt;
    } 

   // if(this.slctdDateOpt == 3 && !this.rangeSelected[1]) return;
    this.initGetInfo.emit([
      this.slctdDateOpt, 
      this.daySelected!.getTime() < this.today!.getTime(), 
      (this.slctdDateOpt != 3) ? this.daySelected! : this.rangeSelected!, 
      (this.utc) ? this.actualTimeZone : this.timeZones![0]??'',
      this.lastSlctdDateOpt]);
  }



  updateDaySelected(_daySelected: Date, _timeZones : string[], _slctdDateOpt : number) {
    this.daySelected = _daySelected;
    this.timeZones = _timeZones;
    if(this.actualTimeZone == '') this.actualTimeZone = this.timeZones[0];
    this.slctdDateOpt = _slctdDateOpt;
  }

  tittleText(): string {
    let secondPart: string = '';
    let firstPart: string = '';
    const dateFormat : Intl.DateTimeFormatOptions = { year: '2-digit', month: 'long', day: 'numeric' };
    if (this.slctdDateOpt == 1 || this.slctdDateOpt == 2) {
      firstPart = this.displayedTimeOptions[this.slctdDateOpt];
      secondPart = (this.utc) ? `UTC + ${Math.round(this.moment().tz(this.actualTimeZone).utcOffset() / 60)}` : '';
      return firstPart ;
    }
    else if(this.slctdDateOpt == 4  ){
      firstPart = this.daySelected.toLocaleDateString('en-US',dateFormat);
      secondPart = (this.utc) ? `UTC + ${Math.round(this.moment().tz(this.actualTimeZone).utcOffset() / 60)}` : '';
      return firstPart;
    } else if ( this.slctdDateOpt == 5){
      firstPart = 'Year to date';
      return firstPart;
    }  
    else if ( this.slctdDateOpt == 6){
      firstPart = 'Last Week';
      return firstPart;
    }
    else if (this.slctdDateOpt == 0){
      firstPart = this.displayedTimeOptions[this.slctdDateOpt];
      return firstPart;
    }
    
    firstPart = (this.rangeSelected[0]) ? this.rangeSelected[0].toLocaleDateString('en-US',dateFormat) : '';
    secondPart = (this.rangeSelected[1]) ? this.rangeSelected[1].toLocaleDateString('en-US',dateFormat) : '';

    return firstPart + ' - ' + secondPart;
  }
}


