import { Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Dashboard } from 'aws-cdk-lib/aws-cloudwatch';
import { EChartsOption } from 'echarts';
import * as echarts from 'echarts';
import { Subscription, lastValueFrom } from 'rxjs';
import { DashboardTypeService } from 'src/app/dashboard-type.service';
import { DashboardComponent } from 'src/app/dashboard/dashboard.component';
import { barChart, barSideChart, incidentCarrousel, salesProductionCarrousel, salesSyncCarrousel } from 'src/app/shared/movano-interfaces';
import { DASHBOARD } from 'src/app/shared/movano-literals';
import { fixPixelsBy1440 } from 'src/app/shared/utils';
import { MovanoService } from '../../movano.service';
import { LAST_MONTH, LAST_SEVEN_DAYS, LAST_WEEK, YEAR_TO_DATE, TODAY } from 'src/constants';
import { SharedService } from '../../stats/shared.service';

interface legendObj { text: string, colorDark: string, colorLight: string, colorStart: string, colorEnd: string, averageLine: boolean, bar: boolean, values: number[], yearToDateLabel: string[], shadowColor?: string, emphasisColor?: string, averageColor?: string }
type DashboardType = 'Sales' | 'Activity' | 'Users' | 'SpotChecks' | 'Sleep analysis' | 'Workout usage' | 'Notifications';

@Component({
  selector: 'app-stats-widget-chart',
  templateUrl: './stats-widget-chart.component.html',
  styleUrls: ['./stats-widget-chart.component.scss']
})

export class StatsWidgetChartComponent implements OnChanges {

  chartData: any;
  //chartTypes = ['Sales', 'Activity', 'Users', 'Spot checks'];
  labelColor = '#00593B';
  option_users?: EChartsOption;

  //Dashboard type
  @Input() dashboardType: DashboardType = 'Users';
  //private dashboardTypeSub: Subscription = new Subscription();
  protected innerWidth: number = 1440;

  protected today: Date;
  protected slctDate: Date;
  private dateSub: Subscription = new Subscription();
  protected dateStart: Date;
  protected dateEnd: Date;
  private dataSub: Subscription = new Subscription();
  protected data: barChart[] = [];
  private dateOptSub: Subscription = new Subscription();
  protected dateOptions: number = 0;
  protected selectedLegend: number = 0;
  protected amountOfDays: number = 30;

  ctx: any;
  @Input() darkMode: boolean | undefined;
  //@Input() chartNow ?: number;
  // @Input() salesData?: salesProductionCarrousel;
  // @Input() syncsData?: salesSyncCarrousel;
  // @Input() incidentsData?: incidentCarrousel;
  private selectedDate: string = LAST_WEEK;
  @Input() paused: boolean = false;

  @Output() changeComponent = new EventEmitter<boolean>();
  @Output() pauseChart = new EventEmitter<boolean>();

  @Input() showTitle: boolean = true;
  @Input() showLegend: boolean = true;
  @Input() showAverage: boolean = true;
  @Input() showTopLegend: boolean = true;
  @Input() showYAxisStats: boolean = true;
  @Input() showGrid: boolean = true;
  @Input() showBoxShadow: boolean = true;
  @Input() sleepStatUserActivity: boolean = true;


  totalValueNowBar01 = 0;
  totalValueNowBar02 = 0;
  //averageChart = 0;
  mainColor = 'black';
  secondColor = 'black';
  maxValueChart = 1;
  ICONS: string = DASHBOARD.BAR_ICONS + '/';
  legendObjects: Map<string, legendObj[]> = new Map<string, legendObj[]>([
    ['Activity', [
      //Syncs BAR
      //-----------------------------------------------------------
      {
        text: 'Syncs', colorDark: '#FFFFFF', colorLight: '#00B277',
        colorStart: 'rgba(0, 179, 119, 0.40)', colorEnd: 'rgba(0, 179, 119, 0.40)',
        shadowColor: 'rgba(0, 179, 119, 0.40)', emphasisColor: '#00B377', averageColor: '#00B377',
        averageLine: true, bar: true, values: [], yearToDateLabel: []
      },
      //Sleep Analysis BAR
      //-----------------------------------------------------------
      {
        text: 'Sleep analysis', colorDark: '#FFFFFF', colorLight: '#061E37',
        colorStart: 'rgba(1, 46, 30, 0.50)', colorEnd: 'rgba(1, 46, 30, 0.50)',
        shadowColor: 'rgba(0, 179, 119, 0.40)', emphasisColor: '#012E1E', averageColor: '#00B377',
        averageLine: false, bar: true, values: [], yearToDateLabel: []
      }
    ]
    ],
    ['Sales', [
      //Direct Sales BAR
      //-----------------------------------------------------------
      {
        text: 'New Devices linked', colorDark: '#FFFFFF', colorLight: '#0B84FF',
        colorStart: '#0B84FF', colorEnd: '#0DCCFF',
        shadowColor: 'rgba(11, 132, 255, 0.50)', emphasisColor: '#0B84FF', averageColor: '#0B84FF',
        averageLine: true, bar: true, values: [], yearToDateLabel: []
      },
    ]
    ],
    ['Users', [
      //Total Users LINE
      //-----------------------------------------------------------
      {
        text: 'Total users', colorDark: '#FFFFFF', colorLight: 'rgba(253, 130, 14, 0.5)',
        colorStart: '#FF830D40', colorEnd: '#FF830D00',
        averageLine: false, bar: false, values: [], yearToDateLabel: []
      },
      //Active Users LINE
      //-----------------------------------------------------------
      {
        text: 'Active users', colorDark: '#FFFFFF', colorLight: '#8F4600',
        colorStart: '#92470033', colorEnd: '#92470000',
        averageLine: false, bar: false, values: [], yearToDateLabel: []
      },
      //New Users BAR
      //-----------------------------------------------------------
      {
        text: 'New users', colorDark: '#FFFFFF', colorLight: '#FD820E',
        colorStart: '#FF830D', colorEnd: '#FF830D',
        shadowColor: 'rgba(255, 131, 13, 0.50)', emphasisColor: '#FF830D', averageColor: '#FF830D',
        averageLine: true, bar: true, values: [], yearToDateLabel: []
      },
    ]
    ],
    ['SpotChecks', [
      //Spot Check BAR
      //-----------------------------------------------------------
      {
        text: 'Spot check', colorDark: '#FFFFFF', colorLight: '#054280',
        colorStart: '#054280', colorEnd: '#7797B868',
        shadowColor: 'rgba(40, 71, 103, 0.30)', emphasisColor: '#054280', averageColor: '#054280',
        averageLine: this.showYAxisStats ? false : true, bar: true, values: [], yearToDateLabel: []
      },
      //Low Perfussion BAR
      //-----------------------------------------------------------
      // {
      //   text: 'Low perfussion', colorDark: '#FFFFFF', colorLight: '#E31583',
      //   colorStart: '#E31482', colorEnd: '#E3148266',
      //   shadowColor: 'rgba(40, 71, 103, 0.30)', emphasisColor: '#E31482', averageColor: '#E31482',
      //   averageLine: false, bar: true, values: [], yearToDateLabel: []
      // },
    ]
    ],
    ['Sleep analysis', [
      //Succesful sleep analysis
      //-----------------------------------------------------------
      {
        text: 'Succesful sleep analysis', colorDark: '#FFFFFF', colorLight: '#054280',
        colorStart: '#054280', colorEnd: '#7797B868',
        shadowColor: 'rgba(40, 71, 103, 0.30)', emphasisColor: '#054280', averageColor: '#054280',
        averageLine: true, bar: true, values: [], yearToDateLabel: []
      },
      //Unsuccesfull sleep analysis
      //-----------------------------------------------------------
      {
        text: 'Unsuccesfull sleep analysis', colorDark: '#FFFFFF', colorLight: '#284767',
        colorStart: '#284767', colorEnd: '#284767',
        shadowColor: '#8395a7', emphasisColor: '#284767', averageColor: '#284767',
        averageLine: true, bar: true, values: [], yearToDateLabel: []
      },
    ]
    ],
    ['Workout usage', [
      //Direct Sales BAR
      //-----------------------------------------------------------
      {
        text: 'borrar legend', colorDark: '#FFFFFF', colorLight: '#54cdad',
        colorStart: '#54cdad', colorEnd: '#00b377',
        shadowColor: 'rgba(0, 179, 119, 0.40)', emphasisColor: '#54cdad', averageColor: '#54cdad',
        averageLine: false, bar: true, values: [], yearToDateLabel: []
      },
    ]
    ],
    ['Notifications', [
      //Direct Sales BAR
      //-----------------------------------------------------------
      {
        text: 'borrar legend', colorDark: '#FFFFFF', colorLight: '#FD820E',
        colorStart: '#FD820E', colorEnd: '#FD820E',
        shadowColor: 'rgba(255, 131, 13, 0.50)', emphasisColor: '#FD820E', averageColor: '#FD820E',
        averageLine: false, bar: true, values: [], yearToDateLabel: []
      },
    ]
    ],
  ])

  constructor(private dashboardSVC: DashboardTypeService, private movanoSvc: MovanoService, private sharedService: SharedService
  ) {
    this.today = new Date();
    this.slctDate = new Date(this.today);
    this.dateStart = new Date(this.today);
    this.dateEnd = new Date(this.today);
  }

  @HostListener('window:resize', ['$event']) //Event to know the window width
  onResize(event: any) {
    this.innerWidth = document.documentElement.clientWidth;
    this.update(0);
  }

  async ngOnInit() {
    this.dashboardSVC.setDashboardType(this.dashboardType);

    this.sharedService.calendarObservable$.subscribe(async data => {
      this.selectedDate = data;

      await this.setCurrentChartData(data);
      this.update(0);
    })

    this.labelColor = this.darkMode ? '#00B377' : '#00593B';

    this.dateOptSub = this.dashboardSVC.getDateOptSelected().subscribe((val: number) => {
      this.dateOptions = val;
      this.update(0);
    });

    this.dateSub = this.dashboardSVC.getDate().subscribe((val: Date[]) => {
      this.dateStart = val[0];
      this.dateEnd = val[1];
      this.update(0);
    });
  }

  setCurrentChartData = async (data: string) => {
    if (this.dashboardType === 'Users')
      await this.setUserChartData(data);

    if (this.dashboardType === 'Sales')
      await this.setSalesChartData(data);

    if (this.dashboardType === 'Activity')
      await this.setActivityChartData(data);

    if (this.dashboardType === 'SpotChecks')
      await this.setSpotChecksChartData(data);

    if (this.dashboardType === 'Sleep analysis')
      await this.setSleepAnalysisChartData(data);

    if (this.dashboardType === 'Workout usage')
      await this.setWorkoutusageCharData(data);

    if (this.dashboardType === 'Notifications')
      await this.setNotificationsCharData(data);

  }

  setSpotChecksChartData = async (data: string) => {
    const dataOfSelectedDate = await this.getDashboardChartDataByDate(data);

    this.legendObjects.get('SpotChecks')?.forEach((lgObj: legendObj, i: number) => {
      if (lgObj.text === 'Spot check') {
        lgObj.values = dataOfSelectedDate.spotChecksByDate.map((item: { count: any; }) => item.count);
        lgObj.yearToDateLabel = dataOfSelectedDate.spotChecksByDate.map((item: { date: string; }) => item.date);
      }

      if (lgObj.text === 'Low perfussion') {
        lgObj.values = dataOfSelectedDate.spotChecksByDate.map((item: { lowPerfusion: any; }) => item.lowPerfusion);
        lgObj.yearToDateLabel = dataOfSelectedDate.spotChecksByDate.map((item: { date: string; }) => item.date);
      }
    });

  }

  setSleepAnalysisChartData = async (data: string) => {
    const dataOfSelectedDate = await this.getDashboardChartUserActivityDataByDate(data);
    console.log('DATA DEL DIA',dataOfSelectedDate)

    this.legendObjects.get('Sleep analysis')?.forEach((lgObj: legendObj, i: number) => {
      if (lgObj.text === 'Succesful sleep analysis') {
        lgObj.values = dataOfSelectedDate.sleepAnalysis.byDate.values.map((item: { successful: any; }) => item.successful);
        lgObj.yearToDateLabel = dataOfSelectedDate.sleepAnalysis.byDate.values.map((item: { date: string; }) => item.date);
      }

      if (lgObj.text === 'Unsuccesfull sleep analysis') {
        lgObj.values = dataOfSelectedDate.sleepAnalysis.byDate.values.map((item: { unsuccesfull: any; }) => item.unsuccesfull);
        lgObj.yearToDateLabel = dataOfSelectedDate.sleepAnalysis.byDate.values.map((item: { date: string; }) => item.date);
      }
    });

  }

  setWorkoutusageCharData = async (data: string) => {
    const dataOfSelectedDate = await this.getDashboardChartUserActivityDataByDate(data);

    this.legendObjects.get('Workout usage')?.forEach((lgObj: legendObj, i: number) => {
        lgObj.values = dataOfSelectedDate.workout.byDate.values.map((item: { count: any; }) => item.count);
        lgObj.yearToDateLabel = dataOfSelectedDate.workout.byDate.values.map((item: { date: string; }) => item.date);
    });
  }

  setNotificationsCharData = async (data: string) => {
    const dataOfSelectedDate = await this.getDashboardChartUserActivityDataByDate(data);

    this.legendObjects.get('Notifications')?.forEach((lgObj: legendObj, i: number) => {
        lgObj.values = dataOfSelectedDate.notifications.byDate.values.map((item: { count: any; }) => item.count);
        lgObj.yearToDateLabel = dataOfSelectedDate.notifications.byDate.values.map((item: { date: string; }) => item.date);
    });
  }

  setActivityChartData = async (data: string) => {
    const dataOfSelectedDate = await this.getDashboardChartDataByDate(data);

    this.legendObjects.get('Activity')?.forEach((lgObj: legendObj, i: number) => {
      if (lgObj.text === 'Syncs') {
        lgObj.values = dataOfSelectedDate.devicesSyncsByDate.map((item: { count: any; }) => item.count);
        lgObj.yearToDateLabel = dataOfSelectedDate.devicesSyncsByDate.map((item: { date: string; }) => item.date);

      }

      if (lgObj.text === 'Sleep analysis') {
        lgObj.values = dataOfSelectedDate.sleepSessionsByDate.map((item: { count: any; }) => item.count);
        lgObj.yearToDateLabel = dataOfSelectedDate.devicesSyncsByDate.map((item: { date: string; }) => item.date);
      }
    });
  }

  setSalesChartData = async (data: string) => {
    const dataOfSelectedDate = await this.getDashboardChartDataByDate(data);

    this.legendObjects.get('Sales')?.forEach((lgObj: legendObj, i: number) => {
      if (lgObj.text === 'New Devices linked') {
        lgObj.values = dataOfSelectedDate.salesByDate.map((item: { count: any; }) => item.count);
        lgObj.yearToDateLabel = dataOfSelectedDate.salesByDate.map((item: { date: string; }) => item.date);
      }
    });
  }

  setUserChartData = async (data: string) => {
    const dataOfSelectedDate = await this.getDashboardChartDataByDate(data);

    this.legendObjects.get('Users')?.forEach((lgObj: legendObj, i: number) => {
      if (lgObj.text === 'Total users') {
        lgObj.values = dataOfSelectedDate.usersByDate.map((item: { total: any; }) => item.total);
        lgObj.yearToDateLabel = dataOfSelectedDate.usersByDate.map((item: { date: string; }) => item.date);
      }

      if (lgObj.text === 'Active users') {
        lgObj.values = dataOfSelectedDate.usersByDate.map((item: { active: any; }) => item.active);
        lgObj.yearToDateLabel = dataOfSelectedDate.usersByDate.map((item: { date: string; }) => item.date);
      }

      if (lgObj.text === 'New users') {
        lgObj.values = dataOfSelectedDate.usersByDate.map((item: { new: any; }) => item.new);
        lgObj.yearToDateLabel = dataOfSelectedDate.usersByDate.map((item: { date: string; }) => item.date);
      }
    });
  }

  getDashboardChartDataByDate = async (date: string) => {
    if (date === TODAY) {
      const response = await lastValueFrom(this.movanoSvc.getStatsSummarySalesToday());
      return response.data.main;
    }

    if (date === LAST_WEEK) {
      const response = await lastValueFrom(this.movanoSvc.getStatsSummarySalesLastWeek());
      return response.data.main;
    }

    if (date === LAST_SEVEN_DAYS) {
      const response = await lastValueFrom(this.movanoSvc.getStatsSummarySalesLast7Days());
      return response.data.main;
    }

    if (date === LAST_MONTH) {
      const response = await lastValueFrom(this.movanoSvc.getStatsSummarySalesLastMonth());
      return response.data.main;
    }

    if (date === YEAR_TO_DATE) {
      const response = await lastValueFrom(this.movanoSvc.getStatsSummarySalesYearToDate());
      return response.data.main;
    }

    const startDate = this.convertDateToISO(date.split('-')[0]);
    const endDate = this.convertDateToISO(date.split('-')[1]);

    const response = await lastValueFrom(this.movanoSvc.getStatsSummarySalesCustomDay(startDate, endDate));

    return response.data.main;
  }

  getDashboardChartUserActivityDataByDate = async (date: string) => {
    if (date === TODAY) {
      const response = await lastValueFrom(this.movanoSvc.getUsersActivityToday());
      return response.data;
    }

    if (date === LAST_WEEK) {
      const response = await lastValueFrom(this.movanoSvc.getUsersActivityLastWeek());
      return response.data;
    }

    if (date === LAST_SEVEN_DAYS) {
      const response = await lastValueFrom(this.movanoSvc.getUsersActivityLast7Days());
      return response.data;
    }

    if (date === LAST_MONTH) {
      const response = await lastValueFrom(this.movanoSvc.getUsersActivityLastMonth());
      return response.data;
    }

    if (date === YEAR_TO_DATE) {
      const response = await lastValueFrom(this.movanoSvc.getUsersActivityYearToDate());
      return response.data;
    }

    const startDate = this.convertDateToISO(date.split('-')[0]);
    const endDate = this.convertDateToISO(date.split('-')[1]);

    const response = await lastValueFrom(this.movanoSvc.getUsersActivityCustomDay(startDate, endDate));

    return response.data;
  }

  convertDateToISO = (dateString: string) => {
    const date = new Date(dateString);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
  }


  async ngOnChanges(changes: SimpleChanges) {
    if (changes['dashboardType']) {
      // Handle changes to dashboardType here
      this.update(0);
    }
  }


  /**
   * Performs necessary cleanup tasks when the component is about to be destroyed.
   * It unsubscribes from the `darkModeSub` subscription to prevent memory leaks.
   * This method is called before the component is removed from the DOM.
   */
  /*   ngOnDestroy() {
      this.dashboardTypeSub.unsubscribe();
    }
   */

  changeLegend(legend: number) {
    this.selectedLegend = legend;
    this.update(legend)
  }

  update(legend: number) {
    const userValues = this.legendObjects.get(this.dashboardType);

    if (userValues) {
      this.amountOfDays = userValues[legend as keyof object].values.length
    }

    switch (this.dashboardType) {
      case 'Sales':
        this.mainColor = '#0B83FE';
        this.secondColor = '#054280';
        this.labelColor = this.darkMode ? '#0B84FF' : '#054280';
        break;
      case 'Activity':
        this.mainColor = '#01B277';
        this.secondColor = '#284767';
        this.labelColor = this.darkMode ? '#00B377' : '#00593B';
        break;
      case 'Users':
        this.mainColor = '#FF830D';
        this.secondColor = '#FF830D';
        this.labelColor = this.darkMode ? '#FFFFFF' : '#FF830D';
        break;
      case 'SpotChecks':
        this.mainColor = '#054180';
        this.secondColor = '#E21581';
        this.labelColor = this.darkMode ? '#FFFFFF' : '#054180';
        break;
      case 'Sleep analysis':
        this.mainColor = '#284767';
        this.secondColor = '#8395a7';
        this.labelColor = this.darkMode ? '#FFFFFF' : '#284767';
        break;
      case 'Workout usage':
        this.mainColor = '#01B277';
        this.secondColor = '#284767';
        this.labelColor = this.darkMode ? '#00B377' : '#00593B';
        break;
      case 'Notifications':
        this.mainColor = '#FF830D';
        this.secondColor = '#FF830D';
        this.labelColor = this.darkMode ? '#FFFFFF' : '#FF830D';
        break;
    };

    this.option_users = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'none'
        },
        formatter: (params: any) => {
          //console.log(params);
          let barUnits = 0;
          (params as any[]).forEach((element: any) => {
            if (element.seriesType === 'bar') barUnits++;
          });
          function formatDate(inputDate: number, dateSlc: Date): string {
            //console.log(inputDate);
            const date = new Date(dateSlc);
            date.setDate(date.getDate() + inputDate);
            const year = date.getFullYear().toString().substring(2);
            const month = new Intl.DateTimeFormat('en', { month: 'short' }).format(date);
            const day = date.getDate();

            return `${day} ${month} '${year}`;
          }

          return (barUnits > 1) ? `
          <div style="display: flex;  flex-direction: column;  font-family: Zen Kaku Gothic Antique;">
            <span style="padding-bottom:fixPixelsBy1440(5, this.innerWidth);font-size: fixPixelsBy1440(10, this.innerWidth); font-weight: 500; color: #7797B8">
            ${formatDate(params[0].axisValueLabel, this.slctDate)}
            </span>
              <div style="display: flex; font-family: Zen Kaku Gothic Antique;">
                <div style="display: flex; flex-direction: column; color: ${this.mainColor}; border-right: max(0.1vw, 2px) solid #D1F5FF; padding-right: max(0.7vw, 10px); gap: max(0.3vw, 5px)">
                  <span style="font-size: max(1.4vw, 20px); font-weight: 700; line-height: 130.4%;">
                    ${params[params.length - 2].data}
                  </span>
                  <span style="font-size: max(0.7vw, 10px); font-weight: 500; line-height: 130.4%;">${(this.totalValueNowBar01 > 0) ? Math.round((params[params.length - 2].data / this.totalValueNowBar01) * 100) : 0}%</span>
                </div>
                <div style="display: flex; flex-direction: column; color: ${this.secondColor}; padding-left: max(0.7vw, 10px); gap: max(0.3vw, 5px)">
                  <span style="font-size: max(1.4vw, 20px); font-weight: 700; line-height: 130.4%;">
                    ${params[params.length - 1].data}
                  </span>
                  <span style="font-size: max(0.7vw, 10px); font-weight: 500; line-height: 130.4%;">${(this.totalValueNowBar02 > 0) ? Math.round((params[params.length - 1].data / this.totalValueNowBar02) * 100) : 0}%</span>
                </div>
            </div>
          </div>
          ` : `
          <div style="display: flex;  flex-direction: column;  font-family: Zen Kaku Gothic Antique;">
            <span style="font-size: fixPixelsBy1440(10, this.innerWidth); font-weight: 500; line-height: 130.4%; color: ${this.mainColor}>
            ${formatDate(params[0].axisValueLabel, this.slctDate)}
            </span>
              <div style="display: flex; font-family: Zen Kaku Gothic Antique;">
                <div style="display: flex; flex-direction: column; color: ${this.mainColor}; gap: max(0.3vw, 5px)">
                  <span style="font-size: max(1.4vw, 20px); font-weight: 700; line-height: 130.4%;">
                    ${params[params.length - 1].data}
                  </span>
                  <span style="font-size: max(0.7vw, 10px); font-weight: 500; line-height: 130.4%;">${(this.totalValueNowBar01 > 0) ? Math.round((params[params.length - 1].data / this.totalValueNowBar01) * 100) : 0}%</span>
                </div>
            </div>
          </div>
          `},
      },
      grid: this.showGrid
      ? {
          height: '100%',
          width: '98%',
          left: 0,
          right: '2%',
          top: '7%',
          bottom: 0,
        }
      : this.sleepStatUserActivity
        ? {
            height: '88%',
            width: '97%',
            left: '0%',
            bottom: '0%',
          }
        : {
            height: '70%',
            width: '97%',
            left: '1%',
            bottom: '15%',
          },
      xAxis:
      {
        type: 'category',
        position: this.showYAxisStats? 'top': 'bottom',
        show: true,
        boundaryGap: false,
        min: -1,
        max: (this.dateOptions === 0 || this.dateOptions === 2) ? this.amountOfDays + 1 : this.amountOfDays,
        splitLine: {
          show: true,
          interval: 'auto',
          lineStyle: {
            color: this.darkMode ? 'rgba(255, 255, 255, 0.10)' : '#D1F5FF',
            width: fixPixelsBy1440(1, this.innerWidth),
          }
        },
        // data: (this.selectedDate == 0) ? this.timeLabels
        //   :
        //   (this.measures_MonthWeek) ? this.measures_MonthWeek![0].dates : [],

        axisLabel: {
          interval: 0,
          formatter: (value: any, index: number) => {
            if (index <= 0 || index >= this.amountOfDays + 1) return ''

            if (this.selectedDate === LAST_WEEK) {
              const weekLetter: string[] = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
              return `${weekLetter[value]}`;
            }

            if (this.selectedDate === YEAR_TO_DATE) {
              if (index % 25 === 0) {
                return this.convertDateToISO((this.legendObjects.get(this.dashboardType) as legendObj[])[0].yearToDateLabel[index - 1]);
              }

              return '';
            }

            if (this.selectedDate.includes('-')) {
              if (index % (Math.round(this.amountOfDays / 10)) === 0)
                return index.toString();

              return '';
            }

            return index.toString();
          },
          fontWeight: 700,
          fontFamily: 'Zen Kaku Gothic Antique',
          fontSize: fixPixelsBy1440(13, this.innerWidth),
          // line-height: '133.4%',
          color: 'rgba(119, 151, 184, 1)',
        }
      },
      yAxis: this.showYAxisStats ?  {
        type: 'value',
        splitNumber: fixPixelsBy1440(4, this.innerWidth),
        nameTextStyle: {
          fontSize: fixPixelsBy1440(10, this.innerWidth),
          fontWeight: 700,
          fontFamily: 'Zen Kaku Gothic Antique',
        },
        axisLabel: {
          inside: true,
          formatter: function (value: any, index: any) {
            if (index === 0) return '';
            return '- ' + value;
          },
          fontWeight: 700,
          color: this.secondColor,
          fontFamily: 'Zen Kaku Gothic Antique',
          fontSize: fixPixelsBy1440(10, this.innerWidth),
        },
        axisLine: {
          show: false
        },
        axisTick: {
          show: false
        },
        splitLine: {
          show: false,
        }
      } : {
        axisLine: {
          show: false
        },
        axisTick: {
          show: false
        },
        splitLine: {
          show: false,
      }},
      series: []
    };
    const slctdOject = this.legendObjects.get(this.dashboardType);
    this.totalValueNowBar01 = 0;
    this.totalValueNowBar02 = 0;

    if (!slctdOject) return;
    const lgObj = slctdOject[legend];
    let serieBar = (lgObj.bar) ?
      this.barChart(lgObj.values, lgObj.colorStart, lgObj.colorEnd, lgObj.shadowColor!, lgObj.emphasisColor!, lgObj.averageColor!, lgObj.averageLine) :
      this.lineChart(lgObj.values, lgObj.colorStart, lgObj.colorEnd);
    (this.option_users!.series as any[]).push(serieBar);
    if (lgObj.bar) {
      if (this.totalValueNowBar01 === 0) this.totalValueNowBar01 = lgObj.values.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
      else this.totalValueNowBar02 = lgObj.values.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    }
  }

  barChart(bar_data: number[], bar_color_start: string, bar_color_last: string, shadow_color: string, emphasis_color: string, average_color: string, average_line: boolean): echarts.SeriesOption {
    return {
      type: 'bar',
      data: bar_data,
      emphasis: {
        itemStyle: {
          color: emphasis_color,
          shadowColor: shadow_color, // Color de la sombra
          shadowBlur: 10, // Intensidad de la sombra
        }
      },
      label: (average_line) ? {
        show: true,
        position: 'top',
        color: average_color,
        fontFamily: 'Zen Kaku Gothic Antique',
        fontSize: fixPixelsBy1440(10, this.innerWidth),
        formatter: function (params: any) {
          // Mostrar etiqueta solo cada 4 valores (0-indexed)
          if (params.dataIndex % 3 === 0) {
            return params.value;
          } else {
            return '';
          }
        },
      } : {},
      barMinWidth: fixPixelsBy1440(1, this.innerWidth),
      markLine: (average_line) ? {
        silent: true,
        symbol: 'none',
        precision: 1,
        lineStyle: {
          color: average_color,
          type: 'dashed'
        },
        label: {
          distance: [fixPixelsBy1440(75, this.innerWidth), fixPixelsBy1440(17, this.innerWidth)],
          fontFamily: 'Zen Kaku Gothic Antique',
          color: average_color,
          position: 'insideStartTop',
          padding: fixPixelsBy1440(7, this.innerWidth),
          borderRadius: fixPixelsBy1440(5, this.innerWidth),
          borderWidth: fixPixelsBy1440(1, this.innerWidth),
          borderType: 'dashed',
          borderColor: average_color,
          backgroundColor: '#FFFFFF',
          formatter: [
            '{a|Average}',
            '{b|{c}}'
          ].join('\n'),
          rich: {
            a: {
              fontWeight: 500,
              lineHeight: fixPixelsBy1440(14, this.innerWidth),
              fontSize: fixPixelsBy1440(10, this.innerWidth),
            },
            b: {
              fontWeight: 700,
              lineHeight: fixPixelsBy1440(27, this.innerWidth),
              fontSize: fixPixelsBy1440(20, this.innerWidth),
            },
          },
          show: true
        },
        data: [
          {
            type: 'average'
          }
        ]
      } : {},
      //zlevel: 2,
      stack: 'barStack',
      barWidth: fixPixelsBy1440(4, this.innerWidth),
      itemStyle: {
        borderRadius: (this.option_users?.series as any[]).length <= 0 && false ? 0 : [5, 5, 0, 0],

        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          { offset: 0, color: bar_color_start },
          { offset: 1, color: bar_color_last }
        ])
      }
    };
  }

  lineChart(line_data: number[], start_color: string, last_color: string): echarts.SeriesOption {
    return {
      name: 'Line 4',
      type: 'line',
      stack: 'lineStack',
      //zlevel: 1,
      smooth: true,
      lineStyle: {
        width: 0
      },
      showSymbol: false,
      emphasis: {
        itemStyle: {
          borderWidth: 0,
        },
      },
      symbolSize: 0,
      areaStyle: {
        opacity: 0.8,
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          { offset: 0, color: start_color },
          { offset: 1, color: last_color }
        ])
      },
      data: line_data,
    };
  }
}



