import { Component, EventEmitter, Input, Output, SimpleChanges, OnChanges, ViewChild, ChangeDetectorRef } from '@angular/core';
import { FilterService } from 'primeng/api';
import { Table } from 'primeng/table';
import { UserPanelTimeSelectorComponent } from 'src/app/users/panel/time-selector/user-panel-time-selector.component';
import { MovanoService } from 'src/app/movano.service';
import { ntfListTable } from 'src/app/shared/movano-interfaces';
import { lastValueFrom } from 'rxjs';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { InsightJobExecutionsRunsComponent } from '../../insight-job-executions/runs/insight-job-executions-runs.component';
import { Router } from '@angular/router';



@Component({
  selector: 'notification-list-filter',
  templateUrl: './notification-list-filter.component.html',
  styleUrls: ['./notification-list-filter.component.scss']
})
export class NotificationListFilterComponent {
  protected filter_Status : string = 'All Status';
  protected filter_development : boolean = false;
  protected filter_staging : boolean = false;
  protected filter_production : boolean = false;
  protected searchDisplay : boolean = false;
  protected filterDisplay : boolean = false;
  protected nextIsPosible: boolean = false;
  protected loadingData: boolean = false;
  protected daySelected?: Date;
  protected rangeSelected: Date[] = [];
  protected today?: Date;  
  protected ntfLists: ntfListTable[] = [];
  protected actualTimeZone: string = '';
  protected timeOptions: string[] = ['', 'week', 'month', ''];
  isTimeSelectorDisabled = false; 
  isSwitchChecked = true; 
  /**
  0 => today / any day
  1 => week
  2 => month (default)
  3 => range day
  */
  protected selectedDate: number = 2;
  protected timeZones : string[] = []; //UTC, Madrid, California... etc
  protected lastselectedDate : number = 0;
  protected dataAvailable: boolean = false;
  @ViewChild('list_calendar') daySelector !: UserPanelTimeSelectorComponent;
  @Input() table !: Table;
  @Output() getInfo = new EventEmitter<[number, boolean, Date | Date[], string, number]>();
  @Output() ntfListsChanged: EventEmitter<ntfListTable[]> = new EventEmitter<ntfListTable[]>();
  @Output() dataAvailableChange = new EventEmitter<boolean>();
  @Output() loadingDataChange = new EventEmitter<boolean>();
  @Input() jobId?: string;
  @Input() name?: string;
  @Input() filteredUsers: ntfListTable[] = [];
  @Input() isFiltered: boolean = false;
  @Input() insightApps: ntfListTable[] = [];


  constructor(private filterService: FilterService,private cdr: ChangeDetectorRef, protected movanoService: MovanoService, private dialog: MatDialog, private router: Router){
    const customFilterName = 'multi-select-in';
    this.filterService.register(customFilterName, (value : any, filter : any): boolean => {
      if (filter == null || filter.length == 0) {
        return true;
      }
      let selected : boolean = false;
      //console.log(filter);
      filter.forEach((element : any) => {
        if(element === value){
          selected = true;
          return;
        }
      });
      return selected;
    });
  }
  ngOnInit(): void {
    this.today = new Date(Date.now());
    this.isTimeSelectorDisabled = !!this.name && this.isSwitchChecked;
    if (this.name) {
        this.handleDataLoading();
        console.log(this.name)
    } 

    else if (this.jobId) {
      console.log(this.jobId)
        this.handleJobId();
    } 
    else {
        this.emitGetInfo(); 
    }
}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['jobId'] && !changes['jobId'].firstChange) {
      console.log(`Job ID changed in child component: ${this.jobId}`);
      this.handleJobId();
    }
  
    if (changes['name'] && !changes['name'].firstChange) {
      console.log(`Name changed in child component: ${this.name}`);
      this.handleDataLoading();
  }
  } 
  

  navigateBack() {
    this.router.navigate([`/notifications/insight-job-executions`]); 
  }

  backToApp(){
    this.router.navigate([`/notifications/insight-application`]);
  }

  search(event: any, keys: string, table: any) {
    const value = event.target.value.toLowerCase();
    table.filter(value, keys);
    this.isFiltered = true;
}

 /*  search(event: any, _row: string, _table: Table) {
    _table.reset();
    _table.filter(event.target.value, _row, 'contains');
    this.isFiltered = true;
  } */

  applyFilter() {
    this.table.reset();
    // if (!this.filter_Status) return;
    // if (this.filter_Status == 'reset') return;

    // if()
    // this.table.filter(this.filter_Status, 'status', 'equals');

    console.log(this.filter_development);
    console.log(this.filter_staging);
    console.log(this.filter_production);
    let statusFilter : string[] = [];
    if(this.filter_development) statusFilter.push('dev');
    if(this.filter_staging) statusFilter.push('staging'); 
    if(this.filter_production)  statusFilter.push('prod');

    this.table.filter(statusFilter, 'status', 'multi-select-in');
  }


  emitGetInfo(calendarData?: [any, boolean, Date | Date[], string, any]): void {
    if (this.name) {
      //this.getInfo.emit(calendarData);
      this.getNtfnInsightListByName(this.name, calendarData);
    } else {
      this.getInfo.emit(calendarData);
      this.loadData(calendarData); 
    }
  }


    handleDataLoading() {
       if (this.name) {
    if (this.isSwitchChecked) {
      this.getNtfnInsightListByNameAll(this.name);
    } else {
      this.emitGetInfo();  
    }
  }
}


 handleJobId(){
  if(this.jobId)
    {
    this.getNtfnInsightListByJobId(this.jobId)
  }
 }

    onSwitchChange(event: Event): void {
      this.isSwitchChecked = (event.target as HTMLInputElement).checked;
      this.isTimeSelectorDisabled = !!this.name && this.isSwitchChecked;
      
      this.handleDataLoading();
    }




    async getNtfnInsightListByJobId(jobId: string) {
      let res: any;
      this.loadingData = true;
      this.loadingDataChange.emit(this.loadingData);
      console.log(`Fetching notifications for jobId: ${jobId}`);
  
      try {
          const dateStart = this.movanoService.getSelectedStartDate() || undefined;
          const dateEnd = this.movanoService.getSelectedEndDate() || undefined;
          const timeFrame = this.movanoService.getTimeFrame();
  
          res = await lastValueFrom(this.movanoService.getNtfnListByJobId(jobId, dateStart, dateEnd, timeFrame));
          console.log('API Response:', res.data);
  
          this.ntfLists = res.data;
          this.ntfListsChanged.emit(this.ntfLists);
          console.log(this.ntfLists.length )
          this.dataAvailable = this.ntfLists.length > 0;
          this.dataAvailableChange.emit(this.dataAvailable);
          this.cdr.detectChanges();
      } catch (error) {
          console.error(error);
          this.ntfLists = [];
          this.ntfListsChanged.emit(this.ntfLists);
          this.dataAvailable = false;
          this.dataAvailableChange.emit(this.dataAvailable);
      } finally {
          this.loadingData = false;
          this.loadingDataChange.emit(this.loadingData);
      }    
  }
  


    async getNtfnInsightListByNameAll(name: string) {
      let res: any 
      let dateStart: Date | undefined;
      let dateEnd: Date | undefined;
      let timeFrame: string | undefined = '';
      this.loadingData = true;
      this.loadingDataChange.emit(this.loadingData);
      console.log(`Fetching notifications for name: ${name}`);
      
      try {

     res = await lastValueFrom(this.movanoService.getNtfnListByName(name, dateStart, dateEnd, timeFrame));
    console.log('API Response:', res);
        
        this.ntfLists = res.data;
        this.ntfListsChanged.emit(this.ntfLists);
        this.dataAvailable = this.ntfLists.length > 0;
        this.dataAvailableChange.emit(this.dataAvailable);
      } catch (error) {
        console.error(error);
        this.ntfLists = [];
        this.ntfListsChanged.emit(this.ntfLists);
        this.dataAvailable = false;
        this.dataAvailableChange.emit(this.dataAvailable);
      } finally {
        this.loadingData = false;
        this.loadingDataChange.emit(this.loadingData);
      }
    }


  async getNtfnInsightListByName(name: string, calendarData?: [any, boolean, Date | Date[], string, any]) {
  let dateStart: Date | undefined;
  let dateEnd: Date | undefined;
  let timeFrame: string | undefined = '';
  let res: any;

  this.loadingData = true;
  this.loadingDataChange.emit(this.loadingData);

  try {
    if (calendarData) {
      this.lastselectedDate = this.selectedDate;
      this.selectedDate = parseInt(calendarData[0]);
      this.nextIsPosible = calendarData[1];
      
      if (this.selectedDate !== 3) {
        this.daySelected = calendarData[2] as Date;
      } else {
        this.rangeSelected = calendarData[2] as Date[];
      }
      this.actualTimeZone = calendarData[3];
    }

 
    switch (this.selectedDate) {
      case 0:
        dateStart = this.daySelected;
        dateEnd = this.daySelected;
        break;
      case 1:
        timeFrame = 'lastWeek';
        break;
      case 2:
        timeFrame = 'lastMonth';
        break;
      case 3:
        dateStart = this.rangeSelected[0];
        dateEnd = this.rangeSelected[1];
        break;
      case 4:
        timeFrame = 'last7Days';
        break;
      case 5:
        timeFrame = 'actualYear';
        break;
      case 6:
        timeFrame = 'today';
        break;
    }

    res = await lastValueFrom(this.movanoService.getNtfnListByName(name, dateStart, dateEnd, timeFrame));
    this.ntfLists = res.data;
    this.ntfListsChanged.emit(this.ntfLists);

    this.dataAvailable = this.ntfLists.length > 0;
    this.dataAvailableChange.emit(this.dataAvailable);
  } catch (error) {
    console.error('Error fetching data:', error);

    this.dataAvailable = false;
    this.ntfLists = [];
    this.dataAvailableChange.emit(this.dataAvailable);
  } finally {
    this.loadingData = false;
    this.loadingDataChange.emit(this.loadingData);
  }
}



    async loadData(calendarData?: [any, boolean, Date | Date[], string, any]) {

      let dateStart: Date | undefined;
      let dateEnd: Date | undefined;
      let timeFrame: string | undefined = '';
      let res: any 
  
  
      this.loadingData = true;
      this.loadingDataChange.emit(this.loadingData);
  
      try {
        if (!calendarData) {
          this.selectedDate = 2; 
        } else {
          console.log(calendarData);
          this.lastselectedDate = this.selectedDate;
          this.selectedDate = parseInt(calendarData[0]);
          this.nextIsPosible = calendarData[1];
          if (this.selectedDate !== 3) {
            this.daySelected = calendarData[2] as Date;
          } else {
            this.rangeSelected = calendarData[2] as Date[];
          }
          this.actualTimeZone = calendarData[3];
        }
    
        this.dataAvailable = true;
        this.dataAvailableChange.emit(this.dataAvailable);
        this.loadingData = true;
        this.loadingDataChange.emit(this.loadingData);
    
       
        if (this.selectedDate === 0) {
          // Handle "today" or any custom day
          timeFrame = 'today' 
         
        } else if (this.selectedDate === 1) {
          // Handle "last week"
          timeFrame = 'lastWeek'
        } else if (this.selectedDate === 2) {
          // Handle " last month"
          timeFrame = 'lastMonth'
         
        } else if (this.selectedDate === 3) {
          // Handle "range"
          dateStart = this.rangeSelected[0];
          dateEnd = this.rangeSelected[1];
        }
        else if (this.selectedDate === 4) {
          // Handle "last 7 days"
          timeFrame = 'last7Days'
        }
        else if (this.selectedDate === 5)
        {
          timeFrame = 'actualYear'
        }
       
      
         res = await lastValueFrom(this.movanoService.getNtfnList( dateStart, dateEnd, timeFrame));
        
          console.log('API Response:', res.data);
    
          this.ntfLists = res.data;
          this.ntfListsChanged.emit(this.ntfLists);
    
          this.dataAvailable = this.ntfLists.length > 0;
          this.dataAvailableChange.emit(this.dataAvailable);
     
      } catch (error) {
        console.error('Error fetching data:', error);
    
        this.dataAvailable = false;
        this.ntfLists = [];
        this.dataAvailableChange.emit(this.dataAvailable);
      } finally {
        this.loadingData = false;
        this.loadingDataChange.emit(this.loadingData);
      }
    }


  
  /**
   * Goes to the previous date based on the selected date range.
   * Updates properties and calls the getInfo function to fetch data for the new date.
   */
  previusDate() {
    // Depending on the selected date range, go to the previous day, week, or month
    switch (+this.selectedDate) {
      case 0: //Previus day
        this.selectedDate = 0;
        this.daySelected?.setDate(this.daySelected.getDate() - 1);
        break;
      case 3:
        this.daySelected?.setDate(this.daySelected.getDate() - 1);
        break;
      case 1: //Previus week
        this.daySelected?.setDate(this.daySelected.getDate() - 7);
        break;
      case 2: //Previus month
        this.daySelected?.setMonth(this.daySelected.getMonth() - 1);
        break;
    }
    console.log('Previous date:', this.daySelected);
    // Update the input field in the day selector component
    this.daySelector.updateDaySelected(this.daySelected!, this.timeZones!, this.selectedDate);

    // Call the getInfo function to update data with the new date
    this.emitGetInfo();
  }


  onResize() {
    const checkboxDivs = document.querySelectorAll('.movano_checkBox') as NodeListOf<HTMLElement>;
    const checkbox = document.querySelectorAll('mat-checkbox') as NodeListOf<HTMLElement>;
    const windowWidth = window.innerWidth;
    const checkboxSize = Math.max(windowWidth / 1440, 1) * 0.85;

    checkboxDivs.forEach((cb: HTMLElement) => {
      cb.style.width = `calc(50px * ${checkboxSize})`;
    });

    //console.log(checkbox);
    checkbox.forEach((cb: HTMLElement) => {
      cb.style.transform = `scale(${checkboxSize})`;
    });
  }
}
