
<div echarts [options]="options" class="demo-chart"
(chartClick)="onChartEvent($event, 'chartClick')"></div>

      <p-progressBar [value]="(2*100)/4" aria-label="Status" [showValue]="false"></p-progressBar>
<button (click)="consolClick()">click</button>
<div  class="movanoHealthMetrics_AtomicSwitch">
    <div class="movano_switchTimeZone">
        <label class="switch">
          <input type="checkbox"  >
          <span class="slider round">
            <span class="sliderText" [ngClass]="{'selected' : false}">Average data</span>
            <span class="sliderText" [ngClass]="{'selected' : !false}">Detail data</span>
          </span>
        </label>
      </div>
    </div>

<app-cognito-user-create-edit></app-cognito-user-create-edit>

<!-- <div style="display: flex; gap: 60px; margin:  movanoResize(20); background-color: #f3f9fe;">
    <div style="display: flex; gap: 30px; flex-direction: column;">

        <h2 style="font-weight: 700;">BUTTONS</h2>
        <h3 style="font-weight: 700;">normal</h3>
        <a href="">Link</a>
        <app-movano-button [buttonText]="'Small'" [size]="'small'"></app-movano-button>
        <app-movano-button [buttonText]="'Medium'" [size]="'medium'"></app-movano-button>
        <app-movano-button [buttonText]="'Large'" [size]="'large'"></app-movano-button>


        <app-movano-button [buttonText]="'Small'" [size]="'small'" [outlined]="true"></app-movano-button>
        <app-movano-button [buttonText]="'Medium'" [size]="'medium'" [outlined]="true"></app-movano-button>
        <app-movano-button [buttonText]="'Large'" [size]="'large'" [outlined]="true"></app-movano-button>

        <app-movano-button [buttonText]="'Loading'" [color]="'blue'" [percentage]="percentage"></app-movano-button>

        <h3 style="font-weight: 700;">colors</h3>
        <app-movano-button [buttonText]="'Medium'" [color]="'blue'"></app-movano-button>
        <app-movano-button [buttonText]="'Medium'" [color]="'magenta'"></app-movano-button>
        <app-movano-button [buttonText]="'Medium'" [color]="'green'"></app-movano-button>
        <app-movano-button [buttonText]="'Medium'" [color]="'denim'"></app-movano-button>
    </div>
    <div style="display: flex; gap: 30px; flex-direction: column; margin-right:  movanoResize(20);">
        <h2 style="font-weight: 700;">COMPONETS</h2>
        <h3 style="font-weight: 700;">CHARTS</h3>
        <app-movano-chart></app-movano-chart>
        <h3 style="font-weight: 700;">INFINITE CAROUSEL</h3>
        <app-movano-info-carousel></app-movano-info-carousel>
        <h3 style="font-weight: 700;">TOTAL INFO</h3>
        <app-movano-tota-info></app-movano-tota-info>
        <h3 style="font-weight: 700;">STATS</h3>
        <app-movano-stats></app-movano-stats>
        <h3 style="font-weight: 700;">ACTIVITY</h3>
        <app-movano-activity></app-movano-activity>
        <h3 style="font-weight: 700;">HISTORY</h3>
        <div style="display: flex; gap: 1.04vw;">
            <app-movano-history [historyType]="'production'"></app-movano-history>
            <app-movano-history [historyType]="'sales'"></app-movano-history>
            <app-movano-history [historyType]="'syncs'"></app-movano-history>
            <app-movano-history [historyType]="'incidents'"></app-movano-history>
        </div>
        <h3 style="font-weight: 700;">LAST MONTH</h3>
        <div style="display: flex; gap: 1.04vw;">
            <app-movano-last-compt [lastType]="'production'"></app-movano-last-compt>
            <app-movano-last-compt [lastType]="'sales'"></app-movano-last-compt>
            <app-movano-last-compt [lastType]="'syncs'"></app-movano-last-compt>
            <app-movano-last-compt [lastType]="'incidents'"></app-movano-last-compt>
        </div>
        <h3 style="font-weight: 700;">EMPTY SPACE</h3>
    </div>
    <div style="display: flex; gap: 30px; flex-direction: column;">
        <h2 style="font-weight: 700;">FORM</h2>
        <h3 style="font-weight: 700;">check box</h3>

        <mat-checkbox class="movano_checkBox">
            I'm a checkbox
        </mat-checkbox>
        <mat-checkbox class="movano_checkBox" [indeterminate]="true">
            I'm a checkbox
        </mat-checkbox>

        <h3 style="font-weight: 700;">switch</h3>
        <div class="movano_switch">
            <label class="switch">
                <input type="checkbox" checked>
                <span class="slider round"><span class="sliderText">active</span></span>
            </label>
        </div>
        <div class="movano_switch movano_switch_disabled">
            <label class="switch">
                <input disabled type="checkbox" checked>
                <span class="slider round"><span class="sliderText">disabled</span></span>
            </label>
        </div>
        <div class="movano_switchLine">
            <label class="switch">
                <input type="checkbox" checked>
                <span class="slider round"><span class="sliderText">small</span></span>
            </label>
        </div>

        <h3 style="font-weight: 700;">select</h3>

        <mat-form-field>
            <mat-select placeholder="Select an option" panelClass="movano-select" disableOptionCentering>
                <mat-option value="option1">Option 1</mat-option>
                <mat-option value="option2">Option 2</mat-option>
                <mat-option value="option3">Option 3</mat-option>
            </mat-select>

            <svg style="transform: rotate(0deg); position: relative; bottom:10px; right:13px" matSuffix width="8"
                height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M7.35199 -2.83255e-08L0.648012 -3.21366e-07C0.136801 -3.43711e-07 -0.173808 0.514539 0.104446 0.910793L3.45643 5.72499C3.7088 6.09167 4.2912 6.09167 4.55004 5.72499L7.89555 0.910793C8.17381 0.514539 7.8632 -5.97973e-09 7.35199 -2.83255e-08Z"
                    fill="#284767" />
            </svg>
        </mat-form-field>
        <mat-form-field>
            <mat-icon matPrefix
                style="position: relative; bottom:  movanoResize(10); transform: scale(0.65);">calendar_today</mat-icon>
            <mat-select placeholder="Select an option" panelClass="movano-select" [disableOptionCentering]="true">
                <mat-option value="option1">Option 1</mat-option>
                <mat-option value="option2">Option 2</mat-option>
                <mat-option value="option3">Option 3</mat-option>
            </mat-select>

            <svg style="transform: rotate(0deg); position: relative; bottom:10px; right:13px" matSuffix width="8"
                height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M7.35199 -2.83255e-08L0.648012 -3.21366e-07C0.136801 -3.43711e-07 -0.173808 0.514539 0.104446 0.910793L3.45643 5.72499C3.7088 6.09167 4.2912 6.09167 4.55004 5.72499L7.89555 0.910793C8.17381 0.514539 7.8632 -5.97973e-09 7.35199 -2.83255e-08Z"
                    fill="#284767" />
            </svg>
        </mat-form-field>
    </div>


</div> -->
<form action="" class="movano-dropdown">
    <p-dropdown placeholder="UTC + 00 - UTC" [options]="['UTC + 00 - UTC', 'UTC + 00 - UTC', 'UTC + 00 - UTC']"
        [dropdownIcon]="''" optionLabel="UTC + 00 - UTC">
        <ng-template let-timeZone pTemplate="item">
            <span class="flex align-items-center gap-2">
                {{timeZone}}
            </span>
        </ng-template>
    </p-dropdown>
    <svg viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M7.35199 -2.83255e-08L0.648012 -3.21366e-07C0.136801 -3.43711e-07 -0.173808 0.514539 0.104446 0.910793L3.45643 5.72499C3.7088 6.09167 4.2912 6.09167 4.55004 5.72499L7.89555 0.910793C8.17381 0.514539 7.8632 -5.97973e-09 7.35199 -2.83255e-08Z"
            fill="#284767" />
    </svg>
</form>