import { Component, Inject, Input } from '@angular/core';
import { deviceTable } from 'src/app/shared/movano-interfaces';
import { ticketsTable } from 'src/app/shared/movano-interfaces';
import * as lit from '../../shared/movano-literals';
import { DarkModeService } from '../../dark-mode.service';
import { Subscription } from 'rxjs';
import { MovanoService } from 'src/app/movano.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';
import { DevicesTicketsComponent } from '../devices-tickets/devices-tickets.component';


@Component({
  selector: 'app-devices-tickets-info',
  templateUrl: './devices-tickets-info.component.html',
  styleUrls: ['./devices-tickets-info.component.scss']
})
export class DevicesTicketsInfoComponent {

  protected devices : deviceTable[] = [];
  protected tickets: ticketsTable[] = [];
  protected LITERALS = lit;
  protected loadingUserDeviceList : boolean = true;
  protected darkMode: boolean = false;
  private darkModeSub: Subscription = new Subscription();
  
  
  constructor(private darkModeSvc: DarkModeService, protected movanoService: MovanoService,
              protected dialogRef: MatDialogRef<deviceTable>,
              protected dialog: MatDialog,
              @Inject(MAT_DIALOG_DATA) protected data: { user: string })
    {
    this.darkModeSub = this.darkModeSvc.getVariable().subscribe((val: any) => {
      this.darkMode = val;
    });
     this.getUserDeviceList(); 
  }

   async getUserDeviceList() {
    this.loadingUserDeviceList = true;
    this.movanoService.getTicketsTableInfo().subscribe((res: any) => {
      this.tickets = res.tickets;
      this.loadingUserDeviceList = false;
      console.log(res);
    });
  } 

  protected openTicketsPanel(_userId: string) {
    this.dialog.open(DevicesTicketsComponent, {data: {user: _userId}});
    this.closePanel();
  }

  protected closePanel() {
    this.dialogRef.close();
  }
}


