import { waveForm_Data_Measures, waveForm_Data_Leds, waveForm_Data_Leds_eChartFix, waveForm_Data_Accel, waveForm_Data_Accel_eChartFix, waveForm_Data_Leds_eChartFixPrueba, Measure, SpotCheck_Hourly, Measurement,DataPoint, MeasurementDaily, SpotCheck_Daily, measurementPeaks } from "./movano-interfaces";
import {MONTHS} from './../shared/movano-literals'
export function abbrNum(num: number, decPlaces: number, useSpace: boolean = true, useCapital: boolean = true): string {
  if(num < 1000)
    return num + '';
  // 2 decimal places => 100, 3 => 1000, etc
  decPlaces = Math.pow(10,decPlaces);

  // Enumerate number abbreviations
  const abbrev = [ "k", "m", "b", "t" ];
  let ret_num = '';

  // Go through the array backwards, so we do the largest first
  for (let i=abbrev.length-1; i>=0; i--) {

    // Convert array index to "1000", "1000000", etc
    let  size = Math.pow(10,(i+1)*3);

    // If the number is bigger or equal do the abbreviation
    if(size <= num) {
      // Here, we multiply by decPlaces, round, and then divide by decPlaces.
      // This gives us nice rounding to a particular decimal place.
      num = Math.round(num*decPlaces/size)/decPlaces;

      // Add the letter for the abbreviation
      const space = useSpace? ' ': '';
      ret_num = num + space + abbrev[i];
      if(useCapital)
        ret_num = ret_num.toUpperCase();

      // We are done... stop
      break;
    }
  }

  return ret_num;
}

export function round(value: number, decimals: number) {
  const factor = Math.pow(10, decimals)
  return Math.round((value + Number.EPSILON) * factor) / factor
}
/**
 * Generates an array representing a day with either values or dashes.
 * @param indexedArray - An array of waveForm_Data_Measures objects.
 * @returns An array representing a day with values or dashes.
 * O((60 * 60 * 24)+indexedArray.lenght)
 */
export function getDayArrraySimple(indexedArray: waveForm_Data_Measures[]): ("-" | number)[] {
  // Create an array of "-" values with a length of 24 hours (60 seconds * 60 minutes * 24 hours).
  let returnArray: ("-" | number)[] = Array((60 * 60 * 24)).fill("-");

  // Fill in the values from the indexedArray into the returnArray.
  for (const element of indexedArray) {
    returnArray[element.i] = element.v??"-";
  }
  return returnArray;
}

/**
 * Transforms an array of waveForm_Data_Measures objects into a 2D array.
 * Each sub-array contains the 'i' and 'v' values from the corresponding object in 'indexedArray'.
 * @param indexedArray - An array of waveForm_Data_Measures objects.
 * @returns A 2D array where each sub-array contains the 'i' and 'v' values from 'indexedArray' objects.
 * O(indexedArray.lenght)
 */
export function getDayArrraySimpleIV(indexedArray: waveForm_Data_Measures[]):  number[][] {
  if(indexedArray.length == 0) return [];
  // Transform the 'indexedArray' using the 'map' function.
  // Each element in 'indexedArray' is transformed into a sub-array containing 'i' and 'v' values.
  return indexedArray.map(element => [element.i, element.v]);
}
/**
 * Transforms an array of waveForm_Data_Measures objects into a 2D array.
 * Each sub-array contains the 'i' and 'v' values from the corresponding object in 'indexedArray'.
 * @param indexedArray - An array of waveForm_Data_Measures objects.
 * @returns A 2D array where each sub-array contains the 'i' and 'v' values from 'indexedArray' objects.
 * O(indexedArray.lenght)
 */


     export function getDayArrraySimpleSPOT(measurementList: Measurement[], type: 'Spot HR' | 'Spot SpO2'): [string, number, boolean][] {
      const measurements: Measurement[] = measurementList.filter(m => m.measure_label === type);
  
      const dataPoints: [string, number, boolean][] = [];
      measurements.forEach((measurement: Measurement) => {
          const dateTimeParts = measurement.date.split('T');
          const date = dateTimeParts[0];
          const time = dateTimeParts[1].slice(0, 8);
  
          const dateTime = `${date} ${time}`;
          dataPoints.push([dateTime, measurement.measure_value, measurement.low_perfusion]);
      });
  
      return dataPoints;
  }
   





//ESTO TIENE MÁS SENTIDO A NIVEL VISUAL

/*     export function getDayArrraySimpleSPOTDay(
      measurement: MeasurementDaily[], 
      type: 'hr_avg' | 'spO2_avg', 
      includeLowPerfusion: boolean
    ): [string, number][] {
      const dataPoints: [string, number][] = [];
    
      measurement.forEach((measurement: MeasurementDaily) => {
        if (includeLowPerfusion || measurement.low_perfusion_count === 0) {
          const dateTimeParts = measurement.date.split('T'); 
          const date = dateTimeParts[0];                 
    
          const dateTime = `${date}`;
    
          let measureValue: number | null;
          if (type === 'hr_avg') {
            measureValue = measurement.hr_avg;
          } else {
            measureValue = measurement.spO2_avg;
          }
    
          if (measureValue !== null) {
            dataPoints.push([dateTime, measureValue]);
          }
        }
      });
    
      return dataPoints;
    } */

 
  
/* 
      export function getDayArrraySimpleSPOTDay(
        inputData: SpotCheck_Daily,
        type: 'Spot HR' | 'Spot SpO2'
    ): [string, number, boolean][] {
        const { dates_info, measure } = inputData;
        const measurementData = measure.find(m => m.measure_label === type)?.data || [];
    
        const dataPoints: [string, number, boolean][] = [];
        
        dates_info.forEach((date, index) => {
          const measurements = measurementData[index] || [];
          measurements.forEach((measurement: DataPoint) => {
              const dateTime = `${date}`;
              dataPoints.push([dateTime, measurement.value, measurement.low_perfusion]);
          });
        });
    
        return dataPoints;
    } */

        export function getDayArrraySimpleSPOTDay(
          inputData: SpotCheck_Daily,
          type: 'Spot HR' | 'Spot SpO2'
        ): [string, number, boolean][] {
          const { spotcheck_hr, spotcheck_spO2 } = inputData;
          
          // Choose the appropriate array for the selected type
          const measurementData = type === 'Spot HR' ? spotcheck_hr : spotcheck_spO2;
          
          const dataPoints: [string, number, boolean][] = [];
          
          // Loop through each day's measurement data
          measurementData.forEach((dayData: Measure[], index: number) => {
            // Check if there is data for this day (i.e., if the array is not empty)
            if (dayData.length > 0) {
              const measurement = dayData[0]; // Use the first measurement for the day
              
              // Convert the ISO date string to the desired "YYYY-MM-DD" format
              const formattedDate = new Date(measurement.date).toISOString().slice(0, 10);
              
              dataPoints.push([formattedDate, measurement.value, measurement.low_perfusion]);
            }
          });
        
          console.log(dataPoints);
          return dataPoints;
        }
        
        


    
   


/**
 * Transforms an array of waveForm_Data_Leds objects into a structured object suitable for eCharts visualization.
 * Each entry in the returned object represents IR and Red LED data for a specific index.
 * @param indexedArray - An array of waveForm_Data_Leds objects.
 * @returns An object containing IR and Red LED data structured for eCharts visualization.
 */
export function getDayArrrayLed(indexedArray: waveForm_Data_Leds[]): waveForm_Data_Leds_eChartFix {
  // Create an object to hold IR and Red LED data, with default values as arrays of "-".
  let return_obj: waveForm_Data_Leds_eChartFix = { ir: Array((60 * 60 * 24)).fill("-"), red: Array((60 * 60 * 24)).fill("-") }
  // Transform the 'indexedArray' to populate IR and Red LED data in the 'return_obj'.
  for (const element of indexedArray) {
    // Initialize arrays for IR and Red LED data at the specific index.
    return_obj.ir[element.i] = [];
    return_obj.red[element.i] = [];
    // Iterate through the LED data for the current index and extract IR and Red LED values.
    for (const led of element.v) {
       // Push the IR and Red LED values to their respective arrays for the current index.
      // @ts-ignore
      return_obj.ir[element.i].push(led.ir);
      // @ts-ignore
      return_obj.red[element.i].push(led.r);
    }
  }
  return return_obj;
}

export function getDayArrrayLedIV(indexedArray: waveForm_Data_Leds[]): waveForm_Data_Leds_eChartFixPrueba {
  // Create an object to hold IR and Red LED data, with default values as arrays of "-".
  let return_obj : waveForm_Data_Leds_eChartFixPrueba = {ir:[],  red: []};
  // Transform the 'indexedArray' to populate IR and Red LED data in the 'return_obj'.
  for (const element of indexedArray) {
    // Initialize arrays for IR and Red LED data at the specific index.
    return_obj.ir.push([element.i, []]);
    return_obj.red.push([element.i, []]);
    // Iterate through the LED data for the current index and extract IR and Red LED values.
    for (const led of element.v) {
       // Push the IR and Red LED values to their respective arrays for the current index.
      // @ts-ignore
      return_obj.ir[element.i].push(led.ir);
      // @ts-ignore
      return_obj.red[element.i].push(led.r);
    }
  }
  return return_obj;
}

export function getDayArrrayAccel(indexedArray: waveForm_Data_Accel[]): waveForm_Data_Accel_eChartFix {
  let return_obj: waveForm_Data_Accel_eChartFix = { x: Array((60 * 60 * 24)).fill("-"), y: Array((60 * 60 * 24)).fill("-"), z: Array((60 * 60 * 24)).fill("-") }
  for (const element of indexedArray) {
    return_obj.x[element.i] = [];
    return_obj.y[element.i] = [];
    return_obj.z[element.i] = [];
    for (const accel of element.v) {
      // @ts-ignore
      return_obj.x[element.i].push(accel.x);
      // @ts-ignore
      return_obj.y[element.i].push(accel.y);
      // @ts-ignore
      return_obj.z[element.i].push(accel.z);
    }
  }
  return return_obj;
}

export function   
/**
* Adjusts a given number of pixels based on the screen width and 1440 reference width.
* If the screen width is 1440 or less, the input value remains unchanged.
* If the screen width is greater than 1440, the input value is proportionally adjusted.
* @param pixelsIn1440 - Number of pixels in a 1440 width reference.
* @returns Adjusted number of pixels based on screen width.
*/
fixPixelsBy1440(pixelsIn1440: number, innerWidth: number): number {
 // Check if the screen width is 1440 or less, return input value unchanged
 if (innerWidth <= 1440) return pixelsIn1440;

 // Calculate adjusted pixels based on screen width
 return (pixelsIn1440 / 1440) * innerWidth;
}

/**
 * Transform Date variable to YYYY-MM-DD string used for api calls
 * @param date 
 * @returns YYYY-MM-DD
 */
export function dateToYYYYMMDD(date ?: Date){
  if (!date) return "";
  // Get the user's time zone
  // Extract day, month, and year from the provided date
  let day, month, year;
  day = date.getDate();
  month = date.getMonth() + 1;
  year = date.getFullYear();
  // Format day and month with leading zeros if necessary
  if (day < 10) day = "0" + day;
  if (month < 10) month = "0" + month;

  return `${year}-${month}-${day}`;
}

//FUNCION MARKLINE SPOT
export function dateMarkLineConstructor(_dates : string, _innerWidth : number) : any {
  let markLinesData: { xAxis: string, name: string }[] = [];
  const arrayLenght: number = _dates.length;
  const firtsMonth: number = parseInt( _dates[0].split('-')[1]);
  const lastMonth: number = parseInt( _dates[arrayLenght - 1].split('-')[1]);
  for (let index = (firtsMonth + 1); index <= lastMonth; index++) {
    let str = index.toString();
    markLinesData.push({ xAxis: `2023-${(index < 10) ? ("0" + str) : str}-01`, name: MONTHS[index - 1] });
  }

  return {
    label: {
      show: true,
      fontWeight: 500, 
      position: 'insideEndBottom',
      fontSize: fixPixelsBy1440(70, _innerWidth),
      color: '#2f3943',
      opacity: 0.15,
      formatter: '{b}'
    },
    lineStyle: {
      type: 'solid',
      color: '#2ad2ff',
      width: fixPixelsBy1440(2, _innerWidth)
    },
    symbol: 'none',
    data: markLinesData
  }
}

export function multipleYaxisConstructor(_name: string, _index: number, _color: string, _innerWidth : number): any {
  return {
    type: 'value',
    name: _name,
    alignTicks: true,
    offset: fixPixelsBy1440(35, _innerWidth) * Math.floor(_index / 2) + fixPixelsBy1440(25, _innerWidth),
    position: (_index % 2 === 1) ? 'right' : 'left',
    nameTextStyle: {
      fontSize: 'max(0.7vw, 10px)',
      fontWeight: 700,
      fontFamily: 'Zen Kaku Gothic Antique',
      align: (_index % 2 === 1) ? 'right' : 'left'
    },
    min: 0,
    axisLine: {
      show: true,
      lineStyle: {
        color: _color,
      }
    },
    axisLabel: {
      inside: true,
      fontWeight: 700,
      fontFamily: 'Zen Kaku Gothic Antique',
      fontSize: 'max(0.7vw, 10px)',
      color: _color,
      formatter: function (value: any) { return abbrNum(Math.round(value), 1, false, false) }
    }
  };
}

export function getDayOfWeek(date: string): string {
  // Convert the date in "YYYY/MM/DD" format to a Date object
  const dateParts = date.split("-");
  const year = parseInt(dateParts[0]);
  const month = parseInt(dateParts[1]) - 1; // Months start at 0 (January is 0)
  const day = parseInt(dateParts[2]);
  const dateObj = new Date(year, month, day);

  // Days of the week in text
  const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

  // Get the day of the week number (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
  const dayOfWeekNumber = dateObj.getDay();

  // Get the name of the day of the week
  const dayOfWeekName = daysOfWeek[dayOfWeekNumber];

  return `${dayOfWeekName} - ${day}nd`;
}


export function minutesToHHMM(minutes: number): string {
  const hours = Math.floor(minutes / 60);
  const leftMinutes = Math.floor(minutes % 60);

  if (hours > 0) return `${hours}h ${leftMinutes}min`;
  if (minutes > 1) return `${leftMinutes}min`;
  if (minutes > 0) return '< 1min';
  return '0 min';
}

export function rangeHoursFormated(initDate: string, endDate: string): string {
  return `${hoursFormated(initDate)} - ${hoursFormated(endDate)}`;
}

export function hoursFormated(_date: string): string {
  const markDayDate = new Date(_date);
  const hours = markDayDate.getHours();
  const minutes = markDayDate.getMinutes();
  const seconds = markDayDate.getSeconds();

  // const amPm = hours >= 12 ? "pm" : "am";
  // const formattedHours = hours % 12; // Convert 0 to 12 for 12-hour format

  return ` - ${hours}:${minutes.toString().padStart(2, '0')}:${seconds}`;
}