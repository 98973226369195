import { Component,ChangeDetectorRef, Input, } from '@angular/core';
import { Router } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import { DarkModeService } from 'src/app/dark-mode.service';
import { DashboardTypeService } from 'src/app/dashboard-type.service';
import { MovanoService } from 'src/app/movano.service';
import { footerInfo } from 'src/app/shared/movano-interfaces';
import { SharedService } from '../shared.service';

@Component({
  selector: 'app-stats-devices',
  templateUrl: './stats-devices.component.html',
  styleUrls: ['./stats-devices.component.scss']
})
export class StatsDevicesComponent {

  protected dynamicText: string = 'last week';
  protected devicesSyncs: string = '';
  protected totalDevices: string = '';
  protected ringsSold: string = '';
  protected ringsSoldDif: string = '';
  protected men: string = '';
  protected menPerc: string = '';
  protected women: string = '';
  protected womenPerc: string = '';
  protected appVersion: string = '';
  protected mostUsed: string = '';
  protected avChargeTime: string = '';

  constructor(
    private movanoSvc: MovanoService,
    private darkModeSvc: DarkModeService,
    private dashboardSVC: DashboardTypeService,
    private router: Router,
    private changeDetector: ChangeDetectorRef,
    private sharedService: SharedService) {

    //this.selectDate();
  }

  @Input() calendarData: string = '';

  protected statsToday?: any;
  protected statsLastWeek?: any;
  protected statsLastMonth?: any;
  protected statsLast7Days?: any;
  protected statsYearToDate?: any;
  protected statsCustomRange?: any;


  protected dashboardType: string = 'Users';
  protected statsFront: boolean = true;
  protected darkMode: boolean = false;
  protected innerWidth: any;
  protected loadingData : boolean = false;


  ngOnInit() {
    this.innerWidth = document.documentElement.clientWidth;
    document.body.style.backgroundColor = this.darkMode ? '#0c1d35' : '#f3f9fe';
    this.sharedService.calendarObservable$.subscribe(data => {
      this.updateInfo(data);
    })
    this.initializeWithLastWeekData();
    this.getFooterInfo()
  }

  async initializeWithLastWeekData() {
    this.loadingData = true;
    this.statsLastWeek = await lastValueFrom(this.movanoSvc.getStatsSummaryDevicesLastWeek());

    this.dynamicText = 'last week'; // Update text for last week
    this.totalDevices = this.statsLastWeek.data.total_devices.total;
    this.devicesSyncs = this.statsLastWeek.data.devicesSyncs
    this.ringsSold = this.statsLastWeek.data.sold.count;
    this.ringsSoldDif = this.statsLastWeek.data.sold.dif;
    this.men = this.statsLastWeek.data.sold.gender.man.count;
    this.menPerc = this.statsLastWeek.data.sold.gender.man.percentage;
    this.women = this.statsLastWeek.data.sold.gender.woman.count;
    this.womenPerc = this.statsLastWeek.data.sold.gender.woman.percentage;
    this.appVersion = this.statsLastWeek.data.total_devices.most_used_firmware.name;
    this.mostUsed = this.statsLastWeek.data.total_devices.most_used_app_version.name;
    this.avChargeTime = this.statsLastWeek.data.charging_time.avg_hours
    this.changeDetector.detectChanges();
    this.loadingData = false;
  }

  async updateInfo(data:any) {
    this.loadingData = true
    switch (data) {
      case 'today':
        this.statsToday = await lastValueFrom(this.movanoSvc.getStatsSummaryDevicesToday());
        console.log('RINGSSSSSS', this.statsToday)
        this.dynamicText = 'today';
        this.devicesSyncs = this.statsToday.data.devicesSyncs
        this.totalDevices = this.statsToday.data.total_devices.total;
        this.ringsSold = this.statsToday.data.sold.count;
        this.ringsSoldDif = this.statsToday.data.sold.dif;
        this.men = this.statsToday.data.sold.gender.man.count;
        this.menPerc = this.statsToday.data.sold.gender.man.percentage;
        this.women = this.statsToday.data.sold.gender.woman.count;
        this.womenPerc = this.statsToday.data.sold.gender.woman.percentage;
        this.appVersion = this.statsToday.data.total_devices.most_used_firmware.name;
        this.mostUsed = this.statsToday.data.total_devices.most_used_app_version.name;
        this.avChargeTime = this.statsToday.data.charging_time.avg_hours
        break;
      case 'Last Week':
        this.statsLastWeek = await lastValueFrom(this.movanoSvc.getStatsSummaryDevicesLastWeek());
        this.dynamicText = 'last week';
        this.devicesSyncs = this.statsLastWeek.data.devicesSyncs
        this.totalDevices = this.statsLastWeek.data.total_devices.total;
        this.ringsSold = this.statsLastWeek.data.sold.count;
        this.ringsSoldDif = this.statsLastWeek.data.sold.dif;
        this.men = this.statsLastWeek.data.sold.gender.man.count;
        this.menPerc = this.statsLastWeek.data.sold.gender.man.percentage;
        this.women = this.statsLastWeek.data.sold.gender.woman.count;
        this.womenPerc = this.statsLastWeek.data.sold.gender.woman.percentage;
        this.appVersion = this.statsLastWeek.data.total_devices.most_used_firmware.name;
        this.mostUsed = this.statsLastWeek.data.total_devices.most_used_app_version.name;
        this.avChargeTime = this.statsLastWeek.data.charging_time.avg_hours
        break;
      case 'Last Month':
        this.statsLastMonth = await lastValueFrom(this.movanoSvc.getStatsSummaryDevicesLastMonth());
        this.dynamicText = 'last month';
        this.devicesSyncs = this.statsLastMonth.data.devicesSyncs
        this.totalDevices = this.statsLastMonth.data.total_devices.total;
        this.ringsSold = this.statsLastMonth.data.sold.count;
        this.ringsSoldDif = this.statsLastMonth.data.sold.dif;
        this.men = this.statsLastMonth.data.sold.gender.man.count;
        this.menPerc = this.statsLastMonth.data.sold.gender.man.percentage;
        this.women = this.statsLastMonth.data.sold.gender.woman.count;
        this.womenPerc = this.statsLastMonth.data.sold.gender.woman.percentage;
        this.appVersion = this.statsLastMonth.data.total_devices.most_used_firmware.name;
        this.mostUsed = this.statsLastMonth.data.total_devices.most_used_app_version.name;
        this.avChargeTime = this.statsLastMonth.data.charging_time.avg_hours
        break;
      case 'Last 7 days':
        this.statsLast7Days = await lastValueFrom(this.movanoSvc.getStatsSummaryDevicesLast7Days());
        this.dynamicText = 'last 7 days';
        this.devicesSyncs = this.statsLast7Days.data.devicesSyncs
        this.totalDevices = this.statsLast7Days.data.total_devices.total;
        this.ringsSold = this.statsLast7Days.data.sold.count;
        this.ringsSoldDif = this.statsLast7Days.data.sold.dif;
        this.men = this.statsLast7Days.data.sold.gender.man.count;
        this.menPerc = this.statsLast7Days.data.sold.gender.man.percentage;
        this.women = this.statsLast7Days.data.sold.gender.woman.count;
        this.womenPerc = this.statsLast7Days.data.sold.gender.woman.percentage;
        this.appVersion = this.statsLast7Days.data.total_devices.most_used_firmware.name;
        this.mostUsed = this.statsLast7Days.data.total_devices.most_used_app_version.name;
        this.avChargeTime = this.statsLast7Days.data.charging_time.avg_hours
        break;
      case 'Year to date':
        this.statsYearToDate = await lastValueFrom(this.movanoSvc.getStatsSummaryDevicesYearToDate());
        this.dynamicText = 'year to date';
        this.devicesSyncs = this.statsYearToDate.data.devicesSyncs
        this.totalDevices = this.statsYearToDate.data.total_devices.total;
        this.ringsSold = this.statsYearToDate.data.sold.count;
        this.ringsSoldDif = this.statsYearToDate.data.sold.dif;
        this.men = this.statsYearToDate.data.sold.gender.man.count;
        this.menPerc = this.statsYearToDate.data.sold.gender.man.percentage;
        this.women = this.statsYearToDate.data.sold.gender.woman.count;
        this.womenPerc = this.statsYearToDate.data.sold.gender.woman.percentage;
        this.appVersion = this.statsYearToDate.data.total_devices.most_used_firmware.name;
        this.mostUsed = this.statsYearToDate.data.total_devices.most_used_app_version.name;
        this.avChargeTime = this.statsYearToDate.data.charging_time.avg_hours
        break;
      case 'customRange':
        // Replace with actual start and end dates
        const startDate = '2024-01-01';
        const endDate = '2024-01-31';
        this.statsCustomRange = await lastValueFrom(this.movanoSvc.getStatsSummaryDevicesCustomDay(startDate, endDate));
        this.dynamicText = 'custom range';
        this.devicesSyncs = this.statsCustomRange.data.devicesSyncs
        this.totalDevices = this.statsCustomRange.data.total_devices.total;
        this.ringsSold = this.statsCustomRange.data.sold.count;
        this.ringsSoldDif = this.statsCustomRange.data.sold.dif;
        this.men = this.statsCustomRange.data.sold.gender.man.count;
        this.menPerc = this.statsCustomRange.data.sold.gender.man.percentage;
        this.women = this.statsCustomRange.data.sold.gender.woman.count;
        this.womenPerc = this.statsCustomRange.data.sold.gender.woman.percentage;
        this.appVersion = this.statsCustomRange.data.total_devices.most_used_firmware.name;
        this.mostUsed = this.statsCustomRange.data.total_devices.most_used_app_version.name;
        this.avChargeTime = this.statsCustomRange.data.charging_time.avg_hours
        break;
      default:
        console.error('Unknown calendarData value:', this.calendarData);
        this.dynamicText = 'unknown';
    }
    this.changeDetector.detectChanges();
    // this.loadingData = false
  }


  protected footerData?: footerInfo;
  /**
   * Retrieves information for the footer.
   * It calls the `getFooterInfo()` method from the `movanoSvc` service and subscribes to the response.
   * If the response is available, it assigns the data to the `footerData` variable.
   * Currently, there are console logs commented out for debugging purposes.
   */
  async getFooterInfo() {
    const footerData = await lastValueFrom(this.movanoSvc.getFooterInfo());
    this.footerData = footerData;
  }

}


