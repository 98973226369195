<!--HISTORY PRODUCTION-->
<div class="movanoHistory movanoHistory_Production"
[ngClass]="{'movanoHistory_Dark' : darkMode}" [ngClass]="{'movanoHistory_Production_Dark' : darkMode}"
*ngIf="historyType === 'production'">
  <div class="movanoHistory_Tittle">
    <span>History productions</span>
    <span>{{Date_Pro.length}} last lots</span>
  </div>
  <div class="movanoHistory_Bars movanoHistory_Bars_Production">
    <div class="movanoHistory_Bars_Bar" *ngFor="let label of Date_Pro; let i = index">
      <div class="movanoHistory_Bars_Bar_Blue"
      [ngStyle]="{'height':((Sold_Pro[i]/(Pro_Pro[i]+Sold_Pro[i]+Dispo_Pro[i]))*100)+'%'}"></div>
      <div class="movanoHistory_Bars_Bar_Red"
      [ngStyle]="{'height':((Dispo_Pro[i]/(Pro_Pro[i]+Sold_Pro[i]+Dispo_Pro[i]))*100)+'%'}"></div>
      <span>{{label | date:'M/d'}}</span>
    </div>
  </div>
</div>

<!--HISTORY SALES-->
<div class="movanoHistory movanoHistory_Sales"
[ngClass]="{'movanoHistory_Dark' : darkMode}" [ngClass]="{'movanoHistory_Sales_Dark' : darkMode}"
*ngIf="historyType === 'sales'">
  <div class="movanoHistory_Tittle">
    <span>History sales</span>
    <span>{{Date_Sales.length}} last months</span>
  </div>
  <div class="movanoHistory_Bars movanoHistory_Bars_Sales">
    <div class="movanoHistory_Bars_Bar" *ngFor="let label of Date_Sales; let i = index">
      <div class="movanoHistory_Bars_Bar_Blue"
      [ngStyle]="{'height':((SoldOther_Sales[i]/(SoldMovano_Sales[i] + SoldOther_Sales[i] + ReMovano_Sales[i]))*100)+'%'}"></div>
      <div class="movanoHistory_Bars_Bar_Red"
      [ngStyle]="{'height':((ReMovano_Sales[i]/(SoldMovano_Sales[i] + SoldOther_Sales[i] + ReMovano_Sales[i]))*100)+'%'}"></div>
      <span>{{label | date:'M/d'}}</span>
    </div>
  </div>
</div>

<!--HISTORY SYNCS-->
<div class="movanoHistory movanoHistory_Syncs"
[ngClass]="{'movanoHistory_Dark' : darkMode}" [ngClass]="{'movanoHistory_Syncs_Dark' : darkMode}"
*ngIf="historyType === 'activity'">
  <div class="movanoHistory_Tittle">
    <span>History syncs</span>
    <span>{{Date_Syncs.length}} last months</span>
  </div>
  <div class="movanoHistory_Bars movanoHistory_Bars_Syncs">
    <div class="movanoHistory_Bars_Bar" *ngFor="let label of Date_Syncs; let i = index">
      <div class="movanoHistory_Bars_Bar_Blue"
      [ngStyle]="{'height':((Sync_Syncs[i]/(Sold_Syncs[i] + Sync_Syncs[i] + Sleep_Syncs[i]))*100)+'%'}"></div>
      <div class="movanoHistory_Bars_Bar_Red"
      [ngStyle]="{'height':((Sleep_Syncs[i]/(Sold_Syncs[i] + Sync_Syncs[i] + Sleep_Syncs[i]))*100)+'%'}"></div>
      <span>{{label | date:'M/d'}}</span>
    </div>
  </div>
</div>

<!--HISTORY INCIDENTS-->
<div class="movanoHistory movanoHistory_Incidents"
[ngClass]="{'movanoHistory_Dark' : darkMode}" [ngClass]="{'movanoHistory_Incidents_Dark' : darkMode}"
*ngIf="historyType === 'incidents'">
  <div class="movanoHistory_Tittle">
    <span>Last month incidents</span>
    <span>{{Date_Incidents.length}} last months</span>
  </div>
  <div class="movanoHistory_Bars movanoHistory_Bars_Incidents">
    <div class="movanoHistory_Bars_Bar" *ngFor="let label of Date_Incidents; let i = index">
      <div class="movanoHistory_Bars_Bar_Blue"
      [ngStyle]="{'height':((Repng_Incidents[i]/(Inc_Incidents[i] + Rep_Incidents[i] + Repng_Incidents[i]))*100)+'%'}"></div>
      <div class="movanoHistory_Bars_Bar_Red"
      [ngStyle]="{'height':((Rep_Incidents[i]/(Inc_Incidents[i] + Rep_Incidents[i] + Repng_Incidents[i]))*100)+'%'}"></div>
      <span>{{label| date:'M/d'}}</span>
    </div>
  </div>
</div>
