import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import * as lit from '../shared/movano-literals';
import { Router } from '@angular/router';
import { DarkModeService } from '../dark-mode.service';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-features-tab',
  templateUrl: './features-tab.component.html',
  styleUrls: ['./features-tab.component.scss']
})
export class FeaturesTabComponent {
  protected LITERALS = lit;
  protected darkMode: boolean = false;
  private darkModeSub: Subscription = new Subscription();
  @Output() closeHimself = new EventEmitter<boolean>();

  constructor(
    private router: Router,
    private darkModeSvc: DarkModeService) { }

  ngOnInit() {
    this.darkModeSub = this.darkModeSvc.getVariable().subscribe((val: any) => {
      this.darkMode = val;
    });
  }

  ngOnDestroy() {
    this.darkModeSub.unsubscribe();
  }
  
  goTo(_place: string) {
    this.router.navigate([_place]);
  }
}
