import { Component, EventEmitter, Input, Output, SimpleChanges, OnChanges, ViewChild } from '@angular/core';
import { FilterService } from 'primeng/api';
import { Table } from 'primeng/table';
import { UserPanelTimeSelectorComponent } from 'src/app/users/panel/time-selector/user-panel-time-selector.component';
import { MovanoService } from 'src/app/movano.service';
import { ntfTable  } from 'src/app/shared/movano-interfaces';
import { lastValueFrom } from 'rxjs';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-users-ntf-list-filter',
  templateUrl: './users-ntf-list-filter.component.html',
  styleUrls: ['./users-ntf-list-filter.component.scss']
})
export class UsersNtfListFilterComponent {

  protected filter_Status : string = 'All Status';
  protected filter_development : boolean = false;
  protected filter_staging : boolean = false;
  protected filter_production : boolean = false;
  protected searchDisplay : boolean = false;
  protected filterDisplay : boolean = false;
  protected nextIsPosible: boolean = false;
  protected loadingData: boolean = false;
  protected daySelected?: Date;
  protected rangeSelected: Date[] = [];
  protected today?: Date;  
  ntfLists: ntfTable [] = [];
  protected filteredUsers: ntfTable [] = [];
  protected actualTimeZone: string = '';
  protected timeOptions: string[] = ['', 'week', 'month', ''];
  isFiltered: boolean = false;
  response: any;
  /**
  0 => today / any day
  1 => week
  2 => month (default)
  3 => range day
  */
  protected selectedDate: number = 2;
  protected timeZones : string[] = []; //UTC, Madrid, California... etc
  protected lastselectedDate : number = 0;
  protected dataAvailable: boolean = false;
  isTimeSelectorDisabled = true; 
  @ViewChild('list_calendar') daySelector !: UserPanelTimeSelectorComponent;
  @Input() table !: Table;
  @Output() getInfo = new EventEmitter<[number, boolean, Date | Date[], string, number]>();
  @Output() ntfListsChanged: EventEmitter<ntfTable []> = new EventEmitter<ntfTable []>();
  @Output() dataAvailableChange = new EventEmitter<boolean>();
  @Output() loadingDataChange = new EventEmitter<boolean>();
  @Input() jobId?: string;
  @Input() name?: string;
  @Input() userData: string ='';


  constructor(private filterService: FilterService, protected movanoService: MovanoService, private dialog: MatDialog, private router: Router, protected dialogRef: MatDialogRef<ntfTable >, ){
    const customFilterName = 'multi-select-in';
    this.filterService.register(customFilterName, (value : any, filter : any): boolean => {
      if (filter == null || filter.length == 0) {
        return true;
      }
      let selected : boolean = false;
      //console.log(filter);
      filter.forEach((element : any) => {
        if(element === value){
          selected = true;
          return;
        }
      });
      return selected;
    });
  }

  ngOnInit(): void {
    this.today = new Date(Date.now());
    this.initializeSwitch();
    //this.emitGetInfo();
   
  }

/*   ngOnChanges(changes: SimpleChanges): void {  
    if (changes['name'] && !changes['name'].firstChange) {
      console.log(`Name changed in child component: ${this.name}`);
      if (this.name) {
        this.getNtfnInsightListByName(this.name);
      } else {
        this.loadData();
      }
    }
  }  */
  

  navigateBack() {
    this.router.navigate([`/notifications/insight-job-executions`]); 
  }

  backToNtf(){
    this.router.navigate([`/notifications/notification-list`]);
  }

  protected closePanel() {
    this.dialogRef.close();
  }


  search(event: any, _row: string, _table: Table) {
    _table.reset();
    _table.filter(event.target.value, _row, 'contains');
    this.isFiltered = true;
  }

  applyFilter() {
    this.table.reset();
    // if (!this.filter_Status) return;
    // if (this.filter_Status == 'reset') return;

    // if()
    // this.table.filter(this.filter_Status, 'status', 'equals');

    console.log(this.filter_development);
    console.log(this.filter_staging);
    console.log(this.filter_production);
    let statusFilter : string[] = [];
    if(this.filter_development) statusFilter.push('dev');
    if(this.filter_staging) statusFilter.push('staging'); 
    if(this.filter_production)  statusFilter.push('prod');

    this.table.filter(statusFilter, 'status', 'multi-select-in');
  }
  /**
   * Advances to the next date based on the selected date range.
   * Updates properties and calls the getInfo function to fetch data for the new date.
   */
  nextDate() {
    // If next date is not possible, exit the function
    if (!this.nextIsPosible) return;
    // Depending on the selected date range, advance to the next day, week, or month
    switch (+this.selectedDate) {
      case 0: //Next day
      this.selectedDate = 0;
      this.daySelected?.setDate(this.daySelected.getDate() + 1);
      break;
      case 3:
        this.daySelected?.setDate(this.daySelected.getDate() + 1);
        break;
      case 1: //Next week
        this.daySelected?.setDate(this.daySelected.getDate() + 7);
        break;
      case 2: //Next month
        this.daySelected?.setMonth(this.daySelected.getMonth() + 1);
        break;
    }
    console.log('Next date:', this.daySelected);
    // Update the input field in the day selector component
    this.daySelector.updateDaySelected(this.daySelected!, this.timeZones!, this.selectedDate);

    // Call the getInfo function to update data with the new date
    this.emitGetInfo();
  }

  emitGetInfo(calendarData?: [any, boolean, Date | Date[], string, any]): void {
    this.getInfo.emit(calendarData);
    this.loadData(calendarData);
  }

  async initializeSwitch() {
    let response : any
    this.loadingData = true;
    this.dataAvailable = true
    this.dataAvailableChange.emit(this.dataAvailable)
    this.loadingDataChange.emit(this.loadingData);
    if (this.isTimeSelectorDisabled) {
      try {
        response = await lastValueFrom(
          this.movanoService.getNtfnAllByUser(this.userData)
        );
        this.ntfLists = response.data;
        this.ntfListsChanged.emit(this.ntfLists);

        this.dataAvailable = this.ntfLists.length > 0;
        this.dataAvailableChange.emit(this.dataAvailable);
        this.ntfListsChanged.emit(this.ntfLists);
        this.loadingData = true;
        this.loadingDataChange.emit(this.loadingData);
  
        this.dataAvailable = this.ntfLists.length > 0;
        this.dataAvailableChange.emit(this.dataAvailable);
        this.dataAvailable = true;
        console.log('API Response:', response.data);
      } catch (error) {
        console.error('API call failed:', error);
        this.dataAvailable = false;
        this.ntfLists = [];
        this.dataAvailableChange.emit(this.dataAvailable);
      }
      finally {
        this.loadingData = false;
        this.loadingDataChange.emit(this.loadingData);
      }
    }
  }


  async onSwitchToggle(event: any) {
    const isChecked = event.target.checked;
    this.isTimeSelectorDisabled = isChecked;
    let response : any
    if (isChecked) {
      try {
         response = await lastValueFrom(
          this.movanoService.getNtfnAllByUser(this.userData)
        );
        this.ntfLists = response.data;
        this.ntfListsChanged.emit(this.ntfLists);
        this.dataAvailable = this.ntfLists.length > 0;
        this.dataAvailableChange.emit(this.dataAvailable);
        this.dataAvailable = true;
        console.log('API Response:', response.data);
      } catch (error) {
        console.error('API call failed:', error);
        this.dataAvailable = false;
      this.ntfLists = [];
      this.dataAvailableChange.emit(this.dataAvailable);
      }
    } else {
      await this.loadData();
    }
  }

  async loadData(calendarData?: [any, boolean, Date | Date[], string, any]) {

    let dateStart: Date | undefined;
    let dateEnd: Date | undefined;
    let timeFrame: string | undefined = '';
    let res: any 


    this.loadingData = true;
    this.loadingDataChange.emit(this.loadingData);

    try {
      if (!calendarData) {
        this.selectedDate = 2; 
      } else {
        console.log(calendarData);
        this.lastselectedDate = this.selectedDate;
        this.selectedDate = parseInt(calendarData[0]);
        this.nextIsPosible = calendarData[1];
        if (this.selectedDate !== 3) {
          this.daySelected = calendarData[2] as Date;
        } else {
          this.rangeSelected = calendarData[2] as Date[];
        }
        this.actualTimeZone = calendarData[3];
      }
  
      this.dataAvailable = true;
      this.dataAvailableChange.emit(this.dataAvailable);
      this.loadingData = true;
      this.loadingDataChange.emit(this.loadingData);
  
     
      if (this.selectedDate === 0) {
        // Handle "today" or any custom day
        timeFrame = 'today' 
      
      } else if (this.selectedDate === 1) {
        // Handle "last week"
        timeFrame = 'lastWeek'
      } else if (this.selectedDate === 2) {
        // Handle " last month"
        timeFrame = 'lastMonth'
       
      } else if (this.selectedDate === 3) {
        // Handle "range"
        dateStart = this.rangeSelected[0];
        dateEnd = this.rangeSelected[1];
      }
      else if (this.selectedDate === 4) {
        // Handle "last 7 days"
        timeFrame = 'last7Days'
      }
      else if (this.selectedDate === 5)
      {
        timeFrame = 'actualYear'
      }
    

      if (this.userData) {
        console.log(this.userData);

        
       res = await lastValueFrom(
          this.movanoService.getNtfnListByUser(this.userData, dateStart, dateEnd, timeFrame)
        );
        console.log('API Response:', res.data);
  
        this.ntfLists = res.data;
        this.ntfListsChanged.emit(this.ntfLists);
  
        this.dataAvailable = this.ntfLists.length > 0;
        this.dataAvailableChange.emit(this.dataAvailable);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
  
      this.dataAvailable = false;
      this.ntfLists = [];
      this.dataAvailableChange.emit(this.dataAvailable);
    } finally {
      this.loadingData = false;
      this.loadingDataChange.emit(this.loadingData);
    }
  }
    
  

  
  /**
   * Goes to the previous date based on the selected date range.
   * Updates properties and calls the getInfo function to fetch data for the new date.
   */
  previusDate() {
    // Depending on the selected date range, go to the previous day, week, or month
    switch (+this.selectedDate) {
      case 0: //Previus day
        this.selectedDate = 0;
        this.daySelected?.setDate(this.daySelected.getDate() - 1);
        break;
      case 3:
        this.daySelected?.setDate(this.daySelected.getDate() - 1);
        break;
      case 1: //Previus week
        this.daySelected?.setDate(this.daySelected.getDate() - 7);
        break;
      case 2: //Previus month
        this.daySelected?.setMonth(this.daySelected.getMonth() - 1);
        break;
    }
    console.log('Previous date:', this.daySelected);
    // Update the input field in the day selector component
    this.daySelector.updateDaySelected(this.daySelected!, this.timeZones!, this.selectedDate);

    // Call the getInfo function to update data with the new date
    this.emitGetInfo();
  }


  onResize() {
    const checkboxDivs = document.querySelectorAll('.movano_checkBox') as NodeListOf<HTMLElement>;
    const checkbox = document.querySelectorAll('mat-checkbox') as NodeListOf<HTMLElement>;
    const windowWidth = window.innerWidth;
    const checkboxSize = Math.max(windowWidth / 1440, 1) * 0.85;

    checkboxDivs.forEach((cb: HTMLElement) => {
      cb.style.width = `calc(50px * ${checkboxSize})`;
    });

    //console.log(checkbox);
    checkbox.forEach((cb: HTMLElement) => {
      cb.style.transform = `scale(${checkboxSize})`;
    });
  }
}

