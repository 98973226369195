<div class="dashboardHeader" [ngClass]="{'movanoDark': darkMode}">
  <div class="dashboardHeader_Tittle">
    <svg class="dashboardHeader_Tittle_btn" (click)="menuDisplay = true" width="max(1.34vw, 20px)"
      height="max(1.11vw, 16px)" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 8L18.375 8" stroke="#284767" stroke-width="2" stroke-linecap="round" />
      <path d="M0 1L18.375 0.999998" stroke="#284767" stroke-width="2" stroke-linecap="round" />
      <path d="M0 15H18" stroke="#284767" stroke-width="2" stroke-linecap="round" />
    </svg>

    <span class="dashboardHeader_Tittle_txt">{{LITERALS.DASHBOARD.TITTLE}}</span>
    <button class="dashboardHeader_pauseButton" (click)="pauseAllWidgets()">
      <svg *ngIf="!pauseAll" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_691_721)">
          <path
            d="M11 1.83337C5.94004 1.83337 1.83337 5.94004 1.83337 11C1.83337 16.06 5.94004 20.1667 11 20.1667C16.06 20.1667 20.1667 16.06 20.1667 11C20.1667 5.94004 16.06 1.83337 11 1.83337ZM10.0834 14.6667H8.25004V7.33337H10.0834V14.6667ZM13.75 14.6667H11.9167V7.33337H13.75V14.6667Z"
            [style.fill]="(darkMode) ? '#D1F5FF' : '#284767'" />
        </g>
        <defs>
          <clipPath id="clip0_691_721">
            <rect width="22" height="22" fill="white" />
          </clipPath>
        </defs>
      </svg>
      <svg *ngIf="pauseAll" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_691_708)">
          <path
            d="M11 1.83337C5.94004 1.83337 1.83337 5.94004 1.83337 11C1.83337 16.06 5.94004 20.1667 11 20.1667C16.06 20.1667 20.1667 16.06 20.1667 11C20.1667 5.94004 16.06 1.83337 11 1.83337ZM9.16671 15.125V6.87504L14.6667 11L9.16671 15.125Z"
            [style.fill]="(darkMode) ? '#D1F5FF' : '#284767'"  />
        </g>
        <defs>
          <clipPath id="clip0_691_708">
            <rect width="22" height="22" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </button>
    <!-- <div class="dashboardHeader_nextLast">
      <button>
        <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M20 8.75L4.7875 8.75L11.775 1.7625L10 1.74846e-06L-1.74846e-06 10L10 20L11.7625 18.2375L4.7875 11.25L20 11.25L20 8.75Z"
            [style.fill]="'#0B84FF'" />
        </svg>
      </button>
      <button>
        <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M7.64949e-07 11.25L15.2125 11.25L8.225 18.2375L10 20L20 10L10 -8.74228e-07L8.2375 1.7625L15.2125 8.75L9.83506e-07 8.75L7.64949e-07 11.25Z"
            [style.fill]="'#0B84FF'"/>
        </svg>
      </button>
    </div> -->
  </div>
  <div class="dashboardHeader_Logo">
    <img class="dashboardHeader_Logo_IMG"
      src="{{darkMode ? LITERALS.MOVANO.IMG_HEADER_DARK : LITERALS.MOVANO.IMG_HEADER}}">
  </div>
  <div class="dashboardHeader_Functions">
    <!------------------------------------------------------------------------------------------------------->
    <!-- DON'T DELETE!! Probably this will come back when they find out the problems of making more clicks -->
    <!-- <div class="movano_switch" style="position: relative;">
      <label class="switch">
        <input [checked]="darkMode" type="checkbox" (change)="changeTheme()">
        <span class="slider round"></span>
      </label>
    </div>
    <svg class="movanoHeader_Functions_Icon" width="max(0.764vw, 11px)" height="max(1.18vw, 17px)" viewBox="0 0 11 17"
      fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.77761 8.76924C5.77761 5.86289 7.56864 3.37988 10.1005 2.36225C10.825 2.06917 10.7111 0.986418 9.94582 0.815457C9.0503 0.620072 8.0978 0.571226 7.11274 0.701482C3.44114 1.18994 0.502227 4.21026 0.119599 7.89001C-0.385145 12.7828 3.433 16.9103 8.21992 16.9103C8.81421 16.9103 9.38408 16.8451 9.94582 16.723C10.7192 16.5521 10.8413 15.4774 10.1086 15.1762C7.48723 14.126 5.76947 11.586 5.77761 8.76924Z"
        [ngStyle]="{fill : darkMode ? '#FFFFFF' : '#054280', boxShadow: darkMode ? '0px 0px  5px rgba(255, 255, 255, 0.5)' : 'none'}" />
    </svg> -->
    <!-- DON'T DELETE!! Probably this will come back when they find out the problems of making more clicks -->
    <!------------------------------------------------------------------------------------------------------->
    <app-stats-calendar style="margin-top: 20px;" #list_calendar ></app-stats-calendar>

<!--     <mat-form-field>
      <img [src]="'../../assets/calendar_icons/' + optionsOrder[selectedDate] + (darkMode ? '_Dark.svg' : '.svg')"
        alt="Calendar icon">
      <mat-select (selectionChange)="changeDateOption()" [(ngModel)]="selectedDate"
        placeholder="{{(selectedDate != 3) ? optionsOrder[selectedDate] + ' view' : 'Date range'}}"
        panelClass="movano-select" [disableOptionCentering]="false">
        <mat-select-trigger>{{(optionsOrder[selectedDate] != "Date_range" ? optionsOrder[selectedDate] + ' view' : 'Date range' )}}</mat-select-trigger>
        <mat-option *ngFor="let opt of dateOptionFixed(); let i = index"  [value]="optionsOrder.indexOf(opt)" >
          <img [src]="'../../assets/calendar_icons/' + opt + (darkMode ? '_Dark.svg' : '.svg')">
          {{(opt != "Date_range") ? opt + ' view' : 'Date range' }}
        </mat-option>
      </mat-select>

      <svg style="transform: rotate(0deg); position: absolute; bottom: 42%; right:0.84vw" matSuffix
        width="max(0.555vw, 8px)" height="max(0.4166vw, 6px)" viewBox="0 0 8 6" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7.35199 -2.83255e-08L0.648012 -3.21366e-07C0.136801 -3.43711e-07 -0.173808 0.514539 0.104446 0.910793L3.45643 5.72499C3.7088 6.09167 4.2912 6.09167 4.55004 5.72499L7.89555 0.910793C8.17381 0.514539 7.8632 -5.97973e-09 7.35199 -2.83255e-08Z"
          fill="#284767" />
      </svg>
    </mat-form-field> -->

    <!--
      selectedDate gives you type of data:
      Week  -> 0
      Month -> 1
      Year  -> 2
      Range -> 3
    -->
<!--     <mat-form-field class="last_MatFormField" [ngClass]="{'last_MatFormField_Calendar' : (dateOpt == 2 || selectedDate == 3)}">
      <mat-select (selectionChange)="selectDate()" [(ngModel)]="dateOpt" placeholder="Monthly view"
        panelClass="movano-select" [disableOptionCentering]="false">
        <div [ngStyle]="{position: 'relative', height: (dateOpt === 2 || selectedDate == 3) ? '270px' : 'unset'}">
          <mat-option [value]="0">{{dateOptions.get(optionsOrder[selectedDate])![0]}}</mat-option>
          <mat-option [value]="1">{{dateOptions.get(optionsOrder[selectedDate])![1]}}</mat-option>
          <div *ngIf="selectedDate !== 0"  (click)="dateOpt = 2;" class="movano-select_fakeOption">{{dateOptions.get(optionsOrder[selectedDate])![2]}}</div>
          <div *ngIf="dateOpt == 2 || selectedDate == 3" style="position: absolute; top: 0px; width: 100%;">
            <p-calendar
            *ngIf="selectedDate != 3"
            class="inside-calendar"
            #dayCalendar
            [inline]="true"
            [view]="(selectedDate == 1) ? 'month' : (selectedDate == 2) ? 'year' : 'date'"
            (onSelect)="selectDate()"
            [(ngModel)]="selectedDay"
              dateFormat="d MM ‘y">
              <ng-template pTemplate="date" let-date>
                <div [class]="calendarClasss(date.year, date.month, date.day)"></div>
                  {{date.day}}
              </ng-template>
            </p-calendar>
            <p-calendar
            *ngIf="selectedDate === 3"
            class="inside-calendar"
            #dayCalendar
            [inline]="true"
            selectionMode="range"
            (onSelect)="updateInfo()"
            [(ngModel)]=" rangeDaySelected"
              dateFormat="d MM ‘y">
              <ng-template pTemplate="date" let-date>
                <div [class]="calendarClasss(date.year, date.month, date.day)"></div>
                  {{date.day}}
              </ng-template>
            </p-calendar>
          </div>
        </div>
      </mat-select>
      <svg style="transform: rotate(0deg); position: absolute; bottom: 42%; right:0.84vw" matSuffix
        width="max(0.555vw, 8px)" height="max(0.4166vw, 6px)" viewBox="0 0 8 6" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7.35199 -2.83255e-08L0.648012 -3.21366e-07C0.136801 -3.43711e-07 -0.173808 0.514539 0.104446 0.910793L3.45643 5.72499C3.7088 6.09167 4.2912 6.09167 4.55004 5.72499L7.89555 0.910793C8.17381 0.514539 7.8632 -5.97973e-09 7.35199 -2.83255e-08Z"
          fill="#284767" />
      </svg>
    </mat-form-field> -->

    <div (click)="userDisplay = true" class="dashboardHeader_userIcon">
      <svg width="max(1.18vw, 17px)" height="max(1.32vw,  19px)" viewBox="0 0 17 19" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M8.5 7.91667C10.6327 7.91667 12.3482 6.14333 12.3482 3.95833C12.3482 1.77333 10.6327 0 8.5 0C6.36727 0 4.63636 1.77333 4.63636 3.95833C4.63636 6.14333 6.36727 7.91667 8.5 7.91667ZM8.5 11.0833C5.67182 11.0833 0 12.54 0 15.4375V17.4167C0 18.2875 0.695454 19 1.54545 19H15.4545C16.3045 19 17 18.2875 17 17.4167V15.4375C17 12.54 11.3282 11.0833 8.5 11.0833Z" />
      </svg>
    </div>
  </div>
</div>
<div class="movanoHeaderMovil" [ngClass]="{'movanoDark': darkMode}">
  <div class="movanoHeader_Tittle">
    <svg class="movanoHeader_Tittle_btn" (click)="menuDisplay = true" width="18px" height="18px" viewBox="0 0 18 18"
      fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Group 567">
        <path id="Vector 19" d="M0 6.5L14.4375 6.5" stroke="#284767" stroke-width="1.5" stroke-linecap="round" />
        <path id="Vector 21" d="M0 1L14.4375 0.999999" stroke="#284767" stroke-width="1.5" stroke-linecap="round" />
        <path id="Vector 20" d="M0 12L8.25 12" stroke="#284767" stroke-width="1.5" stroke-linecap="round" />
      </g>
    </svg>
    <img class="movanoHeader_Logo_IMG"
      src="{{darkMode ? LITERALS.MOVANO.IMG_HEADER_DARK : LITERALS.MOVANO.IMG_HEADER}}">
    <span class="movanoHeader_Version">v{{LITERALS.MOVANO.VERSION}}</span>
    <mat-form-field>
      <mat-select (selectionChange)="updateInfo()" [(ngModel)]="selectedDate" placeholder="Last Month"
        panelClass="movano-select" [disableOptionCentering]="true">
        <mat-option value="1">{{LITERALS.MOVANO.TIME_OPT02}}</mat-option>
        <mat-option value="3">{{LITERALS.MOVANO.TIME_OPT03}}</mat-option>
        <mat-option value="6">{{LITERALS.MOVANO.TIME_OPT04}}</mat-option>
      </mat-select>
      <svg matSuffix viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7.35199 -2.83255e-08L0.648012 -3.21366e-07C0.136801 -3.43711e-07 -0.173808 0.514539 0.104446 0.910793L3.45643 5.72499C3.7088 6.09167 4.2912 6.09167 4.55004 5.72499L7.89555 0.910793C8.17381 0.514539 7.8632 -5.97973e-09 7.35199 -2.83255e-08Z"
          fill="#284767" />
      </svg>
    </mat-form-field>

    <div (click)="userDisplay = true" class="movanoHeader_userIcon">
      <svg width="max(1.18vw, 17px)" height="max(1.32vw, 19px)" viewBox="0 0 17 19" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M8.5 7.91667C10.6327 7.91667 12.3482 6.14333 12.3482 3.95833C12.3482 1.77333 10.6327 0 8.5 0C6.36727 0 4.63636 1.77333 4.63636 3.95833C4.63636 6.14333 6.36727 7.91667 8.5 7.91667ZM8.5 11.0833C5.67182 11.0833 0 12.54 0 15.4375V17.4167C0 18.2875 0.695454 19 1.54545 19H15.4545C16.3045 19 17 18.2875 17 17.4167V15.4375C17 12.54 11.3282 11.0833 8.5 11.0833Z" />
      </svg>
    </div>
  </div>
  <div class="movanoHeader_Tittle">
    <span class="movanoHeader_Tittle_txt">{{LITERALS.DASHBOARD.TITTLE}}</span>
    <button class="movanoHeader_pauseButton" (click)="pauseAllWidgets()">
      <svg *ngIf="!pauseAll" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_691_721)">
          <path
            d="M11 1.83337C5.94004 1.83337 1.83337 5.94004 1.83337 11C1.83337 16.06 5.94004 20.1667 11 20.1667C16.06 20.1667 20.1667 16.06 20.1667 11C20.1667 5.94004 16.06 1.83337 11 1.83337ZM10.0834 14.6667H8.25004V7.33337H10.0834V14.6667ZM13.75 14.6667H11.9167V7.33337H13.75V14.6667Z"
            [style.fill]="(darkMode) ? '#D1F5FF' : '#284767'"  />
        </g>
        <defs>
          <clipPath id="clip0_691_721">
            <rect width="22" height="22" fill="white" />
          </clipPath>
        </defs>
      </svg>
      <svg *ngIf="pauseAll" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_691_708)">
          <path
            d="M11 1.83337C5.94004 1.83337 1.83337 5.94004 1.83337 11C1.83337 16.06 5.94004 20.1667 11 20.1667C16.06 20.1667 20.1667 16.06 20.1667 11C20.1667 5.94004 16.06 1.83337 11 1.83337ZM9.16671 15.125V6.87504L14.6667 11L9.16671 15.125Z"
            [style.fill]="(darkMode) ? '#D1F5FF' : '#284767'"  />
        </g>
        <defs>
          <clipPath id="clip0_691_708">
            <rect width="22" height="22" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </button>
  </div>
</div>
<div class="movanoDashboard" [ngClass]="{'movanoDark': darkMode}">

  <div *ngIf="
  monthData && barSideData && footerData"
    class="movanoDashboard_Components">
    <div class="movanoDashboard_Components_TotalCarousel">
      <!-- pass to total info -->
      <app-movano-tota-info [darkMode]="darkMode" [produced]="monthData.totalRingProduced"
        [sold]="monthData.totalRingSold"></app-movano-tota-info>
      <app-movano-info-carousel [fontSize]="(this.innerWidth > 1024) ? 40 : 30"
        [totalWidth]="(this.innerWidth > 1024) ? 390 : 340" [darkMode]="darkMode"
        [monthData]="monthData"></app-movano-info-carousel>
    </div>
    <div class="movanoDashboard_Components_Middle">
      <div style="position: relative;">
        <app-movano-bar-chart [darkMode]="darkMode" [selectedDate]="selectedDate"
          [paused]="mapChartPaused" (changeComponent)="nextDashboardType($event)"
          (pauseChart)="pauseMapChart()"></app-movano-bar-chart>
        <!-- <app-movano-map *ngIf="!dashboardState && actualDashboardType === 0" [darkMode]="darkMode"
          [infotype]="actualDashboardType" [mapData]="salesAndProductionCarrouselData.mapChart"
          (changeComponent)="nextDashboardType($event)" [paused]="mapChartPaused"
          (pauseMap)="pauseMapChart()"></app-movano-map>
        <app-movano-map *ngIf="!dashboardState && actualDashboardType === 1" [darkMode]="darkMode"
          [infotype]="actualDashboardType" [mapData]="syncAndSleepCarrouselData.mapChart"
          (changeComponent)="nextDashboardType($event)" [paused]="mapChartPaused"
          (pauseMap)="pauseMapChart()"></app-movano-map>
        <app-movano-map *ngIf="!dashboardState && actualDashboardType === 2" [darkMode]="darkMode"
          [infotype]="actualDashboardType" [mapData]="incidentCarrouselData.mapChart"
          (changeComponent)="nextDashboardType($event)" [paused]="mapChartPaused"
          (pauseMap)="pauseMapChart()"></app-movano-map> -->
      <!--<div class="movanoDashboard_Components_Middle_last">
         <app-movano-history *ngIf="!dashboardState" [darkMode]="darkMode" [historyType]="'production'"
          [productionData]="salesAndProductionCarrouselData" [incidentsData]="incidentCarrouselData"
          [syncsData]="syncAndSleepCarrouselData"></app-movano-history> -->
<!--         <app-movano-last-compt [darkMode]="darkMode" [production]="true" [data]="barSideData.sideGraph"></app-movano-last-compt>
          <app-movano-last-compt [darkMode]="darkMode" [data]="barSideData.sideGraph" ></app-movano-last-compt> -->
        <!-- <app-movano-history *ngIf="!dashboardState" [darkMode]="darkMode"
          [historyType]=dashboardTypes[actualDashboardType] [productionData]="salesAndProductionCarrouselData"
          [incidentsData]="incidentCarrouselData" [syncsData]="syncAndSleepCarrouselData"></app-movano-history> -->
      </div>
    </div>
    <div class="movanoDashboard_Components_ActivityStats">
      <app-movano-stats [darkMode]="darkMode" [footerData]="footerData" [front]="statsFront"
        (pauseStats)="pauseStats()">></app-movano-stats>
      <app-movano-activity [darkMode]="darkMode" [footerData]="footerData" [front]="activityFront"
        (pauseActivity)="pauseActivity()"></app-movano-activity>
    </div>
  </div>
  <div
    *ngIf="!monthData || !barSideData || !footerData"
    style="position: absolute; top: 50%; left: 50%;">
    <p-progressSpinner [style]="{width: '6vw', height: '6vw'}"
      styleClass="{{darkMode ? 'custom-spinner' : 'custom-spinner-blue'}}" strokeWidth="2" animationDuration="1.5s">
    </p-progressSpinner>
  </div>
</div>

<p-sidebar styleClass="menu-left" [modal]="false" [(visible)]="menuDisplay" position="left">
  <app-features-tab (closeHimself)="menuDisplay = false"></app-features-tab>
</p-sidebar>

<p-sidebar styleClass="user-menu" [(visible)]="userDisplay" position="right">
  <app-user-menu (closeHimself)="userDisplay = false"></app-user-menu>
</p-sidebar>
