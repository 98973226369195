import { Component, ElementRef, HostListener, Inject, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EChartsOption } from 'echarts';
import { Calendar } from 'primeng/calendar';
import { delay, firstValueFrom, lastValueFrom, of } from 'rxjs';
import { MovanoService } from 'src/app/movano.service';
import * as echarts from 'echarts';
import { SECONDS_IN_A_DAY } from 'src/app/shared/constants';
import { waveForm_Data_Measures, waveForm_Data_Leds, waveForm_Data_Leds_eChartFix, waveForm_Data_Accel, waveForm_Data_Accel_eChartFix, waformData_Data } from 'src/app/shared/movano-interfaces';
import { fixPixelsBy1440, getDayArrrayAccel, getDayArrrayLed, getDayArrraySimple, getDayArrraySimpleIV } from 'src/app/shared/utils';

@Component({
  selector: 'app-user-panel-waveform-data',
  templateUrl: './user-panel-waveform-data.component.html',
  styleUrls: ['./user-panel-waveform-data.component.scss']
})
export class UserPanelWaveformDataComponent implements OnInit {

  protected timeArray: string[] = [];
  protected today: Date;
  protected daySelected?: Date;
  protected utc: boolean = false;
  protected timeZones?: string[];
  protected actualTimeZone : string = '';
  protected moment = require('moment-timezone');
  protected dataColors: string[] = ['#1D5C9C', '#0B84FF', '#6FF2F1', '#346594', '#180BD8', '#392E57', '#C50000', '#C50000'];
  protected labels: string[] = ['Batt', 'Temp1', 'Temp0', 'step', 'pkt stored', 'age', 'tick', 'pkt num'];

  protected loading: boolean = false; //Flag that indicates whether data loading is in progress.
  protected itsTimeToStop: boolean = false; // Signals that it's time to stop API data loading process.
  protected nextIsPosible: boolean = false; //Indicates whether it's possible to load data for the next day. Today => false

  protected maxZoom: number = 100;
  protected minZoom: number = 0;
  protected syncZoom: boolean = false;

  measures_options: EChartsOption[] = [];

  protected measuresData: ("-" | number)[][] = [];
  protected measuresDataPrueba: number[][][] = [];
  protected ledData?: waveForm_Data_Leds_eChartFix;
  protected AccelData?: waveForm_Data_Accel_eChartFix;
  protected innerWidth: number = 1440;
  protected blocksLoaded: number = 1;

  protected secondsXaxys: number[] = Array.from({ length: SECONDS_IN_A_DAY }, (_, index) => index); //[0,1,2,3,...,45051,45052,...,86400]

  protected barLabels: string[] = ['Loading...', 'Batt/Temp/BR/Step...', 'Pkt Stored/Age/Tick/Pkt Num...', 'Led Ir & Red...', 'x, y, z...', 'server functions...', 'Drawing Charts...']


  @Input() lastDaySelected?: Date;

  @ViewChild('dayCalendar') daySelector !: Calendar;
  @ViewChild('waveFormDataBlocks', { static: true }) waveFormDataBlocks?: ElementRef;

  constructor(private movanoService: MovanoService, private renderer: Renderer2,
    @Inject(MAT_DIALOG_DATA) protected data: { user: string }) {
      const righNow = new Date(Date.now());
      this.today = new Date(righNow.getFullYear(), righNow.getMonth(), righNow.getDate());
    }

  async ngOnInit() {
    //console.log(this.lastDaySelected);
    this.daySelected = this.lastDaySelected ?? new Date(Date.now());
    this.startGatheringBlocks();
  }

  //ON DESTROY
  ngOnDestroy() {
    this.itsTimeToStop = true;
  }

  @HostListener('window:beforeunload', ['$event'])
  handleBeforeUnload(event: any) {
    this.itsTimeToStop = true;
  }

  @HostListener('window:resize', ['$event']) //Event to know the window width
  onResize(event: any) {
    this.innerWidth = document.documentElement.clientWidth;
    this.measures_options = [];
    this.measureChartUpdate(true);
  }

  /**
   * Advances to the next day.
   * Updates properties and calls the getInfo function to fetch data for the new date.
   */
  nextDate() {
    // If next day is tomorrow, exit the function
    if (!this.nextIsPosible) return;

    //Advances to the next day.
    this.daySelected?.setDate(this.daySelected.getDate() + 1);

    // Update the input field in the day selector component
    this.daySelector.updateInputfield();

    // Call the getInfo function to update data with the new date
    this.startGatheringBlocks();
  }

  /**
   * Goes to the previous day
   * Updates properties and calls the getInfo function to fetch data for the new date.
   */
  previusDate() {
    //Goes to the previous day
    this.daySelected?.setDate(this.daySelected.getDate() - 1);

    // Update the input field in the day selector component
    this.daySelector.updateInputfield();

    // Call the getInfo function to update data with the new date
    this.startGatheringBlocks();
  }

  /**
   * Initiates the process of gathering data blocks.
   * If the loading flag is set, it cancels the current operation and schedules a retry after 1 second.
   * Otherwise, it clears charts, resets data, and starts loading data for the selected day.
   */
  startGatheringBlocks() {
    if (this.loading) {
      // If currently loading, stop the operation and schedule a retry 1 second later
      this.itsTimeToStop = true;
      of(null).pipe(delay(1000)).subscribe(async () => {
        await this.startGatheringBlocks();
      });
    } else {
      // If not currently loading, initialize data retrieval
      this.clearCharts();
      this.measuresDataPrueba = [];
      this.loading = true;
      this.itsTimeToStop = false;
      this.measures_options = [];
      this.blocksLoaded = 1;
      this.nextIsPosible = this.daySelected!.getTime() < this.today!.getTime();

      // Determine whether to fetch data for today or a previous day
      this.getInfoToday();
    }
  }


  /**
   * Asynchronously fills measures data with data for a specific day & block.
   *
   * @param block - The block number to fill data for.
   */
  async getInfo(block: number) {
    if (this.itsTimeToStop) {
      // If it's time to stop, reset loading state and exit
      this.loading = false;
      this.itsTimeToStop = false;
      return;
    }
    if (block <= 2) { //Block 3-4 dont have chart desing
      try {
        /**
        * Fetch wave form data for the specified block.
        */
        const wfData = await lastValueFrom(this.movanoService.getWaveFormData(this.data.user, block, 'RETRIEVE', this.daySelected??this.today, this.actualTimeZone));

        if (this.itsTimeToStop) {
          // If it's time to stop, reset loading state and exit
          this.loading = false;
          this.itsTimeToStop = false;
          return;
        }

        if (wfData)
          switch (block) {
            case 1:
              // Process and update chart data for block 1
              this.measuresDataPrueba.push(getDayArrraySimpleIV(wfData.batt_day));
              this.measuresDataPrueba.push(getDayArrraySimpleIV(wfData.temp_day));
              this.measuresDataPrueba.push(getDayArrraySimpleIV(wfData.br_day));
              this.measuresDataPrueba.push(getDayArrraySimpleIV(wfData.step_day));
              this.measureChartUpdate(true);
              break;
            case 2:
              // Process and update chart data for block 2
              this.measuresDataPrueba.push(getDayArrraySimpleIV(wfData.pkt_stored_day));
              this.measuresDataPrueba.push(getDayArrraySimpleIV(wfData.age_sec_day));
              this.measuresDataPrueba.push(getDayArrraySimpleIV(wfData.tick_day));
              this.measuresDataPrueba.push(getDayArrraySimpleIV(wfData.pkt_num_day));
              this.measureChartUpdate(false);
              break;
            case 3:
              // Perform data processing operations for block 3
              this.fixLedsData(wfData.led_ir_day);
              // ... Other operations for block 3
              break;
            case 4:
              // Perform data processing operations for block 4
              this.fixAccelData(wfData.xyz_day);
              // ... Other operations for block 4
              break;
          }
        this.blocksLoaded++;
        if(!this.timeZones){
          this.timeZones = wfData.timezones;
          this.actualTimeZone = this.timeZones![0];
        }
        await this.getInfo(block + 1); // Call the next iteration
      } catch (err: any) {
        if (err.status === 404 || err.status === 202) {
          // Wait for 3 seconds before making the next attempt
          of(null).pipe(delay(3000)).subscribe(() => {
            this.getInfo(block);
          });
        } else if(err.status === 502 ){
          this.loading = false;
          this.measures_options = [];
        }
      }
    } else {
      this.loading = false;
      //console.log(this.measures_options);
      this.instanciateCharts();
    }
  }

  /**
   * Asynchronously fetches and processes today's information.
   */
  async getInfoToday() {
    // Request wave form data for today
    this.movanoService.getWaveFormData(this.data.user, 1, 'REQUEST', this.daySelected??this.today, this.actualTimeZone).subscribe({
      next: async (response) => {
        // When data is received, initiate data processing
        await this.getInfo(1);
      },
      complete: async () => {/* Handle completion if needed (not implemented)*/ },
      error: async e => {
        // Handle errors from the API request
        if (e.status === 404) {
          // Wait for 3 seconds before making the next attempt
          of(null).pipe(delay(3000)).subscribe(() => {
            this.getInfoToday();
          });
        } else if (e.status === 200 || e.status === 202) {
          // When a """successful""" response is received, initiate data processing
          await this.getInfo(1);
        }
      }
    });
  }

  fixMeasuresData(wfMeasureData: waveForm_Data_Measures[]) {
    this.measuresData.push(getDayArrraySimple(wfMeasureData));
  }

  fixLedsData(wfLedData: waveForm_Data_Leds[]) {
    this.ledData = getDayArrrayLed(wfLedData);
  }

  fixAccelData(wfAccelData: waveForm_Data_Accel[]) {
    this.AccelData = getDayArrrayAccel(wfAccelData);
  }


  measureChartUpdate(firstBlock: boolean) {
    for (let index = (firstBlock) ? 0 : 4; index < this.measuresDataPrueba.length; index++) {
      const data = this.measuresDataPrueba[index];
      if (data.length === 0) continue;
      this.measures_options.push({
        tooltip: {
          formatter: (params: any) => {
            function secondsToHHMMSS(totalSeconds: number): string {
              const hours = Math.floor(totalSeconds / 3600);
              const minutes = Math.floor((totalSeconds % 3600) / 60);
              const seconds = totalSeconds % 60;

              const formattedHours = hours.toString().padStart(2, '0');
              const formattedMinutes = minutes.toString().padStart(2, '0');
              const formattedSeconds = seconds.toString().padStart(2, '0');

              return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
            }
            return `
            <div style="display: flex; font-family: Zen Kaku Gothic Antique;">
              <div style="display: flex; flex-direction: column; color: ${this.dataColors[index]}">
                <span style="font-size: max(0.7vw, 10px); font-weight: 500; line-height: 130.4%;">${secondsToHHMMSS(params.data[0])}</span>
                <span style="font-size: max(1.4vw, 20px); font-weight: 700; line-height: 130.4%;">
                  ${Math.round(params.data[1])}
                </span>
              </div>
            </div>`;
          },

          hideDelay: 3000,
          axisPointer: {
            type: 'none',
            label: {
              backgroundColor: '#6a7985'
            }
          }
        },
        toolbox: {
          right: 20,
          feature: {
            dataZoom: {
              yAxisIndex: false,
              onclick: function () { console.log('prueba') }
            },
            restore: {}
          },
        },
        xAxis: {
          type: 'category',
          show: true,
          data: this.secondsXaxys,
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          },
          splitLine: {
            show: true,
            interval: function (index: number, value: string) {
              return index % 21600 === 0;
            },
            lineStyle: {
              color: '#D1F5FF'
            }
          },
          axisLabel: {
            fontWeight: 700,
            fontFamily: 'Zen Kaku Gothic Antique',
            fontSize: 'max(0.7vw, 10px)',
            show: true,
            hideOverlap: true,
            interval: function (index: number, value: string) {
              return index % 21600 === 0;
            },
            formatter: function (value: string, index: number) {
              const timeLabels: string[] = ['00:00:00', '06:00:00', '12:00:00', '18:00:00'];
              return timeLabels[parseInt(value) / 21600];
            },
            color: '#7797B8',
          }
        },
        yAxis: [{
          splitNumber: 2,
          position: 'left',
          offset:fixPixelsBy1440(0, this.innerWidth),
          alignTicks: true,
          animation: false,

          //maxInterval: 1,
          axisLine: {
            show: false,

          },
          axisLabel: {
            inside: true,
            fontWeight: 700,
            fontFamily: 'Zen Kaku Gothic Antique',
            fontSize: 'max(0.7vw, 12px)',
            verticalAlign: 'bottom',
            lineHeight: fixPixelsBy1440(30, this.innerWidth),
            color: this.dataColors[index],
          }
        }],
        animation: false,
        dataZoom: [
          {
            type: 'inside',
            startValue: this.startValueZoom,
            endValue: this.endValueZoom,
            zoomLock: true,
          }
        ],
        title: {
          top: '0%',
          left: 'center',
          text: this.labels[index],
          textStyle: {
            fontWeight: 700,
            fontFamily: 'Zen Kaku Gothic Antique',
            fontSize: 'max(1vw, 14px)',
            color: this.dataColors[index]
          }
        },
        grid: {
          right: '0.5%',
          top: '15%',
          bottom: '25%',
          left: 0,
          containLabel: true,
          height: 'max(35vw, 500px)',
          width: 'auto',
        },
        markArea: [],
        series: [
          {
            symbolSize: fixPixelsBy1440(3, this.innerWidth),
            color: this.dataColors[index],
            dimensions: ['x', 'y'],
            data: data,
            type: 'scatter',
            blendMode: 'source-over',
            large: true,
          }
        ]
      });
    }
  }

  protected charts: echarts.ECharts[] = [];
  protected startValueZoom: number = 0;
  protected endValueZoom: number = SECONDS_IN_A_DAY;
  /**
   * Initializes or reconfigures ECharts charts based on the provided measures options.
   */
  instanciateCharts() {

    this.clearCharts();
    // Iterate through measures options and create or reconfigure charts
    this.measures_options.forEach((data, i) => {
      if (i < this.charts.length) {
        this.charts[i].setOption(this.measures_options[i]);
        return;
      }

      // Create a new chart instance for a new measure option
      const idName = 'WaveFormChart' + i;
      const divElement = this.renderer.createElement('div');

      // Set the ID and additional attributes for the <div> element
      this.renderer.setAttribute(divElement, 'id', idName);
      this.renderer.setAttribute(divElement, 'echarts', ''); //e-chart Angular attributes

      // Set the styles for the <div> element
      this.renderer.setStyle(divElement, 'height', 'max(14vw,200px)');
      this.renderer.setStyle(divElement, 'width', 'auto');

      // Append the <div> element to the ng-template
      this.renderer.appendChild(this.waveFormDataBlocks!.nativeElement, divElement);

      // Initialize a new chart instance and configure it
      const chartNow = echarts.init(document.getElementById(idName) as HTMLElement);
      this.charts.push(chartNow);
      chartNow.setOption(this.measures_options[i]);

      // Attach event handlers for dataZoom event
      chartNow.on('dataZoom', (eventParams: any) => {
        if (!this.syncZoom) return;


        // Extract zoom parameters and perform actions on zoom
        const zoomStart = (eventParams.batch != undefined) ? eventParams.batch[0].startValue : eventParams.start;
        const zoomEnd = (eventParams.batch != undefined) ? eventParams.batch[0].endValue : eventParams.end;

        if(zoomStart === undefined) return;
        if(zoomEnd === undefined) return;

        // Update zoom values and trigger chart updates
        this.startValueZoom = zoomStart;
        this.endValueZoom = zoomEnd;
        this.measures_options = [];
        this.measureChartUpdate(true);
        this.instanciateCharts();
      });

      // Attach event handlers for restore event
      chartNow.on('restore', (eventParams: any) => {
        if (!this.syncZoom) return;
        // Update zoom values to default and trigger chart updates
        this.startValueZoom = 0;
        this.endValueZoom = SECONDS_IN_A_DAY;
        this.measures_options = [];
        this.measureChartUpdate(true);
        this.instanciateCharts();
      });
    });
  }

  /**
   * Clears the chart instances and associated resources.
   */
  clearCharts() {
    // Clear chart instances
    this.charts.forEach(chart => {
      chart.dispose(); // Release resources associated with the instance
    });
    this.charts = []; // Clear the array of instances

    // Clear <div> elements from the DOM
    this.measures_options.forEach((_, i) => {
      const idName = 'WaveFormChart' + i;
      const divElement = document.getElementById(idName);
      if (divElement) {
        divElement.remove(); // Remove the element from the DOM if it exists
      }
    });

    //Reset Zoom
    this.startValueZoom = 0;
    this.endValueZoom = SECONDS_IN_A_DAY;
  }
}
