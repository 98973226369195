import { ChangeDetectorRef, Component } from "@angular/core";
import { SharedService } from "../shared.service";
import { MovanoService } from "src/app/movano.service";
import { lastValueFrom } from "rxjs";

@Component({
  selector: "app-stats-activity",
  templateUrl: "./stats-activity.component.html",
  styleUrls: ["./stats-activity.component.scss"],
})
export class StatsActivityComponent {

  protected maxSpot : string = "";
  protected minSpot : string = "";
  protected avgSpot : string = "";

  protected maxWork : string = "";
  protected minWork : string = "";
  protected avrgWork : string = "";
  protected bestHoursWork : string = "";
  protected perTotalWork : string = "";

  protected maxNotifications : string = "";
  protected minNotifications : string = "";
  protected avgNotifications : string = "";
  protected sendToCostumer : string = "";
  protected notSendToCostumer : string = "";
  protected perNotSendToCostumer : string = "";

  protected statsToday?: any;
  protected statsLastWeek?: any;
  protected statsLastMonth?: any;
  protected statsLast7Days?: any;
  protected statsYearToDate?: any;
  protected statsCustomRange?: any;

  constructor(
    private movanoSvc: MovanoService,
    private changeDetector: ChangeDetectorRef,
    private sharedService: SharedService) {
  }

  ngOnInit() {

    this.sharedService.calendarObservable$.subscribe(data => {
      this.updateInfo(data);
    })
    this.initializeWithLastWeekData();
  }

  async initializeWithLastWeekData() {
    this.statsLastWeek = await lastValueFrom(this.movanoSvc.getUsersActivityLastWeek());

    this.maxSpot = this.statsLastWeek.data.spotChecks.byDate.max.count;
    this.minSpot = this.statsLastWeek.data.spotChecks.byDate.min.count;
    this.avgSpot = this.statsLastWeek.data.spotChecks.avg;

    this.maxWork = this.statsLastWeek.data.workout.byDate.max.count;
    this.minWork = this.statsLastWeek.data.workout.byDate.min.count;
    this.avrgWork = this.statsLastWeek.data.workout.avg;
    this.bestHoursWork = this.formatHourRange(this.statsLastWeek.data.workout.bestHoursRange.hourRange);
    this.perTotalWork = this.statsLastWeek.data.workout.bestHoursRange.percentage;

    this.maxNotifications = this.statsLastWeek.data.notifications.byDate.max.count;
    this.minNotifications = this.statsLastWeek.data.notifications.byDate.min.count;
    this.avgNotifications = this.statsLastWeek.data.notifications.avg;
    this.sendToCostumer = this.statsLastWeek.data.notifications.sentToCustomer.count;
    this.notSendToCostumer = this.statsLastWeek.data.notifications.notSentToCustomer.count;
    this.perNotSendToCostumer = this.statsLastWeek.data.notifications.notSentToCustomer.percentage;
    this.changeDetector.detectChanges();
  }

  async updateInfo(data: any) {
    switch (data) {
      case 'today':
        this.statsToday = await lastValueFrom(this.movanoSvc.getUsersActivityToday());

        this.maxSpot = this.statsToday.data.spotChecks.byDate.max.count;
        this.minSpot = this.statsToday.data.spotChecks.byDate.min.count;
        this.avgSpot = this.statsToday.data.spotChecks.avg;

        this.maxWork = this.statsToday.data.workout.byDate.max.count;
        this.minWork = this.statsToday.data.workout.byDate.min.count;
        this.avrgWork = this.statsToday.data.workout.avg;
        this.bestHoursWork = this.formatHourRange(this.statsToday.data.workout.bestHoursRange.hourRange);
        this.perTotalWork = this.statsToday.data.workout.bestHoursRange.percentage;

        this.maxNotifications = this.statsToday.data.notifications.byDate.max.count;
        this.minNotifications = this.statsToday.data.notifications.byDate.min.count;
        this.avgNotifications = this.statsToday.data.notifications.avg;
        this.sendToCostumer = this.statsToday.data.notifications.sentToCustomer.count;
        this.notSendToCostumer = this.statsToday.data.notifications.notSentToCustomer.count;
        this.perNotSendToCostumer = this.statsToday.data.notifications.notSentToCustomer.percentage;
        break;

      case 'Last Week':
        this.statsLastWeek = await lastValueFrom(this.movanoSvc.getUsersActivityLastWeek());

        this.maxSpot = this.statsLastWeek.data.spotChecks.byDate.max.count;
        this.minSpot = this.statsLastWeek.data.spotChecks.byDate.min.count;
        this.avgSpot = this.statsLastWeek.data.spotChecks.avg;

        this.maxWork = this.statsLastWeek.data.workout.byDate.max.count;
        this.minWork = this.statsLastWeek.data.workout.byDate.min.count;
        this.avrgWork = this.statsLastWeek.data.workout.avg;
        this.bestHoursWork = this.formatHourRange(this.statsLastWeek.data.workout.bestHoursRange.hourRange);
        this.perTotalWork = this.statsLastWeek.data.workout.bestHoursRange.percentage;

        this.maxNotifications = this.statsLastWeek.data.notifications.byDate.max.count;
        this.minNotifications = this.statsLastWeek.data.notifications.byDate.min.count;
        this.avgNotifications = this.statsLastWeek.data.notifications.avg;
        this.sendToCostumer = this.statsLastWeek.data.notifications.sentToCustomer.count;
        this.notSendToCostumer = this.statsLastWeek.data.notifications.notSentToCustomer.count;
        this.perNotSendToCostumer = this.statsLastWeek.data.notifications.notSentToCustomer.percentage;
        break;

      case 'Last Month':
        this.statsLastMonth = await lastValueFrom(this.movanoSvc.getUsersActivityLastMonth());

        this.maxSpot = this.statsLastMonth.data.spotChecks.byDate.max.count;
        this.minSpot = this.statsLastMonth.data.spotChecks.byDate.min.count;
        this.avgSpot = this.statsLastMonth.data.spotChecks.avg;

        this.maxWork = this.statsLastMonth.data.workout.byDate.max.count;
        this.minWork = this.statsLastMonth.data.workout.byDate.min.count;
        this.avrgWork = this.statsLastMonth.data.workout.avg;
        this.bestHoursWork = this.formatHourRange(this.statsLastMonth.data.workout.bestHoursRange.hourRange);
        this.perTotalWork = this.statsLastMonth.data.workout.bestHoursRange.percentage;

        this.maxNotifications = this.statsLastMonth.data.notifications.byDate.max.count;
        this.minNotifications = this.statsLastMonth.data.notifications.byDate.min.count;
        this.avgNotifications = this.statsLastMonth.data.notifications.avg;
        this.sendToCostumer = this.statsLastMonth.data.notifications.sentToCustomer.count;
        this.notSendToCostumer = this.statsLastMonth.data.notifications.notSentToCustomer.count;
        this.perNotSendToCostumer = this.statsLastMonth.data.notifications.notSentToCustomer.percentage;
        break;

      case 'Last 7 days':
        this.statsLast7Days = await lastValueFrom(this.movanoSvc.getUsersActivityLast7Days());

        this.maxSpot = this.statsLast7Days.data.spotChecks.byDate.max.count;
        this.minSpot = this.statsLast7Days.data.spotChecks.byDate.min.count;
        this.avgSpot = this.statsLast7Days.data.spotChecks.avg;

        this.maxWork = this.statsLast7Days.data.workout.byDate.max.count;
        this.minWork = this.statsLast7Days.data.workout.byDate.min.count;
        this.avrgWork = this.statsLast7Days.data.workout.avg;
        this.bestHoursWork = this.formatHourRange(this.statsLast7Days.data.workout.bestHoursRange.hourRange);
        this.perTotalWork = this.statsLast7Days.data.workout.bestHoursRange.percentage;

        this.maxNotifications = this.statsLast7Days.data.notifications.byDate.max.count;
        this.minNotifications = this.statsLast7Days.data.notifications.byDate.min.count;
        this.avgNotifications = this.statsLast7Days.data.notifications.avg;
        this.sendToCostumer = this.statsLast7Days.data.notifications.sentToCustomer.count;
        this.notSendToCostumer = this.statsLast7Days.data.notifications.notSentToCustomer.count;
        this.perNotSendToCostumer = this.statsLast7Days.data.notifications.notSentToCustomer.percentage;
        break;

      case 'Year to date':
        this.statsYearToDate = await lastValueFrom(this.movanoSvc.getUsersActivityYearToDate());

        this.maxSpot = this.statsYearToDate.data.spotChecks.byDate.max.count;
        this.minSpot = this.statsYearToDate.data.spotChecks.byDate.min.count;
        this.avgSpot = this.statsYearToDate.data.spotChecks.avg;

        this.maxWork = this.statsYearToDate.data.workout.byDate.max.count;
        this.minWork = this.statsYearToDate.data.workout.byDate.min.count;
        this.avrgWork = this.statsYearToDate.data.workout.avg;
        this.bestHoursWork = this.formatHourRange(this.statsYearToDate.data.workout.bestHoursRange.hourRange);
        this.perTotalWork = this.statsYearToDate.data.workout.bestHoursRange.percentage;

        this.maxNotifications = this.statsYearToDate.data.notifications.byDate.max.count;
        this.minNotifications = this.statsYearToDate.data.notifications.byDate.min.count;
        this.avgNotifications = this.statsYearToDate.data.notifications.avg;
        this.sendToCostumer = this.statsYearToDate.data.notifications.sentToCustomer.count;
        this.notSendToCostumer = this.statsYearToDate.data.notifications.notSentToCustomer.count;
        this.perNotSendToCostumer = this.statsYearToDate.data.notifications.notSentToCustomer.percentage;
        break;

      case 'customRange':
        const startDate = '2024-01-01';
        const endDate = '2024-01-31';
        this.statsCustomRange = await lastValueFrom(this.movanoSvc.getUsersActivityCustomDay(startDate, endDate));

        this.maxSpot = this.statsCustomRange.data.spotChecks.byDate.max.count;
        this.minSpot = this.statsCustomRange.data.spotChecks.byDate.min.count;
        this.avgSpot = this.statsCustomRange.data.spotChecks.avg;

        this.maxWork = this.statsCustomRange.data.workout.byDate.max.count;
        this.minWork = this.statsCustomRange.data.workout.byDate.min.count;
        this.avrgWork = this.statsCustomRange.data.workout.avg;
        this.bestHoursWork = this.formatHourRange(this.statsCustomRange.data.workout.bestHoursRange.hourRange);
        this.perTotalWork = this.statsCustomRange.data.workout.bestHoursRange.percentage;

        this.maxNotifications = this.statsCustomRange.data.notifications.byDate.max.count;
        this.minNotifications = this.statsCustomRange.data.notifications.byDate.min.count;
        this.avgNotifications = this.statsCustomRange.data.notifications.avg;
        this.sendToCostumer = this.statsCustomRange.data.notifications.sentToCustomer.count;
        this.notSendToCostumer = this.statsCustomRange.data.notifications.notSentToCustomer.count;
        this.perNotSendToCostumer = this.statsCustomRange.data.notifications.notSentToCustomer.percentage;
        break;

      default:
        console.error('Unknown calendarData value:')
    }

    this.changeDetector.detectChanges();
  }


  protected formatHourRange(hourRange: string): string {
    return hourRange
      .replace(/(:\d{2})/g, '')
      .trim();
  }

}
