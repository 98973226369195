import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, QueryList, SimpleChanges, ViewChild, ViewChildren } from '@angular/core';
import { Chart } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {footerInfo, totalInfoPerMonth} from "../../shared/movano-interfaces";
import _default from "chart.js/dist/plugins/plugin.legend";
import labels = _default.defaults.labels;
import { round } from '../../shared/utils';
import { EChartsOption } from 'echarts';
import { lastValueFrom } from 'rxjs';
import { MovanoService } from 'src/app/movano.service';
import { DarkModeService } from 'src/app/dark-mode.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedService } from 'src/app/stats/shared.service';


@Component({
  selector: 'app-stats-last-graph',
  templateUrl: './stats-last-graph.component.html',
  styleUrls: ['./stats-last-graph.component.scss']
})
export class StatsLastGraphComponent {
  [x: string]: any
  genderPrueba = ChartDataLabels;

  @Input() darkMode: boolean;
  @Input() footerData: footerInfo | undefined;
  protected statsFront: boolean = true;
  @Output() pauseStats = new EventEmitter<boolean>();
  purgeData: boolean = false;
  paused : boolean = false;
  isUserStats: boolean = true;
  genderStats: any;



  /* protected innerWidth: any;
  private darkModeSub: Subscription = new Subscription();
  protected today: Date;
  protected selectedDay: Date;
  protected rangeDaySelected: Date[];
 */

  protected monthData?: totalInfoPerMonth;


  /**
   * Retrieves the total information for the current month and the previous month.
   * It calls the `getTotalLastMonthInfo()` method from the `movanoSvc` service and subscribes to the response.
   * If the response is available, it assigns the data to the `monthData` variable.
   * Currently, there are console logs commented out for debugging purposes.
   */
  // async getTotalInfoperMonth() {
  //   const totalData = await lastValueFrom(this.movanoSvc.getTotalLastMonthInfo());
  //   this.monthData = totalData;
  // }



  /**
   * Retrieves information for the footer.
   * It calls the `getFooterInfo()` method from the `movanoSvc` service and subscribes to the response.
   * If the response is available, it assigns the data to the `footerData` variable.
   * Currently, there are console logs commented out for debugging purposes.
   */
  // async getFooterInfo() {
  //   const footerData = await lastValueFrom(this.movanoSvc.getFooterInfo());
  //   this.footerData = footerData;
  // }

 // interestData: any;
  //totalDataInterest: number;
  basicOptions: any;
  genderOptions: any;
  womenCountBigger: boolean = false;
  genderData: any;
  genderPercentageArray: any[] = [];
  genderDataChart: any;
  totalPeople = 1000;


/*   firmwareData: any;
  firmwareOptions: any;
  versionData: any;
  versionOptions: any;
  charginMax = '38h 40min';
  charginAvarage = '25h 25min';
  charginMin = '5h 05min';
  percentageChar = this.purgeData?0.01:0.75;  */


  public innerWidth: any;

  ctx: any;
  canvas: any;
  genderChart: any;
  hoverValue: any;
  chartGender: any;
  @ViewChildren('genderData') private mychart: any;

  protected option_DonutEmpty : EChartsOption={
    tooltip: {
      show: false,
      trigger: 'item'
    },
    series: [
      {
        name: 'Access From',
        type: 'pie',
        radius: ['80%', '90%'],
        avoidLabelOverlap: false,
        itemStyle: {
          borderRadius: '50%'
        },
        label: {
          show: false,
          position: 'center'
        },
        labelLine: {
          show: false
        },
        data: []
      }
    ]
  };
  protected option_Gender ?: EChartsOption;
  protected option_Active ?: EChartsOption;
  protected option_Active_gender ?: EChartsOption;
  protected option_Inactive_gender ?: EChartsOption;

  protected statsToday?: any;
  protected statsLastWeek?: any;
  protected statsLastMonth?: any;
  protected statsLast7Days?: any;
  protected statsYearToDate?: any;
  protected statsCustomRange?: any;

  protected activePercentage: string = "";
  protected inactivePercentage: string = "";
  protected activeUsers: string = "";
  protected inactiveUsers: string = "";
  protected menActiveUsers: string = "";
  protected womenActiveUsers: string = "";
  protected menActiveUsersUnits: string = "";
  protected womenActiveUsersUnits: string = "";
  protected menInactiveUsers: string = "";
  protected womenInactiveUsers: string = "";
  protected menInactiveUsersUnits: string = "";
  protected womenInactiveUsersUnits: string = "";


  xAxys = ['0', '5', '18', '35', '50'];
  ageRangePercentages: number[] =[];

  constructor(
    private movanoSvc: MovanoService,
    private darkModeSvc: DarkModeService,
    private router: Router,
    private route: ActivatedRoute,
    private sharedService: SharedService,
    private changeDetector: ChangeDetectorRef,
  ){
    this.darkMode = true;
    this['front'] = true;
   // this.totalDataInterest=0;
  }



    ngOnInit(): void {
      this.innerWidth = document.documentElement.clientWidth;
      this.checkRoute();
      this.sharedService.calendarObservable$.subscribe(data => {
        this.updateInfo(data);
      })
      this.movanoSvc.getStatsSummaryUsersToday().subscribe(
        (data) => {
          this.genderStats = data;
          console.log('GenderStats:', this.genderStats);
        },
        (error) => {
          console.error('Error getting gender stats', error);
        }
      );
      this.initializeWithLastWeekData();
    }

    ngAfterViewInit() {
      this.updateInfo();
    }
    checkRoute(): void {
      const currentRoute = this.router.url;
      this.isUserStats = currentRoute.includes('/stats/users');
     console.log(this.isUserStats);
    }

    @HostListener('window:resize', ['$event'])
    onResize(event: any) {
      this.innerWidth = document.documentElement.clientWidth;
      this.updateInfo();
    }

    ngOnChanges(changes: SimpleChanges) {
      this.updateInfo();
    }

    async initializeWithLastWeekData() {
      this.statsLastWeek = await lastValueFrom(this.movanoSvc.getStatsSummaryUsersLastWeek());
      this.activePercentage = this.statsLastWeek.data.active.percentage_from_total
      this.inactivePercentage = this.statsLastWeek.data.inactive.percentage_from_total
      this.activeUsers = this.statsLastWeek.data.active.count
      this.inactiveUsers = this.statsLastWeek.data.inactive.count
      this.menActiveUsers = this.statsLastWeek.data.active.gender.man.percentage
      this.womenActiveUsers = this.statsLastWeek.data.active.gender.woman.percentage
      this.menActiveUsersUnits = this.statsLastWeek.data.active.gender.man.count
      this.womenActiveUsersUnits = this.statsLastWeek.data.active.gender.woman.count
      this.menInactiveUsers = this.statsLastWeek.data.inactive.gender.man.percentage
      this.womenInactiveUsers = this.statsLastWeek.data.inactive.gender.woman.percentage
      this.menInactiveUsersUnits = this.statsLastWeek.data.inactive.gender.man.count
      this.womenInactiveUsersUnits = this.statsLastWeek.data.inactive.gender.woman.count
    }

  async updateInfo(data?: any) {
    console.log('Updating charts with footer data:', this.footerData);
    let gradient;
    if (this.ctx) {
      gradient = this.ctx.createConicGradient(0, 100, 100);
      gradient.addColorStop(0, "rgba(37, 134, 221,0)");
      gradient.addColorStop(0.5, "rgba(37, 134, 221,0.1)");
      gradient.addColorStop(0.75, "rgba(37, 134, 221,0)");
    }
    this.innerWidth = document.documentElement.clientWidth;
    this.genderData = this.getGenderDonut();
    this.genderDataChart = this.getGenderBars();
    this.applyDarkTheme();

    switch (data) {
      case 'today':
        this.statsToday = await lastValueFrom(this.movanoSvc.getStatsSummaryUsersToday());
        this.activePercentage = this.statsToday.data.active.percentage_from_total
        this.inactivePercentage = this.statsToday.data.inactive.percentage_from_total
        this.activeUsers = this.statsToday.data.active.count
        this.inactiveUsers = this.statsToday.data.inactive.count
        this.menActiveUsers = this.statsToday.data.active.gender.man.percentage
        this.womenActiveUsers = this.statsToday.data.active.gender.woman.percentage
        this.menActiveUsersUnits = this.statsToday.data.active.gender.man.count
        this.womenActiveUsersUnits = this.statsToday.data.active.gender.woman.count
        this.menInactiveUsers = this.statsToday.data.inactive.gender.man.percentage
        this.womenInactiveUsers = this.statsToday.data.inactive.gender.woman.percentage
        this.menInactiveUsersUnits = this.statsToday.data.inactive.gender.man.count
        this.womenInactiveUsersUnits = this.statsToday.data.inactive.gender.woman.count
        break;
      case 'Last Week':
        this.statsLastWeek = await lastValueFrom(this.movanoSvc.getStatsSummaryUsersLastWeek());
        this.activePercentage = this.statsLastWeek.data.active.percentage_from_total
        this.inactivePercentage = this.statsLastWeek.data.inactive.percentage_from_total
        this.activeUsers = this.statsLastWeek.data.active.count
        this.inactiveUsers = this.statsLastWeek.data.inactive.count
        this.menActiveUsers = this.statsLastWeek.data.active.gender.man.percentage
        this.womenActiveUsers = this.statsLastWeek.data.active.gender.woman.percentage
        this.menActiveUsersUnits = this.statsLastWeek.data.active.gender.man.count
        this.womenActiveUsersUnits = this.statsLastWeek.data.active.gender.woman.count
        this.menInactiveUsers = this.statsLastWeek.data.inactive.gender.man.percentage
        this.womenInactiveUsers = this.statsLastWeek.data.inactive.gender.woman.percentage
        this.menInactiveUsersUnits = this.statsLastWeek.data.inactive.gender.man.count
        this.womenInactiveUsersUnits = this.statsLastWeek.data.inactive.gender.woman.count
        break;
      case 'Last Month':
        this.statsLastMonth = await lastValueFrom(this.movanoSvc.getStatsSummaryUsersLastMonth());
        this.activePercentage = this.statsLastMonth.data.active.percentage_from_total
        this.inactivePercentage = this.statsLastMonth.data.inactive.percentage_from_total
        this.activeUsers = this.statsLastMonth.data.active.count
        this.inactiveUsers = this.statsLastMonth.data.inactive.count
        this.menActiveUsers = this.statsLastMonth.data.active.gender.man.percentage
        this.womenActiveUsers = this.statsLastMonth.data.active.gender.woman.percentage
        this.menActiveUsersUnits = this.statsLastMonth.data.active.gender.man.count
        this.womenActiveUsersUnits = this.statsLastMonth.data.active.gender.woman.count
        this.menInactiveUsers = this.statsLastMonth.data.inactive.gender.man.percentage
        this.womenInactiveUsers = this.statsLastMonth.data.inactive.gender.woman.percentage
        this.menInactiveUsersUnits = this.statsLastMonth.data.inactive.gender.man.count
        this.womenInactiveUsersUnits = this.statsLastMonth.data.inactive.gender.woman.count
        break;
      case 'Last 7 days':
        this.statsLast7Days = await lastValueFrom(this.movanoSvc.getStatsSummaryUsersLast7Days());
        this.activePercentage = this.statsLast7Days.data.active.percentage_from_total
        this.inactivePercentage = this.statsLast7Days.data.inactive.percentage_from_total
        this.activeUsers = this.statsLast7Days.data.active.count
        this.inactiveUsers = this.statsLast7Days.data.inactive.count
        this.menActiveUsers = this.statsLast7Days.data.active.gender.man.percentage
        this.womenActiveUsers = this.statsLast7Days.data.active.gender.woman.percentage
        this.menActiveUsersUnits = this.statsLast7Days.data.active.gender.man.count
        this.womenActiveUsersUnits = this.statsLast7Days.data.active.gender.woman.count
        this.menInactiveUsers = this.statsLast7Days.data.inactive.gender.man.percentage
        this.womenInactiveUsers = this.statsLast7Days.data.inactive.gender.woman.percentage
        this.menInactiveUsersUnits = this.statsLast7Days.data.inactive.gender.man.count
        this.womenInactiveUsersUnits = this.statsLast7Days.data.inactive.gender.woman.count
        break;
      case 'Year to date':
        this.statsYearToDate = await lastValueFrom(this.movanoSvc.getStatsSummaryUsersYearToDate());
        this.activePercentage = this.statsYearToDate.data.active.percentage_from_total
        this.inactivePercentage = this.statsYearToDate.data.inactive.percentage_from_total
        this.activeUsers = this.statsYearToDate.data.active.count
        this.inactiveUsers = this.statsYearToDate.data.inactive.count
        this.menActiveUsers = this.statsYearToDate.data.active.gender.man.percentage
        this.womenActiveUsers = this.statsYearToDate.data.active.gender.woman.percentage
        this.menActiveUsersUnits = this.statsYearToDate.data.active.gender.man.count
        this.womenActiveUsersUnits = this.statsYearToDate.data.active.gender.woman.count
        this.menInactiveUsers = this.statsYearToDate.data.inactive.gender.man.percentage
        this.womenInactiveUsers = this.statsYearToDate.data.inactive.gender.woman.percentage
        this.menInactiveUsersUnits = this.statsYearToDate.data.inactive.gender.man.count
        this.womenInactiveUsersUnits = this.statsYearToDate.data.inactive.gender.woman.count
        break;
      case 'customRange':
        // Replace with actual start and end dates
        const startDate = '2024-01-01';
        const endDate = '2024-01-31';
        this.statsCustomRange = await lastValueFrom(this.movanoSvc.getStatsSummaryUsersCustomDay(startDate, endDate));
        this.activePercentage = this.statsCustomRange.data.active.percentage_from_total
        this.inactivePercentage = this.statsCustomRange.data.inactive.percentage_from_total
        this.activeUsers = this.statsCustomRange.data.active.count
        this.inactiveUsers = this.statsCustomRange.data.inactive.count
        this.menActiveUsers = this.statsCustomRange.data.active.gender.man.percentage
        this.womenActiveUsers = this.statsCustomRange.data.active.gender.woman.percentage
        this.menActiveUsersUnits = this.statsCustomRange.data.active.gender.man.count
        this.womenActiveUsersUnits = this.statsCustomRange.data.active.gender.woman.count
        this.menInactiveUsers = this.statsCustomRange.data.inactive.gender.man.percentage
        this.womenInactiveUsers = this.statsCustomRange.data.inactive.gender.woman.percentage
        this.menInactiveUsersUnits = this.statsCustomRange.data.inactive.gender.man.count
        this.womenInactiveUsersUnits = this.statsCustomRange.data.inactive.gender.woman.count
        break;
      default:
        console.error('Unknown active users value:'); // Default case if none match
    }

    this.changeDetector.detectChanges();

    this.option_Gender = {
      tooltip: {
        show: false,
        trigger: 'item'
      },
      series: [
        {
          name: 'Access From',
          type: 'pie',
          radius: ['80%', '90%'],
          color: [
            "#0B84FF",
            "#B01065",
            "#646464",
            "#424242"
          ],
          avoidLabelOverlap: false,
          itemStyle: {
            borderRadius: '50%',
            borderWidth: 20,
          },
          emphasis: {
            scaleSize: 2,
            itemStyle: {
              shadowColor: this.darkMode ? 'rgba(255,255,255,0.5)' : 'rgba(0,0,0,0.25)' ,
              shadowBlur: 5,
            },
          },
          label: {
            show: false,
            position: 'center'
          },
          labelLine: {
            show: false
          },
          data: [this.genderStats.data.total_users.gender.man.percentage,this.genderStats.data.total_users.gender.woman.percentage,this.genderStats.data.total_users.gender.other.percentage,this.genderStats.data.total_users.gender.unkown.percentage]
        }
      ]
    };
    this.option_Active = {
      tooltip: {
        show: false,
        trigger: 'item'
      },
      series: [
        {
          name: 'Access From',
          type: 'pie',
          radius: ['80%', '90%'],
          color: [
            "#00B377",
            "#7797B8",
           ],
          avoidLabelOverlap: false,
          itemStyle: {
            borderRadius: '50%',
            borderWidth: 20,
          },
          emphasis: {
            scaleSize: 2,
            itemStyle: {
              shadowColor: this.darkMode ? 'rgba(255,255,255,0.5)' : 'rgba(0,0,0,0.25)' ,
              shadowBlur: 5,
            },
          },
          label: {
            show: false,
            position: 'center'
          },
          labelLine: {
            show: false
          },
          data: [ { value: Number(this.activeUsers), name: 'Active Users' },
            { value: Number(this.inactiveUsers), name: 'Inactive Users' }]
        }
      ]
    };
    this.option_Active_gender = {
      tooltip: {
        show: false,
        trigger: 'item'
      },
      series: [
        {
          name: 'Access From',
          type: 'pie',
          radius: ['80%', '90%'],
          color: [
            "#0B84FF",
            "#B01065"
           ],
          avoidLabelOverlap: false,
          itemStyle: {
            borderRadius: '50%',
            borderWidth: 20,
          },
          emphasis: {
            scaleSize: 2,
            itemStyle: {
              shadowColor: this.darkMode ? 'rgba(255,255,255,0.5)' : 'rgba(0,0,0,0.25)' ,
              shadowBlur: 5,
            },
          },
          label: {
            show: false,
            position: 'center'
          },
          labelLine: {
            show: false
          },
          data: [ { value: Number(this.menActiveUsers), name: 'Men Active Users' },
            { value: Number(this.womenActiveUsers), name: 'Women Active Users' }]
        }
      ]
    };
    this.option_Inactive_gender = {
      tooltip: {
        show: false,
        trigger: 'item'
      },
      series: [
        {
          name: 'Access From',
          type: 'pie',
          radius: ['80%', '90%'],
          color: [
            "#0B84FF",
            "#B01065"
           ],
          avoidLabelOverlap: false,
          itemStyle: {
            borderRadius: '50%',
            borderWidth: 20,
          },
          emphasis: {
            scaleSize: 2,
            itemStyle: {
              shadowColor: this.darkMode ? 'rgba(255,255,255,0.5)' : 'rgba(0,0,0,0.25)' ,
              shadowBlur: 5,
            },
          },
          label: {
            show: false,
            position: 'center'
          },
          labelLine: {
            show: false
          },
          data: [ { value: Number(this.menInactiveUsers), name: 'Men Inactive Users' },
            { value: Number(this.womenInactiveUsers), name: 'Women Inactive Users' }]
        }
      ]
    };
  }

/*   pause() {
    this.paused = !this.paused;
    this.pauseStats.emit();
  } */

  applyDarkTheme() {
    this.basicOptions = {
      plugins: {
        legend: false,
        tooltip: {
          enabled: false,
          position: 'nearest',
        }
      },
      responsive: true,
    };
    this.genderOptions = {
      plugins: {
        legend: false,
        title: {
          display: true,
          text: '',
          align: 'start',
          justify: 'top',
          color: (this.darkMode) ? '#7797B8' : '#284767',
          font: {
            size: (this.innerWidth >= 1024) ? this.innerWidth / 100 : 30,
            weight: '400',
          },
        },
        tooltip: {
          enabled: false,
          position: 'nearest',
        },
        datalabels: {
          color: (this.darkMode) ? function (context: any) {
            return context.active ? '#0DCCFF' : '#054280';
          } :  '#054280',
          font: {
            size: (this.innerWidth < 1024) ? 10 : this.innerWidth / 125,
            weight: '700',
          },
          // backgroundColor:  (this.darkMode) ? function (context: any) {
          //   return (context.datasetIndex === 1) ?  '' : 'rgb(18, 47, 95)';
          // } :  function (context: any) {
          //   return (context.datasetIndex === 1) ?  '' : '#FFFFFF';
          // },
          align: 'top',
          anchor: 'end',
          formatter: (val: any, context: any) => {
            return (context.datasetIndex === 0) ? '' : `${this.ageRangePercentages[context.dataIndex]}%`;
          }
        }
      },
      responsive: true,
      scales: {
        x: {
          display: true,
          ticks: {
            color: (this.darkMode) ? "rgba(13, 204, 255, 0.5)" : '#7797B8',
            font: {
              size: (this.innerWidth >= 1024) ? this.innerWidth / 110 : 10,
            },
          },
          grid: {
            color: 'rgba(255,255,255,0)'
          }
        },
        y: {
          display: false,
          ticks: {
            color: 'rgba(255,255,255,0)'
          },
          grid: {
            color: 'rgba(255,255,255,0)'
          }
        }
      }
    }
  }

  getAppVersionLength() {
    if(this.footerData?.appVersions) {
      return this.footerData.appVersions.length;
    } else {
      return 0;
    }
  }

  getAppVersionPercentage(index: number) {
    if(this.purgeData){
      return '0';
    }else {
      if (this.footerData?.appVersions) {
        return this.footerData.appVersions[index].percentage;
      } else {
        return '0';
      }
    }
  }

  getAppVersionVersion(index: number) {
    if(this.purgeData) {
      return 'undefined';
    } else {
      if (this.footerData?.firmwareVersions) {
        return this.footerData.firmwareVersions[index].version;
      } else {
        return 'undefined';
      }
    }
  }

  getAppDonut() {
    let versionData = {
      labels: ['A', 'B', 'C', 'D'],
      datasets: [
        {
          data: [250, 50, 50, 350],
          borderRadius: 1000,
          borderWidth: 0,
          spacing: 5,
          borderColor: 'rgba(0, 179, 119, 0)',
          backgroundColor: [
            "#FFDDEF",
            "#E31482",
            (this.darkMode) ? '#FF77BF' : '#66006A',
            (this.darkMode) ? '#FFFFFF' : '#49004B',
          ],
          hoverBackgroundColor: [
            "#FFDDEF",
            "#E31482",
            (this.darkMode) ? '#FF77BF' : '#66006A',
            (this.darkMode) ? '#FFFFFF' : '#49004B',
          ],
        }, {}, {}, {}
      ]
    };
    versionData.labels = [];
    versionData.datasets[0].data = [];
    if(this.purgeData){
      versionData.labels.push('Unknown');
      versionData.datasets[0].data.push(100);
    }
    else {
      this.footerData?.appVersions.forEach((version: any, index) => {
        if (index > 3)
          return;
        versionData.labels.push(version.version);
        if (versionData?.datasets[0]?.data)
          versionData.datasets[0].data.push(version.count);
      });
    }
    return versionData;
  }


  getFirmwareVersionLength() {
    if(this.footerData?.firmwareVersions) {
      return this.footerData.firmwareVersions.length;
    } else {
      return 0;
    }
  }

  getFirmwareVersionPercentage(index: number) {
    if(this.purgeData){
        return '0';
    } else {
      if (this.footerData?.firmwareVersions) {
        return this.footerData.firmwareVersions[index].percentage;
      } else {
        return '0';
      }
    }
  }

  getFirmwareVersionVersion(index: number) {
    if(this.purgeData) {
      return 'Beta Version';
    } else {
      if (this.footerData?.firmwareVersions) {
        return this.footerData.firmwareVersions[index].version;
      } else {
        return 'undefined';
      }
    }
  }

  getFirmwareDonut() {
    let firmwareData = {
      labels: ['A', 'B', 'C', 'D', 'E', 'F'],
      datasets: [
        {
          data: [150, 50, 50, 100, 200, 350],
          borderRadius: 1000,
          borderWidth: 0,
          spacing: 5,
          borderColor: 'rgba(0, 179, 119, 0)',
          backgroundColor: [
            "#C4D9EE",
            "#7797B8",
            "#0DCCFF",
            "#0B84FF",
            (this.darkMode) ? '#000A14' : '#054280',
            (this.darkMode) ? '#FFFFFF' : '#022E5C',
          ],
          hoverBackgroundColor: [
            "#C4D9EE",
            "#7797B8",
            "#0DCCFF",
            "#0B84FF",
            (this.darkMode) ? '#000A14' : '#054280',
            (this.darkMode) ? '#FFFFFF' : '#022E5C',
          ],
        }, {}, {}, {}
      ]
    };
    firmwareData.labels = [];
    firmwareData.datasets[0].data = [];
    if(this.purgeData){
      firmwareData.labels.push('Unknown');
      firmwareData.datasets[0].data.push(100);
    }
    else {
      this.footerData?.firmwareVersions.forEach((version: any) => {
        firmwareData.labels.push(version.version);
        if (firmwareData?.datasets[0]?.data)
          firmwareData.datasets[0].data.push(version.count);
      });
    }
    return firmwareData;
  }

  getInterestsLength() {
    if(this.footerData?.interestData) {
      return this.footerData.interestData.length;
    } else {
      return 0;
    }
  }


  getInterestsName(index: number) {
    if(this.purgeData) {
        return 'undefined';
    } else {
      if (this.footerData?.interestNames) {
        return this.footerData.interestNames[index];
      } else {
        return 'undefined';
      }
    }
  }

  getGenderDonut() {
    let genderData = {
      labels: ['Men', 'Women'],
      datasets: [
        {
          data: [100, 350],
          borderRadius: 1000,
          borderWidth: 3,
          spacing: 5,
          borderColor: 'rgba(0, 0, 0, 0)',
          hoverBorderColor: [
            (this.darkMode) ? 'rgba(227, 20, 130, 0.1)' : 'rgba(0, 0, 0, 0.1)',
            (this.darkMode) ? 'rgba(13, 204, 255, 0)' : 'rgba(0, 0, 0, 0)',
            (this.darkMode) ? 'rgba(119, 151, 184, 0.1)' : 'rgba(0, 0, 0, 0.1)',
          ],
          backgroundColor: [
            "#B01065",
            (this.darkMode) ? '#0DCCFF' : '#0B84FF',
            "#7797B8",
          ],
          hoverBackgroundColor: [
            "#B01065",
            (this.darkMode) ? '#0DCCFF' : '#0B84FF',
            "#7797B8",
          ],
        }, {}, {}, {}
      ]
    };

    genderData.labels.push('Other');
    const percentageWomen = this.footerData?.distribution.percentageWomen? this.footerData?.distribution.percentageWomen : 0;
    const percentageMen = this.footerData?.distribution.percentageMen? this.footerData?.distribution.percentageMen : 0;
    const percentageOther = this.footerData?.distribution.percentageOther? this.footerData?.distribution.percentageOther : 0;
    genderData.datasets[0].data = [percentageWomen, percentageMen, percentageOther];
    let genderPercentageArray = [
      {
        gender: 'F',
        percentage: percentageWomen,
        color: '#B01065',
        age: this.footerData?.distribution.averageAgeWomen? this.footerData?.distribution.averageAgeWomen : 0,
        interest: this.getBestInterest('F'),
        img: "../../assets/women_Icon.svg"
      }, {
        gender: 'M',
        percentage: percentageMen,
        color: (this.darkMode) ? '#0DCCFF' : '#0B84FF',
        age: this.footerData?.distribution.averageAgeMen? this.footerData?.distribution.averageAgeMen : 0,
        interest: this.getBestInterest('M'),
      }, {
        gender: 'O',
        percentage: percentageOther,
        color: '#7797B8',
        age: this.footerData?.distribution.averageAgeOther? this.footerData?.distribution.averageAgeOther : 0,
        interest: this.getBestInterest('O')
      }
    ]
    this.genderPercentageArray = genderPercentageArray.sort((a: any, b: any) => b.percentage - a.percentage);
    return genderData
  }

  getGenderColor(idx: number) {
    return this.genderPercentageArray[idx].color;
  }

  getGenderPercentage(idx: number) {
    return this.genderPercentageArray[idx].percentage;
  }

  getBestInterest(gender: string) {
    let bestInterest = '';
    if (gender === 'F') {
      if(this.footerData?.distribution?.interestByGender.interestWomen[0])
        bestInterest = this.footerData?.distribution?.interestByGender.interestWomen[0]?.interest;
      else
        return bestInterest
      if(this.footerData?.distribution?.interestByGender.interestWomen[1])
        bestInterest += ' / ' + this.footerData?.distribution?.interestByGender.interestWomen[1]?.interest;
    } else if (gender === 'M') {
      if(this.footerData?.distribution?.interestByGender.interestMen[0])
        bestInterest = this.footerData?.distribution?.interestByGender.interestMen[0]?.interest;
      else
        return bestInterest
      if(this.footerData?.distribution?.interestByGender.interestMen[1])
        bestInterest += ' / ' + this.footerData?.distribution?.interestByGender.interestMen[1]?.interest;
    } else {
      if(this.footerData?.distribution?.interestByGender.interestOther[0])
        bestInterest = this.footerData?.distribution?.interestByGender.interestOther[0]?.interest;
      else
        return bestInterest;
      if(this.footerData?.distribution?.interestByGender.interestMen[1])
        bestInterest += ' / ' + this.footerData?.distribution?.interestByGender.interestMen[1]?.interest;
    }

    return bestInterest;
  }

  getGenderAge(gender: string) {
    return Math.floor(this.genderPercentageArray.filter(el => el.gender===gender)[0].age);
  }

  getGenderBestInterest(gender: string) {
    return this.genderPercentageArray.filter(el => el.gender===gender)[0].interest;
  }


  getGenderBars() {
    let genderDataChart = {
      labels: ['', '', '', '', '','','',''],
      datasets: [
        {
          data: [100, 200, 350, 500, 400],
          borderRadius: 100,
          spacing: (this.innerWidth >= 1024) ? this.innerWidth * 0.1 : this.innerWidth * 0.015,
          borderWidth: 1,
          barThickness: (this.innerWidth >= 1024) ? this.innerWidth * 0.008 : 15,
          hoverInnerGlowWidth: 20,
          hoverInnerGlowColor: 'rgba(255, 255, 0, 1)',
          borderColor: 'rgba(0, 179, 119, 0)',
          backgroundColor: (this.darkMode) ? "rgba(13, 204, 255, 0.5)" : "rgba(11, 132, 255, 0.3)",
          hoverBackgroundColor: (this.darkMode) ? "#0DCCFF" : "#0B84FF"
        },
        {
          data: [20, 40, 70, 100, 80],
          borderRadius: 100,
          spacing: (this.innerWidth >= 1024) ? this.innerWidth * 0.00347 : this.innerWidth * 0.007,
          borderWidth: 1,
          barThickness: (this.innerWidth >= 1024) ? this.innerWidth * 0.008 : 15,
          borderColor: 'rgba(0, 179, 119, 0)',
          backgroundColor: (this.darkMode) ? "rgba(176, 16, 101, 0.3)" : "rgba(176, 16, 101, 0.3)",
          hoverBackgroundColor: (this.darkMode) ? "#E31482" : "#B01065",
        }
      ]
    };
    // genderDataChart.labels = []
    genderDataChart.datasets[0].data = []
    genderDataChart.datasets[1].data = []
    this.xAxys = ['up 20', '30', '40', '50', '60', '70', '80', 'more'];
    // men data
    genderDataChart.datasets[0].data.push(this.genderStats.data.total_users.per_age.upto_20.males);
    genderDataChart.datasets[0].data.push(this.genderStats.data.total_users.per_age.upto_29.males);
    genderDataChart.datasets[0].data.push(this.genderStats.data.total_users.per_age.upto_39.males);
    genderDataChart.datasets[0].data.push(this.genderStats.data.total_users.per_age.upto_49.males);
    genderDataChart.datasets[0].data.push(this.genderStats.data.total_users.per_age.upto_59.males);
    genderDataChart.datasets[0].data.push(this.genderStats.data.total_users.per_age.upto_69.males);
    genderDataChart.datasets[0].data.push(this.genderStats.data.total_users.per_age.upto_79.males);
    genderDataChart.datasets[0].data.push(this.genderStats.data.total_users.per_age.beyond_80.males);
    // women data
    genderDataChart.datasets[1].data.push(this.genderStats.data.total_users.per_age.upto_20.females);
    genderDataChart.datasets[1].data.push(this.genderStats.data.total_users.per_age.upto_29.females);
    genderDataChart.datasets[1].data.push(this.genderStats.data.total_users.per_age.upto_39.females);
    genderDataChart.datasets[1].data.push(this.genderStats.data.total_users.per_age.upto_49.females);
    genderDataChart.datasets[1].data.push(this.genderStats.data.total_users.per_age.upto_59.females);
    genderDataChart.datasets[1].data.push(this.genderStats.data.total_users.per_age.upto_69.females);
    genderDataChart.datasets[1].data.push(this.genderStats.data.total_users.per_age.upto_79.females);
    genderDataChart.datasets[1].data.push(this.genderStats.data.total_users.per_age.beyond_80.females);
    let otherDataArray: number[] = [];
    // other data
    otherDataArray.push(this.footerData?.distribution?.ageRanges?.other?.upToTwenty ? this.footerData?.distribution?.ageRanges?.other?.upToTwenty.count : 0);
    otherDataArray.push(this.footerData?.distribution?.ageRanges?.other?.twentyToForty ? this.footerData?.distribution?.ageRanges?.other?.twentyToForty.count : 0);
    otherDataArray.push(this.footerData?.distribution?.ageRanges?.other?.fortyToSixty ? this.footerData?.distribution?.ageRanges?.other?.fortyToSixty.count : 0);
    otherDataArray.push(this.footerData?.distribution?.ageRanges?.other?.sixtyToEighty ? this.footerData?.distribution?.ageRanges?.other?.sixtyToEighty.count : 0);
    otherDataArray.push(this.footerData?.distribution?.ageRanges?.other?.eightyToPlus ? this.footerData?.distribution?.ageRanges?.other?.eightyToPlus.count : 0);
    console.log("Men data: ", genderDataChart.datasets[0].data);
    console.log("Women data: ", genderDataChart.datasets[1].data);
    console.log("Other data: ", otherDataArray);


    //console.log(genderDataChart.datasets);
    //console.log(otherDataArray);
    const totalPeopleArray = [
      ...genderDataChart.datasets[0].data,
      ...genderDataChart.datasets[1].data,
      ...otherDataArray
    ];
    this.totalPeople = totalPeopleArray.reduce((a, b) => a + b, 0);
    console.log("Total people: ", this.totalPeople);
    for(let i:number = 0; i < genderDataChart.datasets[0].data.length; i++) {
      this.ageRangePercentages.push(round(((genderDataChart.datasets[0].data[i] + genderDataChart.datasets[1].data[i]) / this.totalPeople) * 100, 0));
    }
    return genderDataChart;
  }

  purgeFakeData() {
    if (this.purgeData) {
    }
  }

}



