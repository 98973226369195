import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DevicesComponent } from './devices/devices.component';
import { LoginComponent } from './login/login.component';
import { TesterComponent } from './tester/tester.component';
import { UsersComponent } from './users/users.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { NtfInsightApplicationComponent } from './notifications/insight-application/ntf-insight-application.component';
import { NtfNotificationListComponent } from './notifications/notification-list/ntf-notification-list.component';
import { NtfInsightJobExecutionsComponent } from './notifications/insight-job-executions/ntf-insight-job-executions.component';
import { StatsComponent } from './stats/stats.component';
import { StatsUsersComponent } from './stats/stats-users/stats-users.component';
import { StatsDevicesComponent } from './stats/stats-devices/stats-devices.component';
import { StatsActivityComponent } from './stats/stats-activity/stats-activity.component';
import { StatsHealthComponent } from './stats/stats-health/stats-health.component';
import { StatsBugsComponent } from './stats/stats-bugs/stats-bugs.component';

const routes: Routes = [{
  path: "",
  redirectTo: "/login",
  pathMatch: "full"
},
{
  path: "login",
  component: LoginComponent
},
{
  path: "tester",
  component: TesterComponent
},
{
  path: "dashboard",
  component: DashboardComponent
},
{
  path: "devices",
  component: DevicesComponent
},
{
  path: "users",
  component: UsersComponent
},
{
  path: "notifications",
  component: NotificationsComponent,
  children: [
    {
      path: "", // Ruta vacía
      redirectTo: "insight-application", // Redirige al primer hijo
      pathMatch: "full"
    },
    {
      path: "insight-application",
      component: NtfInsightApplicationComponent
    },
    {
      path: "notification-list",
      component: NtfNotificationListComponent
    },
    {
      path: "insight-job-executions",
      component: NtfInsightJobExecutionsComponent
    },
  ]
},
{
  path: "stats",
  component: StatsComponent,
  children: [
    {
      path: "", 
      redirectTo: "users", // Redirige al primer hijo
      pathMatch: "full"
    },
    {
      path: "users",
      component: StatsUsersComponent,
    },
    {
      path: "devices",
      component: StatsDevicesComponent
    },
    {
      path: "activity",
      component: StatsActivityComponent
    },
    {
      path: "health-metrics",
      component: StatsHealthComponent
    },{
      path: "bugs-tickets",
      component: StatsBugsComponent
    }
  ]
}
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
