<div class="movanoTotalInfo"
[ngClass]="{'movanoTotalInfo_Dark' : darkMode}">
  <div class="movanoTotalInfo_TxtBlock">
    <span class="movanoTotalInfo_TxtBlock_Tittle--Main">{{totalUsers}}</span>
    <div class="movanoTotalInfo_TxtBlock_Txt">
      <span>total</span>
      <span>Users</span>
    </div>
  </div>
  <div class="movanoTotalInfo_TxtBlock">
    <span class="movanoTotalInfo_TxtBlock_Tittle--Secondary">{{activeUsers}}</span>
    <div class="movanoTotalInfo_TxtBlock_Txt">
      <span>active</span>
      <span>users</span>
    </div>
  </div>
  <div class="movanoTotalInfo_TxtBlock">
    <span class="movanoTotalInfo_TxtBlock_Tittle--Secondary">{{newUsers}}</span>
    <div class="movanoTotalInfo_TxtBlock_Txt">
      <span>new</span>
      <span>users</span>
    </div>
  </div>
</div>
