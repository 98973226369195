<div class="scrollBarUser">
  <div class="movanoUserPanel">
    <div class="movanoUserPanel_header" [ngClass]="{'movanoDark': darkMode}">
      <span class="movanoUserPanel_header_user">
        User: {{data.user}}
      </span>
      <svg (click)="closePanel()" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M11.25 1.8075L10.1925 0.75L6 4.9425L1.8075 0.75L0.75 1.8075L4.9425 6L0.75 10.1925L1.8075 11.25L6 7.0575L10.1925 11.25L11.25 10.1925L7.0575 6L11.25 1.8075Z"
          fill="#C4D9EE" />
      </svg>
      <div class="movanoUserPanel_header_buttons">
        <div class="movanoUserPanel_header_buttons_chartType"
         [ngClass]="{'movanoUserPanel_header_buttons_chartType-selected' : selectedTab === 'healthMetrics'}"
        (click)="selectTab('healthMetrics')">Health Metrics</div>

        <div class="movanoUserPanel_header_buttons_chartType"
        [ngClass]="{'movanoUserPanel_header_buttons_chartType-selected' : selectedTab === 'waveformData'}"
        (click)="selectTab('waveformData')">Waveform Data</div>

        <div class="movanoUserPanel_header_buttons_chartType"
        [ngClass]="{'movanoUserPanel_header_buttons_chartType-selected' : selectedTab === 'workoutUsage'}"
        (click)="selectTab('workoutUsage')">Workout Usage</div> 

        <div class="movanoUserPanel_header_buttons_chartType"
        [ngClass]="{'movanoUserPanel_header_buttons_chartType-selected' : selectedTab === 'sleepData'}"
        (click)="selectTab('sleepData')">Sleep Data</div>

        <div class="movanoUserPanel_header_buttons_chartType"
        [ngClass]="{'movanoUserPanel_header_buttons_chartType-selected' : selectedTab === 'spotCheck'}"
        (click)="selectTab('spotCheck')">Spot Check</div>
      </div>
    </div>
    <ng-container [ngSwitch]="selectedTab">
      <ng-template ngSwitchCase="healthMetrics">
        <app-user-panel-health-metrics
          [savedMeasureData]="measures_LastData"
          [measures_Battery]="measures_Battery"
          [loadingMesureData]="needHaveHealthMetrics"
          [lastDaySelected]="daySelected"
          [lastDayView]="dayView"
          (saveData)="healthMetricsData($event)">
        </app-user-panel-health-metrics>
      </ng-template>

      <ng-template ngSwitchCase="waveformData">
        <app-user-panel-waveform-data [lastDaySelected]="daySelected"></app-user-panel-waveform-data>
      </ng-template>

      <ng-template ngSwitchCase="workoutUsage">
        <app-workout-usage></app-workout-usage>
      </ng-template>
      
      <ng-template ngSwitchCase="sleepData">
        <app-user-panel-sleep-data></app-user-panel-sleep-data>
      </ng-template>

      <ng-template ngSwitchCase="spotCheck">
        <user-panel-spot-check></user-panel-spot-check>
      </ng-template>
    </ng-container>
  </div>
</div>
