import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { incidentCarrousel, salesProductionCarrousel, salesSyncCarrousel, sideChart, totalInfoPerMonth } from '../../shared/movano-interfaces';
import { abbrNum } from '../../shared/utils';
import { USE_SPACES, USE_CAPITALS, NUM_DECIMALS } from '../../shared/constants';
import { Subscription } from 'rxjs';
import { DashboardTypeService } from 'src/app/dashboard-type.service';
import { DASHBOARD } from 'src/app/shared/movano-literals';

interface lstCmpObj { colorDark: string, colorLight: string, barBckLight: string,  barBckDark: string, data?:number, lastData?:number}

interface lstCmpObject {
  cmp: lstCmpObj[];
  bck: string;
  bck_Dark: string;
  total ?: number;
  percentage ?: number;
}
@Component({
  selector: 'app-movano-last-compt',
  templateUrl: './movano-last-compt.component.html',
  styleUrls: ['./movano-last-compt.component.scss']
})
export class MovanoLastComptComponent{
  @Input() darkMode : boolean;
  @Input() production : boolean = false;
  purgeData: boolean = false;

  // lastDataMax : number = 100;
  // lastDataBlack : number = 42630;
  // lastDataBlackBest : number = -1;
  // lastDataBlue : number = 103260;
  // lastDataBlueBest : number = 1;
  // lastDataRed : number = 54110;
  // lastDataRedBest : number = 0;
  percentageBar0 : number = 0;
  percentageBar1 : number = 0;
  percentageBar1ToShow : number = 0;
  percentageBar2 : number = 0;
  percentageBar2ToShow : number = 0;

  symbolData : number = 1;
  symbolsData : string[] = ['', 'k', 'M', 'B']
  @Input() data : sideChart[] = [];
  //Dashboard type
  protected dashboardType: string;
  private dashboardTypeSub: Subscription = new Subscription();

  //completeData : number = 0;
  //lastCmptData : number[] = [0,0,0]
  //percentage : number = 0;
  dividedBars : string[] = ['Sales'];
  ICONS : string = DASHBOARD.LAST_COMP_ICONS + '/';

  lstCmpObjects: Map<string, lstCmpObject> = new Map<string, lstCmpObject>([ 
    ['Production', {
      cmp:  [
        { colorDark: '#022F5B', colorLight: '#022F5B',
          barBckLight: 'linear-gradient(180deg, #022E5A 0%, #022E5A00 100%)', 
          barBckDark: 'linear-gradient(180deg, #022E5A 0%, #022E5A00 100%)' },
        { colorDark: '#0DCCFF', colorLight: '#0DCCFF', 
          barBckLight: 'linear-gradient(180deg, #0DCCFF  0%, #0DCCFF00 100%)', 
          barBckDark: 'linear-gradient(180deg, #0DCCFF  0%, #0DCCFF00 100%)'  },
        { colorDark: '#FF58B1', colorLight: '#FF58B1', 
          barBckLight: 'linear-gradient(180deg, #E31482  0%, #E3148200 150%)', 
          barBckDark: 'linear-gradient(180deg, #E31482  0%, #E3148200 150%)' }
      ],
      bck: 'linear-gradient(326.11deg, var(--main-blue-dark) 0%, var(--main-blue-medium) 98.5%)',
      bck_Dark : 'linear-gradient(326.11deg, var(--main-denim-dark) 0%, var(--main-blue-dark) 98.5%)'
    }],
    ['Sales', {
      cmp: [
        { colorDark: '#054280', colorLight: '#054280', 
          barBckLight: 'linear-gradient(180deg, #054280 0%, #05428000 100%)', 
          barBckDark: 'linear-gradient(180deg, #054280 0%, #05428000 100%)'  },
        { colorDark: '#D1F5FF', colorLight: '#D1F5FF', 
          barBckLight: 'linear-gradient(180deg, #D1F5FF 0%, #D1F5FF00 150%)', 
          barBckDark: 'linear-gradient(180deg, #D1F5FF 0%, #D1F5FF00 150%)'  },
        { colorDark: '#66006A', colorLight: '#66006A', 
          barBckLight: 'linear-gradient(180deg, #66006A 0%, #66006A00 150%)', 
          barBckDark: 'linear-gradient(180deg, #66006A 0%, #66006A00 150%)'  },
      ],
      bck: 'linear-gradient(330.44deg, var(--main-blue-medium) -0.42%, var(--main-blue-light) 100.65%)',
      bck_Dark : 'linear-gradient(330.44deg, var(--main-blue-medium) -0.42%, var(--main-blue-dark) 100.65%)'
    }],
    ['Activity', {
      cmp: [
        { colorDark: '#BBFFE8', colorLight: '#BBFFE8', 
          barBckLight: 'linear-gradient(180deg, #BBFFE8 0%, #BBFFE800 100%)', 
          barBckDark: 'linear-gradient(180deg, #BBFFE8 0%, #BBFFE800 100%)'  },
        { colorDark: '#71FFBB', colorLight: '#71FFBB', 
          barBckLight: 'linear-gradient(180deg, #71FFBB 0%, #71FFBB00 100%)', 
          barBckDark: 'linear-gradient(180deg, #71FFBB 0%, #71FFBB00 100%)'  },
        { colorDark: '#012E1E', colorLight: '#012E1E', 
          barBckLight: 'linear-gradient(180deg, #012E1E 0%, #012E1E00 150%)', 
          barBckDark: 'linear-gradient(180deg, #012E1E 0%, #012E1E00 150%)'  },
      ],
      bck: 'linear-gradient(54.42deg, var(--main-blue-dark) -65.47%, var(--main-green-medium) 100.91%)',
      bck_Dark : 'linear-gradient(54.42deg, var(--main-blue-dark) -65.47%, var(--main-green-medium) 100.91%)'
    }],
    ['Users', {
      cmp: [
        { colorDark: '#FCDDC0', colorLight: '#FCDDC0', 
          barBckLight: 'linear-gradient(180deg, #FCDDC0 0%, #FCDDC000 100%)', 
          barBckDark: 'linear-gradient(180deg, #FCDDC0 0%, #FCDDC000 100%)'  },
        { colorDark: '#5D2C00', colorLight: '#5D2C00', 
          barBckLight: 'linear-gradient(180deg, #5D2C00 0%, #5D2C0000 100%)', 
          barBckDark: 'linear-gradient(180deg, #5D2C00 0%, #5D2C0000 100%)'  },
        { colorDark: '#FFFFFF', colorLight: '#FFFFFF', 
          barBckLight: 'linear-gradient(180deg, #FFFFFF 0%, #FFFFFF00 150%)', 
          barBckDark: 'linear-gradient(180deg, #FFFFFF 0%, #FFFFFF00 150%)'  },
      ],
      bck: 'linear-gradient(67deg, #FF830D 1.11%, #8E4500 99.05%)',
      bck_Dark : 'linear-gradient(67deg, #FF830D 1.11%, #8E4500 99.05%)'
    }],
    ['SpotChecks', {
      cmp: [
        { colorDark: '#FFFFFF', colorLight: '#FFFFFF', 
          barBckLight: 'linear-gradient(180deg, #FFFFFF 0%, #FFFFFF00 100%)', 
          barBckDark: 'linear-gradient(180deg, #FFFFFF 0%, #FFFFFF00 100%)'  },
        { colorDark: '#E31583', colorLight: '#E31583', 
          barBckLight: 'linear-gradient(180deg, #E31583 0%, #E3158300 150%)', 
          barBckDark: 'linear-gradient(180deg, #E31583 0%, #E3158300 150%)' },
        { colorDark: 'empty', colorLight: 'empty', barBckLight: 'empty', barBckDark: 'empty' },
      ],
      bck: 'linear-gradient(67deg, #054280 1.11%, #002850 99.05%)',
      bck_Dark : 'linear-gradient(67deg, #054280 1.11%, #002850 99.05%)'
    }],
  ]);

  lstCmpSelected : lstCmpObject;

  constructor(private dashboardSVC: DashboardTypeService){
    this.dashboardType = (this.production) ? 'Production' : 'Sales';
    //this.lastDataMax = this.lastDataBlack + this.lastDataBlue + this.lastDataRed;
    this.darkMode = true;
    this.lstCmpSelected = this.lstCmpObjects.get('Sales')!;
    // this.dispo_pro_Percen = ((this.totalDispo_Pro/this.total_Pro)*100) || 0;
    // this.sold_pro_Percen = (((this.totalSold_Pro+this.totalDispo_Pro)/this.total_Pro)*100) || 0;
  }

  ngOnInit() { 
    this.data.forEach((sdData: sideChart) => {
      for(let i : number = 0; i < 3; i++){
        this.lstCmpObjects.get(sdData.label)!.cmp[i].data = (sdData.values[i]?.val) ?? 0;
        this.lstCmpObjects.get(sdData.label)!.cmp[i].lastData = (sdData.values[i]?.lastVal) ?? 0;
      }
      // sdData.values.forEach((v, i) => {
      //   this.lstCmpObjects.get(sdData.label)!.cmp[i].data = v.val;
      //   this.lstCmpObjects.get(sdData.label)!.cmp[i].lastData = v.lastVal;
      // });
      this.lstCmpObjects.get(sdData.label)!.total = sdData.total;
      this.lstCmpObjects.get(sdData.label)!.percentage = sdData.percentage;
    })
    this.dashboardTypeSub = this.dashboardSVC.getDashboardType().subscribe((val: any) => {
      this.dashboardType = (this.production) ? 'Production' : val;
      this.update();
    });
    this.update();
  }

  /**
   * Performs necessary cleanup tasks when the component is about to be destroyed.
   * It unsubscribes from the `darkModeSub` subscription to prevent memory leaks.
   * This method is called before the component is removed from the DOM.
   */
  ngOnDestroy() {
    this.dashboardTypeSub.unsubscribe();
  }
 
  update() { 
    this.lstCmpSelected = this.lstCmpObjects.get(this.dashboardType)!;
    
    this.calculateBarHeight()
    // switch (this.dashboardType) {
    //   case 'Production':
    //     if(!this.productionData) return;
    //     this.lastCmptData[0] = this.productionData.sideUpperGraph.sideTotalProduced;
    //     this.lastCmptData[1] = this.productionData.sideUpperGraph.sideTotalSold;
    //     this.lastCmptData[2] = this.productionData.sideUpperGraph.sideTotalDisposal;
    //     this.completeData = this.productionData.sideUpperGraph.sideTotalProduced;
    //     this.percentage = Math.ceil((this.completeData/this.lastCmptData[0])*100);
    //     break;
    //   case 'Sales':
    //     if(!this.productionData) return;
    //     this.lastCmptData[0] = this.productionData.sideLowerGraph.sideTotalSoldMovano;
    //     this.lastCmptData[1] = this.productionData.sideLowerGraph.sideTotalSoldOther;
    //     this.lastCmptData[2] = this.productionData.sideLowerGraph.sideTotalReturnedOther; 
    //     this.lastCmptData[0] = this.lastCmptData[1] / 2;
    //     this.lastCmptData[2] = this.lastCmptData[1] / 4;
    //     this.completeData = this.lastCmptData[0] + this.lastCmptData[1];
    //     this.percentage = this.productionData.sideLowerGraph.salesPercentage;
    //     break;
    //   case 'Activity':
    //     if(!this.syncsData) return;
    //     this.lastCmptData[0] = this.syncsData.sideGraph.sideTotalSold;
    //     this.lastCmptData[1] = this.syncsData.sideGraph.sideTotalSleep;
    //     this.lastCmptData[2] = this.syncsData.sideGraph.sideTotalSync; 
    //     this.lastCmptData[0] = this.lastCmptData[1] / 2;
    //     this.lastCmptData[2] = this.lastCmptData[1] / 4;
    //     this.completeData = this.lastCmptData[0] + this.lastCmptData[1];
    //     this.percentage = this.syncsData.sideGraph.syncsPercentage;
    //     break;
    //   case 'Users':
    //     if(!this.productionData) return;
    //     this.lastCmptData[0] = this.productionData.sideLowerGraph.sideTotalSoldMovano;
    //     this.lastCmptData[1] = this.productionData.sideLowerGraph.sideTotalSoldOther;
    //     this.lastCmptData[2] = this.productionData.sideLowerGraph.sideTotalReturnedOther; 
    //     this.lastCmptData[0] = this.lastCmptData[1] / 2;
    //     this.lastCmptData[2] = this.lastCmptData[1] / 4;
    //     this.completeData = this.lastCmptData[0] + this.lastCmptData[1];
    //     this.percentage = this.productionData.sideLowerGraph.salesPercentage;
    //     break;
    //   case 'SpotChecks':
    //     if(!this.productionData) return;
    //     this.lastCmptData[0] = this.productionData.sideLowerGraph.sideTotalSoldMovano;
    //     this.lastCmptData[1] = this.productionData.sideLowerGraph.sideTotalSoldOther;
    //     this.lastCmptData[2] = this.productionData.sideLowerGraph.sideTotalReturnedOther; 
    //     this.lastCmptData[0] = this.lastCmptData[1] / 2;
    //     this.lastCmptData[2] = this.lastCmptData[1] / 4;
    //     this.completeData = this.lastCmptData[0] + this.lastCmptData[1];
    //     this.percentage = this.productionData.sideLowerGraph.salesPercentage;
    //     break;
    // }
  }
 
/** 
 * Calculate bar height as a percentage of total data
 */
calculateBarHeight(){
  // Percentage of bar 0 (always 100% of the height)
  this.percentageBar0 = (this.lstCmpSelected.cmp[0].data! / this.lstCmpSelected.total!) * 100;
  
  // Percentage of bar 1 (used to calculate divided bar heights)
  this.percentageBar1ToShow = (this.lstCmpSelected.cmp[1].data! / this.lstCmpSelected.total!) * 100;

  // Percentage of bar 2 (needed for the height calculation of bar 1 in divided bars)
  this.percentageBar2ToShow = (this.lstCmpSelected.cmp[2].data! / this.lstCmpSelected.total!) * 100;

  /*
  Non-divided bars:     | Divided bars:     |
   _                    |                   |
  |0|}100%              |          _        |
  |1|}100% }75%         |         |0|}33%   |
  |1|}100% }75%         |         |1| }66%  |
  |2|}100% }75% }25%    | 33%{|2| |1| }66%  |

  In the case of divided bars, for example, bar 1 needs to be 66% of 145px.
  Therefore, we need to capture this transform value and calculate accordingly.
  */

  // Calculate the percentage height of bar 2, considering divided bars
  this.percentageBar2 = this.dividedBars.includes(this.dashboardType) ? 
    this.percentageBar2ToShow * (100 / (this.percentageBar1ToShow + this.percentageBar0)) :
    this.percentageBar2ToShow;

  // Calculate the percentage height of bar 1, considering divided bars
  this.percentageBar1 = this.dividedBars.includes(this.dashboardType) ? 
    this.percentageBar1ToShow * (100 / (this.percentageBar1ToShow + this.percentageBar0)) :
    this.percentageBar1ToShow + this.percentageBar2ToShow;
}

  getTodayDate() {
    // months abbreviated in english
    const months_abb = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    let now = new Date();
    let day = now.getDate();
    let month = now.getMonth() ;
    let year = now.getFullYear().toString().substring(2);
    return day + ' ' + months_abb[month] + ' \'' + year;
  }


  // purgeFakeData() {
  //   if (this.purgeData) {
  //     if (this.productionData) {
  //       //RINGS PRODUCTION
  //       this.totalPro_Pro = 0;
  //       this.totalDispo_Pro = 0;
  //       this.total_Pro = 0;
  //       this.totalPro = abbrNum(this.total_Pro, NUM_DECIMALS, USE_SPACES, USE_CAPITALS);
  //       this.proPercentage = 0;
  //       //RINGS PRODUCTION

  //       //RINGS SOLD
  //       this.totalReMovano_Sales = 0;
  //       this.totalReOther_Sales = 0;
  //       this.totalSoldMovano_Sales = this.productionData.sideLowerGraph.sideTotalSoldMovano + this.productionData.sideLowerGraph.sideTotalSoldOther;
  //       this.totalSoldOther_Sales = 0;
  //       this.total_Sales = this.totalReMovano_Sales + this.totalSoldMovano_Sales + this.totalSoldOther_Sales;
  //       this.totalSales = abbrNum(this.total_Sales, NUM_DECIMALS, USE_SPACES, USE_CAPITALS);
  //       //RINGS SOLD
  //     }
  //     if(this.syncsData){
  //       this.totalSold_Syncs = this.syncsData.sideGraph.sideTotalSold;
  //       this.totalSleep_Syncs = this.syncsData.sideGraph.sideTotalSleep;
  //       this.totalSync_Syncs = this.syncsData.sideGraph.sideTotalSync;
  //       this.total_Syncs = this.totalSold_Syncs + this.totalSleep_Syncs + this.totalSync_Syncs;
  //       this.totalSyncs = abbrNum(this.total_Syncs, NUM_DECIMALS, USE_SPACES, USE_CAPITALS);
  //     }
  //     if(this.incidentsData){
  //       this.totalRepng_Incidents = 0;
  //       this.totalRep_Incidents = 0;
  //       this.totalInc_Incidents = 0;
  //       this.total_Incidents = this.totalRepng_Incidents + this.totalRep_Incidents + this.totalInc_Incidents;
  //       this.totaIncidents = abbrNum(this.total_Incidents, NUM_DECIMALS, USE_SPACES, USE_CAPITALS);
  //       this.incidentsPercentage = 0;
  //     }
  //   }
  // }

}

