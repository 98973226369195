import { Component, EventEmitter, HostListener, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { deviceTable, userTable } from "../shared/movano-interfaces";
import { Table } from "primeng/table";
import { Router } from "@angular/router";
import { MovanoService } from "../movano.service";
import { MatDialog } from "@angular/material/dialog";
import { UserPanelComponent } from "./panel/user-panel.component";
import { UserDeviceTableComponent } from "./deviceTable/user-device-table.component";
import * as lit from "../shared/movano-literals";
import { Subscription, last, lastValueFrom } from "rxjs";
import { DarkModeService } from "../dark-mode.service";
import { UsersBugsComponent } from "./users-bugs/users-bugs.component";
import { UserTicketsComponent } from "./user-tickets/user-tickets.component";
import { UsersNotificationsListComponent } from "./users-notifications-list/users-notifications-list.component";

@Component({
  selector: "app-users",
  templateUrl: "./users.component.html",
  styleUrls: ["./users.component.scss"],
})
export class UsersComponent implements OnInit, OnDestroy {
  protected LITERALS = lit;
  protected selectedDate: number = 1;
  protected version = 1.12;
  protected innerWidth: any;
  protected darkMode: boolean = false;
  private darkModeSub: Subscription = new Subscription();
  protected userDisplay: boolean = false;
  protected menuDisplay: boolean = false;
  protected filterDisplay: boolean = false;
  protected searchDisplay: boolean = false;
  protected loadingUserList: boolean = true;
  protected loadingUserDeviceList: boolean = true;

  protected REFRESH_TIME: number = 12000;

  protected pauseAll: boolean = false;

  protected pageSelected: number = 0;
  protected firstPage: number = 0;
  protected lastPage: number = 0;
  protected rows: number = 50;

  protected actualPage: number = 1;
  protected totalPages: number = 0;
  protected lastTimeFrameUsed: string | undefined = '';
  protected maxPagesToShow = 5;

  protected customersTotal: number = 0;
  protected selectedIndex: number = -1;

  protected valueSearch: string = '';

  protected filter_Male: boolean = false;
  protected filter_Female: boolean = false;
  protected filter_Country?: string;
  protected filter_registerDate: Date[] = [];
  protected filter_lastSync: Date[] = [];
  protected today?: Date;
  protected type: string = "users";
  items?: any[];
  selectedItem: any;
  suggestions: any[] = [];
  protected users: userTable[] = [];
  devices: deviceTable[] = [];
  filteredUsers: userTable[] = [];
  isFiltered: boolean = false;
  protected timeZones: string[] = [];
  protected loadingData: boolean = false;
  protected daySelected?: Date;
  protected rangeSelected: Date[] = [];
  protected actualTimeZone: string = "";
  protected timeOptions: string[] = ["", "week", "month", ""];
  protected lastselectedDate: number = 0;
  protected dataAvailable: boolean = false;
  @Output() getInfo = new EventEmitter<[number, boolean, Date | Date[], string, number]>();
  @ViewChild("userTable") userTable!: Table;

  protected isEmail: boolean = false;

  constructor(
    protected dialog: MatDialog,
    protected router: Router,
    protected movanoService: MovanoService,
    private darkModeSvc: DarkModeService,
  ) {
    this.today = new Date();
  }

  async ngOnInit() {
    this.darkModeSub = this.darkModeSvc.getVariable().subscribe((val: any) => {
      this.darkMode = val;
    });
    await this.getUserDetails();
    this.emitGetInfo();
    //await this.getUserList();
    let tableElement = document.getElementsByClassName("p-datatable-wrapper")[0];
    let scrollHelper = document.getElementById("mobileScroll");
    let scrollHelperLeft = document.getElementById("mobileScroll_Left");
    if (tableElement && scrollHelper) {
      tableElement.addEventListener("scroll", function () {
        if (tableElement.scrollLeft > tableElement.scrollWidth - tableElement.clientWidth - 15) {
          scrollHelper!.style.display = "none";
        } else {
          scrollHelper!.style.display = "block";
        }

        if (tableElement.scrollLeft > 15) {
          scrollHelperLeft!.style.display = "block";
        } else {
          scrollHelperLeft!.style.display = "none";
        }
      });
    } else {
      // console.log(tableElement);
      // console.log(scrollHelper);
    }
  }

  ngOnDestroy() {
    this.darkModeSub.unsubscribe();
  }

  onResize() {
    const checkboxDivs = document.querySelectorAll(".movano_checkBox") as NodeListOf<HTMLElement>;
    const checkbox = document.querySelectorAll("mat-checkbox") as NodeListOf<HTMLElement>;
    const windowWidth = window.innerWidth;
    const checkboxSize = Math.max(windowWidth / 1440, 1) * 0.85;

    checkboxDivs.forEach((cb: HTMLElement) => {
      cb.style.width = `calc(50px * ${checkboxSize})`;
    });

    checkbox.forEach((cb: HTMLElement) => {
      cb.style.transform = `scale(${checkboxSize})`;
    });
  }

  async search(event: any, _row: string, _table: Table) {
    _table.reset();
    const isEmail = this.isValidEmail(event.target.value);

    if (isEmail) {
      let searchTimeout
      this.valueSearch = event.target.value;
      if (searchTimeout) {
        clearTimeout(searchTimeout);
      }
      const customerUUID = await lastValueFrom(this.movanoService.getUserUuidByEmail(event.target.value));
      const userData = await lastValueFrom(this.movanoService.getCustomerByUUID(customerUUID.uuid));
      this.loadData(userData);
    } else {
      let searchTimeout
      this.valueSearch = event.target.value;
      if (searchTimeout) {
        clearTimeout(searchTimeout);
      }

      if (this.valueSearch.length >= 3) {
            searchTimeout = setTimeout(async () => {
            const userData = await lastValueFrom(this.movanoService.getUsersBySearch(this.valueSearch));
            console.log('search simple',userData);
            this.loadData(userData);
        }, 1000);
    }
    }
    this.isFiltered = false;
  }

  clearSearch(inputElement: HTMLInputElement, _table: Table) {
  this.loadData()
  this.valueSearch = '';
  }

  changeTheme() {
    // this.darkMode = !this.darkMode;
    // document.body.style.backgroundColor = this.darkMode ? '#0c1d35' : '#f3f9fe';
    this.darkModeSvc.setVariable(!this.darkMode);
  }

  goTo(_place: string) {
    this.router.navigate([_place]);
  }

  //Get user details is now only using to know if the user is logged
  async getUserDetails() {
    const user = await this.movanoService.getUser();
    if (!user) {
      this.router.navigate(["login"]);
    }
  }

  onFilter(event: any) {
    this.filteredUsers = event.filteredValue;

    this.isFiltered = this.filteredUsers.length !== this.users.length;
  }

  onFilterStatusChange(isFiltered: boolean) {
    this.isFiltered = isFiltered;
  }

  onDateFilterApplied() {
    // Verifica si hay datos filtrados y actualiza filteredUsers
    if (this.userTable.filteredValue) {
      this.filteredUsers = this.userTable.filteredValue;
    } else {
      this.filteredUsers = this.users; // users es el conjunto completo de datos.
    }
  }

  isValidEmail(email: string) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  updatePaginator(data: number[]) {
    this.rows = data[0];
    this.pageSelected = data[1];
  }

  protected openUserPanel(_userId: string) {
    this.dialog.open(UserPanelComponent, {
      width: '95vw',
      height: '95vh',
      data: { user: _userId }
    });  }

  protected openUserDevicesPanel(_userId: string) {
    this.dialog.open(UserDeviceTableComponent, { data: { user: _userId } });
  }

  protected openBugsPanel(_userId: string) {
    this.dialog.open(UsersBugsComponent, { data: { user: _userId } });
  }

  protected openTicketsPanel(_userId: string) {
    this.dialog.open(UserTicketsComponent, { data: { user: _userId } });
  }

  protected openNtfPanel(_userId: string, notifications: number) {
    if (notifications > 0) {
      this.dialog.open(UsersNotificationsListComponent, { data: { user: _userId } });
      console.log(_userId);
    }
  }

  emitGetInfo(calendarData?: [any, boolean, Date | Date[], string, any]) {
    this.getInfo.emit(calendarData);
    console.log("Parent component emitting:", calendarData);
    this.loadData('',calendarData);
  }

  async loadData(userData?: any, calendarData?: [any, boolean, Date | Date[], string, any]) {
    this.loadingUserList = true;

    let customersTimeFrameUntil: string | undefined = this.lastTimeFrameUsed || '';

    if (calendarData) {
      if (this.selectedDate === 1) {
        customersTimeFrameUntil = 'last7Days';
      } else if (this.selectedDate === 2) {
        customersTimeFrameUntil = 'lastMonth';
      } else if (this.selectedDate === 6) {
        customersTimeFrameUntil = 'lastWeek';
      } else if (this.selectedDate === 4) {
        customersTimeFrameUntil = 'custom';
      } else {
        customersTimeFrameUntil = '';
      }

      if (this.lastTimeFrameUsed !== customersTimeFrameUntil) {
        this.actualPage = 1;
      }

      this.lastTimeFrameUsed = customersTimeFrameUntil;
    }

    try {
      const response = await lastValueFrom(this.movanoService.getCustomers(this.rows, this.actualPage, customersTimeFrameUntil));

      this.totalPages = response.pageCount;
      this.users = response.data;
      this.customersTotal = response.customersCount
      this.loadingUserList = false;
      this.filteredUsers = this.users;
      this.dataAvailable = true;
      if (this.valueSearch) {
        console.log('Searching')
        this.totalPages = userData.pageCount;
        this.users = userData.data;
        this.filteredUsers = this.users;
        this.dataAvailable = true;
      }
    } catch (error) {
      console.log(error);
      this.dataAvailable = false;
    } finally {
      this.loadingData = false;
    }
  }


  async getUserDeviceList(_userId: string) {
    this.loadingUserDeviceList = true;

    const userDeviceData = await lastValueFrom(this.movanoService.getUserDevices(_userId));
    this.devices = userDeviceData;
    this.loadingUserDeviceList = false;
  }

  getDisplayedPages(): number[] {
    const pages: number[] = [];

    let startPage = Math.max(1, this.actualPage - Math.floor(this.maxPagesToShow / 2));
    let endPage = Math.min(this.totalPages, startPage + this.maxPagesToShow - 1);

    if (endPage - startPage + 1 < this.maxPagesToShow) {
      startPage = Math.max(1, endPage - this.maxPagesToShow + 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }

    return pages;
  }

  nextPage() {
    if (this.actualPage < this.totalPages) {
      this.actualPage++;
      this.loadData();
    }
  }

  selectPage(page: number) {
    this.actualPage = page;
    this.loadData();
  }

  previousPage() {
    if (this.actualPage > 1) {
      this.actualPage--;
      this.loadData();
    }
  }

  isFirstPageInRange(): boolean {
    return this.getDisplayedPages().includes(1);
  }

  isLastPageInRange(): boolean {
    return this.getDisplayedPages().includes(this.totalPages);
  }

  shouldShowLeftEllipsis(): boolean {
    return this.actualPage > Math.ceil(this.maxPagesToShow / 2) + 1;
  }

  shouldShowRightEllipsis(): boolean {
    return this.actualPage < this.totalPages - Math.floor(this.maxPagesToShow / 2);
  }

  exportCSV() {
    if (this.users && this.users.length > 0) {
      const csvData = this.convertToCSV(this.users);
      const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "users.csv");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      console.error("No data to export");
    }
  }

  convertToCSV(objArray: any[]): string {
    const array = typeof objArray !== "object" ? JSON.parse(objArray) : objArray;
    let str = "";
    let row = "";

    for (const index in array[0]) {
      if (array[0].hasOwnProperty(index)) {
        // Now convert each value to a string
        const header = index + ",";
        row += header;
      }
    }

    row = row.slice(0, -1);
    str += row + "\r\n";

    for (let i = 0; i < array.length; i++) {
      let line = "";
      for (const index in array[i]) {
        if (array[i].hasOwnProperty(index)) {
          if (line !== "") line += ",";
          line += array[i][index];
        }
      }

      str += line + "\r\n";
    }
    return str;
  }
}
