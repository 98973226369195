<div class="movanoTotalInfo"
[ngClass]="{'movanoTotalInfo_Dark' : darkMode}">
  <div class="movanoTotalInfo_TxtBlock">
    <span class="movanoTotalInfo_TxtBlock_Tittle--Main">{{ usersStatsToday.data.total_users.total }}</span>
    <div class="movanoTotalInfo_TxtBlock_Txt">
      <span>total</span>
      <span>users</span>
    </div>
  </div>
</div>
