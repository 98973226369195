import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import * as lit from '../shared/movano-literals';
import { MovanoService } from '../movano.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { RecoverPasswordComponent } from '../login/recover-password/recover-password.component';
import { Subscription } from 'rxjs';
import { DarkModeService } from '../dark-mode.service';
import { CognitoUserCreateEditComponent } from '../cognito-user/create-edit/cognito-user-create-edit.component';

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss']
})
export class UserMenuComponent implements OnInit, OnDestroy {
  protected LITERALS = lit;
  protected darkMode: boolean = false;
  private darkModeSub: Subscription = new Subscription();

  @Output() closeHimself = new EventEmitter<boolean>();

  constructor(
    protected dialog: MatDialog,
    protected router: Router,
    protected movanoService: MovanoService,
    private darkModeSvc: DarkModeService) { }


  ngOnInit() {
    this.darkModeSub = this.darkModeSvc.getVariable().subscribe((val: any) => {
      this.darkMode = val;
    });
  }

  ngOnDestroy() {
    this.darkModeSub.unsubscribe();
  }
  /**
   * Logs out the user using Cognito authentication.
   * Navigates to the home page after successful logout.
   */
  async logOutWithCognito() {
    await this.movanoService.LogOut();
    this.router.navigate(['']);
  }

  /**
   * Initiates the password recovery process.
   * Hides the user display and opens the recover password component in a dialog.
   */
  recoverPass() {
    this.closeHimself.emit();
    this.dialog.open(RecoverPasswordComponent);
  }

  /**
   * Initiates the new cognito user process.
   * Hides the user display and opens the new cognito user component in a dialog.
   */
  newCognitoUser() {
    this.closeHimself.emit();
    this.dialog.open(CognitoUserCreateEditComponent);
  }

  changeTheme() {
    this.darkModeSvc.setVariable(!this.darkMode);
    //this.darkMode = !this.darkMode;
  }
  goTo(place: string) {
    this.router.navigate([place]);
  }
}
