import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-stats-widget-two',
  templateUrl: './stats-widget-two.component.html',
  styleUrls: ['./stats-widget-two.component.scss']
})
export class StatsWidgetTwoComponent {
  @Input() maxValue: string = '';
  @Input() minValue: string = '';
  @Input() dynamicText: string = '';
}
