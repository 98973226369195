import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as lit from '../../shared/movano-literals';
import { MovanoService } from 'src/app/movano.service';
import { User } from 'src/app/shared/movano-interfaces';

@Component({
  selector: 'app-cognito-user-create-edit',
  templateUrl: './cognito-user-create-edit.component.html',
  styleUrls: ['./cognito-user-create-edit.component.scss']
})
export class CognitoUserCreateEditComponent {

  protected userRoles : string[] = ['Admin', 'Org Admin', 'Operations', 'Customer Service', 'Data Science'];
  protected LITERALS = lit;
  errorMsg: any;
  recoverFormGroup: FormGroup = this.formBuilder.group({
    userId: ['', Validators.compose([Validators.required, Validators.min(8)])],
    userName: ['', Validators.compose([Validators.required])],
    userFullName: ['', Validators.compose([Validators.required])],
    role: ['', Validators.compose([Validators.required])],
    email: ['', Validators.compose([Validators.required, Validators.email])],
  });

  constructor(
    private formBuilder: FormBuilder,
    private movanoSvc: MovanoService) { }

  newUser() {
    this.errorMsg = undefined;
    if(this.recoverFormGroup.controls['email'].hasError('email')){
      this.errorMsg = 'Please enter a valid email'
      return;
    }
    if(this.recoverFormGroup.controls['email'].hasError('required')){
      this.errorMsg = 'Email is required '
      return;
    }
    if(this.recoverFormGroup.controls['userId'].hasError('required')){
      this.errorMsg = 'User ID is required '
      return;
    }
    if(this.recoverFormGroup.controls['role'].hasError('required')){
      this.errorMsg = 'Role is required '
      return;
    }
    if(this.recoverFormGroup.controls['userName'].hasError('required')){
      this.errorMsg = 'User name is required '
      return;
    }
    const user : User = {
        user : this.recoverFormGroup.value.email,
        email : this.recoverFormGroup.value.email,
        password : this.recoverFormGroup.value.password,
    } as User;
    //this.movanoSvc.newUser(user);
  }
}
