import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
enum movanoTypes {
  sales,
  syncs,
  incidents
}
@Component({
  selector: 'app-movano-map',
  templateUrl: './movano-map.component.html',
  styleUrls: ['./movano-map.component.scss']
})

export class MovanoMapComponent implements OnChanges {

  @Input() infotype: number;
  @Input() darkMode: boolean;
  @Input() mapData : any;
  // @Input() statesKey?: string[];
  // @Input() unitSoldStates !: number[];
  @Output() changeComponent = new EventEmitter<boolean>();
  @Output() pauseMap = new EventEmitter<boolean>();
  @Input() paused : boolean = false;
  private purgeData: boolean = false;
  //infotype : movanoTypes;
  mapTittle: string[] = ['Sales', 'Activity', 'Incidents'];
  bubbleColors: string[] = ['#0B84FF', '#00B377', '#E31482'];
  bubbleColorsHard: string[] = ['#004687', '#00593B', '#66006A'];
  statesEEUUobj : any;
  stateNamesEEUU = new Map<string, string>([
    ["AL", "Alabama"],
    ["AK", "Alaska"],
    ["AS", "American Samoa"],
    ["AZ", "Arizona"],
    ["AR", "Arkansas"],
    ["CA", "California"],
    ["CO", "Colorado"],
    ["CT", "Connecticut"],
    ["DE", "Delaware"],
    ["DC", "District Of Columbia"],
    ["FM", "Federated States Of Micronesia"],
    ["FL", "Florida"],
    ["GA", "Georgia"],
    ["GU", "Guam"],
    ["HI", "Hawaii"],
    ["ID", "Idaho"],
    ["IL", "Illinois"],
    ["IN", "Indiana"],
    ["IA", "Iowa"],
    ["KS", "Kansas"],
    ["KY", "Kentucky"],
    ["LA", "Louisiana"],
    ["ME", "Maine"],
    ["MH", "Marshall Islands"],
    ["MD", "Maryland"],
    ["MA", "Massachusetts"],
    ["MI", "Michigan"],
    ["MN", "Minnesota"],
    ["MS", "Mississippi"],
    ["MO", "Missouri"],
    ["MT", "Montana"],
    ["NE", "Nebraska"],
    ["NV", "Nevada"],
    ["NH", "New Hampshire"],
    ["NJ", "New Jersey"],
    ["NM", "New Mexico"],
    ["NY", "New York"],
    ["NC", "North Carolina"],
    ["ND", "North Dakota"],
    ["MP", "Northern Mariana Islands"],
    ["OH", "Ohio"],
    ["OK", "Oklahoma"],
    ["OR", "Oregon"],
    ["PW", "Palau"],
    ["PA", "Pennsylvania"],
    ["PR", "Puerto Rico"],
    ["RI", "Rhode Island"],
    ["SC", "South Carolina"],
    ["SD", "South Dakota"],
    ["TN", "Tennessee"],
    ["TX", "Texas"],
    ["UT", "Utah"],
    ["VT", "Vermont"],
    ["VI", "Virgin Islands"],
    ["VA", "Virginia"],
    ["WA", "Washington"],
    ["WV", "West Virginia"],
    ["WI", "Wisconsin"],
    ["WY", "Wyoming"]
  ]);

  stateValueEEUU = new Map<string, number>([
    ["AL", 0],
    ["AK", 0],
    ["AS", 0],
    ["AZ", 0],
    ["AR", 0],
    ["CA", 0],
    ["CO", 0],
    ["CT", 0],
    ["DE", 0],
    ["DC", 0],
    ["FM", 0],
    ["FM", 0],
    ["FL", 0],
    ["GA", 0],
    ["GU", 0],
    ["HI", 0],
    ["ID", 0],
    ["IL", 0],
    ["IN", 0],
    ["IA", 0],
    ["KS", 0],
    ["KY", 0],
    ["LA", 0],
    ["ME", 0],
    ["MH", 0],
    ["MD", 0],
    ["MA", 0],
    ["MI", 0],
    ["MN", 0],
    ["MS", 0],
    ["MO", 0],
    ["MT", 0],
    ["NE", 0],
    ["NV", 0],
    ["NH", 0],
    ["NJ", 0],
    ["NM", 0],
    ["NY", 0],
    ["NC", 0],
    ["ND", 0],
    ["MP", 0],
    ["OH", 0],
    ["OK", 0],
    ["OR", 0],
    ["PW", 0],
    ["PA", 0],
    ["PR", 0],
    ["RI", 0],
    ["SC", 0],
    ["SD", 0],
    ["TN", 0],
    ["TX", 0],
    ["UT", 0],
    ["VT", 0],
    ["VI", 0],
    ["VA", 0],
    ["WA", 0],
    ["WV", 0],
    ["WI", 0],
    ["WY", 0]
  ]);
  arrayEEUU = [
    0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0
  ];
  constructor() {
    //this.mapType = 0;
    this.infotype = 0;
    this.darkMode = true;
  }

  ngOnChanges(changes: SimpleChanges) {
//    console.log("map: ", this.infotype);
    if (this.mapData) {
      //this.purgeFakeDate();
      this.statesEEUUobj = this.mapData.reduce(function(map : any, obj : any) {
        map[obj.state] = obj.value;
        
        return map
      }, {});
//      console.log(this.statesEEUUobj);
//      console.log('////////////////////////////////');
//      console.log(this.statesEEUUobj['WY']);
    }
  }

  bigBall(state : string, big : boolean) : number{
    //const stateValue = this.stateValueEEUU!.get(state) || 0;
    //console.log((big ? 40 : 30) * (stateValue/this.unitSoldStates[0]),  this.stateValueEEUU!.get(state));
    //console.log(((big ? 23 : 18) * (this.statesEEUUobj[state]/this.mapData[0].value)) );
    if(!this.mapData[0]?.value) return 0;
    let radiusSize : number = ((big ? 23 : 18) * (this.statesEEUUobj[state]/this.mapData[0].value)) || 0;
    return radiusSize;
  }

  passToChart(next : boolean) {
    this.changeComponent.emit(next);
  }
  pauseChart() {
    this.paused = !this.paused;
    this.pauseMap.emit();
  }

  purgeFakeDate() {
    if (this.purgeData) {
      this.mapData.forEach((item : any) => { item.value = 0; });
    }
  }
}

