import { Component, Input, SimpleChanges } from '@angular/core';
import { abbrNum } from '../../shared/utils'
import { lastValueFrom } from 'rxjs';
import { TODAY, LAST_WEEK, LAST_SEVEN_DAYS, LAST_MONTH, YEAR_TO_DATE } from '../../../constants';
import { MovanoService } from '../../movano.service';
import { SharedService } from '../../stats/shared.service';

@Component({
  selector: 'app-movano-tota-info',
  templateUrl: './movano-tota-info.component.html',
  styleUrls: ['./movano-tota-info.component.scss']
})
export class MovanoTotaInfoComponent {

  @Input() darkMode: boolean;
  @Input() produced: number = 0;
  @Input() sold: number = 0;
  totalUsers: string = '0';
  activeUsers: string = '0';
  newUsers: string = '0';

  constructor(private movanoSvc: MovanoService,  private sharedService: SharedService) {
    this.darkMode = true;
  }

  async ngOnInit() {
    this.sharedService.calendarObservable$.subscribe(async data => {
      await this.setValues(data);
    })
  }

  setValues = async (data: string) => {
    const dataOfSelectedDate = await this.getDashboardChartDataByDate(data);

    this.totalUsers = dataOfSelectedDate.totalUsers;
    this.activeUsers = dataOfSelectedDate.activeUsers;
    this.newUsers = dataOfSelectedDate.newUsers;
  }


  getDashboardChartDataByDate = async (date: string) => {
    if (date === TODAY) {
      const response = await lastValueFrom(this.movanoSvc.getStatsSummarySalesToday());
      return response.data.header;
    }

    if (date === LAST_WEEK) {
      const response = await lastValueFrom(this.movanoSvc.getStatsSummarySalesLastWeek());
      return response.data.header;
    }

    if (date === LAST_SEVEN_DAYS) {
      const response = await lastValueFrom(this.movanoSvc.getStatsSummarySalesLast7Days());
      return response.data.header;
    }

    if (date === LAST_MONTH) {
      const response = await lastValueFrom(this.movanoSvc.getStatsSummarySalesLastMonth());
      return response.data.header;
    }

    if (date === YEAR_TO_DATE) {
      const response = await lastValueFrom(this.movanoSvc.getStatsSummarySalesYearToDate());
      return response.data.header;
    }

    const startDate = this.convertDateToISO(date.split('-')[0]);
    const endDate = this.convertDateToISO(date.split('-')[1]);

    const response = await lastValueFrom(this.movanoSvc.getStatsSummarySalesCustomDay(startDate, endDate));

    return response.data.header;
  }

  convertDateToISO = (dateString: string) => {
    const date = new Date(dateString);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
  }
}
