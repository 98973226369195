<div class="ntf-list" [ngClass]="{'movanoDark': darkMode}">
    <div class="movanoTableBox">
 <app-users-ntf-list-filter [table]="ntfTable" [jobId]="jobId" [name]="name" (ntfListsChanged)="onNtfListsChanged($event)" (loadingDataChange)="onLoadingDataChange($event)" (dataAvailableChange)="onDataAvailable($event)" [userData]="user" ></app-users-ntf-list-filter> 
      <p-table [ngStyle]="{display: (!loadingData) ? 'block' : 'none'}" id="ntfListTable" #ntfTable dataKey="application_id" class="movano-Table"
        [globalFilterFields]="['name','user_id', 'job_name', 'message_type', 'sent_customer', 'sent_date']" [value]="ntfLists" [rows]="rows" [first]="pageSelected" [ngClass]="{'hidden': !dataAvailable}" (onFilter)="onFilter($event)">
        <ng-template  pTemplate="header">
          <tr [ngClass]="'movanoTable_Header'">
            <th pSortableColumn="name" >{{LITERALS.NTF_LIST.TABLE_NAME}}
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path id="name-icon" d="M5.25 -2.29485e-07L5.25 9.1275L1.0575 4.935L-2.62268e-07 6L6 12L12 6L10.9425 4.9425L6.75 9.1275L6.75 -2.95052e-07L5.25 -2.29485e-07Z" fill="#C4D9EE"/>
                </svg></th>
            <th pSortableColumn="type" >{{LITERALS.NTF_LIST.TABLE_JOB_NAME}}
              <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path id="type-icon" d="M5.25 -2.29485e-07L5.25 9.1275L1.0575 4.935L-2.62268e-07 6L6 12L12 6L10.9425 4.9425L6.75 9.1275L6.75 -2.95052e-07L5.25 -2.29485e-07Z" fill="#C4D9EE"/>
                </svg></th>
            <th pSortableColumn="message_type">{{LITERALS.NTF_LIST.TABLE_MESSAGE_TYPE}}
              <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path id="message_type-icon" d="M5.25 -2.29485e-07L5.25 9.1275L1.0575 4.935L-2.62268e-07 6L6 12L12 6L10.9425 4.9425L6.75 9.1275L6.75 -2.95052e-07L5.25 -2.29485e-07Z" fill="#C4D9EE"/>
                </svg></th>
            <th pSortableColumn="sent_customer" >{{LITERALS.NTF_LIST.TABLE_SENT_CUSTOMER}}
              <svg  width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path id="sent_customer-icon" d="M5.25 -2.29485e-07L5.25 9.1275L1.0575 4.935L-2.62268e-07 6L6 12L12 6L10.9425 4.9425L6.75 9.1275L6.75 -2.95052e-07L5.25 -2.29485e-07Z" fill="#C4D9EE"/>
                </svg></th>
            <th pSortableColumn="last_modified">{{LITERALS.NTF_LIST.TABLE_SEND_DATE}}
              <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path id="last_modified-icon" d="M5.25 -2.29485e-07L5.25 9.1275L1.0575 4.935L-2.62268e-07 6L6 12L12 6L10.9425 4.9425L6.75 9.1275L6.75 -2.95052e-07L5.25 -2.29485e-07Z" fill="#C4D9EE"/>
                </svg></th>
            <th pSortableColumn="status">{{LITERALS.NTF_LIST.TABLE_INFO}}
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-notification let-i="rowIndex">
          <tr [ngStyle]="{
            'background' : darkMode ?
            i%2 == 0 ? '#05294d' : '#05305B' :
            i%2 == 0 ? 'rgba(5, 66, 128, 0.05)' : 'white'
          }" [ngClass]="'movanoTable_Obj'">
            <td style="max-width: max-content !important;">
              {{notification.name}} 
            </td>
            <td>{{notification.job_name}}</td>
            <td>{{notification.message_type}}</td>
            <td [style.color]="getNotificationStatus(notification.sent_customer).color">
              {{ getNotificationStatus(notification.sent_customer).text }}
            </td>
            <td>{{ notification.sent_date ? 
              (notification.sent_date | date:'MMM d, y') + ' / ' + 
              (notification.sent_date | date:'HH:mm ') 
            : '' 
          }}</td>
            <td class="movanoTable_click" (click)="openUserPanel(notification)" >
              <svg width="max(1vw,14px)" height="max(1vw,14px)" viewBox="0 0 14 14" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <g id="remove_red_eye" clip-path="url(#clip0_1510_2444)">
                  <path id="Vector"
                    d="M7.00004 3.79167C9.21087 3.79167 11.1825 5.03417 12.145 7C11.1825 8.96583 9.21671 10.2083 7.00004 10.2083C4.78337 10.2083 2.81754 8.96583 1.85504 7C2.81754 5.03417 4.78921 3.79167 7.00004 3.79167ZM7.00004 2.625C4.08337 2.625 1.59254 4.43917 0.583374 7C1.59254 9.56083 4.08337 11.375 7.00004 11.375C9.91671 11.375 12.4075 9.56083 13.4167 7C12.4075 4.43917 9.91671 2.625 7.00004 2.625ZM7.00004 5.54167C7.80504 5.54167 8.45837 6.195 8.45837 7C8.45837 7.805 7.80504 8.45833 7.00004 8.45833C6.19504 8.45833 5.54171 7.805 5.54171 7C5.54171 6.195 6.19504 5.54167 7.00004 5.54167ZM7.00004 4.375C5.55337 4.375 4.37504 5.55333 4.37504 7C4.37504 8.44667 5.55337 9.625 7.00004 9.625C8.44671 9.625 9.62504 8.44667 9.62504 7C9.62504 5.55333 8.44671 4.375 7.00004 4.375Z"
                    [ngStyle]="{fill : darkMode ? 'white' : '#054280'}" />
                </g>
                <defs>
                  <clipPath id="clip0_1510_2444">
                    <rect width="14" height="14" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </td>
          </tr>
          
        </ng-template>
      </p-table>
  
   
      <div *ngIf="!dataAvailable" class="ntf-list_NoData">
        <span>NO DATA AVAILABLE</span>
      </div>
  
  
      <div id="mobileScroll" class="movanoTableBox_mobileShadow"></div>
      <div id="mobileScroll_Left" class="movanoTableBox_mobileShadow movanoTableBox_mobileShadow_Left"></div>
    </div>
  
    <div *ngIf="loadingData" style="position: absolute; top: 50%; left: 50%;">
      <p-progressSpinner styleClass="{{darkMode ? 'custom-spinner' : 'custom-spinner-blue'}}" strokeWidth="2"
        animationDuration="1.5s">
      </p-progressSpinner>
    </div>
  </div>
