<div class="movanoUserPanel" [ngClass]="{ movanoDark: darkMode }">
  <div class="movanoUserPanel_Header_Tittle">
    <span> {{ data.user }} devices </span>
    <svg (click)="closePanel()" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.25 1.8075L10.1925 0.75L6 4.9425L1.8075 0.75L0.75 1.8075L4.9425 6L0.75 10.1925L1.8075 11.25L6 7.0575L10.1925 11.25L11.25 10.1925L7.0575 6L11.25 1.8075Z"
        fill="#C4D9EE"
      />
    </svg>
  </div>
  <div *ngIf="!loadingUserDeviceList" class="movanoTableBox movanoDeviceTable">
    <p-table
      id="userDevicesTable"
      #userTable
      class="movano-Table"
      [value]="devices"
      [paginator]="false"
      [scrollable]="true"
      scrollHeight="75vh"
    >
      <ng-template pTemplate="header">
        <tr [ngClass]="'movanoTable_Header'">
          <th>{{ LITERALS.USERS.DEVICE_NAME }}</th>
          <th>{{ LITERALS.USERS.DEVICE_TYPE }}</th>
          <th>{{ LITERALS.USERS.DEVICE_SN }}</th>
          <th>{{ LITERALS.USERS.DEVICE_FIRMWARE }}</th>
          <th>{{ LITERALS.USERS.DEVICE_STATUS }}</th>
          <th>{{ LITERALS.USERS.DEVICE_LAST_CHARGE }}</th>
          <th>{{ LITERALS.USERS.DEVICE_BATTERY }}</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-device let-i="rowIndex">
        <tr
          [ngStyle]="{
            background: darkMode ? (i % 2 == 0 ? '#05294d' : '#05305B') : i % 2 == 0 ? '#f3f6f9' : 'white'
          }"
          [ngClass]="'movanoTable_Obj'"
        >
          <td>{{ device.device_name }}</td>
          <td>{{ device.device_type }}</td>
          <td>{{ device.device_serial }}</td>
          <td>{{ device.firmware }}</td>
          <td>
            <div class="movanoTable_Obj_Badge">{{ device.status }}</div>
          </td>
          <td>{{ device.last_charge | date : "dd/MM/yyyy" }}</td>
          <td
            [ngStyle]="{
              color:
                device.battery > 35 ? (device.battery > 70 ? batteryColors[2] : batteryColors[1]) : batteryColors[0]
            }"
          >
            {{ device.battery }}%
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>

  <div *ngIf="loadingUserDeviceList">
    <p-progressSpinner
      styleClass="{{ darkMode ? 'custom-spinner' : 'custom-spinner-blue' }}"
      strokeWidth="2"
      animationDuration="1.5s"
    >
    </p-progressSpinner>
  </div>
</div>
